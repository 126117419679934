import { clientV3 } from '../../../services/api'
import { hashKey } from '../../../services/order/generateHash'
import { signOrder } from '../../../services/order/sign'
import { Web3Type } from '../../../types/web3'
import { Token } from '../../token'
import { prepareExchangeOrder, prepareOrder } from './prepareOrder'

export const createOrder = async (
  web3: Web3Type,
  token: Token,
  price: Price,
  quantity: Quantity,
  placeBid?: boolean,
  isAuction?: boolean
): Promise<boolean> => {
  const maker = web3.account
  const order = prepareOrder(token, maker, null, price, quantity, web3.chainId, !!placeBid)
  if (order === null) {
    return false
  }
  try {
    const exchangeOffer = prepareExchangeOrder(order)
    const signature = await signOrder(web3, exchangeOffer)
    const hash = hashKey(exchangeOffer)

    await clientV3.exchange.create({
      ...order,
      signature,
      hash,
      isAuction: isAuction || false,
      network: token.collection.network
    })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
