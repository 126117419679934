import Web3 from 'web3'

import { AssetType, OrderExchange } from '../../domain/order/types'

const ASSET_TYPE = 'AssetType(bytes4 assetClass,bytes data)'

const typeHash = (message: string): string => {
  const web3 = new Web3()
  return web3.utils.keccak256(message)
}

const hashAssetType = (assetType: AssetType): string => {
  const web3 = new Web3()
  const { keccak256 } = web3.utils

  return keccak256(
    web3.eth.abi.encodeParameters(
      ['bytes32', 'bytes4', 'bytes32'],
      [typeHash(ASSET_TYPE), assetType.assetClass, keccak256(assetType.data)]
    )
  )
}

export const hashKey = (order: OrderExchange): OrderHash => {
  const web3 = new Web3()

  return web3.utils.keccak256(
    web3.eth.abi.encodeParameters(
      ['address', 'bytes32', 'bytes32', 'uint'],
      [
        order.maker,
        hashAssetType(order.makeAsset.assetType),
        hashAssetType(order.takeAsset.assetType),
        order.salt
      ]
    )
  ) as string
}
