import { useEffect, useState } from 'react'

import { SUPPORT_CHAIN_NAME } from '../../domain/network/contstant'
import { clientV3 } from '../../services/api'

export const useNFTCount = (): {
  tokensForSaleByCurio: number
  total: number
  isSoldOut: boolean
  loaded: boolean
} => {
  const [tokensForSaleByCurio, setTokensForSaleByCurio] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const total = 50

  useEffect(() => {
    clientV3.analytics.tokenSale(SUPPORT_CHAIN_NAME.skale).then((res) => {
      setTokensForSaleByCurio(res.data.tokensForSaleByCurio)
      setLoaded(true)
    })
  }, [])

  return { tokensForSaleByCurio, total, isSoldOut: tokensForSaleByCurio === 0, loaded }
}
