import React from 'react'

import avatar from '../../../assets/img/default_avatar.svg'
import { getIpfsLink } from '../../../utils/ipfs'
import { AvatarWrapper } from './styles'

type Props = {
  width?: string
  image?: AvatarImage | null
  src?: string
  border?: string
}

export const Avatar = ({ image, src, width, border }: Props): JSX.Element => {
  return (
    <AvatarWrapper width={width} border={border}>
      <img src={src || (image ? getIpfsLink(image) : avatar)} alt="Avatar" />
    </AvatarWrapper>
  )
}
