import React from 'react'

import { ProfileUi } from '../../domain'
import Sidebar from '../../ui/common/Sidebar'
import { Loading } from '../../ui/components'
import { Cover } from '../../ui/components/Cover'
import { NotFound } from '../../ui/components/NotFound'
import { Tabs } from '../../ui/components/Tabs'

type Props = {
  data: ProfileUi | null | undefined
  children: JSX.Element
  collection?: boolean
}

export const ProfileLayout = ({ collection, data, children }: Props): JSX.Element => {
  const tabs = [
    {
      link: '/',
      title: 'On sale'
    },
    {
      link: '/owned',
      title: 'Owned'
    }
  ]

  if (!collection) {
    tabs.push({
      link: '/created',
      title: 'Created'
    })
  }

  return (
    <>
      {data ? (
        <>
          <Cover cover={data.coverS3Link || data.coverIpfsHash} />
          <div className="container grid" style={{ paddingTop: 74 }}>
            <Sidebar profile={data} />
            <main>
              <Tabs items={tabs} />
              <div style={{ paddingTop: 48 }}>{children}</div>
            </main>
          </div>
        </>
      ) : data === null ? (
        <NotFound />
      ) : (
        <Loading />
      )}
    </>
  )
}
