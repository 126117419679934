import React from 'react'

import { DiscordBtn } from '../ui/components'
import { DefaultLayout } from './default'

export const MainLayout = ({ children }: { children: JSX.Element }): JSX.Element => (
  <DefaultLayout>
    <div className="container">
      {children}
      <DiscordBtn />
    </div>
  </DefaultLayout>
)
