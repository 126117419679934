import Web3 from 'web3'
import { WebsocketProvider } from 'web3-core'

import { IS_TESTNET } from './rollapp'

export const INFURA = {
  development: 'ec89f520c64641bca5ec4dd3d50d918c',
  production: '227414d2074b499481f6bf6714bd38dc'
}

export const INFURA_ID =
  window._env_.NODE_ENV === 'development' ? INFURA.development : INFURA.production

export const INFURA_URL = `https://mainnet.infura.io/v3/${INFURA_ID}`

export const infuraMainnetProvider = new Web3.providers.WebsocketProvider(
  `wss://mainnet.infura.io/ws/v3/${INFURA_ID}`
)

export const infuraProvider: WebsocketProvider = new Web3.providers.WebsocketProvider(
  `wss://${IS_TESTNET ? 'goerli' : 'mainnet'}.infura.io/ws/v3/${INFURA_ID}`
)
