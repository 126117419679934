import React from 'react'

import { ROADMAP_STEPS } from '../../config/roadmap-steps'
import { RoadmapCard } from '../../ui/components'
import { RoadmapCardsContainer, Wrapper } from './styles'

export const Roadmap = (): JSX.Element => {
  return (
    <Wrapper>
      <h1 className="h2">Roadmap</h1>
      <RoadmapCardsContainer>
        {ROADMAP_STEPS.map((item) => (
          <RoadmapCard step={item} key={item.description} />
        ))}
      </RoadmapCardsContainer>
    </Wrapper>
  )
}
