import Web3 from 'web3'

import { Web3Type } from '../../types/web3'
import { contractError } from '../contractError'
import { WHITELIST_ABI } from './abi'
import { ASSET_WHITELIST_PROXY } from './addresses'

export const isInvestorAddressActive = async (
  w: Web3Type,
  address?: UserAddress,
  wlAddress?: ContractAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(ASSET_WHITELIST_PROXY[w.chainId])
    return await new web3.eth.Contract(
      WHITELIST_ABI,
      wlAddress || ASSET_WHITELIST_PROXY[w.chainId]
    ).methods
      .isInvestorAddressActive(address || w.account)
      .call()
  } catch (e) {
    console.error(e)
    return false
  }
}
