import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import metamask from '../../assets/img/wallets/metamask.svg'
import portisImg from '../../assets/img/wallets/portis.svg'
import walletConnect from '../../assets/img/wallets/wallet-connect.svg'
import { CHAIN_ID, SUPPORT_CHAIN_ID } from '../../domain/network/contstant'
import { INFURA_URL } from '../infura'
import { IS_TESTNET } from '../rollapp'
import { Connector, ConnectorsType } from './types'

export const SUPPORTED_NETWORKS = Object.keys(SUPPORT_CHAIN_ID)
  .map((key) => SUPPORT_CHAIN_ID[key])
  .filter((item) => IS_TESTNET || (!IS_TESTNET && item !== CHAIN_ID.kovan.testnet))

export const CONNECTORS_EXTRA: ConnectorsType = {
  injected: 'Most popular',
  torus: 'Simple',
  portis: 'Credit card flow'
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_NETWORKS
})

export const walletconnect = new WalletConnectConnector({
  rpc: {
    1: INFURA_URL,
    5: INFURA_URL
  },
  qrcode: true,
  supportedChainIds: SUPPORTED_NETWORKS
})

export const portis = new PortisConnector({
  dAppId: process.env.PORTIS_DAPP_ID as string,
  networks: [CHAIN_ID.eth[IS_TESTNET ? 'testnet' : 'mainnet']]
})

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  Portis = 'Portis'
}

export const connectorsByName: { [connectorName: string]: Connector } = {
  [ConnectorNames.Injected]: {
    display: {
      logo: metamask,
      name: 'Metamask',
      description: 'One of the most secure wallets with great flexibility'
    },
    connect: injected
  },
  [ConnectorNames.WalletConnect]: {
    display: {
      logo: walletConnect,
      name: 'WalletConnect',
      description: 'Connect with Rainbow, Trust, Argent and more'
    },
    connect: walletconnect
  },
  [ConnectorNames.Portis]: {
    display: {
      logo: portisImg,
      name: 'Portis',
      description: 'Connect with your email and \n password'
    },
    connect: portis
  }
}
