import styled from 'styled-components'

export const CardMiniavas = styled.div`
  display: flex;
  align-items: center;
  & a:first-child {
    z-index: 1;
  }
  & a:nth-child(2) {
    z-index: 2;
    margin-left: -20px;
  }
  & a:nth-child(3) {
    z-index: 3;
    margin-left: -20px;
  }
`
