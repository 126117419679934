import React from 'react'

import { useETHBalance } from '../../hooks/useETHBalance'
import { Web3Type } from '../../types/web3'
import { toFixed } from '../../utils/toFixed'
import { fromWei } from '../../utils/wei'
import { Modal } from '../common/Modal'
import { CancelBtn } from '../components/CancelBtn'
import { Description, InfoFee, Wrapper } from '../components/ModalStyles'

export const GetOneModal = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  const balance = useETHBalance(web3)

  return (
    <Modal title="Get ONE" width="350px">
      <Wrapper>
        <Description>
          Transactions on Harmony are very cheap, but still require payment of gas fees in ONE. For
          easy onboarding of new users to the Harmony network, you can get a small amount of ONE
          using third party ONE facets from the links below.
        </Description>
        <InfoFee>
          Your balance: <span>{balance && toFixed(fromWei(balance))} ONE</span>
        </InfoFee>
        <a
          className="button primary-button block-button"
          href="https://harmony.supply"
          target="_blank"
          rel="noreferrer"
        >
          Get ONE with Harmony Faucet
        </a>
        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
