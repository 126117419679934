import React, { useEffect } from 'react'

import { Token } from '../../../../domain'
import { useRollapp } from '../../../../hooks/useRollapp'
import { useOrder } from '../../../../hooks/useTokenData'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Loader } from '../../../components'
import { Wrapper } from '../../../components/ModalStyles'
import { Form } from './Form'

export const ChangePriceModal = ({
  web3,
  token
}: {
  web3: Web3Type
  token: Token
}): JSX.Element => {
  const { setModal } = useRollapp()
  const order = useOrder(token, web3.account, true)

  useEffect(() => {
    if (order === null) {
      setModal(undefined)
    }
    // eslint-disable-next-line
  }, [order])

  return (
    <Modal title="Change price" width="350px">
      <Wrapper>
        {order ? <Form web3={web3} order={order} token={token} /> : <Loader center />}
      </Wrapper>
    </Modal>
  )
}
