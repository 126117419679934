import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Value = styled.span`
  display: block;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin-right: 8px;
`

const Perc = styled.span<{ up: boolean }>`
  color: ${(props) => `var(--${props.up ? 'green' : 'red'})`};
  font-weight: 700;
`

const PriceValue = (props: { price: string; perc: string }): JSX.Element => (
  <Wrapper>
    <Value>${props.price}</Value>
    <Perc up={props.perc.includes('+')}>{props.perc}%</Perc>
  </Wrapper>
)

export default PriceValue
