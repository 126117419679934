import React from 'react'

import { PrimaryButtonLink, SubTitle } from '../styled'

export const Wine = (): JSX.Element => (
  <>
    <SubTitle>VINDOME INFORMATION</SubTitle>
    <p>The world&apos;s first app for trading premium wine buyers, collectors, and sellers.</p>
    <PrimaryButtonLink
      className="button primary-button"
      href="https://vindome.net/app"
      target="_blank"
      rel="noreferrer"
    >
      Download the Vindome app
    </PrimaryButtonLink>
  </>
)
