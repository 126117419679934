import styled from 'styled-components'

export const BlockchainBlock = styled.div`
  display: block;
  width: 100%;
`

export const BlockTitle = styled.p`
  margin-bottom: 9px;
  color: var(--text-gray);
`

export const NetworkCard = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const NetworkName = styled.p`
  font-weight: 700;
`
