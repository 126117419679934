import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-template-columns: 1fr;
  grid-gap: 12px;
  display: grid;
`

export const StyledLink = styled.a`
  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 12px;
  &:hover span {
    opacity: 1;
  }
  &.active span {
    opacity: 1;
  }
  &:hover div div {
    width: 100%;
    background: var(--blue);
  }
  &.active div div {
    width: 100%;
    background: var(--blue);
  }
`

export const LinkText = styled.span`
  opacity: 0;
  transition: 0.2s;
  text-align: right;
  font-weight: 700;
`

export const LinkLineBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const LinkLine = styled.div`
  width: 50%;
  transition: 0.3s;
  height: 4px;
  background: var(--line-day);
  border-radius: 2px;
`
