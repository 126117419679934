import { OrderApi } from '../domain'
import { fromWei, toWei } from './wei'

export const getSum = (price: Price, amount: Amount): Price => {
  if (parseInt(price) && !!amount) {
    const priceWei = toWei(price)
    const bn = BigInt(priceWei) * BigInt(!!amount ? amount : 0)
    return fromWei(bn.toString())
  }

  return '0'
}

export const getPriceStock = (price: PriceBN, quantity: string): string =>
  fromWei((BigInt(price) / BigInt(quantity)).toString())

export const getPriceFromSum = (order: OrderApi): Price =>
  getPriceStock(order.take.value, order.make.value)

export const getSumInput = (order: OrderApi, amount: string): Price =>
  !!parseInt(amount) ? getSum(getPriceFromSum(order), parseInt(amount)) : '0'
