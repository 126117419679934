import React, { useEffect, useState } from 'react'

import { ListProfile, Token } from '../../domain'
import { getListAddresses, getProfiles } from '../../domain/profile/mappers/profiles'
import { clientV3 } from '../../services/api'
import { Loading } from '../../ui/components'
import { Card } from '../../ui/components/Card'
import { ButtonWrapper, CardsWrap } from '../../ui/components/Cards/styles'
import { NoData } from '../../ui/components/NoData'

export const SearchItems = ({ query }: { query: string }): JSX.Element => {
  const [page, setPage] = useState(1)
  const [tokens, setTokens] = useState<Token[]>([])
  const [profiles, setProfiles] = useState<ListProfile[]>([])
  const [loading, setLoading] = useState(true)
  const [end, setEnd] = useState(false)

  useEffect(() => {
    setLoading(true)
    clientV3.search
      .tokens(query, page)
      .then((res) => {
        const accounts = getListAddresses(res.data.items)
        setEnd(res.data.pagination.pages === page)
        setTokens(page === 1 ? res.data.items : [...tokens, ...res.data.items])
        return getProfiles(accounts)
      })
      .then((res) => {
        setProfiles(res)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [page, query])

  return (
    <div>
      {tokens.length > 0 ? (
        <CardsWrap columns={3}>
          {tokens.map((token) => (
            <Card token={token} profiles={profiles} key={token.id} />
          ))}
        </CardsWrap>
      ) : (
        !loading && <NoData />
      )}
      {loading && <Loading />}
      {tokens.length > 0 && !loading && !end && (
        <ButtonWrapper>
          <button className="button primary-button" onClick={() => setPage(page + 1)}>
            Load more
          </button>
        </ButtonWrapper>
      )}
    </div>
  )
}
