import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;

  p > a {
    color: var(--blue);
  }
`

export const Description = styled.p<{ color?: string }>`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${(props) => (props.color === 'gray' ? 'var(--text-gray)' : 'var(--text-dark)')};
  & span {
    font-size: 16px;
    line-height: 20px;
    color: var(--text-dark);
  }
`

export const ModalLink = styled.a`
  color: var(--blue);
  padding: 0 2px;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
`

export const ModalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  width: 100%;
`

export const InfoFee = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: var(--text-gray);
  & span {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-dark);
  }
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const InfoType = styled.p`
  width: fit-content;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-gray);
`

export const InfoValue = styled.p`
  width: fit-content;
  font-size: 12px;
  line-height: 20px;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
`

export const TokenName = styled.p`
  transform: translate(0px, 50%);
  position: absolute;
  bottom: 50%;
  font-weight: 400;
  right: 15px;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-gray);
  width: fit-content;
`

export const ImageInner = styled.div`
  border-radius: 16px 16px 0 0;
  height: 276px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: relative;

  & img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    object-fit: contain;
  }
`

export const Notification = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-dark);
`
