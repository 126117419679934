import styled from 'styled-components'

export const CheckboxLabel = styled.label<{ right?: number; center?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.center ? 'center' : 'unset')};

  span {
    position: relative;
    border: 1px solid var(--line-day);
    flex-shrink: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 6px;
    margin-right: ${(props) => (props.right ? props.right : 0)}px;
    margin-top: ${(props) => (props.center ? 0 : 2)}px;

    &:before {
      content: '';
      border-radius: 2px;
      position: absolute;
      height: 8px;
      width: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s;
    }
  }
`

export const CheckboxInput = styled.input`
  appearance: none;
  display: none;
  &:checked + span:before {
    background: var(--blue);
  }
`
