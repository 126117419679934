import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react'

import greetings from '../../../../assets/img/gifs/greetings.gif'
import { useActiveWeb3React } from '../../../../hooks/useActiveWeb3React'
import PaperPlane from '../../../../ui/icons/PaperPlane'
import { EVENTS } from '../../constants/events'
import { useMessenger } from '../../context/useMessenger'
import { IJoinedConversation } from '../../interfaces/IConversation'
import { IMessage } from '../../interfaces/IMessage'
import { ChatService } from '../../services/Chat.service'
import { MessageCloud } from '../MessageCloud'
import { RequestText, RequestWindow } from '../Messenger/styles'
import {
  ChatContainer,
  ChatMessages,
  ChatUp,
  ChatWindow,
  ChatWrapper,
  EmojiButton,
  MessagerBottom,
  MessagerField,
  SendButton,
  StartChatWindow,
  StartWindowDescription,
  StartWindowGreetingsButton
} from './styles'

export const ChatBody = ({ chatAccount }: { chatAccount: string }): JSX.Element => {
  const { account } = useActiveWeb3React()

  const [input, setInput] = useState('')
  const [showUp, setShowUp] = useState(false)
  const ref = useRef(null)
  const { connection } = useMessenger()
  const [conversation, setConversation] = useState<IJoinedConversation | undefined>()
  const [chatService, setChatService] = useState(
    () => new ChatService(connection, chatAccount, conversation?.id)
  )
  const [messages, setMessages] = useState<IMessage[]>([])

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (input.length > 0 && conversation) {
      chatService.sendMessage(input)
      // submitMessage(input, chat.chatId)
      setInput('')
    }
  }

  const scrollUp = () => {
    if (ref && ref.current) {
      ;(ref.current as HTMLDivElement).scrollTop = (ref.current as HTMLDivElement).scrollHeight
    }
  }

  const handleScroll = useCallback(() => {
    if (ref.current) {
      const div = ref.current as HTMLDivElement
      setShowUp(div.scrollTop < div.scrollHeight - div.clientHeight - 50)
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      ;(ref.current as HTMLDivElement).addEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ref.current) {
      ;(ref.current as HTMLDivElement).scrollTop = (ref.current as HTMLDivElement).scrollHeight
    }
  }, [conversation?.messages])

  useEffect(() => {
    if (connection && conversation?.id) {
      setChatService(new ChatService(connection, chatAccount, conversation.id))
    }
  }, [connection, conversation?.id])

  useEffect(() => {
    if (connection) {
      const onConversation = (data: IJoinedConversation) => {
        setConversation(data)
        setMessages(data.messages.reverse())
      }

      const onNewMessage = (data: IMessage) => {
        setMessages((messages) => messages.concat(data))
      }

      const onRemovedMessage = ({ messagesId }: { messagesId: string[] }) => {
        setMessages((messages) =>
          messages.filter((message) => !messagesId.find((id) => id === message.id))
        )
      }

      chatService.join()
      connection.on(EVENTS.conversation, onConversation)
      connection.on(EVENTS.newMessage, onNewMessage)
      connection.on(EVENTS.removedMessagesId, onRemovedMessage)

      return () => {
        connection.off(EVENTS.conversation, onConversation)
        connection.off(EVENTS.newMessage, onNewMessage)
        connection.off(EVENTS.removedMessagesId, onRemovedMessage)
      }
    }
  }, [account, chatAccount, connection])

  return conversation?.isRequest ? (
    <RequestWindow>
      <RequestText>John wants to send you messages</RequestText>
      <button className="button primary-button" onClick={() => chatService.acceptRequest()}>
        Accept
      </button>
    </RequestWindow>
  ) : (
    <ChatWrapper>
      <ChatWindow>
        {!!messages.length && conversation ? (
          <ChatContainer ref={ref}>
            <ChatMessages>
              {messages
                .map((message) => ({
                  ...message,
                  fromMe:
                    (message.sender.ss58Address?.toLowerCase() ??
                      message.sender.ethAddress?.toLowerCase()) === account.toLowerCase()
                }))
                .map((message, idx) => {
                  const preLastDate = messages[idx + 1]
                    ? new Date(messages[idx + 1].updatedAt).toLocaleDateString('en-US')
                    : undefined
                  const date = new Date(message.updatedAt).toLocaleDateString('en-US')

                  return (
                    <MessageCloud
                      key={idx}
                      message={message}
                      displayAvatar={idx + 1 === messages.length || messages[idx + 1]?.fromMe}
                      displayDate={
                        idx + 1 === messages.length ||
                        !(messages[idx + 1]?.fromMe === message.fromMe && preLastDate === date)
                      }
                      removeMsg={(v) => chatService.removeMessage([v])}
                    />
                  )
                })}
            </ChatMessages>
          </ChatContainer>
        ) : (
          <StartChatWindow>
            <p>No messages here yet...</p>
            <StartWindowDescription>
              Send a message or tap on the greeting below
            </StartWindowDescription>
            <StartWindowGreetingsButton>
              <img src={greetings} />
            </StartWindowGreetingsButton>
          </StartChatWindow>
        )}
        {showUp && <ChatUp onClick={scrollUp} />}
      </ChatWindow>
      <MessagerBottom>
        <MessagerField onSubmit={submit}>
          <input
            type="textarea"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Send a message"
          />
          {input.length > 0 ? (
            <SendButton>
              <PaperPlane />
            </SendButton>
          ) : (
            <EmojiButton type="button" />
          )}
        </MessagerField>
      </MessagerBottom>
    </ChatWrapper>
  )
}
