import { useEffect, useState } from 'react'

import { balanceOfCT1 } from '../contracts/erc20/methods'
import { Web3Type } from '../types/web3'

export const useCT1Balance = (web3: Web3Type, trigger?: boolean): string | undefined => {
  const [balance, setBalance] = useState<string>()

  useEffect(() => {
    balanceOfCT1(web3).then((res) => {
      setBalance(res)
    })
  }, [web3, trigger])

  return balance
}
