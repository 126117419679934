import { Rules } from '../../../../services/validate-form/types'

export const InitialValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: '',
  deliveryAddress: '',
  transactionHash: ''
}

export const initialErrors = {
  email: undefined,
  phone: undefined,
  firstName: undefined,
  lastName: undefined,
  deliveryAddress: undefined
}

export const rules: Rules = {
  email: ['email', 'required'],
  phone: ['required', 'phone'],
  firstName: ['required'],
  lastName: ['required'],
  deliveryAddress: ['required']
}
