import styled from 'styled-components'

import metamask from '../../../assets/img/wallets/metamask.svg'

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover > .tooltip {
    display: block;
  }
`

export const Logo = styled.div`
  width: 16px;
  height: 16px;
  background: url(${metamask}) no-repeat center;
  background-size: contain;
`

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 16px;
  right: -16px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 10px 24px rgb(0 0 0 / 10%);
  z-index: 2;
  white-space: nowrap;
  padding: 8px;

  span {
    color: var(--text-gray);
  }
`
