import React from 'react'

import bitcoin from '../assets/media/bitcoinist.svg'
import bloomberg from '../assets/media/bloomberg.svg'
import coindesk from '../assets/media/coindesk.svg'
import yahoo from '../assets/media/yahoo.svg'
import {
  Container,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import { LinkLogo, LinksBlock, MediaLink } from './styled'

const links = [
  {
    logo: `${bloomberg}`,
    address:
      'https://www.bloomberg.com/press-releases/2021-07-23/rollapp-a-real-world-assets-nft-launchpad-from-curioinvest'
  },
  {
    logo: `${bitcoin}`,
    address:
      'https://bitcoinist.com/mercedes-amg-nfts-rental-bonuses-the-new-way-of-nft-monetization-from-curiodao'
  },
  {
    logo: `${coindesk}`,
    address:
      'https://www.coindesk.com/markets/2020/06/17/when-ferrari-tokenized-supercar-gives-european-investors-exposure-to-asset-class'
  },
  {
    logo: `${yahoo}`,
    address: 'https://www.yahoo.com/now/curioinvest-introduces-rollapp-real-world-132000092.html'
  }
]

export const FeaturedSection = (): JSX.Element => (
  <SectionWrapper>
    <Container>
      <SectionHeader>
        <SectionTitle>Featured in</SectionTitle>
        <SectionDescription></SectionDescription>
      </SectionHeader>
      <LinksBlock>
        {links.map((link) => (
          <MediaLink key={link.logo} href={link.address} target="_blank">
            <LinkLogo>
              <img src={link.logo} />
            </LinkLogo>
          </MediaLink>
        ))}
      </LinksBlock>
    </Container>
  </SectionWrapper>
)
