import React, { useState } from 'react'

import { SALE_TYPES, SaleTypes } from '../../../../config/saleTypes'
import { Token } from '../../../../domain'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Wrapper } from '../../../components/ModalStyles'
import { RadioCard } from '../../../components/RadioCard'
import { RadioCards } from '../../../components/RadioCards'
import { FixedPrice } from './FixedPrice'
import { Unlimited } from './Unlimited'

export const PutOnSaleModal = ({
  web3,
  token
}: {
  web3: Web3Type
  token: Token
}): JSX.Element | null => {
  const [type, setType] = useState<SaleTypes | undefined>(undefined)

  return (
    <Modal title="Put on sale" width="350px">
      <Wrapper>
        {type === undefined ? (
          <>
            <p>Choose sale type</p>
            <RadioCards small>
              {SALE_TYPES.map((item) => (
                <RadioCard
                  key={`type-${item.value}`}
                  title={item.title}
                  icon={item.icon}
                  onClick={() => setType(item.value)}
                >
                  <input type="radio" disabled={item.disabled} />
                </RadioCard>
              ))}
            </RadioCards>
          </>
        ) : type === 'unlimited-sale' ? (
          <Unlimited web3={web3} token={token} />
        ) : type === 'fixed-sale' ? (
          <FixedPrice token={token} web3={web3} />
        ) : null}
      </Wrapper>
    </Modal>
  )
}
