import styled from 'styled-components'

import arrow from '../../../assets/img/icons/arrow-back.svg'

export const BackLink = styled.button<{ mb?: number }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 24px;
  margin-bottom: ${(props) => (props.mb === 0 || props.mb ? props.mb : 24)}px;
  padding: 0;
  font-weight: 600;

  &::before {
    content: '';
    display: block;
    width: 18px;
    height: 14px;
    background: url(${arrow}) center no-repeat;
    background-size: contain;
    margin-right: 11px;
  }
`
