import antohaCover from './assets/antoha-bg.png'
import antohaPreview from './assets/antoha-preview.svg'
import sergAva from './assets/azam.svg'
import carsCover from './assets/cars-cover.png'
import carsPreview from './assets/cars-preview.png'
import scarselliAva from './assets/diamonds.svg'
import diamondsCover from './assets/diamonds-cover.png'
import diamondsPreview from './assets/diamonds-preview.png'
import itRemainsCover from './assets/it-remains-bg.png'
import itRemainsPreview from './assets/it-remains-preview.png'
import leoAva from './assets/leo-trust.svg'
import leoTrustCover from './assets/leo-trust-cover.png'
import leoTrustPreview from './assets/leo-trust-preview.png'
import residencesPreview from './assets/residences-preview.png'
import ritzAva from './assets/ritz.svg'
import rollappAva from './assets/rollapp-davos.svg'
import stepOutCover from './assets/step-out-bg.png'
import stepPreview from './assets/step-out-preview.png'
import winePreview from './assets/wine-preview.svg'
import vindomeAva from './assets/wines.svg'
import wineCover from './assets/wines-cover.svg'
import yellowMouthAva from './assets/yellow-mouth.svg'
import { AntohaMC, AntohaMCAbout } from './ContentBlocks/AntohaMC'
import { Diamonds } from './ContentBlocks/Diamonds'
import { ItRemains, ItRemainsAbout } from './ContentBlocks/ItRemains'
import { LeoTrust } from './ContentBlocks/LeoTrust'
import { StepOut } from './ContentBlocks/StepOut'
import { Wine } from './ContentBlocks/Wine'

export type DavosCard = {
  url: string
  preview: string
  avatar: string
  secondAvatar?: string
  organization: string
  name: string
  page?: boolean
}

export type Content = {
  card: DavosCard
  marked?: string
  about?: JSX.Element | string
  other?: JSX.Element
  cover?: string
  staking?: boolean
  claim?: boolean
  nft?: string
  secondNft?: string
  buyUrl?: string
  secondBuyUrl?: string
  buyName?: string
  secondBuyName?: string
}

type DavosContent = {
  [k in string]: Content
}

export const DAVOS_CARDS: DavosCard[] = [
  {
    url: 'music-club',
    preview: `${antohaPreview}`,
    avatar: `${sergAva}`,
    // secondAvatar: `${antohaAva}`,
    organization: `Music Club`,
    name: 'Drum Shiva & Serj',
    page: true
  },
  {
    url: 'it-remains',
    preview: `${itRemainsPreview}`,
    avatar: `${yellowMouthAva}`,
    organization: `Yellow Mouth Studios`,
    name: 'It Remains',
    page: true
  },
  {
    url: 'curio-cars',
    preview: `${carsPreview}`,
    avatar: `${rollappAva}`,
    organization: `RollApp`,
    name: 'Curio Tokenized Cars'
  },
  {
    url: 'step-out',
    preview: `${stepPreview}`,
    avatar: `${rollappAva}`,
    organization: `RollApp`,
    name: 'Step out'
  },
  {
    url: 'leo-trust',
    preview: `${leoTrustPreview}`,
    avatar: `${leoAva}`,
    organization: `LEO Trust`,
    name: 'Art',
    page: true
  },
  {
    url: 'vindome-wine',
    preview: `${winePreview}`,
    avatar: `${vindomeAva}`,
    organization: `VINDOME`,
    name: 'Wine',
    page: true
  },
  {
    url: 'scarselli-diamonds',
    preview: `${diamondsPreview}`,
    avatar: `${scarselliAva}`,
    organization: `Scarselli`,
    name: 'Diamonds'
  },
  {
    url: 'residences',
    preview: `${residencesPreview}`,
    avatar: `${ritzAva}`,
    organization: `Ritz`,
    name: 'Residences'
  }
]

export const DAVOS_CONTENT: DavosContent = {
  [DAVOS_CARDS[0].url]: {
    card: DAVOS_CARDS[0],
    marked:
      'The NFT music label grants musicians unprecedented autonomy and control over their work. Curio tokenizes recordings on RollApp so they can be traded as NFTs, with royalties going to the artist.',
    about: AntohaMCAbout(),
    other: AntohaMC(),
    nft: '99',
    secondNft: '999',
    buyUrl:
      '/token/0x1a0b736B0843a00A85a7D73754B59CBb0A911927:99326131137483886891004264430325786609158860882489678491178031342521990250497',
    secondBuyUrl:
      '/token/0x6db98972096935e2c7c1d4FC15883eB9434CB454:99326131137483886891004264430325786609158860882489678491178031342521990250497',
    buyName: 'Buy BAYCS VIP',
    secondBuyName: 'Buy BAYCS',
    cover: `${antohaCover}`
  },
  [DAVOS_CARDS[1].url]: {
    card: DAVOS_CARDS[1],
    about: ItRemainsAbout(),
    other: ItRemains(),
    marked: 'Get a Legendary NFT together with physical figurines now.',
    nft: '5',
    cover: `${itRemainsCover}`,
    claim: true,
    buyUrl: '/collection/itremainslegendary'
  },
  [DAVOS_CARDS[2].url]: {
    card: DAVOS_CARDS[2],
    nft: '3',
    cover: `${carsCover}`
  },
  [DAVOS_CARDS[3].url]: {
    card: DAVOS_CARDS[3],
    other: StepOut(),
    staking: true,
    nft: '1,000',
    cover: `${stepOutCover}`
  },
  [DAVOS_CARDS[4].url]: {
    card: DAVOS_CARDS[4],
    cover: `${leoTrustCover}`,
    other: LeoTrust(),
    nft: '1,000',
    buyUrl:
      '/token/0xABD9DF17fDfecAdA3BA07dB5170e6D7649205CA4:99326131137483886891004264430325786609158860882489678491178031342521990250497'
  },
  [DAVOS_CARDS[5].url]: {
    card: DAVOS_CARDS[5],
    about:
      "We're ecstatic to announce the debut of the first-of-its-kind physical NFT fine wine collection in collaboration with Vindome, one of the most well-known wine investing platforms situated in Monaco.",
    other: Wine(),
    nft: '10',
    cover: `${wineCover}`,
    buyUrl: '/collection/0xe89f14e3fcf4be1b7976548a1060087e3ccc97f2'
  },
  [DAVOS_CARDS[6].url]: {
    card: DAVOS_CARDS[6],
    about: 'Scarselli and Curio are looking into the realm of high art diamonds to turn into NFTs.',
    other: Diamonds(),
    nft: '?',
    cover: `${diamondsCover}`
  },
  [DAVOS_CARDS[7].url]: {
    card: DAVOS_CARDS[7],
    nft: '1,000'
  }
}
