import React, { useEffect, useRef } from 'react'

import { handleIntersection } from '../../../../utils/handleIntersection'
import { Comment } from '../Comment'
import {
  BottomRow,
  CardBottom,
  CardImage,
  CommentPosition,
  HiddenText,
  LandingCardWrapper,
  PseudoAvatar,
  TopRow
} from './styled'

type Props = {
  image: string
  avatar: string
  comment?: {
    name: string
    text: string | JSX.Element
    color: string
  }
  url: string
}

export const LandingCard = ({ image, avatar, comment, url }: Props): JSX.Element => {
  const target = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection)
    if (target && target.current) {
      observer.observe(target.current)
    }
  }, [])

  return (
    <LandingCardWrapper to={url}>
      <CardImage>
        <img src={image} />
      </CardImage>
      <CardBottom>
        <PseudoAvatar>
          <img src={avatar} />
        </PseudoAvatar>
        <TopRow>
          <HiddenText width="114px" />
          <HiddenText width="29px" pink />
        </TopRow>
        <BottomRow>
          <HiddenText width="80px" />
          <HiddenText width="40px" />
        </BottomRow>
      </CardBottom>
      {comment && (
        <CommentPosition ref={target} className="landing-card__comment">
          <Comment content={comment.text} name={comment.name} color={comment.color} />
        </CommentPosition>
      )}
    </LandingCardWrapper>
  )
}
