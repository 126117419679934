import styled from 'styled-components'

import check from '../../../../assets/img/icons/check-complete.svg'

export const TwoFormsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
`

export const SettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FormName = styled.p`
  font-weight: 500;
  font-size: 14px;
`

export const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ChoiceLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

export const ChoiceName = styled.p`
  width: fit-content;
`

export const RadioButton = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  left: 830px;
  border-radius: 50%;
  outline: none;
  transition: 0.2s;

  border: 1px solid var(--line-day);
  background-color: var(--white);
  background-image: url(${check});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  &:checked {
    border: 1px solid var(--blue);
    background-color: var(--blue);
  }
`

export const ModalSubtitle = styled.h3`
  font-weight: 700;
  line-height: 24px;
  font-size: 19px;
`

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
