import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 14px;
  height: 36px;
  border: 1px solid var(--line-day);
  border-radius: 20px;
  font-size: 13px;
  line-height: 28px;
  color: var(--text-gray);
  background: transparent;
  transition: 0.2s all;
  & svg {
    margin-right: 4px;
    fill: var(--text-gray);
  }

  &:hover {
    color: var(--white);
    background: var(--blue);
    border: 1px solid var(--blue);
  }

  &:hover svg {
    fill: var(--white);
  }

  & .follow path {
    stroke: var(--text-gray);
    fill: none;
  }

  &:hover .follow path {
    stroke: var(--white);
    fill: none;
  }
`
