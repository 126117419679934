import React, { useState } from 'react'

import { NOT_WIN_VALUE } from '../../../contracts/bonus/addresses'
import { claim } from '../../../contracts/bonus/methods'
import { Token } from '../../../domain'
import { useClaimed } from '../../../hooks/useBonus'
import { useCT1Balance } from '../../../hooks/useCT1Balance'
import { useWhitelist } from '../../../hooks/useWhitelist'
import { Web3Type } from '../../../types/web3'
import { toFixed } from '../../../utils/toFixed'
import { Modal } from '../../common/Modal'
import { CT1WhitelistText } from '../../common/WhitelistText'
import { CancelBtn } from '../../components/CancelBtn'
import {
  Description,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  Wrapper
} from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const GetBonusModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const [done, setDone] = useState(false)
  const whitelisted = useWhitelist()
  const balance = useCT1Balance(web3, done)
  const claimed = useClaimed(web3, token, done)

  return (
    <Modal title="Get bonus" width="380px">
      <Wrapper>
        {whitelisted ? (
          <>
            <Description>
              Win a bonus in Curio Ferrari F12 tdf Tokens (CT1). The size of the bonus is selected
              at random when the transaction is executed and can range from 50 to 500 CT1. Not all
              tokens participate in winning the bonus, take the risk of getting it.
            </Description>
            <ModalInfo>
              <InfoRow>
                <InfoType>Your balance</InfoType>
                <InfoValue>{balance ? toFixed(balance) : ''} CT1</InfoValue>
              </InfoRow>
              <InfoRow>
                <InfoType>
                  You{' '}
                  {claimed === NOT_WIN_VALUE ? 'not won' : claimed === '0' ? 'not won yet' : 'won'}
                </InfoType>
                {claimed && parseInt(claimed) > 0 && claimed !== NOT_WIN_VALUE && (
                  <InfoValue>{claimed} CT1</InfoValue>
                )}
              </InfoRow>
            </ModalInfo>
            <StatusBlock
              name="Get bonus"
              text="Try to win"
              method={async () => {
                const res = await claim(web3, token.tokenId)
                setDone(res)
                return res
              }}
              disabled={claimed !== '0'}
              check={!!claimed && parseInt(claimed) > 0}
            />
            <CancelBtn />
          </>
        ) : (
          <CT1WhitelistText />
        )}
      </Wrapper>
    </Modal>
  )
}
