import { Link } from 'react-router-dom'
import styled from 'styled-components'

import menuDots from '../../../assets/img/icons/menuDots.svg'

export const CardWrap = styled.div`
  background: var(--white);
  border-radius: 16px;
  width: 100%;
  position: relative;
`

export const CardMenuBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 14;
`

export const CardMenuButton = styled.button`
  background: url(${menuDots}) no-repeat center rgba(16, 37, 62, 0.25);
  width: 32px;
  height: 32px;
  border-radius: 100%;
`

export const CardLink = styled(Link)`
  display: block;
`

export const ImageWrap = styled.div<{ blur?: string }>`
  border-radius: 16px 16px 0 0;
  height: 276px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  position: relative;
  ${(props) => (props.blur ? 'pointer-events: none;' : '')}

  & > .image {
    height: 100%;
  }

  & img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: ${(props) => (props.blur ? 'cover' : 'contain')};
    border-radius: 16px;
    overflow: hidden;
  }

  & video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    object-fit: cover;
  }
`

export const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(4px);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: var(--white);
`

export const Bottom = styled.div`
  padding: 0 16px 20px;
  position: relative;
  z-index: 12;
`

export const CardIcons = styled.div`
  display: flex;
  margin-top: -4px; // 36 height instead 32, that's why
  transform: translateY(calc(4px - 50%)); // fix margin-top
  align-items: center;
  height: 36px;
`

export const CardInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
`

export const CardTitle = styled.h2`
  font-size: 16px;
  line-height: 24px;
  min-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const CardInfoToken = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
  grid-column-gap: 12px;
`

export const CardAvailable = styled.span`
  color: var(--text-gray);
`

export const CardRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 16px;
`

export const CardBid = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--blue);
  cursor: pointer;
`

export const PreviewWrap = styled(CardWrap)<{ isImage: boolean }>`
  margin-top: 24px;
  min-height: 392px;
  display: ${(props) => (props.isImage ? 'block' : 'flex')};
  align-items: center;
  justify-content: center;

  p {
    color: var(--text-gray);
    padding: 0 20px;
    text-align: center;
  }
`
