import { Rules } from '../../../services/validate-form/types'
import { CreateErrors, CreateValues } from '../types/create.types'

export const defaultForm: ['file', 'cover', 'collection', 'title', 'description', 'email'] = [
  'file',
  'cover',
  'collection',
  'title',
  'description',
  'email'
]

export const loanForm: [
  'file',
  'cover',
  'collection',
  'title',
  'description',
  'email',
  'firstName',
  'lastName',
  'make',
  'model',
  'year',
  'general',
  'confirming'
] = [
  'file',
  'cover',
  'collection',
  'title',
  'description',
  'email',
  'firstName',
  'lastName',
  'make',
  'model',
  'year',
  'general',
  'confirming'
]

export const rules: Rules = {
  file: ['required', 'file', 'ipfs', '100mb'],
  cover: ['required', 'file', 'image', '100mb'],
  collection: ['required'],
  title: ['required'],
  description: ['required'],
  email: ['required', 'email'],

  // default
  addition: ['file', '30mb', 'document'],

  // loan
  firstName: ['required'],
  lastName: ['required'],
  phone: ['phone'],
  make: ['required'],
  model: ['required'],
  year: ['required'],
  general: ['required'],
  confirming: ['required', 'file', '30mb', 'document']
}

export const defaultValues: CreateValues = {
  file: null,
  cover: null,
  collection: 'user-assets',
  title: '',
  description: '',
  email: '',
  request: '',

  // default
  addition: null,

  // loan
  firstName: '',
  lastName: '',
  phone: '',
  make: '',
  model: '',
  year: '',
  general: '',
  confirming: null
}

export const defaultErrors: CreateErrors = {
  file: '',
  cover: null,
  collection: null,
  title: '',
  description: '',
  email: '',
  request: null,

  // default
  addition: null,

  // loan
  firstName: '',
  lastName: '',
  phone: null,
  make: '',
  model: '',
  year: '',
  general: '',
  confirming: ''
}
