import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ListProfile, Token } from '../../domain'
import { getListAddresses, getProfiles } from '../../domain/profile/mappers/profiles'
import { useRollapp } from '../../hooks/useRollapp'
import { clientV3 } from '../../services/api'
import { Loading } from '../../ui/components'
import { NotFound } from '../../ui/components/NotFound'
import { getUsingNetwork } from '../../utils/network'
import { Info } from './Info'
import { Wrapper } from './styles'
import { TokenImage } from './TokenImage'

export const TokenPage = (): JSX.Element => {
  const { modal } = useRollapp()
  const { account } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  const [token, setToken] = useState<Token>()
  const [list, setList] = useState<ListProfile[]>([])
  const { id } = useParams<{ id: ContractAddress & TokenId }>()
  const network = getUsingNetwork()

  useEffect(() => {
    const fetch = async () => {
      try {
        const contract = id.substring(0, id.indexOf(':'))
        const tokenId = id.substring(id.indexOf(':') + 1, id.length)
        const { data } = await clientV3.token.byId(network, contract, tokenId, account)
        if (!!data) {
          const accounts = getListAddresses([data])
          const arr = await getProfiles(accounts)
          setList(arr)
          setToken(data)
        }
        setLoaded(true)
      } catch (e) {
        console.error(e)
        setLoaded(true)
      }
    }

    if (modal === undefined) {
      fetch()
    }
  }, [id, modal, account, network])

  return loaded ? (
    token ? (
      <Wrapper>
        <TokenImage token={token} />
        <Info token={token} list={list} />
      </Wrapper>
    ) : (
      <NotFound />
    )
  ) : (
    <Loading />
  )
}
