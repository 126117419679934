import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`

export const Loader = styled.div<{ width?: number; bg?: string; center?: boolean; color?: string }>`
  width: ${(props) => (props.width ? props.width : 20)}px;
  height: ${(props) => (props.width ? props.width : 20)}px;
  border-radius: 50%;
  background: conic-gradient(
    from 180deg at 50% 50%,
    ${(props) => (props.color ? `var(--${props.color})` : `var(--blue)`)} 0deg,
    rgba(96, 98, 246, 0) 360deg
  );
  padding: 2px;
  animation: ${spin} 1.3s linear infinite;
  ${(props) => props.center && 'margin: 0 auto;'}

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${(props) => (props.bg ? `var(--${props.bg})` : 'var(--white)')};
  }
`
