import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const TabButton = styled.button`
  min-height: 61px;
  width: 100%;
  background: none;
  border: none;
  padding: 8px 17px 8px 24px;
  display: flex;
  align-items: center;
`

export const TabType = styled.p`
  font-weight: 700;
  width: fit-content;
  padding-right: 4px;
  line-height: 22px;
`

export const TabValue = styled.span`
  color: var(--text-gray);
  line-height: 22px;
`

export const TabImage = styled.div`
  width: 24px;
  transition: 0.4s;
  height: 24px;
  margin-left: auto;
  & img {
    width: 100%;
    height: auto;
  }
`

export const LinkMenu = styled.div`
  border-top: 1px solid var(--line-day);
`

export const LinkBlock = styled(NavLink)`
  min-height: 69px;
  border-bottom: 1px solid var(--line-day);
  box-sizing: unset;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--link-gray);
  }
  &.active:not(.disabled) {
    background: var(--link-gray);
  }
`

export const LinkImage = styled.div`
  height: 68px;
  width: 68px;
  margin-right: 12px;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const SharesName = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
`

export const SharesStatus = styled.p`
  font-weight: 400;
  font-size: 12px;
`

export const LinkContent = styled.div`
  display: block;
`

export const TabContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`
