import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import { balanceOfShares } from '../contracts/erc1155/methods'
import { getOwnerByAddress, isMarioPresaleToken, OrderApi, Token } from '../domain'

export const useBuyAvailable = (order: OrderApi, token: Token): string => {
  const { account } = useWeb3React()
  const [available, setAvailable] = useState('1')

  useEffect(() => {
    if (account && isMarioPresaleToken(token)) {
      balanceOfShares(token, account).then((res) => {
        setAvailable(res)
      })
    } else {
      const owner = getOwnerByAddress(order.maker, token.ownerships)
      setAvailable(owner ? owner.stock.toString() : '1')
    }
  }, [account, order.maker, token])

  return available
}
