import React from 'react'

import { Item } from './styles'

export const Tab = ({ link, title }: { title: string; link: string }): JSX.Element => {
  return (
    <Item to={link} exact={true} activeClassName="active">
      {title}
    </Item>
  )
}
