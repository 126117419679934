import React, { useEffect } from 'react'

import { Token } from '../../../domain'
import { TokenImage } from '../../../ui/common/TokenImage'
import { BackLink } from '../../../ui/components/BackLink'
import { Inner, Wrapper } from './styles'

export const ZoomIn = ({ token, close }: { token: Token; close: () => void }): JSX.Element => {
  useEffect(() => {
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen()
    }
    return () => {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }, [])

  return (
    <Wrapper>
      <Inner className="container">
        <BackLink onClick={close}>Back</BackLink>
        <TokenImage token={token} />
      </Inner>
    </Wrapper>
  )
}
