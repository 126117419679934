import styled from 'styled-components'

export const MessengerContainer = styled.div`
  width: 100%;
  gap: 26px;
  display: flex;
  flex-direction: column;
`

export const Description = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
`

export const Buttons = styled.div`
  display: flex;
  gap: 16px;
  width: fit-content;
  margin-left: auto;
  align-items: center;
`
