import styled from 'styled-components'

import greenCheck from '../assets/green-check.svg'
import whiteCheck from '../assets/white-check.svg'
import notCheck from '../assets/x.svg'
import { Container } from '../styled'

export const TableContainer = styled(Container)`
  @media screen and (max-width: 1240px) {
    align-items: flex-start;
  }
`

export const TableListWrapper = styled.div`
  width: 100%;
  display: flex;
  &::-webkit-scrollbar {
    width: 0;
  }
  @media screen and (max-width: 1240px) {
    overflow: scroll;
    width: calc(100% + 90px);
  }
  @media screen and (max-width: 740px) {
    width: calc(100% + 40px);
  }
`

export const TableListBlock = styled.table`
  width: 100%;
  background-color: var(--white);
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  & > .table-row:first-child {
    & > .table-cell {
      padding-top: 20px;
      height: 100px;
      font-weight: 600;
    }
  }
  & > .table-row:nth-child(even) {
    background-color: var(--table-bg);
  }
  & > .table-row:last-child {
    & > .table-cell {
      padding-bottom: 20px;
      height: 100px;
    }
  }
  &.highlight > .table-row:first-child > .table-cell:nth-child(2) {
    background-color: #6dd2ff;
    border-radius: 24px 24px 0px 0px;
  }
  &.highlight > .table-row:nth-child(2) > .table-cell:nth-child(2) {
    background-color: #8ecbff;
  }
  &.highlight > .table-row:nth-child(3) > .table-cell:nth-child(2) {
    background-color: #93b9ff;
  }
  &.highlight > .table-row:nth-child(4) > .table-cell:nth-child(2) {
    background-color: #adb6ff;
  }
  &.highlight > .table-row:nth-child(5) > .table-cell:nth-child(2) {
    background-color: #b7a2ff;
  }
  &.highlight > .table-row:nth-child(6) > .table-cell:nth-child(2) {
    background-color: #cea0ff;
  }
  &.highlight > .table-row:nth-child(7) > .table-cell:nth-child(2) {
    background-color: #d792f6;
  }
  &.highlight > .table-row:nth-child(8) > .table-cell:nth-child(2) {
    background-color: #e3a7e0;
  }
  &.highlight > .table-row:nth-child(9) > .table-cell:nth-child(2) {
    background-color: #e8a7c3;
  }
  &.highlight > .table-row:nth-child(10) > .table-cell:nth-child(2) {
    background-color: #f2bab2;
  }
  &.highlight > .table-row:last-child > .table-cell:nth-child(2) {
    background-color: #fabe8d;
    border-radius: 0 0 24px 24px;
  }
  @media screen and (max-width: 1240px) {
    width: 1176px;
    flex-shrink: 0;
  }
  @media screen and (max-width: 740px) {
    width: 1040px;
  }
`

export const TableRow = styled.tr`
  width: 100%;
  & > .table-cell:first-child {
    width: 30%;
  }
  &.highlight > .table-cell:first-child {
    font-weight: 600;
  }
  &.highlight > .table-cell:nth-child(2) {
    color: var(--white);
  }
  & > .table-cell:nth-child(2) {
    width: 20%;
  }
  & > .table-cell:nth-child(n + 3) {
    width: 12.5%;
  }
  & > .white {
    background-image: url(${whiteCheck});
    background-size: 30px 20px;
  }
  & > .green {
    background-image: url(${greenCheck});
    background-size: 15px 10px;
  }
  & > .not {
    background-image: url(${notCheck});
    background-size: 10px 10px;
  }
  @media screen and (max-width: 740px) {
    & > .table-cell:nth-child(2) {
      width: 18%;
    }
    & > .table-cell:nth-child(n + 3) {
      width: 13%;
    }
  }
`

export const TableCell = styled.td`
  height: 80px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding: 0 16px;

  &.left {
    text-align: left;
  }
`

export const SectionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
`

export const ConfidenceContainer = styled(Container)`
  @media screen and (max-width: 1024px) {
    margin-top: -90px;
  }
  @media screen and (max-width: 740px) {
    margin-top: 120px;
  }
`
