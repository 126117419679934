import styled from 'styled-components'

export const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-column-gap: 32px;
  grid-template-columns: repeat(5, max-content);
  border-bottom: 1px solid var(--line-day);
  margin: 0;
`
