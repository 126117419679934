import { Link } from 'react-router-dom'
import styled from 'styled-components'

import close from '../../../../assets/img/icons/close.svg'
import options from '../../../../assets/img/icons/dots-dark.svg'

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
  width: 100%;
  border-bottom: 1px solid var(--line-day);
  position: relative;
`

export const MessengerNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`

export const MenuButtons = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 32px;
`

export const SettingsButton = styled.button`
  width: 16px;
  height: 16px;
  background: url(${options}) center no-repeat;
  background-size: 100%;
  transform: rotate(90deg);
`

export const HeaderButton = styled.button`
  padding: 0;
  align-items: center;
  gap: 4px;
  display: flex;
`

export const HeaderButtonText = styled.span<{ active: boolean }>`
  color: ${(props) => (props.active ? 'var(--text-dark)' : 'var(--text-gray)')};
  font-weight: 700;
  line-height: 24px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--blue);
    display: ${(props) => (props.active ? 'block' : 'none')};
    bottom: -15px;
  }
`

export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  background: var(--red);
  opacity: 0.8;
  border-radius: 50%;
  flex-shrink: 0;
`

export const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  background: url(${close}) center no-repeat;
  background-size: 12px;
  margin-left: 16px;
`

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 14px;
  position: relative;
`

export const ArrowBack = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  & img {
    width: 15px;
  }
`

export const UserBlock = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const UserName = styled.p`
  font-weight: 700;
  display: inline-flex;
  gap: 4px;
  align-items: center;
`

export const UserRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const OptionButton = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px 0 14px;
  gap: 14px;
  &:hover {
    background: var(--link-gray);
  }
`

export const OptionIcon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

export const ChatOptions = styled.div`
  position: absolute;
  z-index: 7;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  top: 38px;
  right: 20px;
`

export const SettingsOptions = styled.div`
  position: absolute;
  z-index: 7;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  top: 32px;
  right: -6px;
`
