import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    background: var(--link-gray);
  }
  & > div:first-child {
    flex-shrink: 0;
  }
`

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`

export const CardTitle = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
`

export const UserName = styled.div`
  font-weight: 700;
  display: inline-flex;
  gap: 4px;
  align-items: center;
`

export const LastMessageDate = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  color: var(--text-gray);
`

export const MessagePreview = styled.div`
  max-width: 226px;
  width: 100%;
  color: var(--text-gray);
  font-size: 12px;
  line-height: 16px;
`

export const Indicator = styled.div`
  width: 8px;
  height: 8px;
  background: var(--red);
  opacity: 0.8;
  border-radius: 50%;
  flex-shrink: 0;
`
