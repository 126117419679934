import { useContext } from 'react'

import { RollappContext } from '../context'
import { Token } from '../domain'
import { CreateErrors, CreateValues } from '../domain/forms/types/create.types'
import { AppNetwork } from '../domain/network/types'
import { Modals } from '../types/modalTypes'

type Context = {
  modal: Modals | undefined
  setModal: (modal: Modals | undefined) => void
  network: AppNetwork
  setNetwork: (network: string | undefined) => void
  token: Token
  openToken: (modal: Modals | undefined, token: Token | undefined, taker?: string) => void
  taker: string | undefined
  rechargePool: () => void
  backToNetworkMove: () => void
  depositToken: Token | undefined

  create: CreateValues
  setCreate: (value: CreateValues) => void
  createErrors: CreateErrors
  setCreateErrors: (value: CreateErrors) => void
}

export const useRollapp = (): Context => {
  const context = useContext(RollappContext)

  return context as Context
}
