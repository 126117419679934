import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import SoldOut from '../_common/SoldOut'
import { DAO_DATA } from '../data/data'
import circle from '../media/circle.svg'
import gallery from '../media/icons/gallery.svg'
import note from '../media/icons/note.svg'
import opinion from '../media/icons/opinion.svg'
import {
  Document,
  DocumentLink,
  Documents,
  Inner,
  ItemName,
  PageName,
  TextBlock,
  Wrapper
} from './styled'

const Container = styled.div<{ width?: string }>`
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : '520px')};
`

export const Image = styled.div<{ noBg?: boolean }>`
  background: url(${(props) => (props.noBg ? '' : circle)}) no-repeat center;
  background-size: contain;
  margin-top: 32px;
  margin-bottom: 48px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    flex-shrink: 0;
  }

  @media screen and (max-width: 916px) {
    img {
      height: 100%;
      object-fit: contain;
    }
  }
`

const Info = (props: { tag: string; legal: () => void }): JSX.Element => {
  const history = useHistory()
  const { hashtag, title, info, width } = DAO_DATA[props.tag]
  const { docs } = info
  const { legal } = props

  return (
    <Inner id="info" className="slide" style={{ height: info.height }}>
      <Wrapper>
        <Container width={width}>
          <ItemName>#{hashtag}</ItemName>
          <PageName className="h2">{title}</PageName>
          {info.text && <TextBlock style={{ textAlign: 'center' }}>{info.text}</TextBlock>}
          {docs && (
            <Documents columns={4}>
              {docs.opinions && (
                <Document img={opinion} onClick={() => history.push('#quotes')}>
                  Opinions
                </Document>
              )}
              {docs.details && (
                <Document onClick={() => history.push('#details')}>Details</Document>
              )}
              {docs.gallery && (
                <DocumentLink to={`/dao-nfts/${props.tag}/gallery`}>
                  <Document img={gallery}>Gallery</Document>
                </DocumentLink>
              )}
              {docs.legal && (
                <Document img={note} onClick={legal}>
                  Legal
                </Document>
              )}
            </Documents>
          )}
          {info.img && (
            <Image noBg>
              <img src={info.img} />
              {info.soldOut && <SoldOut />}
            </Image>
          )}
          {React.createElement(info.data)}
        </Container>
      </Wrapper>
    </Inner>
  )
}

export default Info
