import React from 'react'

const Link = (): JSX.Element => (
  <a href="https://curioinvest.com/register" className="a" target="_blank" rel="noreferrer">
    Go to CurioInvest
  </a>
)

export const CT1WhitelistText = (): JSX.Element => (
  <>
    To own CT1 tokens, you need to have an account at curioinvest.com and go through the KYC / AML
    procedure. <Link />
  </>
)

export const CreateWhitelistText = (): JSX.Element => (
  <>
    To create your own Asset Tokens, you need to have an account at curioinvest.com and go through
    the KYC / AML procedure for connected wallet. <Link />
  </>
)

export const TransferWhitelistText = (): JSX.Element => (
  <>
    Receiver address must be whitelisted, i.e. the recipient must pass KYC on <Link />
  </>
)

export const WhitelistText = (): JSX.Element => (
  <>
    To purchase, receiver account need to go through the KYC / AML procedure at <Link />
  </>
)

export const BuyNFTText = (): JSX.Element => (
  <>
    <p>
      The purchase is currently only available to owners of at least one Curio Founders Edition NFT
    </p>
    <a href="/" className="button primary-button" rel="noreferrer">
      Explore Curio Founders Edition NFTs
    </a>
  </>
)
