import Web3 from 'web3'

import { infuraProvider } from '../../config/infura'
import { IS_TESTNET } from '../../config/rollapp'
import { SCHAIN_HASH, SCHAIN_NAME } from '../../config/shain'
import { CHAIN_ID } from '../../domain/network/contstant'
import { Web3Type } from '../../types/web3'
import { contractError } from '../contractError'
import { COMMUNITY_POOL_ABI } from './abi'
import { COMMUNITY_POOL } from './addresses'

export const getPoolBalance = async (w: Web3Type): Promise<string> => {
  const web3 = new Web3(w.library)
  try {
    contractError(COMMUNITY_POOL[w.chainId])
    return await new web3.eth.Contract(COMMUNITY_POOL_ABI, COMMUNITY_POOL[w.chainId]).methods
      .getBalance(w.account, SCHAIN_NAME[w.chainId])
      .call()
  } catch (e) {
    console.error(e)
    return '0'
  }
}

export const checkUserBalance = async (w: Web3Type): Promise<boolean> => {
  const chainId = CHAIN_ID.eth[IS_TESTNET ? 'testnet' : 'mainnet']
  const web3 = new Web3(infuraProvider)
  try {
    contractError(COMMUNITY_POOL[chainId])
    return await new web3.eth.Contract(COMMUNITY_POOL_ABI, COMMUNITY_POOL[chainId]).methods
      .checkUserBalance(SCHAIN_HASH[chainId], w.account)
      .call()
  } catch (e) {
    console.error(e)
    return false
  }
}

export const rechargeUserWallet = async (w: Web3Type, value: string): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(COMMUNITY_POOL[w.chainId])
    await new web3.eth.Contract(COMMUNITY_POOL_ABI, COMMUNITY_POOL[w.chainId]).methods
      .rechargeUserWallet(SCHAIN_NAME[w.chainId], w.account)
      .send({ from: w.account, value })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const withdrawFunds = async (w: Web3Type, value: string): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(COMMUNITY_POOL[w.chainId])
    await new web3.eth.Contract(COMMUNITY_POOL_ABI, COMMUNITY_POOL[w.chainId]).methods
      .withdrawFunds(SCHAIN_NAME[w.chainId], value)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
