import React from 'react'

const MiniDc = (): JSX.Element => (
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2286 0.0160341L11.2644 0.0017715H11.2416L11.2286 0.0160341Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8174 0.000583059C6.8174 0.000583059 4.97417 -0.0754828 2.98556 1.51002C2.98556 1.51002 0.996948 5.24676 0.996948 9.85351C0.996948 9.85351 2.0992 11.9216 5.12171 12C5.12171 12 5.56326 11.3665 6.00915 10.8091C4.3113 10.2529 3.6506 9.14039 3.6506 9.14039L4.21799 9.45654C4.32773 9.50534 4.43789 9.55497 4.54885 9.60497C6.11612 10.3112 7.84118 11.0885 10.7632 10.569C10.7784 10.5653 10.7937 10.5616 10.8089 10.5579C11.4544 10.4012 12.1009 10.2443 12.7463 9.93314C12.8648 9.86759 12.9909 9.80205 13.1235 9.73309C13.4718 9.55196 13.8654 9.34728 14.2858 9.05719C14.2858 9.05719 13.624 10.1697 11.8556 10.7259C12.2202 11.2797 12.7322 11.9144 12.7322 11.9144C15.2096 11.856 16.4459 10.4571 16.8482 10.0019C16.9369 9.9016 16.985 9.84713 16.9969 9.86302C16.9969 5.2634 14.9964 1.51953 14.9964 1.51953C13.2085 0.0873462 11.5378 0.0231649 11.2221 0.0219764L11.0203 0.239478C13.304 0.952597 14.4095 2.06625 14.4095 2.06625C11.0962 0.278699 7.51715 0.176486 4.23969 1.58846C3.72437 1.82973 3.42928 1.98781 3.42928 1.98781C3.42928 1.98781 4.53261 0.796899 6.96603 0.161035L6.8174 0.000583059ZM6.44962 8.42608C7.22098 8.42608 7.84913 7.71296 7.84913 6.83345C7.84913 5.95988 7.22532 5.24676 6.45396 5.24676L6.44962 5.25033C5.67826 5.25033 5.05011 5.96107 5.05011 6.84058C5.05011 7.71297 5.67826 8.42608 6.44962 8.42608ZM11.4597 8.42608C12.231 8.42608 12.8592 7.71296 12.8592 6.83345C12.8592 5.95988 12.2343 5.24676 11.4597 5.24676V5.25033C10.6915 5.25033 10.0623 5.96107 10.0601 6.84058C10.0601 7.71297 10.6883 8.42608 11.4597 8.42608Z"
    />
  </svg>
)

export default MiniDc
