import React, { useContext } from 'react'

import closeIcon from '../../../../assets/img/icons/close.svg'
import { MessengerContext } from '../../../../context/Messenger'
import { CloseButton, Inner, ModalHeader, ModalTitle, Wrap } from './styles'

type Props = {
  title?: string
  children: JSX.Element
  fullHeight?: boolean
}

export const MessengerModal = (props: Props): JSX.Element => {
  const { setModal } = useContext(MessengerContext)

  return (
    <Wrap>
      <Inner fullHeight={props.fullHeight}>
        <ModalHeader>
          <ModalTitle>{props.title}</ModalTitle>
          <CloseButton onClick={() => setModal('')}>
            <img src={closeIcon} />
          </CloseButton>
        </ModalHeader>
        {props.children}
      </Inner>
    </Wrap>
  )
}
