import styled from 'styled-components'

export const Wrapper = styled.p`
  font-size: 12px;
  line-height: 20px;
`

export const Link = styled.span`
  color: var(--blue);
  cursor: pointer;
`
