import styled from 'styled-components'

export const Wrapper = styled.main`
  position: relative;
  --width: 532px;
  --margin-row: 36px;

  ul {
    padding: 0;
    list-style-position: inside;
  }

  a {
    color: var(--text-light);
  }

  @media (max-width: 970px) {
    --width: 376px;
  }

  @media (max-width: 768px) {
    --width: 100%;
    padding-top: 0;
  }
`
