import React, { ChangeEvent, useState } from 'react'

import { approve } from '../../../contracts/erc20/methods'
import { createOrder, Token } from '../../../domain'
import { useCGTBalance } from '../../../hooks/useCGTBalance'
import { useERC20Allowance } from '../../../hooks/useERC20Allowance'
import { validate } from '../../../services/validate-form'
import { Web3Type } from '../../../types/web3'
import { getSum } from '../../../utils/sum'
import { toFixed } from '../../../utils/toFixed'
import { fromWei, toWei } from '../../../utils/wei'
import { CheckWhitelist } from '../../common/CheckWhitelist'
import { InputContainer } from '../../common/Footer/styled'
import { Modal } from '../../common/Modal'
import { Owner } from '../../common/Owner'
import { CancelBtn } from '../../components/CancelBtn'
import { Input } from '../../components/Form/Input'
import { LabelWrapper } from '../../components/Form/Label/LabelWrapper'
import {
  Description,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  TokenName,
  Wrapper
} from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const PlaceBidModal = ({
  web3,
  token
}: {
  web3: Web3Type
  token: Token
}): JSX.Element | null => {
  const [values, setValues] = useState({ bid: '1', quantity: '1' })
  const [errors, setErrors] = useState<{
    bid: string | null
    quantity: string | null
  }>({ bid: null, quantity: null })
  const [approved, setApproved] = useState(false)

  const available = token.standard === 'ERC1155' ? token.supply : 1
  const sum = getSum(values.bid, parseInt(values.quantity))

  const balance = useCGTBalance(web3)
  const allowance = useERC20Allowance(web3, toWei(sum))

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    const error = validate(['number'], value)
    setValues({ ...values, [name]: value })
    setErrors({ ...errors, [name]: error })
    if (error === null) {
      if (name === 'quantity') {
        setErrors({ ...errors, quantity: parseInt(value) > available ? 'Invalid quantity' : null })
      }
      if (name === 'bid') {
        setErrors({ ...errors, bid: parseInt(value) > 0 ? null : 'Invalid bid' })
      }
    }
  }

  return (
    <Modal title="Place a bid" width="350px">
      <CheckWhitelist web3={web3} token={token}>
        <Wrapper>
          <Description color="gray">
            You are about to place a bid for <span>{token.name}</span> <Owner token={token} />
          </Description>
          <LabelWrapper title="Your bid" error={errors.bid}>
            <InputContainer>
              <Input
                value={values.bid}
                name="bid"
                type="number"
                placeholder="Your bid"
                min="1"
                onChange={onChange}
              />
              <TokenName>CGT</TokenName>
            </InputContainer>
          </LabelWrapper>

          {token.standard === 'ERC1155' && (
            <LabelWrapper title="Enter quantity" error={errors.quantity}>
              <>
                <Input
                  value={values.quantity}
                  name="quantity"
                  type="number"
                  min="1"
                  onChange={onChange}
                />
                <small>Enter quantity. {available} available</small>
              </>
            </LabelWrapper>
          )}
          <ModalInfo>
            <InfoRow>
              <InfoType>Your bidding balance</InfoType>
              <InfoValue>{sum} CGT</InfoValue>
            </InfoRow>
            <InfoRow>
              <InfoType>Your balance</InfoType>
              {balance && <InfoValue>{toFixed(fromWei(balance))} CGT</InfoValue>}
            </InfoRow>
          </ModalInfo>
          <StatusBlock
            name="Approve"
            text={`Approve ${sum} CGT`}
            disabled={
              !!balance
                ? errors.bid !== null ||
                  errors.quantity !== null ||
                  parseFloat(balance) < parseFloat(sum)
                : true
            }
            method={async () => {
              const res = await approve(web3, toWei(sum))
              setApproved(res)
              return res
            }}
            check={allowance}
          />
          <StatusBlock
            name="Place a bid"
            text={`Place a bid ${sum} CGT`}
            disabled={(!allowance && !approved) || errors.bid !== null || errors.quantity !== null}
            method={() => createOrder(web3, token, sum, values.quantity, true)}
          />
          <CancelBtn />
        </Wrapper>
      </CheckWhitelist>
    </Modal>
  )
}
