import React, { useEffect, useState } from 'react'

import complete from '../../../assets/img/icons/check-complete.svg'
import start from '../../../assets/img/icons/check-start.svg'
import error from '../../../assets/img/icons/error.svg'
import { Status } from '../../../types/status'
import { ErrorText } from '../Form/Error'
import { Loader } from '../Loader'
import { Circle, ProgresBox, StepBox, StepDescription, StepInfo, StepType } from './styles'

type Props = {
  name: string
  text?: string | JSX.Element
  disabled?: boolean
  method: () => Promise<boolean>
  check?: boolean
  errorText?: string
}

export const StatusBlock = ({
  name,
  text,
  disabled,
  method,
  check,
  errorText
}: Props): JSX.Element => {
  const [status, setStatus] = useState<Status>(check ? 'done' : 'start')

  const startHandle = async () => {
    setStatus('pending')
    const value = await method()
    setStatus(value ? 'done' : 'error')
  }

  useEffect(() => {
    setStatus(check ? 'done' : 'start')
  }, [check])

  return (
    <StepBox key={name}>
      <ProgresBox>
        <Circle status={status}>
          {status === 'done' || status === 'error' || status === 'start' ? (
            <img
              src={status === 'done' ? complete : status === 'error' ? error : start}
              alt="Status"
            />
          ) : (
            <Loader width={20} bg="line-day" />
          )}
        </Circle>
        <StepInfo>
          <StepType>
            {name}
            {status === 'error' && <ErrorText>Canceled</ErrorText>}
          </StepType>
          {text && (
            <StepDescription>{status === 'error' ? errorText || text : text}</StepDescription>
          )}
        </StepInfo>
      </ProgresBox>
      {status === 'done' ? null : (
        <button
          className="primary-button block-button button"
          onClick={status !== 'pending' ? startHandle : undefined}
          disabled={status === 'pending' || disabled}
          type="button"
        >
          {status === 'error' ? 'Try again' : status === 'pending' ? 'In progress' : 'Start'}
        </button>
      )}
    </StepBox>
  )
}
