import React, { ChangeEvent, useState } from 'react'

import { approve } from '../../../../contracts/erc20/methods'
import { OrderApi, Token } from '../../../../domain'
import { changePriceOrder } from '../../../../domain/order/methods/changePriceOrder'
import { useCGTBalance } from '../../../../hooks/useCGTBalance'
import { useERC20Allowance } from '../../../../hooks/useERC20Allowance'
import { validate } from '../../../../services/validate-form'
import { ErrorStatus } from '../../../../services/validate-form/types'
import { Web3Type } from '../../../../types/web3'
import { getPriceStock, getSum } from '../../../../utils/sum'
import { toFixed } from '../../../../utils/toFixed'
import { fromWei, toWei } from '../../../../utils/wei'
import { CancelBtn } from '../../../components/CancelBtn'
import { Input, InputContainer } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import {
  Description,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  TokenName
} from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'

export const Form = ({
  web3,
  token,
  offer
}: {
  web3: Web3Type
  token: Token
  offer: OrderApi
}): JSX.Element => {
  const oldPrice = getPriceStock(offer.make.value, offer.take.value)
  const [value, setValue] = useState(oldPrice)
  const oldStock = offer.take.value
  const [stock, setStock] = useState(offer.take.value)
  const [error, setError] = useState<ErrorStatus>(null)
  const [approved, setApproved] = useState(false)

  const sum = getSum(value, parseInt(stock))
  const balance = useCGTBalance(web3)
  const allowance = useERC20Allowance(web3, toWei(sum))

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    const err = validate(['number'], value)
    setError(
      !err && balance
        ? value === '' || BigInt(toWei(sum)) > BigInt(balance)
          ? 'Invalid bid'
          : null
        : null
    )
  }

  return (
    <>
      <Description>
        You are about to edit your bid for <span>{token.name}</span> from{' '}
        <span>{token.collection.name}</span> collection.
      </Description>

      <LabelWrapper title="Your bid">
        <InputContainer>
          <Input
            type="text"
            placeholder="Enter price for one piece"
            value={value}
            onChange={onChange}
          />
          <TokenName>CGT</TokenName>
        </InputContainer>
      </LabelWrapper>

      {offer.take.assetType.assetClass === 'ERC1155' && (
        <LabelWrapper title="Quantity">
          <Input
            type="text"
            placeholder="Enter pieces"
            value={stock}
            onChange={(e) => {
              setStock(e.target.value)
            }}
          />
        </LabelWrapper>
      )}

      <ModalInfo>
        <InfoRow>
          <InfoType>Your bidding balance</InfoType>
          <InfoValue>{sum} CGT</InfoValue>
        </InfoRow>
        <InfoRow>
          <InfoType>Your balance</InfoType>
          {balance && <InfoValue>{toFixed(fromWei(balance))} CGT</InfoValue>}
        </InfoRow>
      </ModalInfo>

      <StatusBlock
        name={`Approve ${sum} CGT`}
        method={async () => {
          const res = await approve(web3, toWei(sum))
          setApproved(res)
          return res
        }}
        disabled={!!error || (value === oldPrice && oldStock === stock)}
        check={allowance}
      />

      <StatusBlock
        name="Edit bid"
        method={() => changePriceOrder(web3, token.collection.network, offer, value, stock, true)}
        disabled={(!allowance && !approved) || value === oldPrice}
      />

      <CancelBtn />
    </>
  )
}
