import React from 'react'
import { Link } from 'react-router-dom'

import { P, Title, Wrapper } from './styles'

export const NoData = (): JSX.Element => (
  <Wrapper>
    <Title>No data item!</Title>
    <P>Come back soon! Or try to browse something for you on our marketplace</P>
    <Link className="button primary-button" to="/">
      Browse marketplace
    </Link>
  </Wrapper>
)
