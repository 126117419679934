import React from 'react'

import { cancel } from '../../../contracts/exchange'
import { getOrderByOwner, prepareExchangeOrder, Token } from '../../../domain'
import { clientV3 } from '../../../services/api'
import { Web3Type } from '../../../types/web3'
import { Modal } from '../../common/Modal'
import { CancelBtn } from '../../components/CancelBtn'
import { Description, Wrapper } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const RemoveFromSaleModal = ({
  web3,
  token
}: {
  web3: Web3Type
  token: Token
}): JSX.Element => {
  const { account } = web3

  const cancelHandle = async (): Promise<boolean> => {
    try {
      const { data } = await clientV3.token.orders(token.id)
      const order = getOrderByOwner(data, account)
      if (order) {
        const orderExchange = prepareExchangeOrder(order)
        return await cancel(web3, orderExchange)
      }
      return false
    } catch (e) {
      return false
    }
  }

  return (
    <Modal title="Remove from sale" width="350px">
      <Wrapper>
        <Description>
          Do you really want to remove your item from sale? You can put it on sale anytime
        </Description>
        <StatusBlock name="Remove from sale" method={() => cancelHandle()} />
        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
