import styled from 'styled-components'

export const AvatarWrapper = styled.div<{ width?: string; border?: string }>`
  border-radius: 50%;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.width ? props.width : '100%')};
  border: ${(props) =>
    props.border === 'white'
      ? '3px solid var(--white)'
      : props.border === '2px'
      ? '2px solid var(--white)'
      : 'none'};

  padding: 0;
  overflow: hidden;
  background-color: var(--white);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`
