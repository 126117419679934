import { getChainIdByChainName } from '../../network/mappers'
import { Token } from '../../token'
import { DAVOS_TOKEN_ID, WINES_WITH_ROYALTIES_TOKEN_IDS } from '../../token/constants'
import {
  CURIO_ASSETS_CARS,
  CURIO_ASSETS_GEEK_1155,
  CURIO_ASSETS_WINES,
  CURIO_FOUNDERS_EDITION_NFTS,
  CURIO_FOUNDERS_EDITION_NFTS_NEW,
  IT_REMAINS,
  MUSIC_CLUB1,
  MUSIC_CLUB2,
  THE_OCEAN
} from '../constants'
import { CHAILINK_TOKENS } from '../constants/chainlink'

export const templateFunction = (addresses: { [p: number]: string }, token: Token): boolean =>
  !!addresses[getChainIdByChainName(token.collection.network)] &&
  addresses[getChainIdByChainName(token.collection.network)].toLowerCase() ===
    token.contract.toLowerCase()

export const isOldCollection = (token: Token): boolean =>
  templateFunction(CURIO_FOUNDERS_EDITION_NFTS, token)

export const isNewCollection = (token: Token): boolean =>
  templateFunction(CURIO_FOUNDERS_EDITION_NFTS_NEW, token)

export const isMigrateCollection = (token: Token): boolean =>
  (isOldCollection(token) || isNewCollection(token)) &&
  !!token.category &&
  (token.category.tag === 'season-2' || token.category.tag === 'season-1')

export const isMarioCollection = (token: Token): boolean =>
  templateFunction(CURIO_ASSETS_GEEK_1155, token)

export const isMercedesCollection = (token: Token): boolean =>
  templateFunction(CURIO_ASSETS_CARS, token)

export const isChainlinkToken = (token: Token): boolean =>
  !!CHAILINK_TOKENS.find(
    (item) => item.toLowerCase() === `${token.contract.toLowerCase()}:${token.tokenId}`
  )

export const isMarioPresaleToken = (token: Token): boolean =>
  isMarioCollection(token) && !!token.presale

export const isItRemains = (token: Token): boolean => templateFunction(IT_REMAINS, token)

export const isWinesWithRoyalties = (token: Token): boolean =>
  templateFunction(CURIO_ASSETS_WINES, token) &&
  WINES_WITH_ROYALTIES_TOKEN_IDS.includes(token.tokenId)

export const isMusicClub = (token: Token): boolean =>
  (templateFunction(MUSIC_CLUB1, token) || templateFunction(MUSIC_CLUB2, token)) &&
  token.tokenId === DAVOS_TOKEN_ID

export const isLeoTrust = (token: Token): boolean =>
  templateFunction(THE_OCEAN, token) && token.tokenId === DAVOS_TOKEN_ID
