import React from 'react'

import { Modals } from '../../../types/modalTypes'
import { CardMenuItem } from './styles'

export const MercedesMenu = ({
  openModal
}: {
  openModal: (modal: Modals) => void
}): JSX.Element => {
  return (
    <>
      <CardMenuItem onClick={() => openModal('stake-shares')}>Stake</CardMenuItem>
      <CardMenuItem onClick={() => openModal('unstake-shares')}>Unstake</CardMenuItem>
      <CardMenuItem onClick={() => openModal('claim-rewards')}>Claim rewards</CardMenuItem>
    </>
  )
}
