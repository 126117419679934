import React, { useEffect, useState } from 'react'

import { Category } from '../../../domain'
import { useCurrentCategory } from '../../../hooks/useTokens'
import { clientV3 } from '../../../services/api'
import { FilterButton } from '../../../ui/components/FilterButton'
import { Popover } from '../../../ui/components/Popover'
import { Category as Icon } from '../../../ui/icons/Category'
import { Wrapper } from './styles'

export const Categories = (): JSX.Element => {
  const [show, setShow] = useState(false)
  const [list, setList] = useState<Category[]>([])
  const [current, setCurrent] = useState<Category>()

  const { category, setParam } = useCurrentCategory()

  useEffect(() => {
    clientV3.categories.nft().then((res) => {
      const list: Category[] = [{ tag: 'all', id: '1', name: 'Category' }, ...res.data]
      setList(list)
    })
  }, [])

  useEffect(() => {
    const current = list.filter((item) => (category ? item.tag === category : item.tag === 'all'))
    setCurrent(current[0])
  }, [category, list])

  const changeLocation = (item: Category) => {
    setParam('category', item.tag)
    setShow(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <FilterButton icon={Icon()} title={current?.name} onClick={() => setShow(!show)} />
      {show && (
        <Popover width="auto" close={() => setShow(false)}>
          <Wrapper>
            {list.map((item) => (
              <li
                style={{ fontWeight: item.tag === current?.tag ? 700 : 500 }}
                key={item.id}
                onClick={() => changeLocation(item)}
              >
                {item.name === 'Category' ? 'All' : item.name}
              </li>
            ))}
          </Wrapper>
        </Popover>
      )}
    </div>
  )
}
