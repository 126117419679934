import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 38px;
  height: 38px;

  @media screen and (max-width: 640px) {
    position: inherit;
  }
`

export const AvatarButton = styled.button`
  padding: 0;
`
