import React, { useState } from 'react'

import { PreviewProfile } from '../../../domain'
import { AccountMenu } from '../AccountMenu'
import { Avatar } from '../Avatar'
import { Popover } from '../Popover'
import { AvatarButton, Wrapper } from './styles'

export const Account = ({ profile }: { profile: PreviewProfile }): JSX.Element => {
  const [popup, setPopup] = useState(false)
  return (
    <Wrapper>
      <AvatarButton onClick={() => setPopup(!popup)}>
        <Avatar width="38px" image={profile.photo.avatarS3Link || profile.photo.avatar} />
      </AvatarButton>
      {popup && (
        <Popover width="300px" top="25px" fullWidth close={() => setPopup(false)}>
          <AccountMenu name={profile.personal.displayName} />
        </Popover>
      )}
    </Wrapper>
  )
}
