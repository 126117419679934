import { useWeb3React } from '@web3-react/core'
import React from 'react'

import { haveFixedPriceByOwner, isAvailableForPurchasing, Token, UserData } from '../../../domain'
import { useRollapp } from '../../../hooks/useRollapp'
import { TokenBlockchain } from '../TokenBlockchain'
import { TokenUser } from '../TokenUser'
import { Wrapper } from './styles'

export const Owners = ({ owners, token }: { owners: UserData[]; token: Token }): JSX.Element => {
  const { account } = useWeb3React()
  const { openToken } = useRollapp()

  return (
    <>
      {owners.length > 0 &&
        owners.map((owner) => (
          <Wrapper key={owner.profile.id}>
            <TokenUser profile={owner.profile} info={owner.info} />
            {token.standard === 'ERC1155' &&
              account?.toLowerCase() !== owner.profile.walletAddress &&
              haveFixedPriceByOwner(token.ownerships, owner.profile.walletAddress) &&
              isAvailableForPurchasing(token.ownerships, owner.profile.walletAddress) && (
                <button
                  className="button primary-button"
                  onClick={() => openToken('checkout', token, owner.profile.walletAddress)}
                >
                  Buy
                </button>
              )}
          </Wrapper>
        ))}
      {token.standard === 'ERC721' && <TokenBlockchain token={token} />}
    </>
  )
}
