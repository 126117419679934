import axios from 'axios'

import { URLS } from '../config/rollapp'

type GweiPrice = {
  data: {
    average: number
  }
}

export const getGweiPrice = (): Promise<GweiPrice> => axios.get(URLS.gwei)
