import styled from 'styled-components'

export const MessengerWrapper = styled.div`
  max-height: 480px;
  height: calc(100vh - 48px);
  position: fixed;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--line-day);
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
`

export const MessengerBody = styled.div`
  height: calc(100vh - 48px - 53px);
  max-height: calc(480px - 53px);
`

export const SignInWindow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  gap: 10px;
  padding: 24px 24px;
  flex-direction: column;
`

export const SignInText = styled.p`
  text-align: center;
  color: var(--text-gray);
`

export const Chats = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 0 0;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const Requests = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 12px 0 0;
  height: 100%;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const RequestWindow = styled.div`
  height: 119px;
  margin-top: auto;
  border-top: 1px solid var(--line-day);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`

export const RequestText = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-gray);
`

export const AddChatButton = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: var(--blue);
  position: absolute;
  right: 18px;
  bottom: 6px;
  z-index: 99;
  & svg {
    fill: var(--white);
  }
`
