import React from 'react'

import { Socials } from '../../../domain'
import { Social } from './Social'
import { Wrapper } from './styles'

export const SocialsBlock = ({ socials }: { socials: Socials }): JSX.Element => (
  <Wrapper>
    {Object.entries(socials).map(([key, value]) => (
      <Social type={key} key={key} value={value} />
    ))}
  </Wrapper>
)
