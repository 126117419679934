import MiniApple from '../ui/icons/MiniApple'
import MiniDc from '../ui/icons/MiniDc'
import MiniGplay from '../ui/icons/MiniGplay'
import MiniIg from '../ui/icons/MiniIg'
import MiniTw from '../ui/icons/MiniTw'

export const SOCIALS = [
  {
    link: 'https://www.instagram.com/curioinvest/',
    icon: MiniIg()
  },
  {
    link: 'https://twitter.com/curio_invest',
    icon: MiniTw()
  },
  {
    link: 'https://discord.com/invite/VSvY6tBF4g',
    icon: MiniDc()
  }
]

export const APPS = [
  {
    icon: MiniApple()
  },
  {
    icon: MiniGplay()
  }
]
