import React from 'react'
import styled from 'styled-components'

import { Chart } from '../_common/Chart'
import { PriceType } from '../data/data'
import PriceValue from './components/PriceValue'
import { Inner } from './styled'

const Text = styled.div`
  margin-bottom: 90px;
`

const Title = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 8px;
  text-align: center;
`

const PriceSlide = (props: { price: PriceType }): JSX.Element => {
  return (
    <Inner id="price" className="slide">
      <Text>
        <Title>Price</Title>
        <PriceValue price={props.price.value} perc={props.price.change} />
      </Text>
      <Chart />
    </Inner>
  )
}

export default PriceSlide
