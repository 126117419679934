import styled from 'styled-components'

export const Wrap = styled.div`
  position: absolute;
  background: rgba(16, 37, 62, 0.8);
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
  display: flex;
  height: calc(100% + 2px);
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 20px;
`

export const Inner = styled.div<{ fullHeight?: boolean }>`
  max-width: 310px;
  width: 100%;
  height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
  max-height: 310px;
  overflow-y: scroll;
  border-radius: 16px;
  background: var(--white);
  padding: 24px;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 18px;
  // align-items: center;
  margin-bottom: 24px;
  width: 100%;
`

export const ModalTitle = styled.h3`
  font-weight: 700;
  line-height: 24px;
  font-size: 19px;
`

export const CloseButton = styled.button`
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 11px;
    height: auto;
    vertical-align: middle;
  }
`
