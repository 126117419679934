import styled from 'styled-components'

import changeAva from '../../../assets/img/icons/changeAva.svg'

export const Description = styled.p`
  color: var(--text-gray);
  margin-bottom: 24px;
`

export const WrapperAside = styled.aside`
  position: relative;
`

export const AvatarPosition = styled.div`
  position: absolute;
  top: -74px;
  transform: translate(0px, -50%);
  left: 0;
  z-index: 3;
`

export const CoveringAvatar = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(16, 37, 62, 0.8);
  z-index: 2;
  border-radius: 50%;
  border: 2px solid var(--white);
  overflow: hidden;
  cursor: pointer;
`

export const AvatarButton = styled.div`
  cursor: pointer;
  outline: none;
  width: 44px;
  height: 44px;
  border: none;
  background: url(${changeAva}) no-repeat center;
`
