import React, { useEffect } from 'react'

import { Wrapper } from './styles'
import { Props } from './types'

export const Popover = ({ close, children, p, top, width, fullWidth }: Props): JSX.Element => {
  useEffect(() => {
    const outSideClick = (e: MouseEvent) => {
      const el = document.querySelector('.popover')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (el && !el.contains(e.target)) {
        close()
      }
    }

    window.addEventListener('click', outSideClick)

    return () => {
      window.removeEventListener('click', outSideClick)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper p={p} width={width} fullWidth={fullWidth} top={top} className="popover">
      {children}
    </Wrapper>
  )
}
