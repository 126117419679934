import { clientV3 } from './api'
import { names } from './localStorage'

const getUsdValue = async (): Promise<number> => {
  const now = new Date()
  const { data } = await clientV3.price()
  localStorage.setItem(names.usd, JSON.stringify({ value: data, time: now.getTime() }))
  return data
}

export const getLocalUsd = async (): Promise<number> => {
  const min10 = 1000 * 60 * 10
  const localData = localStorage.getItem(names.usd)

  if (localData) {
    const now = new Date()
    const { value, time } = JSON.parse(localData)
    if (now.getTime() - time < min10) {
      return parseFloat(value)
    }
  }
  const newVal = await getUsdValue()
  return newVal || 0
}
