import styled from 'styled-components'

import search from '../../../assets/img/icons/search.svg'

export const ButtonsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 12px;
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, max-content);
  }
`

export const ConnectionButtons = styled.div`
  display: flex;
  grid-column-gap: 12px;
  @media screen and (max-width: 560px) {
    & > .network__button {
      display: none;
    }
    & > .create__button {
      display: none;
    }
  }
`

export const MessengerButton = styled.button<{ newChats: number }>`
  display: flex;
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px;
  border: 1px solid var(--line-day);
  border-radius: 20px;
  position: relative;
  & svg {
    fill: var(--text-gray);
    width: 19px;
    height: 19px;
  }
  &:hover svg {
    fill: var(--text-light);
  }
  ${(props) =>
    props.newChats
      ? `&::after {
    content: '${props.newChats}';
    font-size: 12px;
    display: flex;
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: var(--red);
    border-radius: 8px;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: var(--white);
  }`
      : ''}
`

export const SearchButton = styled.button`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    background-image: url(${search});
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    cursor: pointer;
    border: 1px solid var(--line-day);
    border-radius: 20px;
  }
`
