import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import { IS_TESTNET } from '../../../config/rollapp'
import { OWNER_ERC1155 } from '../../../contracts/erc1155/constants'
import { balanceOfShares } from '../../../contracts/erc1155/methods'
import { CHAIN_ID } from '../../../domain/network/contstant'
import { useDaoToken } from '../../../hooks/useTokenData'
import { formatPrice } from '../../../utils/formatPrice'
import { ValueType } from '../data/info/ValueBlock'

export const useShares = (isMario?: boolean): { values: ValueType[]; founded: number } => {
  const { chainId } = useWeb3React()
  const [values, setValues] = useState<ValueType[]>([])
  const [founded, setFounded] = useState(0)
  const token = useDaoToken(isMario)

  useEffect(() => {
    if (token && chainId) {
      balanceOfShares(
        token,
        OWNER_ERC1155[IS_TESTNET ? CHAIN_ID.eth.testnet : CHAIN_ID.eth.mainnet]
      ).then((res) => {
        const shares = isMario ? 1000 : 100
        const sharesPrice = isMario ? 50 : 1000
        const remainingShares = parseInt(res)
        const invested = (shares - remainingShares) * sharesPrice
        const founded = Math.round((shares - remainingShares) * (1 / shares) * 100)
        setFounded(founded)

        setValues([
          {
            title: 'Shares',
            value: shares.toString()
          },
          {
            title: 'Share price',
            value: `$${formatPrice(sharesPrice)}`
          },
          {
            title: 'Invested',
            value: `$${formatPrice(invested)}`
          },
          {
            title: 'Funded',
            value: `${founded}%`
          }
        ])
      })
    }
  }, [chainId, isMario, token])

  return { values, founded }
}
