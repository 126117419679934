import React from 'react'

import { useRollapp } from '../../../../hooks/useRollapp'

export const Deposit = (): JSX.Element => {
  const { rechargePool } = useRollapp()

  return (
    <>
      <p>
        There is not enough ETH for your wallet in the Ethereum community pool. The ETH Community
        Pool is used to fund the gas fee of your tokens transfers from the SKALE network to
        Ethereum. For a successful transfer of a token from the SKALE network to the Ethereum
        network, you must deposit ETH on the Ethereum network from your wallet. Before transferring
        tokens from SKALE, make sure you have a minimum deposit in this pool. At any time you can
        withdraw ETH from the Community Pool. After clicking on Deposit ETH, you must deposit ETH in
        this pool on the Ethereum network and after that make transfer of tokens or ETH from SKALE
        to Ethereum.
      </p>
      <button className="button block-button" onClick={rechargePool}>
        Deposit
      </button>
    </>
  )
}
