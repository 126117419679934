import React from 'react'

import { useETHBalance } from '../../hooks/useETHBalance'
import { useFaucetAllowance } from '../../hooks/useFaucetAllowance'
import { requestSketh } from '../../services/faucet'
import { Web3Type } from '../../types/web3'
import { toFixed } from '../../utils/toFixed'
import { fromWei } from '../../utils/wei'
import { Modal } from '../common/Modal'
import { CancelBtn } from '../components/CancelBtn'
import { ErrorText } from '../components/Form/Error'
import { Description, InfoFee, Wrapper } from '../components/ModalStyles'
import { StatusBlock } from '../components/StatusBlock'

export const GetSkETHModal = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  const balance = useETHBalance(web3)
  const allowance = useFaucetAllowance(web3.account)

  return (
    <Modal title="Get skETH" width="350px">
      <Wrapper>
        <Description>
          skETH is a gas token in the SKALE network and has no market price. Here you can get it for
          free for transactions. Transactions on the SKALE network require very low gas and are paid
          in skETH token. You can get 0.01 skETH once every 3 days, but when your wallet balance is
          less than 0.05 skETH.
        </Description>
        <InfoFee>
          Your balance: <span>{balance && toFixed(fromWei(balance))} skETH</span>
        </InfoFee>
        {allowance === false && (
          <ErrorText>Warning: skETH has already been received for this wallet</ErrorText>
        )}
        <StatusBlock
          name="Get skETH"
          text="Sign message to get 0.01 skETH"
          disabled={!allowance}
          method={() => requestSketh(web3)}
        />
        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
