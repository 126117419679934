import React from 'react'

import { Token } from '../../../domain'
import { BLOCKCHAIN_NAME } from '../../../domain/network/contstant'
import { getNetworkIcon } from '../../../domain/network/mappers'
import { NetworkLogo } from '../../../ui/components/NetworkLogo'
import { BlockchainBlock, BlockTitle, NetworkCard, NetworkName } from './styles'

export const TokenBlockchain = ({ token }: { token: Token }): JSX.Element | null => (
  <BlockchainBlock>
    <BlockTitle>Blockchain</BlockTitle>
    <NetworkCard>
      <NetworkLogo icon={getNetworkIcon(token.collection.network)} size={44} />
      <NetworkName>{BLOCKCHAIN_NAME[token.collection.network]}</NetworkName>
    </NetworkCard>
  </BlockchainBlock>
)
