import React from 'react'

import { Token } from '../../../domain'
import { useWhitelistERC1155 } from '../../../hooks/useWhitelist'
import { Web3Type } from '../../../types/web3'
import { Loader } from '../../components'
import { WhitelistText } from '../WhitelistText'

export const CheckWhitelist = ({
  web3,
  token,
  children
}: {
  web3: Web3Type
  token: Token
  children: JSX.Element
}): JSX.Element => {
  const whitelisted = useWhitelistERC1155(web3, token)

  return whitelisted ? children : whitelisted === false ? <WhitelistText /> : <Loader center />
}
