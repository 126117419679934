import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import { balanceOfShares } from '../contracts/erc1155/methods'
import { getAPY, pendingReward, userInfo } from '../contracts/stalking'
import { Token } from '../domain'
import { Web3Type } from '../types/web3'

export const useAllowance = (web3: Web3Type, token: Token, unstake?: boolean): string => {
  const { account } = useWeb3React()
  const [value, setValue] = useState('0')

  useEffect(() => {
    if (unstake) {
      userInfo(web3).then((res) => {
        setValue(res)
      })
    } else {
      balanceOfShares(token, web3.account).then((res) => {
        setValue(res)
      })
    }
  }, [account, token, unstake, web3])

  return value
}

export const useStakingApy = (web3: Web3Type, token: Token, quantity: string): string => {
  const [value, setValue] = useState('0.00')

  useEffect(() => {
    getAPY(web3, token, quantity).then((res) => {
      setValue(res)
    })
  }, [quantity, token, web3])

  return value
}

export const usePendingReward = (web3: Web3Type): string => {
  const [value, setValue] = useState('0')

  useEffect(() => {
    pendingReward(web3).then((res) => {
      setValue(res)
    })
  }, [value, web3])

  return value
}
