import styled from 'styled-components'

export const Wrapper = styled.div<{ bg?: string }>`
  border-radius: 32px;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${(props) => (props.bg ? 'unset' : 'var(--red)')};
  background-size: cover;
  overflow: hidden;
  height: 360px;
  margin-bottom: 48px;
  @media screen and (max-width: 1216px) {
    min-height: 360px;
    height: auto;
    background-position: 78% center;
  }
  @media screen and (max-width: 916px) {
    background-position: 62% center;
  }
`

export const TopInfoBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 47px;
  gap: 24px;
  position: relative;
  background: radial-gradient(
    127.81% 177.29% at -36.9% 50%,
    rgba(255, 255, 255, 0.02) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  @media screen and (max-width: 1216px) {
    padding: 56px 0px 56px 47px;
    height: auto;
    width: fit-content;
  }
  @media screen and (max-width: 916px) {
    padding: 56px 16px 56px 16px;
    height: auto;
    width: 100%;
    backdrop-filter: blur(6px);
    align-items: center;
    text-align: center;
  }
`

export const AvatarBlock = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  & > div:first-child {
    z-index: 3;
  }
  & > div:nth-child(2) {
    z-index: 2;
    margin-left: -20px;
  }
`

export const InfoTitles = styled.div`
  display: block;
`

export const OrganizationName = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: var(--white);
`

export const TitleName = styled.p`
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  color: var(--white);
  @media screen and (max-width: 916px) {
    font-size: 46px;
  }
`

export const InfoLowerBlock = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 916px) {
    justify-content: center;
  }
`

export const ValuesImage = styled.div`
  width: 21px;
  & img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
`

export const ValuesInfo = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

export const ValuesNumber = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
`
