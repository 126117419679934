import styled from 'styled-components'

export const Label = styled.label`
  display: block;
  overflow: auto;
  position: relative;

  input {
    position: absolute;
    left: -9999px;
    appearance: none;
  }

  p {
    margin-bottom: 9px;
  }
`

export const FilesWrapper = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 9px;
  }

  span {
    font-size: 12px;
    line-height: 16px;
  }
`
