import { CHAIN_ID } from '../../network/contstant'

export const CURIO_ASSETS_WINES = {
  [CHAIN_ID.eth.mainnet]: '0xe89f14e3fcf4be1b7976548a1060087e3ccc97f2',
  [CHAIN_ID.skale.mainnet]: '0x7fc5a5effb16dbc60a775497c38328cbc9f038c3'
}

export const CURIO_ASSETS_SNEAKERS = {
  [CHAIN_ID.eth.mainnet]: '0xfdb3475cd47f3d82d448a594cea6232410131d56',
  [CHAIN_ID.skale.mainnet]: '0x8efcfc84643a8e46dac36a4b6777e12e516aeee4'
}

export const CURIO_ASSETS_WATCHES = {
  [CHAIN_ID.eth.mainnet]: '0x257a46306e9b473beccaa3843315f7584082e9a3',
  [CHAIN_ID.skale.mainnet]: '0x232be7f0c8cd1288a4e1a7568896b6e06b694c1f'
}

export const CURIO_FOUNDERS_EDITION_NFTS = {
  [CHAIN_ID.eth.mainnet]: '0xc22faf9f506e63e6f4f0088d15e9197b27c77ac7',
  [CHAIN_ID.eth.testnet]: '0x3A509904c3E2319b7679A38ee98E22176164DC41',
  [CHAIN_ID.skale.testnet]: '0xFCCB6033EeCcD8335889944040Ab7deDA634E126'
}

export const CURIO_FOUNDERS_EDITION_NFTS_NEW = {
  [CHAIN_ID.eth.mainnet]: '0xea166c7f2A4D65A8d583D7D144da1CF6d6A560eF',
  [CHAIN_ID.eth.testnet]: '0x355D59b945e187BE0F5E94DE3d0f43264e8E2165',
  [CHAIN_ID.skale.testnet]: '0xBE0C94D81719a9e2397C04d12769375c7f5b897E',
  [CHAIN_ID.skale.mainnet]: '0x4261e364e9459a799ff83260714564bc9578b2a3'
}

export const CURIO_ASSETS_CARS = {
  [CHAIN_ID.eth.mainnet]: '0x6884ef328ea1862e69bed5aa30ffafd4ed096ce8',
  [CHAIN_ID.eth.testnet]: '0xa22DbDeB9f3BA9a0BF62360BBA83C10BDE85ABD5',
  [CHAIN_ID.skale.testnet]: '0xc24f3b1abeda8eb069fb97887d3e4715c9350b40',
  [CHAIN_ID.skale.mainnet]: '0xbC49d909111728345D857a8a120C5b23A7C1a9A0'
}

export const CURIO_TOKENIZED_CARS = {
  [CHAIN_ID.eth.mainnet]: '0x92d3927a589933738fe3572648075a3840a6644b',
  [CHAIN_ID.eth.testnet]: '0xa22DbDeB9f3BA9a0BF62360BBA83C10BDE85ABD5',
  [CHAIN_ID.skale.testnet]: '0x10c90dae4647368eed8aeb65c8cf0e12fb90e465'
}

export const CURIO_ASSETS_GEEK_721 = {
  [CHAIN_ID.eth.mainnet]: '0x726a3ba6052c003a034cedaa69f447801e3474a7',
  [CHAIN_ID.skale.mainnet]: '0xc98585469cec63cde9dde8d3d1576f8feed36ec7'
}

export const CURIO_ASSETS_GEEK_1155 = {
  [CHAIN_ID.eth.mainnet]: '0x81461129e4ba6d1c657b09600e310cc47a2a398a',
  [CHAIN_ID.skale.mainnet]: '0x96a47d8fe3ac23bf809e799444c7c52cf6a993ee',
  [CHAIN_ID.eth.testnet]: '0xe051E7111AE36b2C71902460671C4801e243F5d4'
}

export const ADIDAS_ORIGINALS_INTO_THE_METAVERSE = {
  [CHAIN_ID.eth.mainnet]: '0x28472a58A490c5e09A238847F66A68a47cC76f0f',
  [CHAIN_ID.skale.mainnet]: '0xBCE3F5E70D3950dC4f8737C76811F70656318b54',
  [CHAIN_ID.skale.testnet]: '0xe7d4a5949600a07ac554c5e3ecc534765964da24'
}

export const CURIO_WRAPPED_ASSETS = {
  [CHAIN_ID.harmony.testnet]: '0x026aaed0178856f93a4eb4bec1ab2a86eff29222',
  [CHAIN_ID.eth.testnet]: '0x0cea7eeafc4a3c1f58d193d61255f5b03e756f43'
}

export const IT_REMAINS = {
  [CHAIN_ID.eth.mainnet]: '0x449ef3a9f9ffdc975e8bac43ef080d766f0b2a51',
  [CHAIN_ID.eth.testnet]: '0x45cb9f9162e6c150b99b141692cbf19cae58b41a'
}

export const THE_OCEAN = {
  [CHAIN_ID.eth.mainnet]: '0xABD9DF17fDfecAdA3BA07dB5170e6D7649205CA4'
}

// TODO
export const MUSIC_CLUB1 = {
  [CHAIN_ID.eth.mainnet]: '0x6db98972096935e2c7c1d4FC15883eB9434CB454'
}

export const MUSIC_CLUB2 = {
  [CHAIN_ID.eth.mainnet]: '0x1a0b736B0843a00A85a7D73754B59CBb0A911927'
}
