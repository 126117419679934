import React from 'react'

import { useParamsAddress } from '../../hooks/useParamsAddress'
import { useProfileData } from '../../hooks/useProfileData'
import { ProfileLayout } from '../../layouts/profile'
import { Loading } from '../../ui/components'
import { Cards } from '../../ui/components/Cards'

export const Profile = (): JSX.Element => {
  const { tab } = useParamsAddress()
  const data = useProfileData()

  return (
    <ProfileLayout data={data}>
      {data ? (
        <Cards
          owned={tab === 'owned' || !tab}
          onSale={!tab}
          created={tab === 'created'}
          address={data.walletAddress}
        />
      ) : (
        <Loading />
      )}
    </ProfileLayout>
  )
}
