import React, { useEffect, useRef } from 'react'

import { handleIntersection } from '../../../utils/handleIntersection'
import pixels3 from '../assets/brown-pixels.svg'
import marioBody from '../assets/mario.svg'
import marioBG from '../assets/mario-bg.png'
import pixels1 from '../assets/orange-pixels.svg'
import pixels2 from '../assets/white-pixel.svg'
import { SectionDescription, SectionHeader, SectionTitle, SectionWrapper } from '../styled'
import { InfoMessage } from './Info'
import {
  CardWrapper,
  ContentBlock,
  DescriptionContainer,
  Filler,
  ImageBlock,
  ImagesBlock,
  MarioImage,
  Piece,
  ProgressBar
} from './styled'

export const HeroSection = (): JSX.Element => {
  const perc = 70
  const parts = 5
  const partPerc = 100 / parts

  let restPerc = perc + partPerc
  const restWidth = restPerc % partPerc

  const target = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection)
    if (target && target.current) {
      observer.observe(target.current)
    }
  }, [])

  return (
    <SectionWrapper>
      <DescriptionContainer>
        <SectionHeader>
          <SectionTitle>Fractional ownership of prestigious pieces of history</SectionTitle>
          <SectionDescription>
            We provide individuals and companies with a toolset to create fractional NFTs for their
            physical assets. Rollapp enables you to unlock the value of your physical items as
            collateral.
          </SectionDescription>
        </SectionHeader>
        <ContentBlock ref={target}>
          <CardWrapper>
            <ImagesBlock>
              <ImageBlock>
                <img src={marioBG} />
              </ImageBlock>
              <MarioImage>
                <img src={marioBody} />
              </MarioImage>
            </ImagesBlock>
            <ProgressBar>
              {[...Array(parts)].map((item) => {
                restPerc -= partPerc
                return (
                  <Piece className="piece" key={item}>
                    {restPerc > 0 && (
                      <Filler
                        className="filler"
                        w={restPerc === restWidth ? 100 / (partPerc / restWidth) : undefined}
                      />
                    )}
                  </Piece>
                )
              })}
            </ProgressBar>
          </CardWrapper>
          <div className="info-messages">
            <InfoMessage
              image={pixels1}
              name="Kriss"
              info="3% (~$1,500)"
              status="OWNERSHIP OF NFT"
              top={35}
              left="-40px"
            />
            <InfoMessage
              image={pixels2}
              name="Kate"
              info="1% (~$500)"
              status="OWNERSHIP OF NFT"
              top={190}
              left="calc(100% - 105px)"
            />
            <InfoMessage
              image={pixels3}
              name="Vlad"
              info="5% (~$2,500)"
              status="OWNERSHIP OF NFT"
              top={277}
              left="-40px"
            />
          </div>
        </ContentBlock>
      </DescriptionContainer>
    </SectionWrapper>
  )
}
