import React from 'react'

import { useShares } from '../../hooks/useShares'
import ValueBlocks from '../info/ValueBlocks'
import { ProgressBar } from './ProgressBar'

const Mercedes = (): JSX.Element => {
  const { founded, values } = useShares()

  return (
    <>
      <ProgressBar perc={founded} />
      <ValueBlocks values={values} />
    </>
  )
}

export default Mercedes
