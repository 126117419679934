import React from 'react'

import { SectionDescription, SectionHeader, SectionTitle, SectionWrapper } from '../styled'
import {
  ConfidenceContainer,
  SectionContent,
  TableCell,
  TableListBlock,
  TableListWrapper,
  TableRow
} from '../TableListSection/styled'
import { header, rows } from './content'

export const Confidence = (): JSX.Element => {
  return (
    <SectionWrapper>
      <ConfidenceContainer>
        <SectionHeader>
          <SectionTitle>Own with confidence</SectionTitle>
        </SectionHeader>
        <SectionContent>
          <TableListWrapper>
            <TableListBlock>
              <TableRow className="table-row">
                {header.map((item) => (
                  <TableCell className="table-cell left" key={item}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
              {rows.map((row, index) => (
                <TableRow className="table-row" key={index}>
                  {row.map((cell) => (
                    <TableCell className="table-cell left" key={cell}>
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableListBlock>
          </TableListWrapper>
          <SectionDescription>
            We’re sure you`ll love your Rollapp. As the global leader in co-ownership, we back this
            up with brand promises so you can feel confident in your choice.
          </SectionDescription>
        </SectionContent>
      </ConfidenceContainer>
    </SectionWrapper>
  )
}
