import React, { useState } from 'react'

// import mercedes from './../../../../assets/media/shares/mercedes-mini.png'
// import tesla from './../../../../assets/media/shares/tesla-mini.png'
// import lamborgini from './../../../../assets/media/shares/lamborgini-mini.png'
import arrow from './../../../../assets/img/icons/arrow-menu.svg'
import {
  LinkBlock,
  LinkImage,
  LinkMenu,
  SharesName,
  SharesStatus,
  TabButton,
  TabContent,
  TabImage,
  TabType,
  TabValue
} from './styles'
import { Tab_Type } from './types'

type Props = {
  item: Tab_Type
  isOpenValue: boolean
}

export const Tab = ({ item, isOpenValue }: Props): JSX.Element => {
  const [isOpen, setOpen] = useState(isOpenValue)
  return (
    <div>
      <TabButton disabled={item.disabled} onClick={() => setOpen(!isOpen)}>
        <TabContent>
          <TabType>{item.type}</TabType>
          <TabValue>{item.links.length === 0 ? 'Coming soon' : item.links.length}</TabValue>
        </TabContent>
        <TabImage style={{ transform: isOpen ? `rotate(90deg)` : `rotate(0deg)` }}>
          <img src={arrow} />
        </TabImage>
      </TabButton>
      {isOpen && (
        <LinkMenu>
          {item.links.map((link) => (
            <LinkBlock
              to={link.link}
              key={link.name}
              className={link.disabled ? 'disabled' : ''}
              exact
            >
              <LinkImage>
                <img src={link.image} />
              </LinkImage>
              <div>
                <SharesName>{link.name}</SharesName>
                <SharesStatus
                  style={{
                    color: link.status === 'soon' ? `var(--text-gray)` : `var(--blue)`
                  }}
                >
                  {link.status === 'soon' ? 'Coming soon' : link.status}
                </SharesStatus>
              </div>
            </LinkBlock>
          ))}
        </LinkMenu>
      )}
    </div>
  )
}
