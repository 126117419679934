import styled from 'styled-components'

export const RadioCards = styled.div<{ small?: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  ${(props) => props.small && 'font-size: 12px;'};
  ${(props) => props.small && 'line-height: 16px'};
  @media (max-width: 475px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
