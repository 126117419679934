export type Step = {
  title?: string
  date: string
  description: string
  status?: 'done' | 'in progress'
  text?: string
}

export const ROADMAP_STEPS: Step[] = [
  {
    date: 'July 2021',
    description: 'RollApp NFT Launchpad release',
    status: 'done'
  },
  {
    date: 'August 2021',
    description: 'First oraclized RWA NFT - Ferrari F12tdf',
    status: 'done'
  },
  {
    date: 'October 2021',
    description:
      'Sale of the first DAO-car for CGT - tokenization and rental of Mercedes on the RWA NFT',
    status: 'done'
  },
  {
    date: 'March 2022',
    description: 'Cross-chain RWA NFT launch - SKALE network support',
    status: 'done'
  },
  {
    date: 'June 2022',
    description: 'Escrow P2P and P2B'
  },
  {
    date: 'July 2022',
    description:
      'A reputation token ERC-725 to build a trusted environment between sellers and buyers'
  },
  {
    date: 'August 2022',
    description:
      'Tokenization of the first real estate and passive income from its rental on the RWA NFT'
  },
  {
    date: 'September 2022',
    description: 'Granting a loan under the RWA NFT'
  },
  {
    date: 'October 2022',
    description: 'Tokenization of companies through NFTs'
  }
]
