import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import styled from 'styled-components'

const options: Highcharts.Options = {
  title: {
    text: ''
  },
  chart: {
    backgroundColor: '',
    marginTop: 8, // 32,
    marginBottom: 0,
    marginLeft: 0,
    events: {
      load() {
        const last = this.series[0].points.length - 1
        this.series[0].points[last].setState('hover')
        const div = document.querySelectorAll('.highcharts-xaxis-grid path')
        div[div.length - 1].setAttribute('stroke', '#6062F6')
      }
    }
  },
  legend: {
    enabled: false
  },
  xAxis: {
    gridLineWidth: 1,
    gridLineColor: '#E2E9F4',
    labels: {
      enabled: false
    },
    crosshair: {
      color: '#6062F6',
      width: 1,
      zIndex: 12
    },
    showLastLabel: true
  },
  yAxis: {
    labels: {
      enabled: false
    },
    title: {
      text: null
    },
    gridLineWidth: 0
  },
  tooltip: {
    formatter: function () {
      return this.point.name
    },
    borderWidth: 0,
    backgroundColor: 'transparent',
    shadow: false,
    positioner: function (labelWidth, labelHeight, point) {
      return {
        x: point.plotX - labelWidth / 2,
        y: 0
      }
    },
    style: {
      fontSize: '16px',
      fontFamily: 'Gilroy',
      fontWeight: '700',
      color: '#10253E',
      height: 24
    }
  },
  series: [
    {
      type: 'areaspline',
      data: [7, 10, 3, 5, 4, 7, 1, 20, 17, 18, 12, 14, 13, 16, 10, 22, 22],
      color: '#6062F6',
      fillColor: {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, 'rgba(96, 98, 246, 0.2)'],
          [1, 'rgba(96, 98, 246, 0)']
        ]
      },
      lineWidth: 4,
      marker: {
        enabled: false
      },
      enableMouseTracking: false
    }
  ],
  credits: {
    enabled: false
  }
}

const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
`

export const Chart = (): JSX.Element => {
  const date = new Date()
  const dayValue = date.getDate()
  const monthValue = date.getMonth() + 1
  const day = dayValue >= 10 ? dayValue : `0${dayValue}`
  const month = monthValue >= 10 ? monthValue : `0${monthValue}`
  const year = date.getFullYear()
  return (
    <div>
      <Title>
        {day}.{month}.{year}
      </Title>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
