import { ListProfile, Token } from '../domain'
import { getListAddresses, getProfiles } from '../domain/profile/mappers/profiles'
import { clientV3 } from './api'
import { Filters } from './api/types'

export const getTokensData = async (
  page: PageNumber,
  account: AccountAddress,
  filters: Filters
): Promise<{ tokens: Token[]; profiles: ListProfile[]; end: boolean }> => {
  try {
    const { data } = await clientV3.token.get(page, account, filters)
    const accounts = getListAddresses(data.items)
    const profiles = await getProfiles(accounts)
    return {
      tokens: data.items,
      profiles,
      end: data.pagination.pages === page
    }
  } catch (e) {
    return {
      tokens: [],
      profiles: [],
      end: true
    }
  }
}
