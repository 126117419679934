import React from 'react'

import { ErrorText } from '../Error'
import { CloseBtn, Image, StyledSpan, Text, UploadWrapper } from './styles'

type Props = {
  children: JSX.Element
  image?: File | null
  clearImage?: () => void
  imageType?: boolean
  error?: string | null
}

export const UploadImage = ({
  children,
  clearImage,
  imageType,
  image,
  error
}: Props): JSX.Element => {
  const url = image ? URL.createObjectURL(image) : undefined

  return (
    <div>
      <StyledSpan>Upload {imageType ? 'cover' : 'file'}</StyledSpan>
      <UploadWrapper>
        {!image ? (
          <label>
            {children}
            <Text>PNG, GIF, WEBP{imageType ? ' , JPG' : ' , MP4 or MP3'}. Max 100mb</Text>
            <div className="button primary-button">Choose file</div>
          </label>
        ) : (
          <>
            <Image>
              {image.type === 'video/mp4' ? (
                <video controls={false} src={url} autoPlay loop playsInline />
              ) : image.type === 'audio/mpeg' ? (
                <audio src={url} controls />
              ) : (
                <img src={url} />
              )}
            </Image>
            {clearImage && <CloseBtn type="button" onClick={clearImage} />}
          </>
        )}
      </UploadWrapper>
      {error && error.length > 0 && <ErrorText>{error}</ErrorText>}
    </div>
  )
}
