import React from 'react'

import { Token } from '../../../domain'
import { PUNK_ID } from '../../../domain/token/constants'
import { Modal } from '../../common/Modal'
import { Wrapper } from '../../components/ModalStyles'

export const GetETCPunkNFT = ({ token }: { token: Token }): JSX.Element => (
  <Modal title="Get ETCPunk NFT" width="380px">
    <Wrapper>
      <p>
        Anyone who bought this Curio Founders Edition NFT on rollapp.store during the initial sale
        from Curio will automatically receive ETHPunk NFT on the Ethereum Classic network. If you
        bought this NFT from Curio at the initial token sale, then you can see the ETCPunk NFT in
        your wallet on ETCPunks.
      </p>
      <p>
        <a href="/docs/how-to-connect-etc.pdf" target="_blank">
          How to connect to ETC network
        </a>
      </p>
      <a
        className="button block-button primary-button"
        href={
          PUNK_ID[token.tokenId]
            ? `https://etcpunks.com/marketplace/${PUNK_ID[token.tokenId]}`
            : 'https://etcpunks.com/mypunks'
        }
        target="_blank"
        rel="noreferrer"
      >
        Explore ETCPunk
      </a>
    </Wrapper>
  </Modal>
)
