import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const CardWrapper = styled(Link)`
  display: grid;
  grid-template-columns: 48px 1fr;
  align-items: center;
  gap: 24px;
  background: var(--white);
  width: 100%;
  padding: 12px 24px;
  &:hover {
    background: var(--result-gray);
  }
  @media screen and (max-width: 768px) {
    padding: 12px 16px;
  }
`

export const ItemImage = styled.div`
  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
  width: 48px;
  height: 48px;

  img,
  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: fit-content;
`

export const CardStatus = styled.div`
  display: flex;
  align-items: center;
`

export const StatusText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
  & > b {
    padding-left: 12px;
    color: var(--text-dark);
  }
`

export const StatusValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-gray);
  padding-left: 12px;
`

export const AvatarWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-shrink: 0;
  position: relative;
`

export const NetworkBadge = styled.div`
  position: absolute;
  bottom: -8px;
  right: -8px;
  display: flex;
`
