import React, { useEffect, useState } from 'react'

import { getOwner, Token } from '../../../domain'

export const Owner = ({ token }: { token: Token }): JSX.Element | null => {
  const [owner, setOwner] = useState<{ name: string; address: string } | null>(null)

  useEffect(() => {
    getOwner(token).then((res) => {
      setOwner(res)
    })
  }, [token])

  return owner ? (
    <>
      by{' '}
      <a href={`/profile/${owner.address}`} target="_blank" rel="noreferrer">
        {owner.name}
      </a>
    </>
  ) : null
}
