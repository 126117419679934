import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { IS_TESTNET } from '../../config/rollapp'
import { CURIO_FOUNDERS_EDITION_NFTS_NEW } from '../../domain'
import { CHAIN_ID } from '../../domain/network/contstant'
import { Loader } from '../../ui/components'
import { SubscribeFormModal } from '../../ui/modals/SubscribeModal/Form'
import adidas from './assets/adidas.mp4'
import egg from './assets/egg.mp4'
import {
  BLockCard,
  BlockContent,
  BlockDate,
  BlocksContainer,
  BlockText,
  BlockTitle,
  FirstContentGlance,
  GroupCards,
  LowerBox,
  MiniCard,
  SecondContentGlance,
  Values,
  ValuesBox,
  VideoContent,
  Wrapper
} from './styled'
import { useNFTCount } from './useNFTCount'

export const Season3 = (): JSX.Element => {
  const [modal, setModal] = useState(false)
  const { tokensForSaleByCurio, total, isSoldOut, loaded } = useNFTCount()

  return (
    <Wrapper>
      <BlocksContainer>
        <BLockCard className="block-card first right">
          <BlockContent>
            <BlockTitle>Season 3</BlockTitle>
            <BlockText className="text">
              To celebrate the launch of CurioDAO’s gas-free NFT launchpad RollApp on the SKALE
              network, we’re dropping a very special Curio Season 3 digital collection of NFTs,
              uniquely packaged as “NFT boxes”. Each of the 50 boxes contains three NFTs. <br />
              <br />
              Gamble: get 3 random NFTs from ETCPunks and ETCBayc collection on the SKALE network in
              one magic box. These NFTs come from a collection of 900 exclusive NFTs minted since
              2018.
            </BlockText>
            <LowerBox>
              <Link
                style={{ minWidth: 90 }}
                to={
                  isSoldOut
                    ? '/explore?category=season-3'
                    : `/collection/${
                        CURIO_FOUNDERS_EDITION_NFTS_NEW[
                          IS_TESTNET ? CHAIN_ID.skale.testnet : CHAIN_ID.skale.mainnet
                        ]
                      }?network=skale`
                }
                className="button green-button"
              >
                {loaded ? (
                  isSoldOut ? (
                    'Explore Season 3'
                  ) : (
                    'Buy now'
                  )
                ) : (
                  <Loader color="white" bg="second-green" />
                )}
              </Link>
              <Values>
                {loaded ? (
                  isSoldOut ? (
                    'Sold out'
                  ) : (
                    <>
                      <span>{tokensForSaleByCurio}</span>/{total}
                    </>
                  )
                ) : (
                  <span>
                    <Loader color="white" bg="violet-bg" width={32} />
                  </span>
                )}
              </Values>
            </LowerBox>
          </BlockContent>
        </BLockCard>
        <BLockCard className="block-card second">
          <BlockContent>
            <BlockTitle>Skale network</BlockTitle>
            <BlockText className="text">
              The Season 3 NFT collection is minted on the SKALE, lightning-fast, gasless network.
              SKALE gives RollApp users a smooth, seamless experience while sacrificing none of
              Ethereum’s security guarantees.
            </BlockText>
          </BlockContent>
        </BLockCard>
        <BLockCard className="block-card third right">
          <BlockContent>
            <BlockTitle>Club token</BlockTitle>
            <BlockText className="text">
              Branching out into the “real” world, the NFT boxes also grant the holder special
              access to CurioDAO events, where they’ll enjoy some valuable perks. At the NFT Shop{' '}
              <a
                href="https://share.hsforms.com/1D_mSTPA0SSS0dAWVA5fX4g2vbvf"
                target="_blank"
                rel="noreferrer"
                className="yellow-link"
              >
                Davos 2022
              </a>{' '}
              event, Curio Season 3 NFT holders will receive free merch! Find out more about the
              benefits of the club token{' '}
              <a
                href="https://investcurio.medium.com/curio-founders-membership-club-access-to-excellent-vip-benefits-and-exclusive-discounts-on-97c482d72867"
                target="_blank"
                rel="noreferrer"
                className="yellow-link"
              >
                here
              </a>
              .
            </BlockText>
          </BlockContent>
        </BLockCard>
        <BLockCard className="block-card fourth">
          <BlockContent>
            <BlockTitle className="black-text">For owners S3</BlockTitle>
            <BlockText className="black-text">
              Curio Season 3 NFT holders will automatically get the chance to win a highly prized
              NFT from the iconic “
              <a
                href="https://opensea.io/collection/adidasoriginals?tab=activity"
                target="_blank"
                rel="noreferrer"
                className="blue-link"
              >
                Adidas Originals Into the Metaverse
              </a>
              ” and “
              <a
                href="https://www.theindifferentduck.com/"
                target="_blank"
                rel="noreferrer"
                className="blue-link"
              >
                The Indifferent Duck
              </a>
              ” collections. This would make an excellent introductory package for anyone wanting to
              dip their toes into the sometimes intimidating world of NFTs. Especially when there’s
              no risk of large gas fees when you’re using SKALE.
            </BlockText>
            <LowerBox>
              <Link to="/my-items/owned" className="button dark-button">
                Try now
              </Link>
            </LowerBox>
          </BlockContent>
          <GroupCards>
            <MiniCard>
              <VideoContent>
                <video playsInline muted loop autoPlay controls={false}>
                  <source src={egg} type="video/mp4" />
                </video>
                <FirstContentGlance />
              </VideoContent>
              <ValuesBox className="numbers">
                <span>x5</span>
              </ValuesBox>
            </MiniCard>
            <MiniCard>
              <VideoContent>
                <video playsInline muted loop autoPlay controls={false}>
                  <source src={adidas} type="video/mp4" />
                </video>
                <SecondContentGlance />
              </VideoContent>
              <ValuesBox className="numbers">
                <span>x1</span>
              </ValuesBox>
            </MiniCard>
          </GroupCards>
        </BLockCard>
        <BLockCard className="block-card fifth right">
          <BlockContent>
            <BlockTitle>Season 4</BlockTitle>
            <BlockDate>MAY 22-26, 2022</BlockDate>
            <BlockText className="text">
              NFT&#39;s Season 4 collection will grant access to events taking place from 22-26 May
              in Davos at the NFT shop by RollApp.store. All NFT holders are eligible for free merch
              at the event.
            </BlockText>
            <LowerBox>
              <button className="button dark-button" onClick={() => setModal(true)}>
                Send request
              </button>
            </LowerBox>
          </BlockContent>
        </BLockCard>
      </BlocksContainer>
      {modal && <SubscribeFormModal close={() => setModal(false)} />}
    </Wrapper>
  )
}
