import { io, Socket } from 'socket.io-client'

export const socketInstance = (account: string): Socket => {
  const baseURL = process.env.REACT_APP_MESSENGER_API_URL

  return io(`wss://messenger.testnet.beta.rollapp.store`, {
    path: `/api/v3/ws/chat/socket.io`,
    extraHeaders: {
      'x-wallet-address': account
    },
    reconnection: false
    // autoConnect: false
  })
}
