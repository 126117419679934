import React, { useContext } from 'react'

import deleteIcon from '../../../../assets/img/icons/delete-black.svg'
import { MessengerContext } from '../../../../context/Messenger'
import { OptionButton, OptionButtons, OptionIcon, Options } from './styles'

export const MessageOptions = ({
  close,
  removeMsg
}: {
  out: boolean
  close: () => void
  removeMsg: () => void
}): JSX.Element => {
  const { setModal } = useContext(MessengerContext)
  return (
    <Options className="options">
      <OptionButtons>
        {/*<OptionButton>*/}
        {/*  <OptionIcon>*/}
        {/*    <img src={editIcon} />*/}
        {/*  </OptionIcon>*/}
        {/*  <p>Edit</p>*/}
        {/*</OptionButton>*/}
        {/*<OptionButton>*/}
        {/*  <OptionIcon>*/}
        {/*    <img src={replyIcon} />*/}
        {/*  </OptionIcon>*/}
        {/*  <p>Reply</p>*/}
        {/*</OptionButton>*/}
        {/*<OptionButton>*/}
        {/*  <OptionIcon>*/}
        {/*    <img src={forwardIcon} />*/}
        {/*  </OptionIcon>*/}
        {/*  <p>Forward</p>*/}
        {/*</OptionButton>*/}
        <OptionButton
          onClick={() => {
            removeMsg()
            close()
          }}
        >
          <OptionIcon>
            <img src={deleteIcon} />
          </OptionIcon>
          <p>Delete</p>
        </OptionButton>
      </OptionButtons>
    </Options>
  )
}

export default MessageOptions
