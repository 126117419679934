import styled from 'styled-components'

export const ProgressBar = styled.div<{ perc: number; green?: boolean }>`
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  height: 20px;
  padding: 5px;
  margin: 24px 0;

  &::after {
    transition: 0.4s width;
    content: '';
    display: block;
    border-radius: inherit;
    background-color: var(${(props) => (props.green ? '--light-green' : '--blue')});
    width: ${(props) => props.perc}%;
    height: 100%;
  }
`
