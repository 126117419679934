import { useEffect, useState } from 'react'

import { balanceOfETH } from '../contracts/erc20/methods'
import { Web3Type } from '../types/web3'

export const useETHBalance = (web3: Web3Type): string | undefined => {
  const [balance, setBalance] = useState<string>()

  useEffect(() => {
    balanceOfETH(web3).then((res) => {
      setBalance(res)
    })
  }, [web3])

  return balance
}
