import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { PreviewProfile } from '../../../domain'
import { useRollapp } from '../../../hooks/useRollapp'
import { clientV3 } from '../../../services/api'
import PaperPlane from '../../icons/PaperPlane'
import { Account } from '../Account'
import { NetworkSelector } from '../NetworkSelector'
import { ButtonsBox, ConnectionButtons, MessengerButton, SearchButton } from './styles'

type Props = {
  search: () => void
  openMessenger: () => void
}

export const HeaderBtns = (props: Props): JSX.Element => {
  const { account } = useWeb3React()
  const { network, setModal } = useRollapp()
  const [profile, setProfile] = useState<PreviewProfile>()

  const open = () => {
    setModal(
      network === 'skale'
        ? 'get-sketh'
        : network === 'eth'
        ? 'recharge-pool'
        : network === 'harmony'
        ? 'get-one'
        : undefined
    )
  }

  useEffect(() => {
    if (account) {
      clientV3.profile
        .preview(account)
        .then((res) => {
          setProfile(res.data)
        })
        .catch((e) => {
          console.error(e)
          setProfile(undefined)
        })
    }
  }, [account])

  return (
    <ButtonsBox>
      <ConnectionButtons>
        <SearchButton onClick={props.search} />
        {account && (
          <MessengerButton onClick={props.openMessenger} newChats={3}>
            <PaperPlane />
          </MessengerButton>
        )}
        {(network === 'skale' || network === 'eth' || network === 'harmony') && (
          <button className="button header__button network__button" onClick={open}>
            {network === 'skale'
              ? 'Get skETH'
              : network === 'eth'
              ? 'ETH Pool'
              : network === 'harmony' && 'Get ONE'}
          </button>
        )}
        <Link className="button primary-button header__button create__button" to="/create">
          Create
        </Link>
        {account && profile ? (
          <Account profile={profile} />
        ) : (
          <Link className="button header__button" to="/login">
            Connect
          </Link>
        )}
        <NetworkSelector />
      </ConnectionButtons>
    </ButtonsBox>
  )
}
