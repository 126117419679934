import React, { useContext, useState } from 'react'

import { MessengerContext } from '../../../../context/Messenger'
import { ChatTop } from '../ChatTop'
import settingsIcon from './../../../../assets/img/icons/settings.svg'
import {
  CloseButton,
  Header,
  HeaderButton,
  HeaderButtonText,
  Indicator,
  // Indicator,
  MenuButtons,
  MessengerNavigation,
  OptionButton,
  OptionIcon,
  SettingsButton,
  SettingsOptions
} from './styles'

export const MessageHeader = ({
  signIn,
  close,
  tab,
  setTab
}: {
  signIn: boolean
  tab: string
  setTab: (v: string) => void
  close: () => void
}): JSX.Element => {
  const { chat, closeChat, setModal, profileList } = useContext(MessengerContext)
  const [openSettings, setOpenSettings] = useState(false)

  return (
    <Header>
      {chat && signIn ? (
        <ChatTop userAddress={chat.to} list={profileList} back={closeChat} />
      ) : (
        <MessengerNavigation>
          {signIn ? (
            <>
              <MenuButtons>
                <HeaderButton onClick={() => setTab('chats')}>
                  <HeaderButtonText active={tab === 'chats'}>Chats</HeaderButtonText>{' '}
                  {/* <Indicator /> */}
                </HeaderButton>
                <HeaderButton onClick={() => setTab('requests')}>
                  <HeaderButtonText active={tab === 'requests'}>Requests</HeaderButtonText>{' '}
                  {<Indicator />}
                </HeaderButton>
              </MenuButtons>
              <SettingsButton onClick={() => setOpenSettings(!openSettings)} />
              {openSettings && (
                <SettingsOptions>
                  <OptionButton
                    onClick={() => {
                      setOpenSettings(false)
                      setModal('settings')
                    }}
                  >
                    <OptionIcon>
                      <img src={settingsIcon} />
                    </OptionIcon>
                    <p>Settings</p>
                  </OptionButton>
                </SettingsOptions>
              )}
            </>
          ) : (
            <h4>Messenger</h4>
          )}
        </MessengerNavigation>
      )}
      <CloseButton onClick={close} />
    </Header>
  )
}
