import React from 'react'

import { DefaultLayout } from '../../layouts/default'
import { NotFound } from '../../ui/components/NotFound'

export const NotFoundPage = (): JSX.Element => (
  <DefaultLayout>
    <NotFound />
  </DefaultLayout>
)
