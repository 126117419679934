import styled from 'styled-components'

export const InfoBlock = styled.div`
  overflow-wrap: break-word;
  padding: 49px 0;
  max-width: var(--width);
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    padding-top: 54px;
    padding-bottom: 8px;
  }

  @media (max-width: 576px) {
    padding-top: 20px;
    padding-bottom: 3px;
  }
`

export const InfoBlockInner = styled.div`
  width: 100%;
  max-width: 424px;
  margin-left: auto;
  padding: 0 24px;
  display: grid;
  row-gap: 24px;
  grid-template-columns: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const Extra = styled.span`
  background: radial-gradient(74.42% 200% at 25.58% 0%, #adff00 0%, #07ab18 47.83%, #00b533 91.88%);
  padding: 3px 16px;
  border-radius: 40px;
  margin-left: 12px;
  color: var(--white);
  font-size: 50%;
  vertical-align: bottom;
`

export const SaleInfo = styled.div`
  color: var(--text-gray);
`
