import { Mario } from './products/mario'
import { MercedesAmgGTS } from './products/mercedes'
import { Season2 } from './products/season2'
import { TeslaRoadster } from './products/tesla'

export type InfoType = {
  img?: string
  text?: string
  docs?: {
    opinions?: boolean
    details?: boolean
    gallery?: boolean
    legal?: boolean
  }
  soldOut?: boolean
  data: () => JSX.Element
  height?: string
}

export type PriceType = {
  value: string
  change: string
}

export type Quote = {
  name: string
  image: string | null
  text: string
}

export type VisualsType = {
  text?: string
  images: string[]
  mini: string[]
}

export type TimelineType = {
  events: {
    text: string
    date: string
  }[]
}

export type DaoData = {
  footer?: boolean
  width?: string
  hashtag: string
  title: string
  pages: ('info' | 'price' | 'details' | 'quotes' | 'visuals' | 'timeline')[]
  info: InfoType
  details?: JSX.Element
  price?: PriceType
  quotes?: Quote[]
  visuals?: VisualsType
  timeline?: TimelineType
  moreDetails?: {
    title: string
    items: {
      title: string
      value: string
    }[]
  }[]
  terms?: {
    local: string
  }
}

type Data = {
  [key in string]: DaoData
}

export const DAO_DATA: Data = {
  'mercedes-amg-gts': MercedesAmgGTS,
  'tesla-roadster': TeslaRoadster,
  'season-2': Season2,
  'super-mario-bros': Mario
}
