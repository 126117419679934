import React from 'react'

import { SubTitle } from '../styled'

export const AntohaMCAbout = (): JSX.Element => (
  <>
    The creation of an NFT music label, with special guest Serj and Drum Shiva as co-producer of the
    official Bored Ape Yacht Club song, is something that excites everyone interested in the
    convergence of music and crypto.
  </>
)

export const AntohaMC = (): JSX.Element => (
  <>
    <SubTitle>Music Club</SubTitle>
    <p>
      We are NFT collectors, BAYC #4250 (
      <a href="https://opensea.io/cashuzbek" target="_blank" rel="noreferrer">
        https://opensea.io/cashuzbek
      </a>
      ). We have strong investment community - 2.8m instagram, 1.7m TikTok, 123k telegram, 1000+ vip
      members on telegram ($3000-$15000 access fee). We provide blue chips NFT, early stage access
      to the top projects for our private investors group.
    </p>
    <p>
      We tokenized BAYC Song that gives access to our investment community with 2 options:
      <ul>
        <li>BAYCS VIP - 99 NFT</li>
        <li>BAYCS - 999 NFT</li>
      </ul>
    </p>
    <p>
      Gold VIP NFT series:
      <ul>
        <li>99 NFT only</li>
        <li>
          Access to VIP invest chat with Azam and his team where Azam shows blue chips, seed/presale
          round projects he invest
        </li>
        <li>Top projects allocations only for VIP chat</li>
        <li>Offline networking for VIP members</li>
        <li>Ability to manage community</li>
        <li>38 sold already</li>
      </ul>
    </p>
    <p>
      Club NFT series:
      <ul>
        <li>999 NFT only</li>
        <li>Access to signals channel, where Azam shows early stage projects he invest</li>
        <li>Top projects allocations. 2nd priority after VIP</li>
        <li>Yearly event offline access</li>
        <li>We buy blue chips NFT together</li>
      </ul>
    </p>
    <p>Average statistics of community: 500-700% profit</p>
  </>
)
