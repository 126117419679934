import React, { useEffect, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { validate } from '../../../services/validate-form'
import { Modal } from '../../common/Modal'
import { Wrapper } from '../../components/ModalStyles'
import { Status } from './Status'

type Props = {
  value: string
  close: () => void
  waitlist?: string
}

export const SubscribeModal = ({ waitlist, close, value }: Props): JSX.Element => {
  const [status, setStatus] = useState('pending')

  useEffect(() => {
    const error = validate(['required', 'email'], value)
    if (error) {
      setStatus('error')
    } else {
      const client = waitlist
        ? clientV3.subscriptions.waitlist(value, waitlist)
        : clientV3.subscriptions.email(value)

      client
        .then(() => {
          setStatus('success')
        })
        .catch(() => {
          setStatus('warning')
        })
    }
  }, [value, waitlist])

  return (
    <Modal
      closeHandle={close}
      title={
        status === 'success'
          ? 'Success'
          : status === 'warning'
          ? 'Warning'
          : status === 'pending'
          ? 'Waiting'
          : 'Error'
      }
      width="350px"
    >
      <Wrapper>
        <Status status={status} />
        <button type="button" onClick={close} className="block-button button">
          Close
        </button>
      </Wrapper>
    </Modal>
  )
}
