import { NETWORK_CONFIG } from '../../config/network'
import { SUPPORT_CHAIN_ID } from '../../domain/network/contstant'
import { AppNetwork } from '../../domain/network/types'
import { convertToHex } from '../../utils/convertToHex'

const setCustomMetamaskNetwork = async (chainId: number): Promise<void> => {
  const config = NETWORK_CONFIG[chainId]
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
        chainId: convertToHex(chainId),
        chainName: config.name,
        nativeCurrency: config.nativeCurrency,
        rpcUrls: [config.rpc],
        blockExplorerUrls: [config.explorer]
      }
    ]
  })
}

const setMetamask = async (chainId: number): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: convertToHex(chainId) }]
  })
}

export const selectMetamaskNetwork = async (network: AppNetwork): Promise<boolean> => {
  try {
    switch (network) {
      case 'kovan':
      case 'eth':
        await setMetamask(SUPPORT_CHAIN_ID[network])
        return true
      default:
        await setCustomMetamaskNetwork(SUPPORT_CHAIN_ID[network])
        return true
    }
  } catch (e) {
    console.error(e)
    return false
  }
}
