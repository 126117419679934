import React from 'react'

import { SubTitle } from '../styled'

export const Diamonds = (): JSX.Element => (
  <>
    <SubTitle>About Scarselli</SubTitle>
    <p>
      Scarselli Diamonds has offered diamond collectors the world&apos;s most magnificent natural
      colored diamonds.
    </p>
  </>
)
