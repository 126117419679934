import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
`

export const HeaderInner = styled.div`
  width: 100%;
  padding: 16px 40px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  @media screen and (max-width: 768px) {
    padding: 16px 32px;
    align-items: flex-start;
  }
  @media screen and (max-width: 475px) {
    padding: 16px 20px;
  }
`

export const LogoLink = styled(Link)`
  width: 100%;
  max-width: 143px;
  & img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  & img:last-child {
    display: none;
  }
  @media screen and (max-width: 640px) {
    & img:first-child {
      display: none;
    }
    & img:last-child {
      display: flex;
    }
    max-width: 32px;
  }
`

export const LinksBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 14px 24px;
  width: fit-content;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content;
`

export const HeaderLink = styled.a`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--text-gray);
  &:hover {
    color: var(--black);
  }
`

export const LinksBox = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: fit-content;
`
