import React, { ChangeEvent, useContext, useState } from 'react'

import { MessengerContext } from '../../../../context/Messenger'
import { ModalForm, ModalInput } from '../AddChatModal/styles'
import { Buttons, Description, MessengerContainer } from '../DeleteModal/styles'
import { MessengerModal } from '../MessengerModal'

export const ReportModal = (): JSX.Element => {
  const { setModal } = useContext(MessengerContext)
  const [input, setInput] = useState('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInput(value)
  }

  return (
    <MessengerModal title="Report person">
      <MessengerContainer>
        <Description>Please tell us the reason for your complaint about this user.</Description>
        <ModalForm>
          <ModalInput
            name="report"
            placeholder="Add details"
            type="textarea"
            value={input}
            onChange={handleInputChange}
          />
        </ModalForm>
        <Buttons>
          <button type="button" className="button" onClick={() => setModal('')}>
            Cancel
          </button>
          <button type="button" className="button primary-button" onClick={() => setModal('')}>
            Report
          </button>
        </Buttons>
      </MessengerContainer>
    </MessengerModal>
  )
}
