import React from 'react'

import { useWhitelist } from '../../hooks/useWhitelist'
import { Loading } from '../../ui/components'
import { Main } from './Main'
import { Sidebar } from './Sidebar'

export const Create = (): JSX.Element => {
  const whitelisted = useWhitelist()

  return whitelisted !== undefined ? (
    <div className="grid">
      <Sidebar whitelisted={whitelisted} />
      <Main whitelisted={whitelisted} />
    </div>
  ) : (
    <Loading />
  )
}
