import React, { useState } from 'react'

import { approveHandle } from '../../../contracts/erc721/methods'
import { OrderApi, Token } from '../../../domain'
import { sellOrder } from '../../../domain/order/methods/sellOrder'
import { useERC721Allowance } from '../../../hooks/useERC721Allowance'
import { Web3Type } from '../../../types/web3'
import { fromWei } from '../../../utils/wei'
import { Modal } from '../../common/Modal'
import { TokenImage } from '../../common/TokenImage'
import {
  Description,
  ImageInner,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  Wrapper
} from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

type Props = {
  web3: Web3Type
  token: Token
  offer: OrderApi
  cancel: () => void
}

export const AcceptModal = ({ web3, token, offer, cancel }: Props): JSX.Element => {
  const [approved, setApproved] = useState(false)
  const allowance = useERC721Allowance(web3, token)

  return (
    <Modal title="Accept" width="350px" closeHandle={cancel}>
      <Wrapper>
        <ImageInner>
          <TokenImage token={token} />
        </ImageInner>
        <Description>
          You are about to accept bid for {token.name} {/*from {tokenName} */}
        </Description>
        <ModalInfo>
          <InfoRow>
            <InfoType>Price</InfoType>
            <InfoValue>{fromWei(offer.make.value)} CGT</InfoValue>
          </InfoRow>
        </ModalInfo>

        <StatusBlock
          name="Approve collection"
          method={async () => {
            const res = await approveHandle(web3, token)
            setApproved(res)
            return res
          }}
          text="This transaction is conducted only once per collection"
          check={allowance}
        />

        <StatusBlock
          name="Accept bid"
          text="Sign message to accept bid"
          method={() => sellOrder(token, offer, web3)}
          disabled={!approved && !allowance}
          errorText="The error may be related to the balance of the buyer. Try to accept a bid from another user"
        />

        <button className="button block-button" type="button" onClick={cancel}>
          Cancel
        </button>
      </Wrapper>
    </Modal>
  )
}
