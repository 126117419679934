import axios from 'axios'

import { URLS } from '../../config/rollapp'
import { accountHeader, getLinkForToken, LIMIT_PAGES, prepareHeaders } from './secondary'
import { ApiClient } from './types'

const apiV3 = axios.create({
  baseURL: URLS.api,
  withCredentials: true
})

export const clientV3: ApiClient = {
  security: {
    connect: (data) => apiV3.post('/security/connect', data)
  },
  profile: {
    get: (address) => apiV3.get(`/profile/${address}`),
    preview: (address) => apiV3.get(`/profile/${address}/preview`),
    list: (ethAddresses) => apiV3.post('/list', { ethAddresses }),
    edit: {
      data: (signature, address, profile) =>
        apiV3.put('/profile', profile, prepareHeaders(signature, address)),
      avatar: (signature, address, formData) =>
        apiV3.post('/profile/avatar', formData, prepareHeaders(signature, address)),
      cover: (signature, address, formData) =>
        apiV3.post('/profile/cover', formData, prepareHeaders(signature, address))
    },
    credentials: {
      get: (signature, address) =>
        apiV3.get('/profile/credentials', prepareHeaders(signature, address)),
      set: (signature, address, email) =>
        apiV3.put('/profile/credentials', { email }, prepareHeaders(signature, address))
    }
  },
  token: {
    get: (page, account, filters) =>
      apiV3.get(getLinkForToken(page, filters), accountHeader(account)),
    create: (account, signature, formData) =>
      apiV3.post('/tokens', formData, prepareHeaders(signature, account)),
    redeem: (id, data) => apiV3.post(`/tokens/${id}/redeem`, data),
    byId: (network, contract, tokenId, account) =>
      apiV3.get(`/tokens/${network}/${contract}/${tokenId}`, accountHeader(account)),
    like: (token, signature, address) =>
      apiV3.post(
        `/tokens/${token.id}/likes`,
        {},
        prepareHeaders(signature, address, token.tokenId)
      ),
    offers: (id) => apiV3.get(`/tokens/${id}/offers`),
    orders: (id) => apiV3.get(`/tokens/${id}/orders`)
  },
  collection: {
    all: () => apiV3.get('/collections'),
    get: (contractOrUrl, network) =>
      apiV3.get(`/collections/${network ? `${network}/${contractOrUrl}` : contractOrUrl}`)
  },
  exchange: {
    create: (order) => apiV3.post('/exchange/orders', order),
    rating: (period, buyers) =>
      apiV3.get(`/exchange/users-rating?period=${period}&buyers=${buyers}`),
    cancel: (order, signature, address) =>
      apiV3.delete(`/exchange/orders/${order.id}`, {
        headers: {
          'x-order-hash': order.hash,
          'x-Wallet-signature': signature,
          'x-Wallet-address': address
        }
      })
  },
  price: () => apiV3.get('/currency'),
  subscriptions: {
    email: (email) => apiV3.post('/subscriptions/email', { email }),
    waitlist: (email, tag) => apiV3.post('/subscriptions/waiting-lists', { email, tag })
  },
  categories: {
    nft: () => apiV3.get('/categories/nft')
  },
  tokenManagers: {
    tethers: (network, collection) =>
      apiV3.get(`/token-managers/tethers?network=${network}&collection=${collection}`)
  },
  search: {
    all: (query) => apiV3.get(`/search?page=1&per_page=3&query=${query}`),
    tokens: (query, page) =>
      apiV3.get(`/search/tokens?page=${page}&per_page=${LIMIT_PAGES}&query=${query}`),
    collections: (query, page) =>
      apiV3.get(`/search/collections?page=${page}&per_page=${LIMIT_PAGES}&query=${query}`),
    users: (query, page) =>
      apiV3.get(`/search/users?page=${page}&per_page=${LIMIT_PAGES}&query=${query}`)
  },
  analytics: {
    tokenSale: (network) => apiV3.get(`/analytics/token-sale/${network}/season-3`)
  },
  forms: {
    contactUs: (formsData) => apiV3.post(`/forms/contact-us`, formsData)
  },
  chats: {
    connect: (account, signature) =>
      apiV3.post('/chats', undefined, {
        headers: {
          'x-wallet-address': account,
          'x-message-signature': signature
        }
      }),
    get: (account) => apiV3.get(`/chats?wallet_address=${account}`)
  }
}
