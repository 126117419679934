import { isEthNetwork, isSkaleNetwork } from '../../network/mappers'
import { Token } from '../../token'
import { isOldCollection } from './collections.mapper'

export const isBonusContract = (token: Token): boolean =>
  isSkaleNetwork(token.collection.network)
    ? token.category?.tag === 'season-3'
    : isEthNetwork(token.collection.network) &&
      (token.category?.tag === 'season-2' || token.category?.tag === 'season-1')

export const isGetETCPunks = (token: Token): boolean =>
  isEthNetwork(token.collection.network) &&
  token.category?.tag === 'season-2' &&
  isOldCollection(token)

export const isWinBonus = (token: Token): boolean =>
  isEthNetwork(token.collection.network) && token.category?.tag === 'season-1'

export const isBonusOnSkale = (token: Token): boolean =>
  isSkaleNetwork(token.collection.network) && token.category?.tag === 'season-3'
