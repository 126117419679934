import { names } from '../../../../services/localStorage'
import img from '../../media/mario.png'
import img1 from '../../media/mario/1.jpeg'
import img2 from '../../media/mario/2.jpeg'
import img3 from '../../media/mario/3.jpeg'
import img4 from '../../media/mario/4.jpeg'
import img5 from '../../media/mario/5.jpeg'
import img6 from '../../media/mario/6.jpeg'
import img7 from '../../media/mario/7.jpeg'
import img8 from '../../media/mario/8.jpeg'
import img9 from '../../media/mario/9.jpeg'
import img10 from '../../media/mario/10.jpeg'
import { DaoData } from '../data'
import { DETAILS } from '../details'
import { Info } from '../info/Mario'

export const Mario: DaoData = {
  title: 'Super Mario Bros.',
  hashtag: 'SUPERMARIOBROS',
  pages: ['info', 'price', 'details', 'quotes', 'visuals', 'timeline'],
  info: {
    text: '',
    img,
    data: Info,
    docs: {
      opinions: true,
      details: true,
      gallery: true,
      legal: true
    },
    height: '940px'
  },
  price: {
    value: '50,000',
    change: '+10'
  },
  details: DETAILS['super-mario-bros'],
  quotes: [
    {
      name: 'Hideo Kojima',
      image: null,
      text: 'Super Mario Bros. is equivalent to the Big Bang of our gaming universe. If it were not for this blindingly spectacular creation, digital entertainment as we know it today would not exist.'
    }
  ],
  visuals: {
    images: [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10],
    mini: [img1, img9, img5, img7]
  },
  timeline: {
    events: [
      {
        date: 'SEP. 1889',
        text: 'Nintendo Karuta was founded as a company that produces playing cards in Kyoto, Japan by Fusajiro Yamauchi.'
      },
      {
        date: 'JAN. 1959',
        text: "Nintendo and Walt Disney agree to print cards featuring Disney's animated characters"
      },
      {
        date: 'JAN. 1980',
        text: 'In Japan, Nintendo releases the first handheld video game device, the "Game & Watch", which eventually sells 40 million units worldwide.'
      },
      {
        date: 'JUL, 1981',
        text: 'Nintendo releases the arcade game "Donkey Kong" featuring Jumpman, a carpenter who is trying to save his girlfriend, Pauline, from the ape.'
      },
      {
        date: 'JAN. 1982',
        text: 'In Donkey Kong Jr., Jumpman is renamed “Mario” after a Nintendo warehouse landlord, Mario Segale, who famously berated the company’s American president over late rent payments.'
      },
      {
        date: 'SEP. 1983',
        text: "Mario debuts on CBS's Saturday Supercade, voiced by Peter Cullen (who was Optimus Prime's voice from The Transformers for many years)."
      },
      {
        date: 'OCT. 1985',
        text: 'The Nintendo Entertainment System and Super Mario Bros. is launched in New York City as a test market, followed by a February 1986 release in Los Angeles. The NES debuted with 17 “Black Box” games made for the platform.'
      },
      {
        date: 'SEP. 1986',
        text: 'The NES is given a full North American release and Nintendo subsequently sells over 60 million units worldwide. Following Super Mario Bros., over 200 games are produced as part of the “Mario” franchise, including Super Mario Bros. 3, Mario Kart, Mario Party, Mario Golf, and many more.'
      },
      {
        date: 'JUL. 2020',
        text: 'Heritage Auctions sells a Wata 9.4 A+ Sealed copy of 1985 Super Mario Bros. for a record $114,000'
      },
      {
        date: 'AUG. 2021',
        text: "A sealed copy of 'Super Mario Bros.' has been sold for a record-breaking $2 million"
      },
      {
        date: 'NOV. 2021',
        text: 'RollApp buys a copy of the 1985 Super Mario Bros. game from a private collector and offers it on the RollApp platform as fractionalized NFTs.'
      }
    ]
  },
  footer: true,
  terms: {
    local: names.marioTerms
  }
}
