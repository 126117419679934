import styled from 'styled-components'
export const Card = styled.label`
  border-radius: 16px;
  overflow: hidden;
  height: 140px;
  cursor: pointer;
  position: relative;

  div {
    background-color: var(--white);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    text-align: center;
  }

  input {
    appearance: none;
    position: absolute;
  }

  input:checked + div {
    background-color: var(--blue);
  }

  input:checked + div span {
    color: var(--white);
  }

  input:checked + div svg {
    fill: var(--white);
  }

  input:disabled + div {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const Icon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  margin-bottom: 16px;

  svg {
    width: 100%;
    height: 100%;
    fill: var(--text-dark);
  }
`

export const Extra = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: var(--text-gray);
`
