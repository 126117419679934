import logo from '../../../assets/img/logo.svg'
import { validate, validateFile, validateFiles } from '../../../services/validate-form'
import { Token } from '../../token'
import { defaultForm, loanForm, rules } from '../constants/create'
import { CreateErrors, CreateValues } from '../types/create.types'

export const validateCreateValue = (field: string, value: string): string | null =>
  validate(rules[field], value)

export const validateCreateFile = (
  field: string,
  file: FileList | null,
  files?: boolean
): { value: FileList | File | null; error: string | null } => {
  const error = files
    ? validateFiles(rules[field], file as FileList)
    : validateFile(rules[field], file ? file[0] : null)
  const value = !!error ? null : files || !file ? file : file[0]
  return {
    value,
    error
  }
}

export const checkErrors = (errors: CreateErrors, form: 'DEFAULT' | 'LOAN'): boolean => {
  const fields = form === 'DEFAULT' ? defaultForm : loanForm
  for (let i = 0; i < fields.length; i++) {
    if (errors[fields[i]] || errors[fields[i]] === '') {
      return true
    }
  }

  return false
}

export const getPreviewToken = (values: CreateValues, creator: CreatorAddress): Token | undefined =>
  values.file
    ? {
        id: '',
        name: values.title,
        description: values.description,
        contract: '',
        creatorAddress: creator,
        tokenId: '',
        highestBid: null,
        minPrice: null,
        supply: 1,
        totalStock: 1,
        onSale: 0,
        imageUrl: URL.createObjectURL(values.file),
        imageS3Url: null,
        videoS3Url: null,
        videoUrl: null,
        activity: {
          isLiked: false,
          likes: '0'
        },
        exchange: {
          isBid: false
        },
        ownerships: [
          {
            isAvailableForPurchasing: false,
            amount: 1,
            ownerAddress: creator,
            price: null,
            saleCurrency: 'CGT',
            selling: 0,
            sold: 0,
            status: 'NOT_FOR_SALE',
            stock: 1,
            tokenId: '',
            userId: ''
          }
        ],
        standard: 'ERC721',
        collection: {
          avatarS3Link: logo,
          avatarIpfsHash: '',
          category: {
            id: '',
            name: '',
            tag: ''
          },
          coverS3Link: '',
          coverIpfsHash: '',
          description: '',
          ethAddress: '',
          id: '',
          name: 'User Assets',
          ownerAddress: '',
          symbol: '',
          tokenStandards: ['ERC721'],
          network: 'ethereum-mainnet',
          isKycRequired: false,
          url: null
        },
        category: null
      }
    : undefined
