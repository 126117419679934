import tesla from '../../media/cars/tesla/tesla.png'
import { DaoData } from '../data'
import Info from '../info/Tesla'

export const TeslaRoadster: DaoData = {
  title: 'CurioInvest x NEAR Tesla Launch',
  hashtag: 'TESLA',
  pages: [],
  info: {
    text: 'Expanding Carbon Neutral Tokenization of Collectible Assets',
    img: tesla,
    data: Info
  }
}
