import React, { useState } from 'react'

import { Token } from '../../../domain'
import { Popover } from '../Popover'
import { TokenMenu } from '../TokenMenu'
import { CardMenuBox, CardMenuButton } from './styles'

export const Menu = ({ token }: { token: Token }): JSX.Element => {
  const [popup, setPopup] = useState(false)

  return (
    <CardMenuBox>
      <CardMenuButton onClick={() => setPopup(!popup)} />
      {popup && (
        <Popover close={() => setPopup(false)}>
          <TokenMenu token={token} />
        </Popover>
      )}
    </CardMenuBox>
  )
}
