import styled from 'styled-components'

export const Options = styled.div`
  position: absolute;
  z-index: 7;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  top: 22px;
`

export const OptionButtons = styled.div`
  display: flex;
  flex-direction: column;
`

export const OptionButton = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px 0 14px;
  gap: 14px;
  &:hover {
    background: var(--link-gray);
  }
`

export const OptionIcon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`
