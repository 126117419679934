import styled from 'styled-components'

export const SocialInput = styled.div`
  position: relative;

  input {
    padding-left: 31px;
  }

  &::before {
    content: '@';
    position: absolute;
    top: 16px;
    left: 20px;
    z-index: 2;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`
