import axios from 'axios'

import { names } from '../../../services/localStorage'
import { Sign } from '../interfaces/messenger'

const client = axios.create({
  baseURL: ''
})

export const messengerClient = {
  getChats: async () => await client.get('/message/get-conversations')
}

export const getMessengerSignes = (): Sign[] => {
  const signes = localStorage.getItem(names.messenger)
  return signes ? JSON.parse(signes) : []
}

export const getMessengerSignByAccount = (account: string): Sign | undefined =>
  getMessengerSignes().filter((item) => item.signer.toLowerCase() === account.toLowerCase())[0]

export const setMessengerSignes = (sign: Sign): void => {
  const signes = getMessengerSignes()
  signes.push(sign)
  localStorage.setItem(names.messenger, JSON.stringify(signes))
}
