import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.header`
  height: var(--header-height);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;

  --big-path-fill: 'url(#paint0_radial)';
  --small-path-fill: 'url(#paint1_radial)';
`

export const Inner = styled.header`
  background-color: var(--white);
  box-shadow: 0 12px 20px rgba(100, 123, 157, 0.1);
  display: flex;
  align-items: center;
  padding: 0 40px;
  height: 68px;
  width: 100%;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 32px;
  }
  @media screen and (max-width: 475px) {
    padding: 0 20px;
  }
`

export const LeftBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 438px;
  width: 100%;
  margin-right: 12px;
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    margin-right: 0;
  }
`

export const NavBox = styled.div`
  height: 67px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1024px) {
    flex-flow: row-reverse;
  }
`

export const Logo = styled(Link)`
  width: 36px;
  height: 36px;
  margin-right: 24px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  & svg {
    width: 100%;
    height: auto;

    path {
      fill: var(--big-path-fill);
    }
  }
`

export const DesctopBlock = styled.div`
  width: 100%;
  max-width: 370px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const MobileSearch = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    width: 100%;
    max-width: 475px;
    bottom: -71px;
    left: 50%;
    transform: translate(-50%, 0);
    background: var(--white);
    border: 1px solid var(--line-day);
    border-radius: 12px;
    z-index: 18;
  }
`
