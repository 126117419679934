import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const LandingCardWrapper = styled(Link)`
  width: 100%;
  max-width: 312px;
  padding: 20px 20px 30px;
  background: var(--white);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
  border-radius: 36px;
  position: relative;
  display: block;
  @media screen and (max-width: 1024px) {
    padding: 17px 18px 26px;
    max-width: 276px;
  }
  @media screen and (max-width: 425px) {
    max-width: 240px;
  }
`

export const CardImage = styled.div`
  background: var(--error-bg);
  border-radius: 20px;
  width: 272px;
  height: 272px;
  overflow: hidden;
  & > img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1024px) {
    width: 240px;
    height: 240px;
  }
  @media screen and (max-width: 425px) {
    width: 204px;
    height: 204px;
  }
`

export const CardBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  position: relative;
  padding-top: 42px;
  @media screen and (max-width: 1024px) {
    padding-top: 38px;
    gap: 13px;
  }
`

export const PseudoAvatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--blue-bg);
  position: absolute;
  top: 0;
  left: 24px;
  transform: translate(0%, -50%);
  & > img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 1024px) {
    left: 21px;
  }
`

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const BottomRow = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  gap: 15px;
`

export const HiddenText = styled.div<{ pink?: boolean; width?: string }>`
  width: 100%;
  max-width: ${(props) => (props.width ? props.width : '29px')};
  background: ${(props) => (props.pink ? 'var(--light-pink)' : 'var(--hidden-text)')};
  height: 20px;
  border-radius: 20px;
  @media screen and (max-width: 1024px) {
    height: 17px;
  }
`

export const CommentPosition = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 2;
  transform: translateY(-100px);
  transition: opacity 0.5s, transform 0.5s;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  &.visible > div::after {
    transition-delay: 0.5s;
    opacity: 1;
  }
  @media screen and (max-width: 475px) {
    display: none;
  }
`
