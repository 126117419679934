import React from 'react'

import { Modal } from '../../common/Modal'
import { Wrapper } from '../../components/ModalStyles'

export const GetNFTsPackModal = (): JSX.Element => (
  <Modal title="Get NFTs Pack" width="380px">
    <Wrapper>
      <p>
        Anyone who purchased this Curio Founders Edition NFT on rollapp.store during the initial
        sale will automatically receive one NFT in each of the 3 collections on the Ethereum Classic
        network: ETCPunks, ETCBits, ETCBayc. If you bought this NFT from Curio in the initial sale,
        you can see each of the 3 NFTs in your wallet on the Ethereum Classic network.
      </p>
      <p>
        <a href="/docs/how-to-connect-etc.pdf" target="_blank">
          How to connect to ETC network
        </a>
      </p>
      <a
        className="button block-button primary-button"
        href="https://etcpunks.com"
        target="_blank"
        rel="noreferrer"
      >
        Explore ETCPunks
      </a>
      <a
        className="button block-button primary-button"
        href="https://etcbits.com"
        target="_blank"
        rel="noreferrer"
      >
        Explore ETCBits
      </a>
      <a
        className="button block-button primary-button"
        href="https://etcbayc.com"
        target="_blank"
        rel="noreferrer"
      >
        Explore ETCBayc
      </a>
    </Wrapper>
  </Modal>
)
