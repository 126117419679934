import { Collection, ListProfile } from '../domain'
import { getQueryNetwork } from './network'

export const getProfileLink = (profile: ListProfile): string =>
  `/${profile.type === 'user' ? 'profile' : 'collection'}/${
    profile.url || profile.walletAddress
  }/owned${!profile.url && profile.network ? getQueryNetwork(profile.network) : ''}`

export const getCollectionLink = (collection: Collection): string => {
  return `/collection/${collection.url || collection.ethAddress}/owned${
    !collection.url ? getQueryNetwork(collection.network) : ''
  }`
}
