import { useWeb3React } from '@web3-react/core'
import React, { ChangeEvent, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { EDIT_PROFILE } from '../../../services/sign/messages'
import { validateFile } from '../../../services/validate-form'
import { Avatar } from '../../components/Avatar'
import { UploadFile } from '../../components/Form/UploadFile'
import { AvatarButton, AvatarPosition, CoveringAvatar, Description, WrapperAside } from './styles'

export const SettingSidebar = ({ avatar }: { avatar: ImageUrl }): JSX.Element => {
  const { account, library } = useWeb3React()
  const [newAvatar, setNewAvatar] = useState<string>()
  const [signature, setSignature] = useState<Signature>()

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (files && account) {
      const error = validateFile(['image'], files[0])
      console.info(error)
      if (error === null) {
        const signatureValue = signature
          ? signature
          : await signMessage(EDIT_PROFILE(account, 'set_avatar'), account, library)
        setSignature(signatureValue)
        const formData = new FormData()
        formData.append('file', files[0])
        await clientV3.profile.edit.avatar(signatureValue, account.toLowerCase(), formData)
        const url = URL.createObjectURL(files[0])
        setNewAvatar(url)
      }
    }
  }

  return (
    <WrapperAside>
      <AvatarPosition>
        <Avatar image={newAvatar || avatar} width="96px" />
        <CoveringAvatar>
          <AvatarButton />
          <UploadFile type="file" accept=".jpg, .png, .gif" onChange={onChange} />
        </CoveringAvatar>
      </AvatarPosition>
      <h1 className="h2">Edit profile</h1>
      <Description>
        You can set preferred display name, create your branded profile URL and manage other
        personal settings
      </Description>
    </WrapperAside>
  )
}
