import React from 'react'

import aurora from '../assets/networks/aurora.svg'
import dot from '../assets/networks/dot.svg'
import eth from '../assets/networks/eth-landing.svg'
import harmony from '../assets/networks/harmony-landing.svg'
import near from '../assets/networks/near.svg'
import skl from '../assets/networks/skale-landing.svg'
import {
  Container,
  LandingLink,
  SectionButtons,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import {
  CardTokenBox,
  NetCardsBottom,
  NetworkCardContent,
  NetworkCardsBlock,
  NetworkCardsTop,
  NetworkCardWrapper,
  NetworkIcon,
  NetworkName,
  NetworkStatus
} from './styled'

const topCards = [
  {
    network: 'ETH',
    icon: `${eth}`,
    status: `Ready`
  },
  {
    network: 'SKL',
    icon: `${skl}`,
    status: `Ready`
  }
]

const bottomCards = [
  {
    network: 'AURORA',
    icon: `${aurora}`,
    status: `Q2 2022`
  },
  {
    network: 'NEAR',
    icon: `${near}`,
    status: `Q3 2022`
  },
  {
    network: 'DOT',
    icon: `${dot}`,
    status: `Q4 2022`
  },
  {
    network: 'HARMONY',
    icon: `${harmony}`,
    status: `Q4 2022`
  }
]

export const CrossChainSection = (): JSX.Element => {
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SectionTitle>Cross-chain NFT experience</SectionTitle>
          <SectionDescription></SectionDescription>
          <SectionButtons>
            <LandingLink
              className="landing-button landing-main-button"
              href="https://linktr.ee/CurioDAO"
              target="_blank"
            >
              Learn more
            </LandingLink>
          </SectionButtons>
        </SectionHeader>
        <NetworkCardsBlock>
          <NetworkCardsTop>
            {topCards.map((card) => (
              <NetworkCardWrapper key={card.network} className="network-card">
                <NetworkCardContent>
                  <CardTokenBox>
                    <NetworkIcon>
                      <img src={card.icon} />
                    </NetworkIcon>
                    <NetworkName>${card.network}</NetworkName>
                  </CardTokenBox>
                  <NetworkStatus>{card.status}</NetworkStatus>
                </NetworkCardContent>
              </NetworkCardWrapper>
            ))}
          </NetworkCardsTop>
          <NetCardsBottom>
            {bottomCards.map((card) => (
              <NetworkCardWrapper key={card.network} className="network-card">
                <NetworkCardContent>
                  <CardTokenBox>
                    <NetworkIcon>
                      <img src={card.icon} />
                    </NetworkIcon>
                    <NetworkName>${card.network}</NetworkName>
                  </CardTokenBox>
                  <NetworkStatus>{card.status}</NetworkStatus>
                </NetworkCardContent>
              </NetworkCardWrapper>
            ))}
          </NetCardsBottom>
        </NetworkCardsBlock>
      </Container>
    </SectionWrapper>
  )
}
