import React from 'react'

import { SubTitle } from '../styled'

export const StepOut = (): JSX.Element => (
  <>
    <SubTitle>Cars</SubTitle>
    <p>
      Another partnership RollApp.store is thrilled to introduce on Davos is the collaboration with
      the permium car dealership,{' '}
      <a href="https://www.autoatelier-zh.ch/" target="_blank" rel="noreferrer">
        Auto Atelier
      </a>{' '}
      from Zurich, which will be adding new assets to the platform and co-host experiences for the
      CurioDAO community in the future.
    </p>
  </>
)
