import { CHAIN_ID } from '../domain/network/contstant'

const skaleCurrency = {
  name: 'SKALE Ether',
  symbol: 'skETH',
  decimals: 18
}

const harmonyCurrency = {
  name: 'Harmony Ether',
  symbol: 'ONE',
  decimals: 18
}

export const NETWORK_CONFIG = {
  [CHAIN_ID.skale.mainnet]: {
    name: 'SKALE Mainnet',
    rpc: 'https://mainnet.skalenodes.com/v1/fit-betelgeuse',
    symbol: 'skETH',
    explorer: 'https://fit-betelgeuse.explorer.mainnet.skalenodes.com',
    nativeCurrency: skaleCurrency
  },
  [CHAIN_ID.skale.testnet]: {
    name: 'SKALE Testnet',
    rpc: 'https://staging-v2.skalenodes.com/fs/fit-graffias',
    symbol: 'skETH',
    explorer: 'https://fit-graffias.explorer.staging-v2.skalenodes.com/',
    nativeCurrency: skaleCurrency
  },
  [CHAIN_ID.harmony.testnet]: {
    name: 'Harmony Testnet',
    rpc: 'https://api.s0.b.hmny.io',
    symbol: 'ONE',
    explorer: 'https://explorer.pops.one/',
    nativeCurrency: harmonyCurrency
  },
  [CHAIN_ID.harmony.mainnet]: {
    name: 'Harmony Mainnet',
    rpc: 'https://api.harmony.one',
    symbol: 'ONE',
    explorer: 'https://explorer.harmony.one/',
    nativeCurrency: harmonyCurrency
  }
}
