import { useEffect, useState } from 'react'

import { allowance } from '../contracts/erc721/methods'
import { Token } from '../domain'
import { Web3Type } from '../types/web3'

export const useERC721Allowance = (
  web3: Web3Type,
  token: Token,
  type?: 'migrate' | 'move'
): boolean => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    allowance(web3, token, type).then((res) => {
      setValue(res)
    })
  }, [token, type, web3])

  return value
}
