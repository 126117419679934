import styled from 'styled-components'

import closeGray from '../../../assets/img/icons/gray-close.svg'
import search from '../../../assets/img/icons/search.svg'

export const SearchForm = styled.div`
  display: block;
  width: 100%;
  position: relative;
  background: var(--white);
  @media screen and (max-width: 768px) {
    display: flex;
    gap: 12px;
  }
`

export const SearchInputBar = styled.input`
  display: flex;
  overflow: auto;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px 30px;
  border: 1px solid var(--line-day);
  border-radius: 8px;
  font-size: 13px;
  line-height: 15px;
  position: relative;
  background: url(${search}) no-repeat;
  background-position: 8px center;
  background-size: auto;
  &::placeholder {
    color: var(--search-light-gray);
  }
`

export const SearchBlock = styled.div`
  width: 100%;
  max-width: 370px;
  position: relative;
  @media screen and (max-width: 768px) {
    max-width: 578px;
    padding: 16px;
  }
`

export const ClearButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 8.5px;
  height: 8.5px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 6;
`

export const SearchResultBlock = styled.div`
  position: absolute;
  bottom: -25px;
  transform: translate(0px, 100%);
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 0;
  background: var(--white);
  border-radius: 16px;
  z-index: 8;
  width: 100%;
  max-width: 360px;
  overflow: auto;
  max-height: calc(100vh - 114px);
  border: 1px solid var(--line-day);
  &::-webkit-scrollbar {
    width: 0;
  }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 175px);
    bottom: -0px;
    max-width: 578px;
    padding: 16px 0;
  }
`

export const ResultList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  & > b {
    padding: 0 24px;
  }
  @media screen and (max-width: 768px) {
    & > b {
      padding: 0 16px;
    }
  }
`

export const ResultCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 100%;
`

export const LinkBlock = styled.div`
  display: flex;
  padding: 0 24px;
  width: 100%;
  & > a {
    flex-shrink: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`

export const NoResults = styled.div`
  color: var(--text-gray);
  text-align: center;
  width: 100%;
  padding: 22px;
`

export const SearchCloseButton = styled.button`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    background-image: url(${closeGray});
    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px;
    cursor: pointer;
    border: 1px solid var(--line-day);
    border-radius: 20px;
  }
`
