import React from 'react'

import { OrderApi, Token } from '../../../../domain'
import { changePriceOrder } from '../../../../domain/order/methods/changePriceOrder'
import { useSaleForm } from '../../../../hooks/useSaleForm'
import { Web3Type } from '../../../../types/web3'
import { getPriceStock } from '../../../../utils/sum'
import { CancelBtn } from '../../../components/CancelBtn'
import { Input, InputContainer } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import { Description, TokenName } from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'

export const Form = ({
  web3,
  token,
  order
}: {
  web3: Web3Type
  token: Token
  order: OrderApi
}): JSX.Element => {
  const oldPrice = getPriceStock(order.take.value, order.make.value)
  const oldStock = order.make.value

  const { onChange, available, values, errors } = useSaleForm(web3, token)

  return (
    <>
      <Description>
        Change price for your existing item. Next price must be less than previous. If you want
        increase price, you should cancel then create sale with new price
      </Description>

      <LabelWrapper error={errors.bid}>
        <InputContainer>
          <Input
            type="text"
            placeholder="Enter price for one piece"
            value={values.bid}
            name="bid"
            onChange={onChange}
          />
          <TokenName>CGT</TokenName>
        </InputContainer>
      </LabelWrapper>

      {order.make.assetType.assetClass === 'ERC1155' && (
        <LabelWrapper title="Quantity" error={errors.quantity}>
          <Input
            type="text"
            name="quantity"
            placeholder="Enter pieces"
            value={values.quantity}
            onChange={onChange}
          />
          <small>{available} available</small>
        </LabelWrapper>
      )}

      <StatusBlock
        name="Change price"
        method={() =>
          changePriceOrder(web3, token.collection.network, order, values.bid, values.quantity)
        }
        disabled={
          !!errors.bid ||
          !!errors.quantity ||
          (values.bid === oldPrice && oldStock === values.quantity)
        }
      />

      <CancelBtn />
    </>
  )
}
