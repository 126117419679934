import React from 'react'

import { Modal } from '../common/Modal/'
import { Wrapper } from '../components/ModalStyles'
import { Description } from '../components/ModalStyles'

export const ComingSoonModal = ({ close }: { close: () => void }): JSX.Element => {
  return (
    <Modal closeHandle={close} width="350px" title="Coming soon">
      <Wrapper>
        <Description>
          You can get NFTs from the original It Remains collection. This action will be available
          soon.
        </Description>
        <button onClick={close} className="block-button button">
          Ok
        </button>
      </Wrapper>
    </Modal>
  )
}
