import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { Token } from '../../../domain'
import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { LIKE } from '../../../services/sign/messages'
import Like from '../../icons/Like'
import { Button } from './styles'

export const LikeButton = ({
  token,
  preview
}: {
  token: Token
  preview?: boolean
}): JSX.Element => {
  const { account, library } = useWeb3React()
  const { likes, isLiked } = token.activity
  const [liked, setLiked] = useState(isLiked)
  const [count, setCount] = useState(parseInt(likes))

  const likeHandle = async () => {
    if (account && !preview) {
      const signature = await signMessage(LIKE(token.tokenId), account, library)
      try {
        await clientV3.token.like(token, signature, account)
        setCount(liked ? count - 1 : count + 1)
        setLiked(!liked)
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    setLiked(isLiked)
  }, [isLiked])

  return (
    <Button className={liked ? 'liked' : ''} onClick={likeHandle}>
      <Like />
      {(count > 0 || liked) && <span>{count}</span>}
    </Button>
  )
}
