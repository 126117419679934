import React, { useState } from 'react'

import opinion from '../../../../assets/img/shares/icons/opinion.svg'
import quotesImg from '../../../../assets/img/shares/quotes.svg'
import { ArrowButton } from '../../../../ui/components/ArrowButton/styled'
import { Avatar } from '../../../../ui/components/Avatar'
import { Quote } from '../../data/data'
import avatar from '../../media/avatar.png'
import { Inner, Wrapper } from '../styled'
import {
  QuoteBlock,
  QuotesBottomSlider,
  QuotesButtons,
  QuotesContainer,
  QuotesIcons,
  QuoteText,
  QuoteUserBlock,
  SliderInner,
  StyledLink,
  UserName,
  UserNameBlock
} from './styled'

const Quotes = (props: { quotes: Quote[] }): JSX.Element => {
  const [slide, setSlide] = useState(0)
  const { quotes } = props

  return (
    <Inner id="quotes" className="slide">
      <Wrapper bg>
        <QuotesContainer>
          <QuotesIcons>
            <img src={quotesImg} />
          </QuotesIcons>
          <SliderInner
            style={{
              transform: `translateX(-${slide * 100}%)`
            }}
          >
            {quotes.map((item) => (
              <QuoteBlock key={item.text}>
                <QuoteText>{item.text}</QuoteText>
              </QuoteBlock>
            ))}
          </SliderInner>
          <QuotesButtons>
            <ArrowButton onClick={() => setSlide(slide === 0 ? 0 : slide - 1)} />
            <QuotesBottomSlider>
              <SliderInner
                style={{
                  transform: `translateX(-${slide * 100}%)`
                }}
              >
                {quotes.map((item) => (
                  <QuoteUserBlock key={item.text}>
                    <Avatar src={item.image ? item.image : avatar} width="80px" border="white" />
                    <UserNameBlock key={item.text}>
                      <UserName>{item.name}</UserName>
                    </UserNameBlock>
                  </QuoteUserBlock>
                ))}
              </SliderInner>
            </QuotesBottomSlider>
            <ArrowButton
              right
              onClick={() => setSlide(slide === quotes.length - 1 ? quotes.length - 1 : slide + 1)}
            />
          </QuotesButtons>
          <StyledLink href="https://discord.gg/VSvY6tBF4g" target="_blank">
            <img src={opinion} />
            <span>Join the conversation</span>
          </StyledLink>
        </QuotesContainer>
      </Wrapper>
    </Inner>
  )
}

export default Quotes
