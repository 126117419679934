import React from 'react'

import check from '../../../assets/img/icons/check-complete.svg'
import { Modal } from '../../../ui/common/Modal'
import { WrapperCentered } from '../../../ui/common/Modal/styles'
import { Loader } from '../../../ui/components'
import { Circle } from './styles'

export const SuccessSendModal = ({
  status,
  close
}: {
  status?: 'pending' | 'done'
  close: () => void
}): JSX.Element | null => {
  return status ? (
    <Modal width="300px" closeHandle={close}>
      <WrapperCentered>
        {status === 'pending' ? (
          <Loader />
        ) : (
          <>
            <Circle>
              <img src={check} />
            </Circle>
            <p>Your request for tokenization has been accepted and will be processed shortly.</p>
          </>
        )}
      </WrapperCentered>
    </Modal>
  ) : null
}
