import React from 'react'

import { getNetworkIcon } from '../../../domain/network/mappers'
import { shortAddress } from '../../../utils/shortAddress'
import { NetworkLogo } from '../NetworkLogo'
import { AddressText, Copy, Wrapper } from './styles'

export const Address = ({
  network,
  address
}: {
  address: string
  network?: Network
}): JSX.Element => {
  const copy = () => {
    navigator.clipboard.writeText(address)
  }
  return (
    <Wrapper>
      {network && <NetworkLogo size={27} icon={getNetworkIcon(network)} />}
      <AddressText>{shortAddress(address)}</AddressText>
      <Copy onClick={copy} />
    </Wrapper>
  )
}
