import { provider } from 'web3-core'

import { OrderExchange } from '../../domain'
import { DOMAIN_TYPE } from './dataOrder'
import { Domain, TypeData, Types } from './types'

export const EIP712 = {
  createTypeData: (
    domain: Domain,
    primaryType: 'Order',
    message: OrderExchange,
    types: Types
  ): TypeData => {
    return {
      types: Object.assign(
        {
          EIP712Domain: DOMAIN_TYPE
        },
        types
      ),
      domain,
      primaryType,
      message
    }
  },

  signTypedData: async (provider: provider, from: string, data: TypeData): Promise<Signature> => {
    // eslint-disable-next-line
    const connector = provider as any
    const msgData = JSON.stringify(data)
    const sig = await connector.send('eth_signTypedData_v4', [from, msgData])
    const sig0 = sig.result.substring(2)
    const r = '0x' + sig0.substring(0, 64)
    const s = '0x' + sig0.substring(64, 128)
    const v = parseInt(sig0.substring(128, 130), 16)
    console.log('v: ', v, ', r: ', r, ', s: ', s, ', sig: ', sig.result)
    const deleteLastTwoSymbols = sig.result.substring(0, 130)
    // ledgerium signature v replace
    return sig0.substring(128, 130) === '00'
      ? deleteLastTwoSymbols + '1b'
      : sig0.substring(128, 130) === '01'
      ? deleteLastTwoSymbols + '1c'
      : sig.result
  }
}
