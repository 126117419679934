import { ADIDAS_ORIGINALS_INTO_THE_METAVERSE } from '../../collection/constants'
import { Token } from '../types/Token'

export const isERC1155 = (token: Token): boolean => token.standard === 'ERC1155'

export const isAdidasToken = (contract: ContractAddress): boolean =>
  !!Object.keys(ADIDAS_ORIGINALS_INTO_THE_METAVERSE).find(
    (key) =>
      ADIDAS_ORIGINALS_INTO_THE_METAVERSE[key as never].toLowerCase() === contract.toLowerCase()
  )
