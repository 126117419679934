import React from 'react'
import styled from 'styled-components'

import ValueBlock, { ValueType } from './ValueBlock'

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 120px);
  grid-column-gap: 12px;
  height: 68px;

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 120px);
  }
`

const ValueBlocks = (props: { values: ValueType[] }): JSX.Element => {
  return (
    <Wrapper>
      {props.values.map((value) => (
        <ValueBlock title={value.title} value={value.value} key={value.title} />
      ))}
    </Wrapper>
  )
}

export default ValueBlocks
