import React, { ChangeEvent, useEffect, useState } from 'react'

import { rechargeUserWallet, withdrawFunds } from '../../../contracts/community-pool/methods'
import { useRollapp } from '../../../hooks/useRollapp'
import { useWeiPrice } from '../../../hooks/useWeiPrice'
import { validate } from '../../../services/validate-form'
import { Web3Type } from '../../../types/web3'
import { formatValue } from '../../../utils/formatValue'
import { fromWei, toWei } from '../../../utils/wei'
import { CancelBtn } from '../../components/CancelBtn'
import { Input } from '../../components/Form/Input'
import { LabelWrapper } from '../../components/Form/Label/LabelWrapper'
import { MaxBtn } from '../../components/Form/MaxBtn'
import { Description, InfoRow, InfoType, InfoValue } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'
import { Buttons } from './styles'

export const Content = ({
  poolBalanceBn,
  balanceEthBn,
  web3
}: {
  poolBalanceBn: string
  balanceEthBn: string
  web3: Web3Type
}): JSX.Element => {
  const { depositToken, backToNetworkMove } = useRollapp()
  const [value, setValue] = useState('')
  const [error, setError] = useState<string | null | undefined>()
  const [isDeposit, setIsDeposit] = useState(true)
  const [deposited, setDeposited] = useState(false)

  const gasPriceWeiBn = useWeiPrice()
  const minimumInPoolWeiBn = BigInt(1350000) * BigInt(gasPriceWeiBn)

  // convert to show
  const poolBalance = fromWei(poolBalanceBn)
  const balanceEth = fromWei(balanceEthBn)
  const minimumInPoolWei = parseFloat(fromWei(String(minimumInPoolWeiBn))).toFixed(4)
  const text = isDeposit ? 'deposit' : 'withdraw'

  const checkErrors = (value: string): string | null => {
    const minVal = minimumInPoolWeiBn - BigInt(poolBalanceBn)
    const minimum = minVal < 0 || !isDeposit ? BigInt(1) : minVal

    const valueBn = toWei(formatValue(value))
    if (BigInt(valueBn) < minimum) {
      return `The minimum value for a ${text} is ${fromWei(String(minimum))}`
    } else if (
      (isDeposit && BigInt(valueBn) > BigInt(balanceEthBn)) ||
      (!isDeposit && BigInt(valueBn) > BigInt(poolBalanceBn))
    ) {
      return 'Insufficient funds'
    }
    return null
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const error = validate(['required', 'float'], value)
    const val = formatValue(value)

    if (error === null) {
      setError(checkErrors(val))
    } else {
      setError(error)
    }

    setValue(val)
  }

  const transferCount = (value: string): string =>
    BigInt(value) > 0 && BigInt(gasPriceWeiBn) > 0
      ? String(BigInt(value) / (BigInt(450000) * BigInt(gasPriceWeiBn)))
      : '0'

  const setMax = () => {
    if (balanceEth && poolBalance) {
      const newValue = poolBalance
      setValue(newValue)
      setError(checkErrors(newValue))
    }
  }

  const handle = async (): Promise<boolean> => {
    const res = isDeposit
      ? await rechargeUserWallet(web3, toWei(value))
      : await withdrawFunds(web3, toWei(value))
    setDeposited(true)
    return res
  }

  useEffect(() => {
    if (depositToken) {
      backToNetworkMove()
    }
    // eslint-disable-next-line
  }, [deposited])

  useEffect(() => {
    const error = validate(['required', 'float'], value)
    if (error === null) {
      setError(checkErrors(value))
    }
    // eslint-disable-next-line
  }, [isDeposit, value])

  return (
    <>
      <Description>
        The ETH Community Pool is used to fund the gas fee of your tokens transfers from the SKALE
        network to Ethereum. Here you can deposit / withdraw ETH in the ETH Community Pool for your
        wallet. You can deposit any amount of ETH to fund the gas fee for multiple tokens transfers
        across the bridge from the SKALE network. Note that for the correct transfer of tokens in
        SKALE, the minimum amount of ETH is set, which must be in the Community Pool for the wallet
        and equal to 3 bridge transfers. Before transferring tokens from SKALE, make sure you have a
        minimum deposit in the ETH Pool. At any time you can withdraw ETH from the ETH Pool
        (Withdraw tab).
      </Description>
      <InfoRow>
        <InfoType>Your balance in pool</InfoType>
        <InfoValue>
          {parseFloat(poolBalance).toFixed(4)} ETH (~{transferCount(poolBalanceBn)} bridge
          transfers)
        </InfoValue>
      </InfoRow>
      <Buttons>
        <button
          className={`${isDeposit && 'primary-button'} block-button button`}
          onClick={() => setIsDeposit(true)}
          type="button"
        >
          Deposit
        </button>
        <button
          className={`${!isDeposit && 'primary-button'} block-button button`}
          onClick={() => setIsDeposit(false)}
          type="button"
        >
          Withdraw
        </button>
      </Buttons>
      <LabelWrapper error={error} title={`ETH amount to ${text}`}>
        <Input onChange={onChange} value={value} placeholder="0.0000" />
        {error === null ? (
          isDeposit ? (
            <small>~{transferCount(toWei(value))} bridge transfers of tokens</small>
          ) : (
            <small>
              In order to ensure a successful transfer of tokens from the SKALE network to Ethereum
              network, there must be at least {minimumInPoolWei} ETH in the pool. You can withdraw
              the entire ETH balance if you do not want to use the bridge.
            </small>
          )
        ) : (
          <></>
        )}
        {!isDeposit && BigInt(balanceEthBn) > 0 ? (
          <MaxBtn onClick={setMax} type="button">
            Max
          </MaxBtn>
        ) : (
          <></>
        )}
      </LabelWrapper>
      <StatusBlock
        name={`Process to ${text}`}
        text=""
        disabled={error !== null}
        method={() => handle()}
      />
      <CancelBtn />
    </>
  )
}
