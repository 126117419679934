import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import { InputContainer, StyledInput, SubscribeButton } from '../../../../ui/common/Footer/styled'
import { LabelWrapper } from '../../../../ui/components/Form/Label/LabelWrapper'
import { SubscribeModal } from '../../../../ui/modals/SubscribeModal'
import VideoModal from '../../_common/VideoModal'
import play from '../../media/icons/play.svg'
import img from '../../media/season2.png'
import { Image } from '../../slides/Info'
import { TextBlock } from '../details'

const Play = styled.div`
  background: url(${play}) no-repeat center;
  background-size: contain;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s transform;
  z-index: 2;
  cursor: pointer;

  &:hover,
  &:focus {
    transform: translate(-50%, -50%) scale(1.1);
  }
`

const Block = styled(TextBlock)`
  text-align: center;

  a {
    color: var(--blue);
  }
`

const Info = (): JSX.Element => {
  const [modalState, setModalState] = useState('')
  const [email, setEmail] = useState('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }

  return (
    <div>
      <Image noBg>
        <img src={img} />
        <Play onClick={() => setModalState('video')} />
      </Image>
      <Block>Join the waiting list & get notified when the sale starts</Block>
      <LabelWrapper>
        <InputContainer style={{ maxWidth: '360px', margin: '0 auto 24px' }}>
          <StyledInput
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange}
          />
          <SubscribeButton
            className="primary-button button subscribe-button"
            onClick={() => setModalState('subscribe')}
          >
            Notify when active
          </SubscribeButton>
        </InputContainer>
      </LabelWrapper>
      {modalState === 'subscribe' && (
        <SubscribeModal value={email} close={() => setModalState('')} waitlist={'season2'} />
      )}
      {modalState === 'video' && <VideoModal close={() => setModalState('')} />}
    </div>
  )
}

export default Info
