import styled from 'styled-components'

export const CardMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
`

export const CardMenuItem = styled.button`
  font-weight: 700;
  text-align: left;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-dark);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const CardLine = styled.div`
  width: 100%;
  height: 1px;
  background: var(--line-day);
`
