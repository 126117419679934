import { CHAIN_ID } from '../../domain/network/contstant'

export const CT1 = {
  [CHAIN_ID.eth.mainnet]: '0x4F01887Cbd397a676921985639cEF79398204Cf0',
  [CHAIN_ID.eth.testnet]: '0xe308f589b3ceaec8444e28317981cd4f51089dd7'
}

export const CGT = {
  [CHAIN_ID.eth.mainnet]: '0xF56b164efd3CFc02BA739b719B6526A6FA1cA32a',
  [CHAIN_ID.eth.testnet]: '0xd219F93eab932441eE2c5A41b3353f0497447DF2',
  [CHAIN_ID.skale.testnet]: '0x026aAED0178856f93a4eB4Bec1ab2A86eFf29222',
  [CHAIN_ID.skale.mainnet]: '0x134EbAb7883dFa9D04d20674dd8A8A995fB40Ced',
  [CHAIN_ID.harmony.testnet]: '0x745A1f20d256003C8019622E42C8712A9b2de2bb',
  [CHAIN_ID.kovan.testnet]: '0x2f4d4cFAb714e4573189B300293944673Fe0efF7'
}

export const ERC20_TRANSFER_PROXY = {
  [CHAIN_ID.eth.mainnet]: '0x639014Aa1B4E026b2904acD40C276FaB6Fed45d0',
  [CHAIN_ID.eth.testnet]: '0x43e5F218602FCBBE923f130e567232f82ECA6464',
  [CHAIN_ID.skale.testnet]: '0xFc717050570398FA7ADB380ba76C1aF78D2Fd154',
  [CHAIN_ID.skale.mainnet]: '0x639014Aa1B4E026b2904acD40C276FaB6Fed45d0',
  [CHAIN_ID.harmony.testnet]: '0xFc717050570398FA7ADB380ba76C1aF78D2Fd154'
}
