import Web3 from 'web3'
import { fromWei } from 'web3-utils'

import { infuraMainnetProvider } from '../../config/infura'
import { CHAIN_ID } from '../../domain/network/contstant'
import { CHAINLINK_ABI } from './abi'
import { CHAINLINK } from './addresses'

export const latestAnswer = async (): Promise<string> => {
  const web3 = new Web3(infuraMainnetProvider)
  const price = await new web3.eth.Contract(CHAINLINK_ABI, CHAINLINK[CHAIN_ID.eth.mainnet]).methods
    .latestAnswer()
    .call()
  return (parseFloat(fromWei(price, 'gwei')) * 10).toString()
}
