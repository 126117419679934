const formatValueWithComma = (value: string, decimals?: number) => {
  const dec = decimals || 18
  const comma = value.indexOf('.')
  if (comma !== -1) {
    const lengthAfterComma = value.slice(comma + 1, value.length).length
    if (lengthAfterComma > dec) {
      return value.slice(0, comma + 1 + dec)
    }
  }
  return value
}

export const formatValue = (value: string): string => formatValueWithComma(value.replace(',', '.'))
