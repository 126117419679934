import React, { ChangeEvent, useState } from 'react'

import { approve } from '../../../../contracts/erc20/methods'
import { buyOrder } from '../../../../domain/order/methods/buyOrder'
import { useBalancesOfNFT } from '../../../../hooks/useBalancesOfNFT'
import { useBuyAvailable } from '../../../../hooks/useBuyAvailable'
import { useCGTBalance } from '../../../../hooks/useCGTBalance'
import { useERC20Allowance } from '../../../../hooks/useERC20Allowance'
import { validate } from '../../../../services/validate-form'
import { getPriceFromSum, getSumInput } from '../../../../utils/sum'
import { toFixed } from '../../../../utils/toFixed'
import { fromWei, toWei } from '../../../../utils/wei'
import { CheckWhitelist } from '../../../common/CheckWhitelist'
import { Modal } from '../../../common/Modal'
import { Owner } from '../../../common/Owner'
import { BuyNFTText } from '../../../common/WhitelistText'
import { CancelBtn } from '../../../components/CancelBtn'
import { Input, InputContainer } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import {
  Description,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  TokenName,
  Wrapper
} from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'
import { ActionProps } from './types'

export const Action = ({ web3, token, order }: ActionProps): JSX.Element => {
  const [quantity, setQuantity] = useState('1')
  const [error, setError] = useState<string | null>(null)
  const [approved, setApproved] = useState(false)

  const sum = getSumInput(order, quantity)
  const sumBn = toWei(sum)

  const curioOwned = useBalancesOfNFT(web3, token)
  const available = useBuyAvailable(order, token)
  const balance = useCGTBalance(web3)
  const allowance = useERC20Allowance(web3, sumBn)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const error = validate(['required', 'number'], value)
    setError(error)
    setQuantity(value)
    if (error === null) {
      setError(
        parseInt(value) > parseInt(available) ? `Value must be less than ${available}` : null
      )
    }
  }

  return (
    <Modal title="Checkout" width="350px">
      <CheckWhitelist web3={web3} token={token}>
        {curioOwned ? (
          <Wrapper>
            <Description>
              You are about to purchase a {token.name} <Owner token={token} />
            </Description>

            <LabelWrapper title="Your pay">
              <InputContainer>
                <Input type="text" value={getPriceFromSum(order)} disabled />
                <TokenName>CGT</TokenName>
              </InputContainer>
            </LabelWrapper>

            {token.standard === 'ERC1155' && (
              <LabelWrapper title="Quantity" error={error}>
                <InputContainer>
                  <Input
                    type="text"
                    value={quantity}
                    onChange={onChange}
                    disabled={available === '1'}
                  />
                </InputContainer>
                <small>Enter quantity. {available} available</small>
              </LabelWrapper>
            )}

            <ModalInfo>
              <InfoRow>
                <InfoType>Your balance</InfoType>
                {balance && <InfoValue>{toFixed(fromWei(balance))} CGT</InfoValue>}
              </InfoRow>
              <InfoRow>
                <InfoType>Your will pay</InfoType>
                <InfoValue>{sum} CGT</InfoValue>
              </InfoRow>
            </ModalInfo>

            <StatusBlock
              name="Approve"
              text={`Approve ${sum} CGT`}
              disabled={!!balance ? error !== null || BigInt(balance) < BigInt(sumBn) : true}
              method={async () => {
                const res = await approve(web3, sumBn)
                setApproved(res)
                return res
              }}
              check={allowance}
            />

            <StatusBlock
              name="Process payment"
              text={`Buy for ${sum} CGT`}
              method={() => buyOrder(web3, token, order, sum, quantity)}
              disabled={!approved && !allowance}
            />

            <CancelBtn />
          </Wrapper>
        ) : (
          <BuyNFTText />
        )}
      </CheckWhitelist>
    </Modal>
  )
}
