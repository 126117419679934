import { OrderApi, Ownership } from '../domain'
import { fromWei } from './wei'

export const getOwnerInfo = (ownership: Ownership): string => {
  if (ownership.isAvailableForPurchasing) {
    switch (ownership.status) {
      case 'NOT_FOR_SALE':
        return `${ownership.amount} editions not for sale`
      case 'FIXED_PRICE':
        return ownership.price
          ? `${ownership.stock} of ${ownership.amount} on sale for ${fromWei(
              ownership.price.toString()
            )} CGT each`
          : 'On sale'
      case 'AUCTION':
        return `${ownership.stock} of ${ownership.amount} open for bids`
    }
  } else {
    return `${ownership.amount} editions not for sale`
  }
  return 'Owner'
}

export const getOfferBid = (offer: OrderApi): string => {
  return `${fromWei(offer.make.value)} CGT for ${offer.take.value} edition`
}
