export const EVENTS = {
  connect: 'connect',
  requests: 'requests',
  createRequest: 'createConversationRequest',
  acceptRequest: 'acceptRequest',
  joinConversation: 'joinConversation',
  conversation: 'conversation',
  sendMessage: 'sendMessage',
  newMessage: 'newMessage',
  removeMessages: 'removeMessages',
  removedMessagesId: 'removedMessagesId'
}
