import React from 'react'

import { ListProfile, Token } from '../../../domain'
import { getTokenNetworkBadge } from '../../../utils/network'
import { NetworkBadge } from '../NetworkBadge'
import { CardBottom } from './Bottom'
import { CardImage } from './Image'
import { Menu } from './Menu'
import { CardWrap } from './styles'

export const Card = ({
  token,
  profiles
}: {
  token: Token
  profiles: ListProfile[]
}): JSX.Element => {
  const networks = getTokenNetworkBadge(token)

  return (
    <CardWrap>
      <Menu token={token} />
      <CardImage token={token} />
      {networks && <NetworkBadge networks={networks} />}
      <CardBottom token={token} profiles={profiles} />
    </CardWrap>
  )
}
