import { Link } from 'react-router-dom'
import styled from 'styled-components'

import details from '../../../assets/img/shares/icons/details.svg'
import bg from '../media/sharesbg.png'

export const Inner = styled.div`
  padding: 50px 0;
  min-height: calc(100vh - 68px - 68px);

  // differentStyle
  @media screen and (max-width: 916px) {
    min-height: initial;
  }
`
export const Wrapper = styled.div<{ bg?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.bg && `background: url(${bg}) center no-repeat;background-size: contain;`}
`

export const PageContainer = styled.div<{ maxWidth?: string }>`
  text-align: center;
  width: 100%;
`

export const ItemName = styled.h3`
  margin-bottom: 8px;
  font-weight: 400;
  text-align: center;
`

export const PageName = styled.h2`
  margin-bottom: 32px;
  text-align: center;
`

export const TimelineBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`

export const EventBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 80px 40px 1fr;
  grid-gap: 16px;
`

export const EventDate = styled.p`
  font-weight: 700;
  text-align: right;
  padding-top: 8px;
`

export const EventText = styled.p`
  font-weight: 400;
  text-align: left;
  padding-top: 7px;
  font-size: 14px;
  line-height: 28px;
`

export const TextBlock = styled.p`
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 28px;
`

export const Documents = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => (props.columns ? props.columns : 3)}, auto);
  grid-column-gap: 16px;
  justify-content: center;
  margin-bottom: 24px;

  @media screen and (max-width: 435px) {
    grid-template-columns: repeat(2, auto);
  }
`

export const DocumentExtLink = styled.a`
  display: block;
`

export const DocumentLink = styled(Link)`
  display: block;
`

export const Document = styled.div<{ img?: string }>`
  text-align: center;
  line-height: 24px;
  font-weight: 700;
  width: 80px;
  cursor: pointer;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    background: url(${(props) => (props.img ? props.img : details)}) no-repeat center;
    background-size: contain;
    margin: 0 auto 4px auto;
  }
`

export const MiniGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 80%;
  margin: 0 auto 32px;
  & div video,
  & div img {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
  & div:nth-child(2) {
    align-items: flex-end;
    justify-content: flex-start;
  }
  & div:nth-child(2) video,
  & div:nth-child(2) img {
    width: 80%;
  }
  & div:nth-child(3) {
    align-items: flex-start;
    justify-content: flex-end;
  }
  & div:nth-child(3) video,
  & div:nth-child(3) img {
    width: 80%;
  }
`

export const GalleryItem = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
`
