import eth_classic from '../assets/img/networks/eth-classic.svg'
import kleros from '../assets/img/networks/kleros.svg'
import { isMarioCollection, Token } from '../domain'
import { SUPPORT_CHAIN_NAME } from '../domain/network/contstant'
import { getAppNetworkByChainName, getNetworkIcon } from '../domain/network/mappers'

export const getUsingNetwork = (): Network => {
  const search = new URLSearchParams(window.location.search)
  const network = search.get('network')
  return network ? SUPPORT_CHAIN_NAME[network] || SUPPORT_CHAIN_NAME.eth : SUPPORT_CHAIN_NAME.eth
}

export const getQueryNetwork = (network: Network): string => {
  const key = getAppNetworkByChainName(network)
  return key === 'eth' ? '' : `?network=${key}`
}

export const getTokenNetworkBadge = (token: Token): string[] | null => {
  const { category } = token
  const icons = [getNetworkIcon(token.collection.network)]
  if (category && category.tag === 'season-3') {
    icons.push(eth_classic)
  }
  if (isMarioCollection(token)) {
    icons.push(kleros)
  }
  return icons
}
