import styled from 'styled-components'

import { NetworkLogo } from '../NetworkLogo'

export const Wrapper = styled.div`
  position: relative;

  p {
    cursor: pointer;
  }

  p:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const Button = styled(NetworkLogo)`
  cursor: pointer;
`
