import { AbiItem } from 'web3-utils'

export const NFT_LOTTERY_ABI: AbiItem[] = [
  {
    type: 'constructor',
    stateMutability: 'nonpayable',
    inputs: [{ type: 'address', name: '_ticketCollection', internalType: 'contract IERC721' }]
  },
  {
    type: 'event',
    name: 'AddCollections',
    inputs: [
      {
        type: 'tuple',
        name: 'nftCollection',
        internalType: 'struct NftLottery.Collection',
        indexed: false,
        components: [
          { type: 'uint8', name: 'tokenType', internalType: 'enum NftLottery.TokenType' },
          { type: 'address', name: 'collectionAddress', internalType: 'address' },
          { type: 'uint256', name: 'tokenId', internalType: 'uint256' }
        ]
      }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'AddNewTicketId',
    inputs: [{ type: 'uint256', name: 'tokenId', internalType: 'uint256', indexed: false }],
    anonymous: false
  },
  {
    type: 'event',
    name: 'FailedClaim',
    inputs: [
      { type: 'address', name: 'nftOwner', internalType: 'address', indexed: true },
      { type: 'uint256', name: 'tokenId', internalType: 'uint256', indexed: false }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'OwnershipTransferred',
    inputs: [
      { type: 'address', name: 'previousOwner', internalType: 'address', indexed: true },
      { type: 'address', name: 'newOwner', internalType: 'address', indexed: true }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'RemoveCollection',
    inputs: [{ type: 'uint256', name: 'index', internalType: 'uint256', indexed: false }],
    anonymous: false
  },
  {
    type: 'event',
    name: 'SuccessfulClaim',
    inputs: [
      { type: 'address', name: 'nftOwner', internalType: 'address', indexed: true },
      { type: 'uint256', name: 'tokenId', internalType: 'uint256', indexed: false },
      { type: 'address', name: 'collectionAddress', internalType: 'address', indexed: false },
      { type: 'uint256', name: 'collectionTokenId', internalType: 'uint256', indexed: false }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'WithdrawERC1155',
    inputs: [
      { type: 'address', name: 'collection', internalType: 'contract IERC1155', indexed: false },
      { type: 'uint256', name: 'tokenId', internalType: 'uint256', indexed: false },
      { type: 'uint256', name: 'amount', internalType: 'uint256', indexed: false }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'WithdrawERC20',
    inputs: [
      { type: 'address', name: 'token', internalType: 'contract IERC20', indexed: false },
      { type: 'uint256', name: 'amount', internalType: 'uint256', indexed: false }
    ],
    anonymous: false
  },
  {
    type: 'event',
    name: 'WithdrawERC721',
    inputs: [
      { type: 'address', name: 'collection', internalType: 'contract IERC721', indexed: false },
      { type: 'uint256', name: 'tokenId', internalType: 'uint256', indexed: false }
    ],
    anonymous: false
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'BP',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'addCollections',
    inputs: [
      {
        type: 'tuple[]',
        name: '_nftCollections',
        internalType: 'struct NftLottery.Collection[]',
        components: [
          { type: 'uint8', name: 'tokenType', internalType: 'enum NftLottery.TokenType' },
          { type: 'address', name: 'collectionAddress', internalType: 'address' },
          { type: 'uint256', name: 'tokenId', internalType: 'uint256' }
        ]
      }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'addTicketIds',
    inputs: [{ type: 'uint256[]', name: '_tokenIds', internalType: 'uint256[]' }]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'claim',
    inputs: [{ type: 'uint256', name: '_tokenId', internalType: 'uint256' }]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      {
        type: 'tuple',
        name: 'reward',
        internalType: 'struct NftLottery.Collection',
        components: [
          { type: 'uint8', name: 'tokenType', internalType: 'enum NftLottery.TokenType' },
          { type: 'address', name: 'collectionAddress', internalType: 'address' },
          { type: 'uint256', name: 'tokenId', internalType: 'uint256' }
        ]
      },
      { type: 'address', name: 'owner', internalType: 'address' },
      { type: 'bool', name: 'isSpent', internalType: 'bool' }
    ],
    name: 'claimed',
    inputs: [{ type: 'uint256', name: '', internalType: 'uint256' }]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [
      { type: 'uint8', name: 'tokenType', internalType: 'enum NftLottery.TokenType' },
      { type: 'address', name: 'collectionAddress', internalType: 'address' },
      { type: 'uint256', name: 'tokenId', internalType: 'uint256' }
    ],
    name: 'collections',
    inputs: [{ type: 'uint256', name: '', internalType: 'uint256' }]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'bool', name: '', internalType: 'bool' }],
    name: 'isTicketId',
    inputs: [{ type: 'uint256', name: '', internalType: 'uint256' }]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'bytes4', name: '', internalType: 'bytes4' }],
    name: 'onERC1155BatchReceived',
    inputs: [
      { type: 'address', name: '', internalType: 'address' },
      { type: 'address', name: '', internalType: 'address' },
      { type: 'uint256[]', name: '', internalType: 'uint256[]' },
      { type: 'uint256[]', name: '', internalType: 'uint256[]' },
      { type: 'bytes', name: '', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'bytes4', name: '', internalType: 'bytes4' }],
    name: 'onERC1155Received',
    inputs: [
      { type: 'address', name: '', internalType: 'address' },
      { type: 'address', name: '', internalType: 'address' },
      { type: 'uint256', name: '', internalType: 'uint256' },
      { type: 'uint256', name: '', internalType: 'uint256' },
      { type: 'bytes', name: '', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [{ type: 'bytes4', name: '', internalType: 'bytes4' }],
    name: 'onERC721Received',
    inputs: [
      { type: 'address', name: '', internalType: 'address' },
      { type: 'address', name: '', internalType: 'address' },
      { type: 'uint256', name: '', internalType: 'uint256' },
      { type: 'bytes', name: '', internalType: 'bytes' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'address' }],
    name: 'owner',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'uint256', name: '', internalType: 'uint256' }],
    name: 'participants',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'removeCollection',
    inputs: [{ type: 'uint256', name: '_index', internalType: 'uint256' }]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'renounceOwnership',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'bool', name: '', internalType: 'bool' }],
    name: 'supportsInterface',
    inputs: [{ type: 'bytes4', name: 'interfaceId', internalType: 'bytes4' }]
  },
  {
    type: 'function',
    stateMutability: 'view',
    outputs: [{ type: 'address', name: '', internalType: 'contract IERC721' }],
    name: 'ticketCollection',
    inputs: []
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'transferOwnership',
    inputs: [{ type: 'address', name: 'newOwner', internalType: 'address' }]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawERC1155',
    inputs: [
      { type: 'address', name: '_collection', internalType: 'contract IERC1155' },
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawERC20',
    inputs: [
      { type: 'address', name: '_token', internalType: 'contract IERC20' },
      { type: 'uint256', name: '_amount', internalType: 'uint256' }
    ]
  },
  {
    type: 'function',
    stateMutability: 'nonpayable',
    outputs: [],
    name: 'withdrawERC721',
    inputs: [
      { type: 'address', name: '_collection', internalType: 'contract IERC721' },
      { type: 'uint256', name: '_tokenId', internalType: 'uint256' }
    ]
  }
]
