import styled from 'styled-components'

export const LinksBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 0 50px;
    & > a:last-child {
      grid-column: 2;
    }
  }
  @media screen and (max-width: 940px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 588px;
    & > a:last-child {
      grid-column: unset;
    }
  }
`

export const MediaLink = styled.a`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 57px;
  @media screen and (max-width: 1240px) {
    padding: 0;
  }
`

export const LinkLogo = styled.div`
  display: flex;
  // width: 100%;
  & > img {
    width: 100%;
    height: auto;
  }
`
