import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, max-content);
  // grid-template-columns: repeat(3, max-content);
  grid-column-gap: 24px;
  padding-inline-start: 0;
  @media screen and (max-width: 1140px) {
    grid-column-gap: 18px;
  }
`
export const activeClassName = 'ACTIVE'

export const Nav = styled.nav`
  &.nav_mobile {
    position: absolute;
    top: 70px;
    left: 20px;
    right: 20px;
    background-color: var(--white);
    width: calc(100% - 40px);
    border-radius: 16px;
    padding: 0 20px;
    z-index: 2;

    ul {
      display: block;
    }
  }
`

export const Item = styled(NavLink).attrs({
  activeClassName
})`
  font-weight: 700;
  transition: 0.3s;
  font-size: 12px;
  color: var(--text-gray);
  display: block;
  &.${activeClassName} {
    color: var(--text-dark);
  }
  &:hover {
    color: var(--text-light);
    text-decoration: underline;
  }
  &:focus {
    color: var(--text-light);
    text-decoration: underline;
  }
`
