import { useEffect, useState } from 'react'

import { checkUserBalance, getPoolBalance } from '../contracts/community-pool/methods'
import { Web3Type } from '../types/web3'

export const usePoolBalance = (web3: Web3Type): string | undefined => {
  const [balance, setBalance] = useState<string>()

  useEffect(() => {
    getPoolBalance(web3).then((res) => {
      setBalance(res)
    })
  }, [web3])

  return balance
}

export const useCheckUserBalance = (web3: Web3Type): boolean | undefined => {
  const [value, setValue] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    checkUserBalance(web3).then((res) => {
      setValue(res)
    })
  }, [web3])

  return value
}
