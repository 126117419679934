import React, { useEffect, useState } from 'react'

import logo from '../../../assets/img/logo.svg'
import grayLogo from '../../../assets/img/logo-white.svg'
import { Messenger } from '../../../modules/Messenger/components/Messenger'
import { MessengerProvider } from '../../../modules/Messenger/context/Messenger.context'
import { HeaderBtns } from '../../components/HeaderBtns'
import { HeaderSearchBar } from '../HeaderSearchBar'
import { Menu } from '../Menu'
import { DesctopBlock, Inner, LeftBox, Logo, MobileSearch, NavBox } from './styles'

type Props = {
  blue?: boolean
}

export const HeaderInner = (props: Props): JSX.Element => {
  const [mobileSearch, setMobileSearch] = useState(false)
  const [openMessenger, setOpenMessenger] = useState(false)
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        setMobileSearch(false)
      }
    })
  }, [])
  useEffect(() => {
    if (mobileSearch && window.innerWidth <= 475) document.body.classList.add('hidden')
    else document.body.classList.remove('hidden')
  }, [mobileSearch])

  return (
    <MessengerProvider>
      <Inner>
        <LeftBox>
          <Logo to="/">
            <img src={props.blue ? grayLogo : logo} alt="Logo" />
          </Logo>
          <DesctopBlock>
            <HeaderSearchBar />
          </DesctopBlock>
        </LeftBox>
        <NavBox>
          <Menu />
          <HeaderBtns
            search={() => setMobileSearch(!mobileSearch)}
            openMessenger={() => setOpenMessenger(!openMessenger)}
          />
        </NavBox>
        {mobileSearch && (
          <MobileSearch>
            <HeaderSearchBar close={() => setMobileSearch(false)} />
          </MobileSearch>
        )}
        {openMessenger && <Messenger closeMessenger={() => setOpenMessenger(false)} />}
      </Inner>
    </MessengerProvider>
  )
}
