import { create } from 'ipfs-http-client'

export const getIpfsLink = (hash: string): string =>
  hash.includes('https') || hash.includes('logo') ? hash : `https://ipfs.io/ipfs/${hash}`

const ipfs = create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' })

type Meta = {
  image: string
  name: string
  description: string
}

export const ipfsFileUpload = async (file: File): Promise<string> => {
  const data = await ipfs.add(file)
  return data.path
}

export const ipfsMetaUpload = async (hash: string, meta: Meta): Promise<string> => {
  const data = await ipfs.add(JSON.stringify(meta))
  return data.path
}

export const uploadNFT = async (file: File, name: string, description: string): Promise<string> => {
  const image = await ipfsFileUpload(file)
  const meta = {
    image,
    name,
    description
  }
  return await ipfsMetaUpload(image, meta)
}
