import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

type Response = {
  category: string | null
  blockchain: string | null
  setParam: (param: 'blockchain' | 'category', value?: string) => void
}

export const useCurrentCategory = (): Response => {
  const location = useLocation()
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  const blockchain = params.get('blockchain')
  const category = location.pathname === '/explore' ? params.get('category') || 'rwa' : null

  const setParam = (param: string, value?: string) => {
    if (value) {
      params.set(param, value)
    } else {
      params.delete(param)
    }
    history.push({ search: params.toString() })
  }

  return {
    category: category === 'all' ? null : category,
    blockchain,
    setParam
  }
}
