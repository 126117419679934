import { contractError } from '../../contracts/contractError'
import { CGT } from '../../contracts/erc20/addresses'

export const addTokenToMetamask = async (chainId: number): Promise<boolean> => {
  try {
    contractError(CGT[chainId])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: CGT[chainId],
          symbol: 'CGT',
          decimals: 18,
          image:
            'https://raw.githubusercontent.com/CurioTeam/capital-dex-token-list/master/assets/tokens/cgt.svg'
        }
      }
    })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
