import React from 'react'

import { Token } from '../../../../domain'
import { BLOCKCHAIN_NAME } from '../../../../domain/network/contstant'
import { isSkaleNetwork } from '../../../../domain/network/mappers'
import { useCheckUserBalance } from '../../../../hooks/usePoolBalance'
import { isTimeLimit } from '../../../../services/localStorage/bridge-timer'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Loader } from '../../../components'
import { Wrapper } from '../../../components/ModalStyles'
import { Action } from './Action'
import { Deposit } from './Deposit'
import { Timer } from './Timer'

export const MoveToNetwork = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const title = `Move to ${BLOCKCHAIN_NAME[token.collection.network]}`
  const userBalance = useCheckUserBalance(web3)

  return (
    <Modal title={title} width="350px">
      <Wrapper>
        {isSkaleNetwork(token.collection.network) && !isTimeLimit() ? (
          <Timer />
        ) : isSkaleNetwork(token.collection.network) && !userBalance ? (
          userBalance === undefined ? (
            <Loader center />
          ) : (
            <Deposit />
          )
        ) : (
          <Action token={token} web3={web3} title={title} />
        )}
      </Wrapper>
    </Modal>
  )
}
