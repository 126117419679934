export const questions = [
  {
    question: 'What is Rollapp?',
    answer:
      'A digital token that represents a tangible object in the real world is called a physically-backed NFT. You can convert your physical objects into physically-backed NFTs and vice versa using Rollapp.'
  },
  {
    question: 'Does Rollapp charge any fees?',
    answer:
      'We charge 2.5% only when your asset is resold. If you set a royalty, we take a 20% cut from any additional reward you get onwards.'
  },
  {
    question: 'What is CGT?',
    answer:
      'CGT token refers to our decentralized CurioDAO ecosystem and provides you a mean to participate in the governance activities of the association among several other utilities.'
  },
  {
    question: 'How do I earn money using Rollapp?',
    answer:
      'Everytime you convert your physical asset to an NFT, the NFT can be sold therefore generating a pay out. Moreover, you can set a royalty providing you with an additional reward.'
  },
  {
    question: 'How do I convert my physical asset to an NFT?',
    answer:
      'It is simple to transform any actual object into an NFT using Rollapp. For a physical NFT, fill out this intake form. Within a few days, a member of our staff will get in touch with you to walk you through the procedure of sending your item to our warehouse and minting it as an NFT.'
  },
  {
    question: 'What occurs to my physical asset once it has been transformed into an NFT?',
    answer:
      'Sellers are obliged to ship their things to our secure warehousing facility after the NFT is issued in order to guarantee that there is a one-to-one relationship between a physical asset and an NFT. The physical item will be carefully protected, providing you the confidence to complete all digital transactions using your physically-backed NFT. In the future, a digital solution by Kleros will enable you to keep the object with you.'
  },
  {
    question: 'Is the physical asset still mine once it was transformed into an NFT?',
    answer: 'Yes. You can always exchange your physically-backed NFT for the tangible object.'
  },
  {
    question:
      'If my physical asset doesn`t sell on the Rollapp Marketplace, can I get my money back?',
    answer: 'Yes. You can always exchange your physically-backed NFT for the tangible object.'
  },
  {
    question: 'Do I really have ownership in the asset?',
    answer:
      'Yes. Owning a Rollapp is true co-ownership. Your purchase a digital asset representing co-ownership. The asset is fully managed and designed specifically for co-ownership.'
  },
  {
    question: 'Can I sell my NFT that is physically backed on other online stores?',
    answer:
      'While it is technically possible, we strongly advise using Rollapp to buy and sell your physically-backed NFTs in order to ensure complete functioning and lower fees. Redeeming physical items is currently only possible through Rollapp.'
  }
]
