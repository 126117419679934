import { fromWei } from '../../../utils/wei'
import { Ownership } from '../types/Ownership'
import { Token } from '../types/Token'
import { getOwnerByAddress } from './owner.mapper'

export const isTokenOnSale = (token: Token, account: AccountAddress): boolean =>
  !!account &&
  !!token.ownerships.find(
    (owner) =>
      owner.ownerAddress.toLowerCase() === account.toLowerCase() &&
      owner.status !== 'NOT_FOR_SALE' &&
      owner.isAvailableForPurchasing
  )

export const haveFixedPrice = (ownerships: Ownership[]): boolean =>
  !!ownerships.find((owner) => owner.status === 'FIXED_PRICE')

export const haveFixedPriceByOwner = (ownerships: Ownership[], address: UserAddress): boolean =>
  !!ownerships.find(
    (owner) =>
      owner.status === 'FIXED_PRICE' && owner.ownerAddress.toLowerCase() === address.toLowerCase()
  )

export const haveAuction = (ownerships: Ownership[]): boolean =>
  !!ownerships.find((owner) => owner.status === 'AUCTION')

export const onSale = (ownerships: Ownership[]): boolean =>
  !!ownerships.find((owner) => owner.status !== 'NOT_FOR_SALE')

export const getAvailable = (account: AccountAddress, token: Token): number => {
  const owner = account ? getOwnerByAddress(account, token.ownerships) : undefined
  return owner ? owner.amount : 0
}

export const isAvailableForPurchasing = (ownerships: Ownership[], address: UserAddress): boolean =>
  !!ownerships.find(
    (owner) =>
      owner.ownerAddress.toLowerCase() === address.toLowerCase() && owner.isAvailableForPurchasing
  )

export const getStockForCard = (token: Token): string =>
  `${token.totalStock ? `${token.totalStock}/` : 'x'}${token.supply}`

export const getMinPrice = (token: Token, address: AccountAddress): string | null => {
  const orders = token.ownerships.filter(
    (item) =>
      item.status === 'FIXED_PRICE' && address?.toLowerCase() !== item.ownerAddress.toLowerCase()
  )
  let minPrice: string | null = null
  if (orders.length > 0) {
    orders.map((item) => {
      if (minPrice) {
        if (item.price && BigInt(item.price) < BigInt(minPrice)) {
          minPrice = item.price
        }
      } else {
        minPrice = item.price
      }
    })
  }
  return minPrice ? fromWei(minPrice) : null
}
