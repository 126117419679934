import styled, { keyframes } from 'styled-components'

const animationq = keyframes`
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
`

export const Placeholder = styled.div<{ height?: string; width?: number; border?: string }>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? props.height : '24px')};
  background-color: var(--line-day);
  border-radius: ${(props) => (props.border ? props.border : '6px')};
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: relative;
    height: 100%;

    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${animationq};
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(
      to right,
      var(--line-day) 10%,
      var(--white) 18%,
      var(--line-day) 33%
    );
    background-size: 800px 104px;
  }
`
