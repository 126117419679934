import React, { ChangeEvent, useState } from 'react'

import grayLogo from '../../../assets/img/rollapp-footer.svg'
import { APPS } from '../../../config/socials'
import MiniDc from '../../../ui/icons/MiniDc'
import MiniIg from '../../../ui/icons/MiniIg'
import MiniTw from '../../../ui/icons/MiniTw'
import { SubscribeModal } from '../../../ui/modals/SubscribeModal'
import {
  FooterBottomText,
  FooterContainer,
  FooterMainBlock,
  FooterText,
  FooterWrapper,
  LabelWrapper,
  LogoLink,
  MainLeftBlock,
  MainRightBlock,
  Popup,
  SocialLink,
  SocialsLinks,
  SubsribeInput,
  VertLine
} from './styled'

export const Footer = (): JSX.Element => {
  const [modalState, setModalState] = useState(false)
  const [email, setEmail] = useState('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }
  const SOCIALS = [
    {
      link: 'https://twitter.com/curio_invest',
      icon: MiniTw()
    },
    {
      link: 'https://www.instagram.com/curioinvest/',
      icon: MiniIg()
    },
    {
      link: 'https://discord.com/invite/VSvY6tBF4g',
      icon: MiniDc()
    }
  ]

  return (
    <FooterWrapper>
      <FooterContainer>
        <FooterMainBlock>
          <MainLeftBlock>
            <LogoLink to="/">
              <img src={grayLogo} />
            </LogoLink>
            <FooterText>
              The leading Swiss marketplace for NFTs (non-fungible tokens) backed by physical
              assets. Buy, sell, invest in collectible items.
            </FooterText>
          </MainLeftBlock>
          <MainRightBlock>
            <SocialsLinks>
              {APPS.map((item, index) => (
                <SocialLink key={index}>
                  {item.icon}
                  <Popup>Coming soon</Popup>
                </SocialLink>
              ))}
              <VertLine />
              {SOCIALS.map((item, index) => (
                <SocialLink key={index} target="_blank" href={item.link}>
                  {item.icon}
                </SocialLink>
              ))}
            </SocialsLinks>
            <LabelWrapper>
              <SubsribeInput
                type="email"
                value={email}
                placeholder="Email address"
                onChange={handleInputChange}
              />
              <button
                onClick={() => setModalState(!modalState)}
                className="landing-subscribe-button button"
              >
                Subscribe
              </button>
            </LabelWrapper>
          </MainRightBlock>
        </FooterMainBlock>
        <FooterBottomText>RollApp.store 2022</FooterBottomText>
      </FooterContainer>
      {modalState && <SubscribeModal value={email} close={() => setModalState(false)} />}
    </FooterWrapper>
  )
}
