import React from 'react'
import { useParams } from 'react-router-dom'

import { Tabs } from '../../ui/components/Tabs'
import { SearchCollections } from './Collections'
import { SearchItems } from './Items'
import { SearchSideText } from './styles'
import { SearchUsers } from './Users'

export const Search = (): JSX.Element => {
  const queryPath = window.location.search
  const query = queryPath.replace('?query=', '').replace('%20', ' ')

  const { page } = useParams<{ page?: string }>()

  const tabs = [
    {
      link: `/`,
      title: 'Items'
    },
    {
      link: `/users`,
      title: 'Users'
    },
    {
      link: `/collections`,
      title: 'Collections'
    }
  ]

  return (
    <div className="container grid">
      <SearchSideText>
        Search results for <span>{query}</span>
      </SearchSideText>
      <main>
        <Tabs items={tabs} />
        <div style={{ paddingTop: 48 }}>
          {page === 'collections' ? (
            <SearchCollections query={query} />
          ) : page === 'users' ? (
            <SearchUsers query={query} />
          ) : (
            <SearchItems query={query} />
          )}
        </div>
      </main>
    </div>
  )
}
