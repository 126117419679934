import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { Title } from '../../../ui/common/Modal/styles'
import { NotFound } from '../../../ui/components/NotFound'
import { DAO_DATA } from '../data/data'

const Wrapper = styled.div`
  max-width: 576px;
  margin: 0 auto;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 24px;
`

export const BlockTitle = styled.h3`
  font-size: 24px;
  line-height: 48px;
  margin-bottom: 24px;
`

export const Details = (): JSX.Element => {
  const params = useParams<{ page: string }>()
  const { moreDetails, title } = DAO_DATA[params.page]

  return moreDetails ? (
    <Wrapper>
      <Title className="h2">{title}</Title>
      {/*<Documents>
        <DocumentExtLink href="/" target="_blank">
          <Document>C.O.A</Document>
        </DocumentExtLink>
        <DocumentExtLink href="/" target="_blank">
          <Document>Records</Document>
        </DocumentExtLink>
        <DocumentExtLink href="/" target="_blank">
          <Document>Specs</Document>
        </DocumentExtLink>
      </Documents> */}
      {moreDetails.map((item) => (
        <div key={item.title}>
          <BlockTitle>{item.title}</BlockTitle>
          {item.items.map((row) => (
            <Row key={item.title}>
              <span>{row.title}</span>
              <span>{row.value}</span>
            </Row>
          ))}
        </div>
      ))}
    </Wrapper>
  ) : (
    <NotFound />
  )
}

export default Details
