import React, { ChangeEvent, useEffect, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { validate } from '../../../services/validate-form'
import { ErrorStatus, Rules } from '../../../services/validate-form/types'
import { SubResultModal } from '../../../ui/modals/SubResultModal'
import {
  Container,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import {
  ButtonBlock,
  Checkboxes,
  CheckBoxesBlock,
  CheckboxLabel,
  ChooseTitle,
  FormBlock,
  InputsBlock,
  InputTitle,
  LabelWrapper,
  RadioGroup,
  RadioLabel,
  RadiosBlock,
  SubTitle
} from './styled'

type TestProfile = {
  fullName: string
  email: string
  phone: string
  userType: string
}

export const FormsSection = (): JSX.Element => {
  const [modalState, setModalState] = useState(false)
  const [status, setStatus] = useState('pending')
  const [errorText, setErrorText] = useState('')

  const assets = ['Watches', 'Cars', 'Wines', 'IP', 'Gems', 'Others']

  const rules: Rules = {
    fullName: ['required', 'string'],
    email: ['required', 'email'],
    phone: ['required', 'phone'],
    userType: ['required']
  }

  const [values, setValues] = useState<TestProfile>({
    fullName: '',
    email: '',
    phone: '',
    userType: 'I am a buyer'
  })

  const [errors, setErrors] = useState<{ [k in string]: ErrorStatus }>({
    fullName: undefined,
    email: undefined,
    phone: undefined,
    userType: null
  })

  const [interests, setInterests] = useState<{ [k in string]: boolean }>({
    Watches: false,
    Cars: false,
    Wines: false,
    IP: false,
    Gems: false,
    Others: false
  })

  const defaultValue = (value: string | null) => (value ? value : '')

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, type, checked } = e.target
    if (type === 'checkbox') {
      setInterests({ ...interests, [name]: checked })
    } else {
      setValues({ ...values, [name]: value })
      setErrors({ ...errors, [name]: validate(rules[name], value) })
    }
  }

  const submit = async () => {
    setErrorText('')
    setStatus('pending')
    const checkboxes = Object.keys(interests).filter((key) => interests[key])

    try {
      const data = {
        fullName: values.fullName,
        email: values.email,
        phone: values.phone,
        userType: values.userType,
        interestsInAssets: checkboxes
      }
      await clientV3.forms.contactUs(data)
      setStatus('success')
      setValues({
        fullName: '',
        email: '',
        phone: '',
        userType: 'I am a buyer'
      })
    } catch (e: any) {
      if (e.response.data.message.includes('Your request has already been sent earlier')) {
        setStatus('warning')
      } else {
        setStatus('error')
        if (e.response.data.message.includes('phone must be a valid phone number')) {
          setErrorText('Check if the phone number is correct')
        } else {
          setErrorText('Something went wrong. Try again later')
        }
      }
    }
  }

  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SectionTitle>Don’t miss out</SectionTitle>
          <SectionDescription>
            The best assets sell fast. See the latest listings, inspiring objects and buying tips.
          </SectionDescription>
        </SectionHeader>
        <FormBlock>
          <RadiosBlock>
            <SubTitle>Select one of the following</SubTitle>
            <RadioGroup>
              <RadioLabel>
                <input
                  type="radio"
                  name="userType"
                  value="I am a buyer"
                  checked={values.userType === 'I am a buyer'}
                  onChange={onChange}
                />
                <span />
                <ChooseTitle>I am a buyer</ChooseTitle>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name="userType"
                  value="I am a seller"
                  checked={values.userType === 'I am a seller'}
                  onChange={onChange}
                />
                <span />
                <ChooseTitle>I am a seller</ChooseTitle>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name="userType"
                  value="I'm an agent or broker"
                  checked={values.userType === `I'm an agent or broker`}
                  onChange={onChange}
                />
                <span />
                <ChooseTitle>I&#39;m an agent or broker</ChooseTitle>
              </RadioLabel>
            </RadioGroup>
          </RadiosBlock>
          <InputsBlock>
            <LabelWrapper>
              <InputTitle>Full name</InputTitle>
              <input
                name="fullName"
                value={defaultValue(values.fullName)}
                placeholder=""
                onChange={onChange}
              />
              <span>{errors.fullName}</span>
            </LabelWrapper>
            <LabelWrapper>
              <InputTitle>Email</InputTitle>
              <input
                name="email"
                value={defaultValue(values.email)}
                placeholder=""
                onChange={onChange}
              />
              <span>{errors.email}</span>
            </LabelWrapper>
            <LabelWrapper>
              <InputTitle>Phone</InputTitle>
              <input
                name="phone"
                value={defaultValue(values.phone)}
                placeholder=""
                onChange={onChange}
              />
              <span>{errors.phone}</span>
            </LabelWrapper>
          </InputsBlock>
          <CheckBoxesBlock>
            <SubTitle>Interest in assets</SubTitle>
            <Checkboxes>
              {assets.map((item) => (
                <CheckboxLabel key={item}>
                  <input type="checkbox" name={item} onChange={onChange} />
                  <span />
                  <ChooseTitle>{item}</ChooseTitle>
                </CheckboxLabel>
              ))}
            </Checkboxes>
          </CheckBoxesBlock>
          <ButtonBlock>
            <button
              disabled={
                !Object.keys(interests).find((key) => interests[key]) ||
                !!Object.keys(errors).find((key) => errors[key] !== null)
              }
              className="button block-button primary-button"
              onClick={() => {
                setModalState(!modalState)
                submit()
              }}
            >
              Contact us
            </button>
          </ButtonBlock>
        </FormBlock>
      </Container>
      {modalState && (
        <SubResultModal errorText={errorText} status={status} close={() => setModalState(false)} />
      )}
    </SectionWrapper>
  )
}
