import React from 'react'

import { PreviewProfile } from '../../../domain'
import { shortAddress } from '../../../utils/shortAddress'
import { Avatar } from '../Avatar'
import { CardAvatar, CardBottom, CardImage, CardTitle, MiniStyled, Wrap } from '../MiniCard/styles'

export const UserCard = ({ user }: { user: PreviewProfile }): JSX.Element => {
  return (
    <MiniStyled to={`/profile/${user.walletAddress}/owned`}>
      <Wrap>
        <CardImage bg={user.photo.cover} />
        <CardBottom>
          <CardAvatar>
            <Avatar
              border="white"
              image={user.photo.avatarS3Link || user.photo.avatar}
              width="72px"
            />
          </CardAvatar>
          <CardTitle>{user.personal.displayName || shortAddress(user.walletAddress)}</CardTitle>
        </CardBottom>
      </Wrap>
    </MiniStyled>
  )
}
