import React, { useEffect, useState } from 'react'

import { Collection } from '../../../domain'
import { clientV3 } from '../../../services/api'
import { Carousel } from './Carousel'
import { Title } from './styles'

export const Collections = (): JSX.Element => {
  const [collections, setCollections] = useState<Collection[]>()

  useEffect(() => {
    clientV3.collection
      .all()
      .then((res) => {
        const { data } = res
        setCollections(data)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  return (
    <section>
      <Title>Collections</Title>
      {collections && collections.length > 0 && <Carousel collections={collections} />}
    </section>
  )
}
