import { Filters, Headers } from './types'

export const LIMIT_PAGES = 12

const paginator = (page: number): string => `?page=${page}&per_page=${LIMIT_PAGES}`

export const getLinkForToken = (page: number, filters?: Filters): string => {
  let add = ''
  if (filters) {
    for (const filter in filters) {
      if (filters[filter]) {
        add += `&${filter}=${filters[filter]}`
      }
    }
  }
  return `/tokens${paginator(page)}${add}`
}

export const accountHeader = (address: AccountAddress): { headers: Headers } | undefined => {
  return address
    ? {
        headers: {
          'X-Wallet-Address': address.toLowerCase()
        }
      }
    : undefined
}

export const prepareHeaders = (
  signature: Signature,
  address: UserAddress,
  tokenId?: TokenId
): { headers: Headers } => {
  let headers: Headers = {
    'X-Wallet-Signature': signature,
    'X-Wallet-Address': address.toLowerCase()
  }
  if (tokenId) {
    headers = { ...headers, 'x-token-id': tokenId }
  }
  return { headers }
}
