import Web3 from 'web3'

import { Token } from '../../domain'
import { Web3Type } from '../../types/web3'
import { contractError } from '../contractError'
import { NFT_LOTTERY_ABI } from './abi'
import { NFT_LOTTERY } from './addresses'
import { Claimed } from './types'

export const collections = async (w: Web3Type): Promise<Claimed | undefined> => {
  try {
    contractError(NFT_LOTTERY[w.chainId])
    const web3 = new Web3(w.library)
    return await new web3.eth.Contract(NFT_LOTTERY_ABI, NFT_LOTTERY[w.chainId]).methods
      .collections(0)
      .call()
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export const claimed = async (w: Web3Type, token: Token): Promise<Claimed | undefined> => {
  try {
    contractError(NFT_LOTTERY[w.chainId])
    const web3 = new Web3(w.library)
    return await new web3.eth.Contract(NFT_LOTTERY_ABI, NFT_LOTTERY[w.chainId]).methods
      .claimed(token.tokenId)
      .call()
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export const claim = async (w: Web3Type, token: Token): Promise<boolean> => {
  try {
    contractError(NFT_LOTTERY[w.chainId])
    const web3 = new Web3(w.library)
    await new web3.eth.Contract(NFT_LOTTERY_ABI, NFT_LOTTERY[w.chainId]).methods
      .claim(token.tokenId)
      .send({ from: w.account, gasLimit: '300000' })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
