import Web3 from 'web3'

import { OrderExchange } from '../../domain'
import { Web3Type } from '../../types/web3'
import { EXCHANGE_ABI } from './abi'
import { EXCHANGEV2 } from './addresses'
import { Match } from './types'

export const matchOrders = async (w: Web3Type, match: Match): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(EXCHANGE_ABI, EXCHANGEV2[w.chainId]).methods
      .matchOrders(match.sellOrder, match.sellSignature, match.buyOrder, match.buySignature)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.info(JSON.stringify(match))
    console.error(e)
    return false
  }
}

export const cancel = async (w: Web3Type, order: OrderExchange): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(EXCHANGE_ABI, EXCHANGEV2[w.chainId]).methods
      .cancel(order)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
