import Web3 from 'web3'

import { Web3Type } from '../../types/web3'
import { BONUS_ABI } from './abi'
import { BONUS_ADDRESS } from './addresses'

export const claimed = async (web: Web3Type, tokenId: TokenId): Promise<string> => {
  const web3 = new Web3(web.library)
  return await new web3.eth.Contract(BONUS_ABI, BONUS_ADDRESS[web.chainId]).methods
    .claimed(tokenId)
    .call()
}

export const claim = async (web: Web3Type, tokenId: TokenId): Promise<boolean> => {
  const web3 = new Web3(web.library)
  try {
    await new web3.eth.Contract(BONUS_ABI, BONUS_ADDRESS[web.chainId]).methods
      .claim(tokenId)
      .send({ from: web.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
