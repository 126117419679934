import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Title = styled.p`
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 24px;
`

export const Addition = styled.p`
  color: var(--text-gray);
  font-size: 12px;
  line-height: 16px;
`
