import React from 'react'

import { useParamsAddress } from '../../hooks/useParamsAddress'
import { useProfileData } from '../../hooks/useProfileData'
import { ProfileLayout } from '../../layouts/profile'
import { Loading } from '../../ui/components'
import { Cards } from '../../ui/components/Cards'

export const Collection = (): JSX.Element => {
  const { tab } = useParamsAddress()
  const data = useProfileData(true)

  return (
    <ProfileLayout data={data} collection>
      {data ? <Cards onSale={!tab} collection address={data.walletAddress} /> : <Loading />}
    </ProfileLayout>
  )
}
