import styled from 'styled-components'

import davosBG from './assets/davos-bg.png'

export const DavosContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 150px;
`

export const WrapperPage = styled.div`
  width: 100%;
  min-height: 1024px;
  background-color: var(--blue-bg);
  background-image: url(${davosBG});
  background-position: center calc(100% - 0px);
  background-repeat: no-repeat;
  background-size: 100% auto;
  @media screen and (max-width: 916px) {
    background-attachment: fixed;
    background-position-y: calc(100% - 0px);
  }
  @media screen and (max-height: 810px) and (max-width: 916px) {
    background-attachment: fixed;
    background-position-y: calc(100% + 50px);
  }
  @media screen and (max-height: 768px) and (max-width: 916px) {
    background-attachment: unset;
    background-position-y: calc(100% - 0px);
  }
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 63px;
  padding-top: 131px;
  padding-bottom: 114px;
  @media screen and (max-width: 1024px) {
    padding-top: 121px;
  }
  @media screen and (max-width: 586px) {
    align-items: center;
  }
  @media screen and (max-width: 475px) {
    padding-top: 100px;
    gap: 74px;
  }
`

export const TitleBlock = styled.div`
  width: 100%;
  max-width: 390px;
  @media screen and (max-width: 916px) {
    margin-left: 13px;
  }
  @media screen and (max-width: 586px) {
    max-width: 320px;
    margin-left: 0;
  }
  @media screen and (max-width: 475px) {
    max-width: 296px;
  }
`

export const TitleLogo = styled.div`
  width: 100%;
  max-width: 390px;
  margin-bottom: 20px;
  & img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  @media screen and (max-width: 1024px) {
    max-width: 370px;
  }
`

export const TitleDescription = styled.p`
  font-weight: 400;
  color: var(--white);
  @media screen and (max-width: 586px) {
    text-align: center;
  }
`

export const LinkCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 258px);
  gap: 48px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 1216px) {
    grid-template-columns: repeat(3, 258px);
  }
  @media screen and (max-width: 916px) {
    grid-template-columns: repeat(2, 258px);
    gap: 48px 34px;
  }
  @media screen and (max-width: 586px) {
    grid-template-columns: repeat(2, 202px);
    gap: 48px 33px;
  }
  @media screen and (max-width: 475px) {
    grid-template-columns: repeat(1, 258px);
    gap: 48px 33px;
  }
`

export const SubTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 50px;
  @media screen and (max-width: 1216px) {
    grid-template-columns: 1fr 290px;
  }
  @media screen and (max-width: 916px) {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const Text = styled.div`
  div {
    margin-bottom: 32px;
  }
  p {
    color: var(--text-gray);
    margin-bottom: 22px;
  }
  a {
    text-decoration: underline;
  }
  div p:last-child {
    // color: var(--text-gray);
    // margin-bottom: 32px;
  }
`

export const PrimaryButtonLink = styled.a`
  text-decoration: none !important;
`
