import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { parseText } from '../../../utils/parseText'
import { Wrapper } from '../styles'
import { Link } from './styles'

export const Description = ({ description }: { description: string }): JSX.Element => {
  const [less, setLess] = useState(true)
  const text = parseText(description)
  const needLess = description.length > 200
  return (
    <Wrapper>
      <ReactMarkdown>{!needLess || !less ? text : text.substring(0, 200)}</ReactMarkdown>
      {needLess && <Link onClick={() => setLess(!less)}>{` Show ${less ? 'more' : 'less'}`}</Link>}
    </Wrapper>
  )
}
