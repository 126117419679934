import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router'

type Props = {
  whitelisted: boolean
  submit: () => void
}

export const SubmitButton = ({ whitelisted, submit }: Props): JSX.Element => {
  const { account } = useWeb3React()
  const history = useHistory()
  return (
    <button
      className="button primary-button"
      disabled={!whitelisted}
      type="button"
      onClick={() => (account ? submit() : history.push('/login'))}
    >
      Send tokenization request
    </button>
  )
}
