import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getIpfsLink } from '../../../utils/ipfs'

export const MiniStyled = styled(Link)`
  display: block;
`

export const Wrap = styled.div`
  background: var(--white);
  border-radius: 16px;
  width: 100%;
  position: relative;
`

export const CardImage = styled.div<{ bg: string | null }>`
  background: url(${(props) =>
      props.bg
        ? getIpfsLink(props.bg)
        : getIpfsLink('QmWedt5h5DEtBcH5mPTGoYeMpn4JXCUZpr7WtfjHDN6h5n')})
    no-repeat center;
  background-size: cover;
  width: 100%;
  height: 110px;
  border-radius: 16px 16px 0 0;
  & img {
    vertical-align: middle;
  }
`

export const CardBottom = styled.div`
  padding: 50px 16px 16px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
`

export const CardAvatar = styled.div`
  position: absolute;
  justify-content: center;
  z-index: 1;
  left: 16px;
  top: 0;
  transform: translate(0px, -50%);
`

export const CardTitle = styled.h4`
  font-weight: 700;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const CardInfo = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
`
