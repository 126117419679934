import React from 'react'

import { Button } from './styles'

type Props = {
  title?: string
  icon: JSX.Element
  onClick?: () => void
}

export const FilterButton = ({ icon, onClick, title }: Props): JSX.Element => {
  return (
    <Button onClick={onClick}>
      {icon}
      <span>{title}</span>
    </Button>
  )
}
