import { useEffect, useState } from 'react'

import { balanceOfCGT } from '../contracts/erc20/methods'
import { Web3Type } from '../types/web3'

export const useCGTBalance = (web3: Web3Type, trigger?: boolean): string | undefined => {
  const [balance, setBalance] = useState<string>()

  useEffect(() => {
    balanceOfCGT(web3).then((res) => {
      setBalance(res)
    })
  }, [web3, trigger])

  return balance
}
