import styled from 'styled-components'

export const DescriptionBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 706px;
`

export const QuestionsBlock = styled.div`
  width: 100%;
  max-width: 806px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--black);
`
