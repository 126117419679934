import { clientV3 } from '../../../services/api'
import { shortAddress } from '../../../utils/shortAddress'
import { Ownership } from '../types/Ownership'
import { Token } from '../types/Token'

export const isOwned = (token: Token, address: AccountAddress): boolean =>
  !!address &&
  !!token.ownerships.find((owner) => owner.ownerAddress.toLowerCase() === address.toLowerCase())

export const getOwner = async (token: Token): Promise<{ name: string; address: string } | null> => {
  const owner = token.standard === 'ERC721' ? token.ownerships[0] : null
  if (owner) {
    const { data } = await clientV3.profile.list([owner.ownerAddress])
    return {
      name: data[0].name || shortAddress(owner.ownerAddress),
      address: owner.ownerAddress
    }
  }
  return null
}

export const getOwnerByAddress = (address: UserAddress, ownerships: Ownership[]): Ownership =>
  ownerships.filter((owner) => owner.ownerAddress.toLowerCase() === address.toLowerCase())[0]
