import { isEthNetwork } from '../../network/mappers'
import { WRAPPED, WRAPPED_ETH } from '../constants/wrapped'

export const getOriginCollection = (contract: string, network: Network): string | undefined =>
  isEthNetwork(network)
    ? WRAPPED_ETH[
        Object.keys(WRAPPED_ETH).filter(
          (wrapped) => wrapped.toLowerCase() === contract.toLowerCase()
        )[0]
      ]
    : WRAPPED.filter((wrapped) => wrapped.toLowerCase() === contract.toLowerCase())[0]

export const isWrapped = (contract: string, network: Network): boolean =>
  !!getOriginCollection(contract, network)
