import React from 'react'

const MiniGplay = (): JSX.Element => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17883 7.99867L1.02266 16C0.609985 15.7873 0.333313 15.4 0.333313 14.8967V1.10333C0.333313 0.6 0.609985 0.212667 1.02266 0V0.000666667L9.17883 7.99867ZM2.85406 0.406657L11.7789 5.44466L9.86821 7.32332L2.85406 0.406657ZM9.86821 8.68001L11.7789 10.5587L2.85406 15.5967L9.86821 8.68001ZM14.5756 8.95134C15.1476 8.50467 15.1476 7.498 14.5376 7.05134L12.6656 5.986L10.5768 8.002L12.6656 10.018L14.5756 8.95134Z"
    />
  </svg>
)

export default MiniGplay
