import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OuterCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--line-day);
  border-radius: 50%;
`

const InnerCircle = styled.div`
  width: 20px;
  height: 20px;
  background: var(--blue);
  border-radius: 50%;
`

const VertLine = styled.div`
  width: 1px;
  height: 24px;
  background: linear-gradient(180deg, var(--line-day) 0%, rgba(226, 233, 244, 0) 100%);
`

const EventBlockDecor = (): JSX.Element => {
  return (
    <Wrapper>
      <OuterCircle>
        <InnerCircle />
      </OuterCircle>
      <VertLine />
    </Wrapper>
  )
}

export default EventBlockDecor
