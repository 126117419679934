import React from 'react'

import { getIpfsLink } from '../../../utils/ipfs'
import { Wrapper } from './styles'

export const Cover = ({ cover }: { cover: string | undefined | null }): JSX.Element => {
  return (
    <Wrapper>
      <img
        alt={'Cover image'}
        src={
          cover
            ? getIpfsLink(cover)
            : 'https://curio.fra1.cdn.digitaloceanspaces.com/apps/rollapp/static/QmdSta8HbXgrFZt8HinQ1eXbRw8UAYt8xRbNpgebcJyQwT.jpg'
        }
      />
    </Wrapper>
  )
}
