import React from 'react'
import { useParams } from 'react-router-dom'

import { NotFound } from '../../ui/components/NotFound'
import { DAO_DATA } from './data/data'
import { Dao } from './views'

export const DaoPage = (): JSX.Element => {
  const params = useParams<{ page: string }>()
  const check = !!DAO_DATA[params.page]

  return check ? <Dao tag={params.page} /> : <NotFound />
}
