import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { Avatar } from '../../../ui/components/Avatar'
import { SubscribeFormModal } from '../../../ui/modals/SubscribeModal/Form'
import {
  AvatarBlock,
  CardImage,
  CardInfo,
  CardWrapper,
  OrganizationName,
  ProjectName
} from './styled'

type Props = {
  url: string | '/'
  preview?: string
  avatar?: string
  secondAvatar?: string
  organization?: string
  name?: string
  page?: boolean
}

export const DavosCard = ({ item }: { item: Props }): JSX.Element => {
  const history = useHistory()
  const [modal, setModal] = useState(false)

  const onClick = () => {
    if (item.page) {
      history.push(`/davos/${item.url}`)
    } else {
      setModal(!modal)
    }
  }

  return (
    <CardWrapper onClick={onClick}>
      <CardImage>
        <img src={item.preview} />
      </CardImage>
      <CardInfo>
        <AvatarBlock>
          <Avatar width="80px" src={item.avatar} />
          {item.secondAvatar && <Avatar width="80px" src={item.secondAvatar} />}
        </AvatarBlock>
        <OrganizationName>{item.organization}</OrganizationName>
        <ProjectName>{item.name}</ProjectName>
      </CardInfo>
      {modal && <SubscribeFormModal close={() => setModal(false)} coming />}
    </CardWrapper>
  )
}
