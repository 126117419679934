import Listings from '../ui/icons/Listings'
import Time from '../ui/icons/Time'
import Unlimited from '../ui/icons/Unlimited'

export type SaleTypes = 'fixed-sale' | 'unlimited-sale' | 'timed-sale'

type Sale = {
  value: SaleTypes
  title: string
  icon: () => JSX.Element
  disabled?: boolean
}

export const SALE_TYPES: Sale[] = [
  {
    value: 'fixed-sale',
    title: 'Fixed price',
    icon: Listings
  },
  {
    value: 'timed-sale',
    title: 'Timed auction (soon)',
    icon: Time,
    disabled: true
  },
  {
    value: 'unlimited-sale',
    title: 'Unlimited auction',
    icon: Unlimited
  }
]
