import React from 'react'

import { Token } from '../../domain'
import { BLOCKCHAIN_NAME } from '../../domain/network/contstant'
import { getAppNetworkByChainName, getBlockchain } from '../../domain/network/mappers'
import { useRollapp } from '../../hooks/useRollapp'
import { selectMetamaskNetwork } from '../../services/metamask'
import { Modal } from '../common/Modal'
import { WrapperCentered } from '../common/Modal/styles'
import { Loader } from '../components'

export const WrongNetwork = ({ token }: { token?: Token }): JSX.Element | null => {
  const { network } = useRollapp()

  const changeNetwork = () => {
    selectMetamaskNetwork(
      token ? getAppNetworkByChainName(token.collection.network) || 'eth' : network || 'eth'
    )
  }

  const text = token ? BLOCKCHAIN_NAME[token.collection.network] : getBlockchain(network)

  return (
    <Modal close={!!token} width="370px">
      <WrapperCentered>
        <Loader width={36} />
        <h2>Wrong network</h2>
        <p>Change network to {text}</p>
        <button className="button" onClick={changeNetwork}>
          Change network
        </button>
      </WrapperCentered>
    </Modal>
  )
}
