import React from 'react'

import { Modal } from '../../common/Modal'
import { Notification, Wrapper } from '../../components/ModalStyles'

type Props = {
  close: () => void
  status: string
  errorText: string
}

export const SubResultModal = ({ errorText, close, status }: Props): JSX.Element => {
  return (
    <Modal
      closeHandle={close}
      title={
        status === 'pending'
          ? 'Loading...'
          : status === 'success'
          ? 'Success'
          : status === 'warning'
          ? 'Warning'
          : 'Error'
      }
      width="350px"
    >
      <Wrapper>
        <Notification>
          {status === 'pending' ? (
            'Waiting for results'
          ) : status === 'success' ? (
            'You have successfully submitted your request. We will contact you shortly'
          ) : status === 'warning' ? (
            'Your request has already been sent'
          ) : (
            <p>{errorText}</p>
          )}
        </Notification>
        <button type="button" onClick={close} className="block-button button">
          Close
        </button>
      </Wrapper>
    </Modal>
  )
}
