import React from 'react'

import { haveAuction, Token } from '../../../domain'
import { PriceUI } from '../PriceUI'

export const BidText = ({ token }: { token: Token }): JSX.Element => {
  const { ownerships, minPrice } = token
  const auction = haveAuction(ownerships)

  return (
    <>
      {minPrice ? (
        <>
          {token.standard === 'ERC1155' && 'From'} <PriceUI value={minPrice} />
        </>
      ) : auction ? (
        'Auction'
      ) : (
        'Not for sale'
      )}
    </>
  )
}
