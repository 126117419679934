import React from 'react'

import { Placeholder } from './styles'

export const PlaceHolderLoading = (props: {
  height?: string
  width?: number
  border?: string
}): JSX.Element => {
  return <Placeholder height={props.height} border={props.border} width={props.width} />
}
