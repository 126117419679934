import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { CreateWhitelistText } from '../../../ui/common/WhitelistText'
import { BackLink } from '../../../ui/components/BackLink'
import { Preview } from '../../../ui/components/Card/Preview'

const Aside = styled.aside`
  height: max-content;
  position: sticky;
  top: 100px;
`

export const Sidebar = ({ whitelisted }: { whitelisted: boolean }): JSX.Element => {
  const history = useHistory()
  const { account } = useWeb3React()

  return (
    <Aside>
      <BackLink onClick={() => history.goBack()} />
      <h1 className="h2">Create single collectible</h1>
      {whitelisted && account ? (
        <>
          <p>Preview</p>
          <Preview account={account} />
        </>
      ) : (
        <CreateWhitelistText />
      )}
    </Aside>
  )
}
