import styled from 'styled-components'

import { Container } from '../styled'

export const ContentBlock = styled.div`
  width: auto;
  padding-top: 50px;
  position: relative;

  .info-message {
    transition: 0.5s opacity, 0.5s transform;
    opacity: 0;
    transform: translateY(-100px);
  }

  &.visible .info-messages {
    .info-message:nth-child(2) {
      transition-delay: 0.5s;
    }
    .info-message:nth-child(3) {
      transition-delay: 1s;
    }
    .info-message {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media screen and (max-width: 740px) {
    max-width: 576px;
  }
`

export const DescriptionContainer = styled(Container)`
  // @media screen and (max-width: 1024px) {
  //   margin-top: -90px;
  // }
  // @media screen and (max-width: 740px) {
  //   margin-top: 120px;
  // }
`

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 26px 25px 25px;
  position: relative;

  background: var(--white);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.1);
  border-radius: 36px;
  @media screen and (max-width: 640px) {
    max-width: 360px;
  }
  @media screen and (max-width: 475px) {
    max-width: 320px;
  }

  @media screen and (max-width: 425px) {
    max-width: 240px;
    padding: 26px 20px 25px;
  }
`

export const ImagesBlock = styled.div`
  position: relative;
`

export const ImageBlock = styled.div`
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: var(--pale-blue);
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 24px;

  @media screen and (max-width: 640px) {
    height: 310px;
  }

  & > img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 475px) {
    height: 270px;
  }
  @media screen and (max-width: 425px) {
    height: 200px;
  }
`

export const ProgressBar = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  gap: 4px;
  & .piece:first-child {
    border-radius: 10px 0 0 10px;
  }
  & .piece:last-child {
    border-radius: 0 10px 10px 0;
  }
`

export const MarioImage = styled.div`
  width: 100%;
  max-width: 200px;
  position: absolute;
  top: -76px;
  left: 50%;
  transform: translate(-50%, 0%);
  & > img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 640px) {
    max-width: 180px;
  }
  @media screen and (max-width: 475px) {
    max-width: 165px;
  }
  @media screen and (max-width: 425px) {
    max-width: 135px;
  }
`

export const Piece = styled.div`
  width: 100%;
  height: 20px;
  background: var(--hidden-text);
  overflow: hidden;
  position: relative;
`

export const Filler = styled.div<{ w?: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  background: var(--light-green);
  width: ${(props) => (props.w ? props.w : 100) + '%'};
`
