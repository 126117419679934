import React from 'react'

import { ErrorText } from '../Error'
import { Label } from './styles'

type Props = {
  children: JSX.Element | JSX.Element[]
  error?: string | null | ''
  title?: string
}

export const LabelWrapper = ({ title, children, error }: Props): JSX.Element => (
  <Label>
    {title && <span>{title}</span>}
    {children}
    {error && error.length > 0 && <ErrorText>{error}</ErrorText>}
  </Label>
)
