import React, { ChangeEvent, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { validate } from '../../../services/validate-form'
import { ErrorStatus } from '../../../services/validate-form/types'
import { Modal } from '../../common/Modal'
import { Input } from '../../components/Form/Input'
import { LabelWrapper } from '../../components/Form/Label/LabelWrapper'
import { Description, Wrapper } from '../../components/ModalStyles'
import { Status } from './Status'

export const SubscribeFormModal = ({
  close,
  coming
}: {
  close: () => void
  coming?: boolean
}): JSX.Element => {
  const [value, setValue] = useState('')
  const [status, setStatus] = useState('')
  const [error, setError] = useState<ErrorStatus>(undefined)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
    setError(validate(['email'], value))
  }

  const subscribe = async () => {
    setStatus('pending')
    if (error === null) {
      clientV3.subscriptions
        .waitlist(value, 'season-4')
        .then(() => {
          setStatus('success')
        })
        .catch(() => {
          setStatus('warning')
        })
    }
  }

  return (
    <Modal closeHandle={close} width="350px" title={coming ? 'Coming soon' : 'Subscribe'}>
      <Wrapper>
        {coming && <Description>Subscribe to updates to stay tuned</Description>}
        {status !== '' && <Status status={status} />}
        {status !== 'success' && status !== 'pending' && (
          <>
            <LabelWrapper title="Email" error={error}>
              <Input type="text" placeholder="Enter email" value={value} onChange={onChange} />
            </LabelWrapper>
            <button
              className="button block-button primary-button"
              onClick={subscribe}
              disabled={error !== null}
            >
              Subscribe
            </button>
          </>
        )}
      </Wrapper>
    </Modal>
  )
}
