import React from 'react'

import { DAO_DATA } from '../data/data'
import { ItemName, PageContainer, Wrapper } from './styled'
import { Inner } from './styled'

const Details = (props: { tag: string }): JSX.Element => {
  const data = DAO_DATA[props.tag]
  return (
    <Inner id="details" className="slide">
      <Wrapper>
        <PageContainer>
          <ItemName>#{data.hashtag}</ItemName>
          {data.details}
        </PageContainer>
      </Wrapper>
    </Inner>
  )
}

export default Details
