import styled from 'styled-components'

import arrow from '../../../assets/img/icons/top-selector-arrow.svg'

export const TopContainer = styled.div`
  display: block;
  width: 100%;
`

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (max-width: 475px) {
    margin-bottom: 20px;
  }
`

export const TopTitle = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin-right: 24px;
  @media screen and (max-width: 475px) {
    font-size: 28px;
    line-height: 36px;
    margin-right: 16px;
  }
`

export const TopSelector = styled.select`
  display: block;
  font-weight: 600;
  appearance: none;
  background: var(--white);
  background-position: calc(100% - 8px) center;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  border-radius: 40px;
  padding: 11px 28px 11px 8px;
  font-size: 12px;
  border: none;
  outline: none;
  @media screen and (max-width: 475px) {
    padding: 9.5px 26px 9.5px 8px;
  }
`

export const TopCardsBox = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px 16px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding: 18px 16px;
  background: var(--white);
  border-radius: 16px;
  overflow: hidden;
  height: 202px;
  width: 100%;
  @media screen and (max-width: 640px) {
    grid-template-rows: repeat(5, 45px);
    grid-template-columns: 1fr;
    // don't neeed now
    // overflow: auto;
  }
  @media screen and (max-width: 475px) {
    padding: 16px 16px;
  }
`
