import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { Header } from '../../../ui/common/Header'
import termsText from '../../../ui/common/Terms/text'
import BuyFooter from '../_common/BuyFooter'
import { SideNavMenu } from '../_common/Sidebar'
import { Tabs } from '../_common/Tabs'
import { TermsModal } from '../_common/TermsModal'
import { DAO_DATA } from '../data/data'
import marioBg from '../media/mario-bg.svg'
import Details from '../slides/Details'
import Info from '../slides/Info'
import Price from '../slides/Price'
import Quotes from '../slides/Quotes'
import Timeline from '../slides/Timeline'
import Visuals from '../slides/Visuals'

const Wrapper = styled.div`
  background-color: var(--gray);
  // min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const MainContainer = styled.div<{ mario?: boolean }>`
  display: grid;
  grid-template-columns: 232px calc(100% - 182px - 232px - 40px) 182px;
  grid-gap: 20px;
  width: 100%;
  min-height: calc(100vh - 136px);
  background: url(${(props) => (props.mario ? marioBg : '')}) center bottom no-repeat;
  background-size: cover;

  @media screen and (max-width: 916px) {
    grid-template-columns: 1fr;
    background: none;
  }
`

const SideMenu = styled.div<{ footer?: boolean }>`
  background: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: space-between;
  top: var(--header-height);
  min-height: calc(
    100vh - var(--header-height) ${(props) => (props.footer ? ' - var(--footer-height)' : '')}
  );

  @media screen and (max-width: 916px) {
    min-height: initial;
  }
`

const SideBlockPosition = styled.div`
  height: calc(100vh - var(--header-height) - var(--footer-height));
  position: sticky;
  top: var(--header-height);

  @media screen and (max-width: 916px) {
    display: none;
  }
`

const SideBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 40px;
`

const Content = styled.div`
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  @media screen and (max-width: 916px) {
    max-width: 100%;
    padding: 0 24px;
  }
`

const Scroll = styled.div`
  display: grid;
  transition: 0.5s transform;
`

export const Dao = ({ tag }: { tag: string }): JSX.Element => {
  const location = useLocation()
  const history = useHistory()
  // eslint-disable-next-line
  const [loaded, setLoaded] = useState(false)
  const [height, setHeight] = useState(0)
  // eslint-disable-next-line
  const [current, setCurrent] = useState(0)
  const [translate, setTranslate] = useState(0)
  const [open, setOpen] = useState(false)

  const { price, visuals, timeline, details, quotes, pages, footer, terms } = DAO_DATA[tag]

  const initHeights = (): void => {
    const slides = document.querySelectorAll('.slide')
    let tran = 0
    let cur = 0
    for (let i = 0; i < slides.length; i++) {
      // max = slides[i].clientHeight > max ? slides[i].clientHeight : max
      const currentSlide = `#${slides[i].id}` === (!!location.hash ? location.hash : '#info')
      // max += slides[i].clientHeight
      if (currentSlide) {
        setHeight(slides[i].clientHeight)
        setCurrent(i)
        cur = i
      }
    }
    for (let i = 0; i < cur; i++) {
      tran += slides[i].clientHeight
    }
    setTranslate(tran)
    setLoaded(true)
  }

  let curDeltaY: number | undefined = undefined
  let time = 0
  const isMobile = window.innerWidth <= 916

  const wheelHandle = (e: WheelEvent): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    curDeltaY = !!curDeltaY
      ? Math.abs(e.deltaY) > curDeltaY
        ? curDeltaY
        : curDeltaY
      : Math.abs(e.deltaY)

    const slides = document.querySelectorAll('.slide')
    const first = e.deltaY > 0 && window.innerHeight + window.scrollY === document.body.offsetHeight
    const last = e.deltaY < 0 && window.scrollY === 0

    if (first || last) {
      const timeNow = performance.now()
      if (timeNow - time > 1000) {
        let current = 0
        for (let i = 0; i < slides.length; i++) {
          const currentSlide =
            `#${slides[i].id}` === (!!window.location.hash ? window.location.hash : '#info')
          if (currentSlide) {
            current = i
          }
        }

        if (current !== slides.length - 1 && first) {
          e.preventDefault()
          const cur = current + 1 === slides.length ? current : current + 1
          history.push(`#${slides[cur].id}`)
          time = performance.now()
        } else if (current !== 0 && last) {
          e.preventDefault()
          const cur = current - 1 === -1 ? current : current - 1
          history.push(`#${slides[cur].id}`)
          time = performance.now()
        }
      }
    }
  }

  useEffect(() => {
    if (pages.length > 0 && !isMobile) {
      window.addEventListener('wheel', wheelHandle, { passive: false })

      return () => window.removeEventListener('wheel', wheelHandle)
    }
    // eslint-disable-next-line
  }, [pages, isMobile])

  useEffect(() => {
    if (pages.length > 0) {
      initHeights()
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Wrapper>
      <Header />
      <MainContainer
        mario={
          location.pathname.includes('mario') && (location.hash === '' || location.hash === '#info')
        }
      >
        <div>
          <SideMenu footer={footer}>
            <Tabs />
          </SideMenu>
        </div>
        <div>
          <Content
            style={{
              height: isMobile || pages.length === 0 ? 'auto' : `${height}px`
            }}
          >
            <Scroll
              className="scroll"
              style={{
                transform: pages.length > 0 ? `translateY(-${translate}px)` : 'none',
                gridTemplateRows: 'auto'
              }}
            >
              <Info tag={tag} legal={() => setOpen(true)} />
              {price && <Price price={price} />}
              {details && <Details tag={tag} />}
              {quotes && <Quotes quotes={quotes} />}
              {visuals && <Visuals tag={tag} visuals={visuals} />}
              {timeline && <Timeline tag={tag} timeline={timeline} />}
            </Scroll>
          </Content>
        </div>
        {pages.length > 0 && (
          <SideBlockPosition>
            <SideBlock>
              <SideNavMenu tag={tag} />
            </SideBlock>
          </SideBlockPosition>
        )}
      </MainContainer>
      {footer && <BuyFooter isMario={location.pathname.includes('mario')} terms={terms} />}
      {terms && open && (
        <TermsModal local={terms.local} terms={termsText} close={() => setOpen(false)} />
      )}
    </Wrapper>
  )
}
