import { useWeb3React } from '@web3-react/core'
import React, { ChangeEvent, useState } from 'react'

import { disconnectLS } from '../../services'
import { clientV3 } from '../../services/api'
import { Web3Type } from '../../types/web3'
import { Modal } from '../common/Modal'
import { Checkbox, CheckboxInput } from '../components/Form/Checkbox'
import { ErrorText } from '../components/Form/Error'
import { Wrapper } from '../components/ModalStyles'

export const SignUpModal = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  const { deactivate } = useWeb3React()
  const [checkboxes, setCheckboxes] = useState({ first: false, second: false })
  const [error, setError] = useState<string | undefined>()

  const disconnect = () => {
    disconnectLS()
    deactivate()
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target
    setCheckboxes({ ...checkboxes, [name]: checked })
  }

  const signUp = async () => {
    if (checkboxes.first && checkboxes.second) {
      try {
        await clientV3.security.connect({
          walletAddress: web3.account,
          moreThan13: checkboxes.first,
          termsOfService: checkboxes.second
        })
        location.reload()
        setError(undefined)
      } catch (e) {
        setError('Something wrong')
      }
    }
  }

  return (
    <Modal title="RollApp Terms of Service" width="380px" close={false}>
      <Wrapper>
        <p>
          Please take a few minutes to read and understand{' '}
          <a href="/terms" className="a" target="_blank">
            RollApp Terms of Service
          </a>
          . To continue, you`ll need to accept the Terms of Service by checking the box.
        </p>
        <Checkbox
          right={10}
          text="I am at least 13 years old (for “wine section” requirements are 21 years old)"
        >
          <CheckboxInput
            name="first"
            type="checkbox"
            checked={checkboxes.first}
            onChange={onChange}
          />
        </Checkbox>
        <Checkbox center right={10} text="I accept the RollApp Terms of Service">
          <CheckboxInput
            name="second"
            type="checkbox"
            checked={checkboxes.second}
            onChange={onChange}
          />
        </Checkbox>
        {error && <ErrorText>{error}</ErrorText>}
        <button
          className="button primary-button block-button"
          type="button"
          disabled={!checkboxes.first || !checkboxes.second}
          onClick={signUp}
        >
          Proceed
        </button>
        <button className="button block-button" type="button" onClick={disconnect}>
          Disconnect
        </button>
      </Wrapper>
    </Modal>
  )
}
