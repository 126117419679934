import styled from 'styled-components'

export const Grid = styled.div`
  margin-top: 26px;
  padding-top: 210px;
  display: grid;
  grid-template-columns: repeat(3, 312px);
  justify-content: space-between;
  width: 100%;
  gap: 60px 0px;

  & > a:nth-child(3n + 2) {
    transform: translateY(-50%);
  }

  & > a:last-child {
    grid-column: span 3;
    justify-self: center;
    transform: translateY(-50%);
  }

  & > a:first-child .landing-card__comment {
    top: 96px;
    right: -20px;
  }

  & > a:nth-child(4) .landing-card__comment {
    top: 65px;
    left: -20px;
  }

  & > a:nth-child(5) .landing-card__comment {
    top: 44px;
    right: -62px;
  }

  & > a:nth-child(6) .landing-card__comment {
    top: 190px;
    right: -20px;
  }
  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(2, 312px);
    max-width: 664px;

    & > a:nth-child(3n) {
      display: none;
    }

    & > a:nth-child(3n + 2) {
      transform: translateY(-50%);
    }

    & > a:last-child {
      grid-column: 2;
      transform: translateY(-50%);
    }
  }
  @media screen and (max-width: 1024px) {
    margin-top: 16px;
    grid-template-columns: repeat(2, 276px);
    max-width: 600px;
    gap: 40px 24px;
  }
  @media screen and (max-width: 768px) {
    max-width: 576px;
  }
  @media screen and (max-width: 740px) {
    padding-top: 0px;
    margin-top: 16px;
    flex-direction: column;
    display: flex;

    & > a:nth-child(3n + 2) {
      transform: translateY(0%);
      margin-left: auto;
    }

    & > a:last-child {
      grid-column: 1;
      transform: translateY(0%);
    }

    & > a:nth-child(4) .landing-card__comment {
      right: -20px;
      left: unset;
    }

    & > a:nth-child(5) .landing-card__comment {
      right: unset;
      left: -62px;
    }
  }
  @media screen and (max-width: 475px) {
    align-items: center;
    & > a:nth-child(3n + 2) {
      transform: translateY(0%);
      margin-left: unset;
    }
  }
`

export const Title = styled.h1`
  font-size: 64px;
  text-align: center;
  font-weight: 900;
  line-height: 80px;
  max-width: 706px;
  @media screen and (max-width: 768px) {
  font-size: 48px;
  line-height: 64px;
`
