import styled from 'styled-components'

import arrow from './../../../assets/img/shares/arrow.svg'

export const ArrowButton = styled.button<{ height?: number; right?: boolean }>`
  height: ${(props) => (props.height ? props.height : 80)}px;
  flex-shrink: 0;
  padding: 0;
  width: 24px;
  background: url(${arrow}) center no-repeat;
  background-size: 100% auto;
  transform: ${(props) => (props.right ? 'rotate(180deg)' : null)};
`
