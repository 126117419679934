import React, { useEffect, useState } from 'react'
import { Socket } from 'socket.io-client'

import { useActiveWeb3React } from '../../../hooks/useActiveWeb3React'
import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { EVENTS } from '../constants/events'
import { IConversation } from '../interfaces/IConversation'
import { IRequest } from '../interfaces/IRequest'
import { socketInstance } from '../services/socketInstance'
import { IMessengerContext } from './types'

export const MessengerContext = React.createContext<IMessengerContext | undefined>(undefined)

export const MessengerProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { account, library } = useActiveWeb3React()
  const [chats, setChats] = useState<IConversation[]>([])
  const [isSign, setIsSign] = useState(false)
  const [connection, setConnection] = useState<Socket | undefined>(undefined)
  const [requests, setRequests] = useState<IRequest[]>([])

  const [modal, setModal] = useState('')

  const close = () => setModal('')

  const request = (account: string) => {
    connection?.emit(
      EVENTS.createRequest,
      {
        friendWalletAddress: account
      },
      (request: any) => {
        console.log(request)
        setModal('')
      }
    )
  }

  const signIn = async () => {
    try {
      connection?.disconnect()
      const sign = await signMessage(`I want to get my credentials ${account}`, account, library)
      await clientV3.chats.connect(account, sign)
      setIsSign(true)
    } catch (e) {
      console.error(e)
    }
  }

  const onRequests = (data: any) => {
    console.log(data)
    setRequests(data)
  }

  useEffect(() => {
    if (!!account) {
      if (connection) {
        connection.disconnect()
      }
      const socket = socketInstance(account)
      const onConnect = () => {
        console.info('Chat connected')
        setIsSign(true)
        setConnection(socket)
      }

      const onConnectError = (err: any) => {
        console.error(`connect_error due to ${err.message}`)
        setIsSign(false)
        // socket.disconnect()
        // setConnection(undefined)
      }

      socket.on('connect', onConnect)
      socket.on('error', onConnectError)
    }
  }, [account])

  useEffect(() => {
    ;(async () => {
      if (!!connection && isSign) {
        try {
          const { data } = await clientV3.chats.get(account)
          setChats(data.items)
          console.log(data)

          connection.emit('getRequests')

          // connection.on(EVENTS.conversation, onChats)
          connection.on(EVENTS.requests, onRequests)
        } catch (e) {
          console.error(e)
        }
      }
    })()

    return () => {
      if (connection) {
        console.log('return')
        // connection.off(EVENTS.conversation, onChats)
        connection.off(EVENTS.requests, onRequests)
      }
    }
  }, [account, connection, isSign])

  return (
    <MessengerContext.Provider
      value={{
        isSignIn: isSign,
        signIn,
        request,
        chats,
        modal,
        setModal,
        close,
        requests,
        connection
      }}
    >
      {children}
    </MessengerContext.Provider>
  )
}
