import styled from 'styled-components'

export const Main = styled.main`
  display: grid;
  grid-row-gap: 48px;
  height: max-content;

  @media (max-width: 1216px) {
    grid-row-gap: 24px;
  }
`

export const GroupInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;

  @media (max-width: 1216px) {
    grid-template-columns: 100%;
    grid-row-gap: 24px;
  }
`
