import React, { useState } from 'react'

import { SwitchBlock } from '../../../ui/components/Form/SwitchBlock'
import { DefaultForm } from '../Forms'
import { LoanForm } from '../Forms/LoanForm'

export const Main = ({ whitelisted }: { whitelisted: boolean }): JSX.Element => {
  const [loan, setLoan] = useState(false)
  return (
    <main>
      <div style={{ marginBottom: 24 }}>
        <SwitchBlock
          title="Get a loan for your car"
          addition="Send us a request to tokenize your own car and get a loan at Curio Stable Coin (CSC)."
          value={loan}
          onChange={() => setLoan(!loan)}
        />
      </div>
      {loan ? <LoanForm whitelisted={whitelisted} /> : <DefaultForm whitelisted={whitelisted} />}
    </main>
  )
}
