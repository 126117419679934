import { useWeb3React } from '@web3-react/core'
import React, { ChangeEvent, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { EDIT_PROFILE } from '../../../services/sign/messages'
import { validateFile } from '../../../services/validate-form'
import { UploadFile } from '../Form/UploadFile'
import { Cover } from './index'
import { CoveringProfileBackground, WrappperProfileBackground } from './styles'

export const UploadCover = ({ cover }: { cover: CoverImage }): JSX.Element => {
  const { account, library } = useWeb3React()
  const [newCover, setNewCover] = useState<string>()
  const [signature, setSignature] = useState<Signature>()

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target
    if (files && account) {
      const error = validateFile(['image'], files[0])
      if (error === null) {
        const signatureValue = signature
          ? signature
          : await signMessage(EDIT_PROFILE(account, 'set_cover'), account, library)
        setSignature(signatureValue)
        // const coverIpfsHash = await ipfsFileUpload(files[0])
        const formData = new FormData()
        formData.append('file', files[0])
        await clientV3.profile.edit.cover(signatureValue, account.toLowerCase(), formData)
        const url = URL.createObjectURL(files[0])
        setNewCover(url)
      }
    }
  }
  return (
    <WrappperProfileBackground>
      <Cover cover={newCover || cover} />
      <CoveringProfileBackground>
        <div className="button">Edit cover</div>
        <UploadFile type="file" accept=".jpg, .png, .gif" onChange={onChange} />
      </CoveringProfileBackground>
    </WrappperProfileBackground>
  )
}
