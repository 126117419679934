import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import { stake } from '../../../contracts/stalking'
import { Token } from '../../../domain'
import { useAllowance, useStakingApy } from '../../../hooks/useStaking'
import { acceptStake, getAcceptedStake } from '../../../services/localStorage/stake-terms'
import { validate } from '../../../services/validate-form'
import { Web3Type } from '../../../types/web3'
import { Modal } from '../../common/Modal'
import { CancelBtn } from '../../components/CancelBtn'
import { Input } from '../../components/Form/Input'
import { LabelWrapper } from '../../components/Form/Label/LabelWrapper'
import { InfoRow, InfoType, InfoValue, Wrapper } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

const IFrame = styled.iframe`
  height: 65vh;
  width: 100%;
`

export const StakeShares = ({
  web3,
  token,
  unstake
}: {
  web3: Web3Type
  token: Token
  unstake?: boolean
}): JSX.Element => {
  const [accepted, setAccepted] = useState(getAcceptedStake())

  const [quantity, setQuantity] = useState('1')
  const [error, setError] = useState<string | null>(null)

  const apy = useStakingApy(web3, token, quantity)
  const allowance = useAllowance(web3, token, unstake)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const err = validate(['number'], value)
    if (err === null) {
      setError(
        parseInt(value) > parseInt(allowance)
          ? `Value must be less than ${allowance}`
          : value.length === 0 || value === '0'
          ? 'Value must be greater than 0'
          : null
      )
    } else {
      setError(err)
    }
    setQuantity(value)
  }

  return (
    <Modal
      title={
        accepted ? `${unstake ? 'Unstake' : 'Stake'} shares` : 'NFT Staking Terms and Conditions'
      }
      width={accepted ? '350px' : '855px'}
    >
      {accepted ? (
        <Wrapper>
          <LabelWrapper title="Quantity" error={error}>
            <Input
              type="text"
              value={quantity}
              onChange={onChange}
              disabled={parseInt(allowance) < 2}
            />
            <small>Enter quantity. {allowance} available</small>
          </LabelWrapper>

          {apy !== '0.00' && error === null && !unstake && (
            <InfoRow>
              <InfoType>APY</InfoType>
              <InfoValue>{`~${apy}%`}</InfoValue>
            </InfoRow>
          )}

          <StatusBlock
            name={`${unstake ? 'Unstake' : 'Stake'} shares`}
            text={`${unstake ? 'Withdraw' : 'Deposit'} ${error === null ? quantity : ''} shares`}
            method={() => stake(web3, quantity, unstake)}
            disabled={!!error || allowance === '0'}
          />

          <CancelBtn />
        </Wrapper>
      ) : (
        <Wrapper>
          <IFrame src="/rollapp-terms.pdf#zoom=100&toolbar=0" />
          <button
            className="button primary-button block-button"
            onClick={() => {
              setAccepted(true)
              acceptStake()
            }}
          >
            Accept
          </button>
        </Wrapper>
      )}
    </Modal>
  )
}
