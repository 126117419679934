import { useEffect, useState } from 'react'

import { clientFaucet } from '../services/faucet'

export const useFaucetAllowance = (account: string): boolean | undefined => {
  const [allowance, setAllowance] = useState<boolean | undefined>()

  useEffect(() => {
    clientFaucet
      .checkAllowance(account)
      .then((res) => {
        setAllowance(res.data.allowance !== 0)
      })
      .catch(() => {
        setAllowance(false)
      })
  }, [account])

  return allowance
}
