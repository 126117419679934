import { balanceOfCGT, balanceOfETH } from '../contracts/erc20/methods'
import { isSkaleChainId } from '../domain/network/mappers'
import { clientV3 } from '../services/api'
import { Modals } from '../types/modalTypes'
import { Web3Type } from '../types/web3'

export const getAutoModal = async (web3: Web3Type): Promise<Modals | undefined> => {
  try {
    await clientV3.profile.preview(web3.account)
    const cgt = await balanceOfCGT(web3)
    if (cgt === '0') {
      return 'add-funds'
    } else if (isSkaleChainId(web3.chainId)) {
      const eth = await balanceOfETH(web3)
      if (eth === '0') {
        return 'get-sketh'
      }
    } else {
      return undefined
    }
  } catch (e) {
    return 'sign-up'
  }
}
