import { ChangeEvent, useState } from 'react'

import { getAvailable, Token } from '../domain'
import { validate } from '../services/validate-form'
import { Web3Type } from '../types/web3'
import { getSum } from '../utils/sum'
import { useERC721Allowance } from './useERC721Allowance'

type Errors = {
  bid: string | null
  quantity: string | null
}

type UseSaleForm = {
  approved: boolean
  setApproved: (v: boolean) => void
  allowance: boolean
  available: number
  sum: string
  values: { bid: string; quantity: string }
  errors: Errors
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const useSaleForm = (web3: Web3Type, token: Token): UseSaleForm => {
  const [values, setValues] = useState({ bid: '1', quantity: '1' })
  const [errors, setErrors] = useState<Errors>({ bid: null, quantity: null })
  const [approved, setApproved] = useState(false)

  const allowance = useERC721Allowance(web3, token)
  const available = getAvailable(web3.account, token)
  const sum = getSum(values.bid, parseInt(values.quantity))

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })

    const error = validate(['required', 'number'], value)
    setErrors({ ...errors, [name]: error })
    if (error === null && name === 'quantity') {
      const noError = parseInt(value) <= available
      setErrors({ ...errors, quantity: !noError ? 'Invalid amount' : null })
    }
  }

  return {
    approved,
    setApproved,
    allowance,
    available,
    sum,
    values,
    errors,
    onChange
  }
}
