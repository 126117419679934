import React from 'react'

import { NetworkLogo } from '../NetworkLogo'
import { Wrapper } from './styles'

export const NetworkBadge = ({ networks }: { networks: string[] }): JSX.Element => {
  return (
    <Wrapper>
      {networks.map((item) => (
        <NetworkLogo key={item} size={34} icon={item} />
      ))}
    </Wrapper>
  )
}
