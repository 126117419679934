import { AbiItem } from 'web3-utils'

export const BRIDGE_721_ABI: AbiItem[] = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'contractOnMainnet',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'exitToMainERC721',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export const BRIDGE_1155_ABI: AbiItem[] = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'contractOnMainnet',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'id',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256'
      }
    ],
    name: 'exitToMainERC1155',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'contractOnMainnet',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]'
      }
    ],
    name: 'exitToMainERC1155Batch',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export const DEPOSIT_BOX_ABI: AbiItem[] = [
  {
    type: 'function',
    name: 'depositERC721',
    constant: false,
    payable: false,
    inputs: [
      {
        type: 'string',
        name: 'schainName'
      },
      {
        type: 'address',
        name: 'erc721OnMainnet'
      },
      {
        type: 'uint256',
        name: 'tokenId'
      }
    ],
    outputs: []
  },
  {
    type: 'function',
    name: 'depositERC1155',
    constant: false,
    payable: false,
    inputs: [
      {
        type: 'string',
        name: 'schainName'
      },
      {
        type: 'address',
        name: 'erc1155OnMainnet'
      },
      {
        type: 'uint256',
        name: 'id'
      },
      {
        type: 'uint256',
        name: 'amount'
      }
    ],
    outputs: []
  }
]
