import { AbiItem } from 'web3-utils'

export const WHITELIST_ABI: AbiItem[] = [
  {
    inputs: [{ internalType: 'address', name: '_addr', type: 'address' }],
    name: 'isInvestorAddressActive',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  }
]
