import React from 'react'

import instagram from '../../../assets/img/icons/socials/instagram.svg'
import twitter from '../../../assets/img/icons/socials/twitter.svg'
import website from '../../../assets/img/icons/socials/website.svg'
import { Web } from '../../../domain'
import { IconWrapper, StyleLink, Username } from './styles'

export const Social = ({
  value,
  type
}: {
  value: Web | SocialLink
  type: string
}): JSX.Element | null => {
  const icon = type === 'instagram' ? instagram : type === 'twitter' ? twitter : website
  const link =
    typeof value === 'string'
      ? type === 'instagram'
        ? `https://www.instagram.com/${value}`
        : type === 'twitter'
        ? `https://twitter.com/${value}`
        : undefined
      : value?.full
  const text = typeof value === 'string' ? value : value?.partial
  return link ? (
    <StyleLink href={link} target="_blank">
      <IconWrapper>
        <img src={icon} alt="icon" />
      </IconWrapper>
      <Username>{text}</Username>
    </StyleLink>
  ) : null
}
