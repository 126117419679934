import styled from 'styled-components'

export const CommentWrapper = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(241, 239, 239, 0.5) 100%);

  border-radius: 20px;
  max-width: 177px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
  position: relative;
  overflow: hidden;

  &::after {
    backdrop-filter: blur(20px);
    content: '';
    position: absolute;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: opacity 0.5s;
    z-index: -1;
  }
`

export const NickNameBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const DecorativeCircle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${(props) => props.color};
`

export const NickName = styled.p`
  font-weight: 700;
  color: var(--black);
`

export const CommentContent = styled.div`
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
