import Web3 from 'web3'

import {
  CURIO_FOUNDERS_EDITION_NFTS,
  CURIO_FOUNDERS_EDITION_NFTS_NEW,
  isOldCollection,
  Token
} from '../../domain'
import { Web3Type } from '../../types/web3'
import { TOKEN_MANAGER, TOKEN_MANAGER_ERC1155 } from '../bridge/addresses'
import { MIGRATOR } from '../migrator/addresses'
import { ERC721_ABI, OLD_ERC721_ABI } from './abi'
import { TRANSFER_PROXY } from './addresses'

const getAddress = (chainId: number, token: Token, type?: 'migrate' | 'move') =>
  type === 'migrate'
    ? MIGRATOR[chainId]
    : type === 'move'
    ? token.standard === 'ERC721'
      ? TOKEN_MANAGER[chainId]
      : TOKEN_MANAGER_ERC1155[chainId]
    : TRANSFER_PROXY[chainId]

const tokenIndexToApproved = async (
  w: Web3Type,
  token: Token,
  address: ContractAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  const res = await new web3.eth.Contract(OLD_ERC721_ABI, token.contract).methods
    .tokenIndexToApproved(token.tokenId)
    .call()
  return res.toLowerCase() === address.toLowerCase()
}

const isApprovedForAll = async (
  w: Web3Type,
  token: Token,
  address: ContractAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  return await new web3.eth.Contract(ERC721_ABI, token.contract).methods
    .isApprovedForAll(w.account, address)
    .call()
}

const getApproved = async (
  w: Web3Type,
  token: Token,
  address: ContractAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  const res = await new web3.eth.Contract(ERC721_ABI, token.contract).methods
    .getApproved(token.tokenId)
    .call()
  return res.toLowerCase() === address.toLowerCase()
}

export const allowance = async (
  w: Web3Type,
  token: Token,
  type?: 'migrate' | 'move'
): Promise<boolean> => {
  const address = getAddress(w.chainId, token, type)

  const isNew = !isOldCollection(token)
  return (!type || (type === 'move' && token.standard === 'ERC1155')) && isNew
    ? await isApprovedForAll(w, token, address)
    : !!type && isNew
    ? await getApproved(w, token, address)
    : await tokenIndexToApproved(w, token, address)
}

const approve = async (w: Web3Type, token: Token, address: ContractAddress): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(ERC721_ABI, token.contract).methods
      .approve(address, token.tokenId)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

const setApprovalForAll = async (
  w: Web3Type,
  token: Token,
  address: ContractAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(ERC721_ABI, token.contract).methods
      .setApprovalForAll(address, true)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const approveHandle = async (
  w: Web3Type,
  token: Token,
  type?: 'migrate' | 'move'
): Promise<boolean> => {
  const address = getAddress(w.chainId, token, type)
  try {
    if ((!type || (type === 'move' && token.standard === 'ERC1155')) && !isOldCollection(token)) {
      return await setApprovalForAll(w, token, address)
    } else {
      return await approve(w, token, address)
    }
  } catch (e) {
    console.error(e)
    return false
  }
}

export const transferFrom = async (
  w: Web3Type,
  contract: ContractAddress,
  tokenId: TokenId,
  userAddress: UserAddress
): Promise<string | null> => {
  const web3 = new Web3(w.library)
  try {
    const data = await new web3.eth.Contract(ERC721_ABI, contract).methods
      .transferFrom(w.account, userAddress, tokenId)
      .send({ from: w.account })
    return data.transactionHash
  } catch (e) {
    console.error(e)
    return null
  }
}

export const transfer = async (
  w: Web3Type,
  token: Token,
  userAddress: UserAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(OLD_ERC721_ABI, token.contract).methods
      .transfer(userAddress, token.tokenId)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const transferHandle = async (
  w: Web3Type,
  token: Token,
  userAddress: UserAddress
): Promise<boolean> =>
  isOldCollection(token)
    ? await transfer(w, token, userAddress)
    : !!(await transferFrom(w, token.contract, token.tokenId, userAddress))

export const balancesOfNFT = async (w: Web3Type): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    const balanceOfOld = await new web3.eth.Contract(
      OLD_ERC721_ABI,
      CURIO_FOUNDERS_EDITION_NFTS[w.chainId]
    ).methods
      .balanceOf(w.account)
      .call()
    const balanceOfNew = await new web3.eth.Contract(
      ERC721_ABI,
      CURIO_FOUNDERS_EDITION_NFTS_NEW[w.chainId]
    ).methods
      .balanceOf(w.account)
      .call()
    return balanceOfOld !== '0' || balanceOfNew !== '0'
  } catch (e) {
    console.error(e)
    return false
  }
}
