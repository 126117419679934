import styled from 'styled-components'

import menu from '../../../assets/img/icons/menu.svg'

export const Nav = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 24px;
  margin-right: -24px;
  @media screen and (max-width: 1140px) {
    grid-column-gap: 12px;
    margin-right: -16px;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
`

export const NavButton = styled.button`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
    cursor: pointer;
    width: 36px;
    height: 36px;
    border: 1px solid var(--line-day);
    box-sizing: border-box;
    border-radius: var(--border-radius);
    background: url(${menu}) no-repeat center;
    margin-left: 12px;
  }
`
