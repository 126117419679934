import React, { useState } from 'react'

import { approveHandle } from '../../../contracts/erc721/methods'
import { migrateTo } from '../../../contracts/migrator/methods'
import { isOldCollection, Token } from '../../../domain'
import { useERC721Allowance } from '../../../hooks/useERC721Allowance'
import { Web3Type } from '../../../types/web3'
import { Modal } from '../../common/Modal'
import { CancelBtn } from '../../components/CancelBtn'
import { Description, Wrapper } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const MigrateModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const [approved, setApproved] = useState(false)
  const isOld = isOldCollection(token)

  const allowance = useERC721Allowance(web3, token, 'migrate')

  const approve = async (): Promise<boolean> => {
    const res = await approveHandle(web3, token, 'migrate')
    setApproved(true)
    return res
  }

  return (
    <Modal title={isOld ? 'Migrate to new' : 'Migrate to old'} width="350px">
      <Wrapper>
        <Description>
          {isOld
            ? 'You can migrate NFT from the old collection to new'
            : 'You can migrate NFT from the new collection to old'}
        </Description>
        <StatusBlock name="Approve token" method={() => approve()} check={allowance} />
        <StatusBlock
          name="Migrate token"
          disabled={!allowance && !approved}
          method={() => migrateTo(web3, token)}
        />
        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
