import React from 'react'

import discord from '../../../assets/img/icons/socials/discord.svg'
import { Wrapper } from './styles'

export const DiscordBtn = (): JSX.Element => (
  <Wrapper href="https://discord.gg/VSvY6tBF4g" target="_blank">
    <img src={discord} alt="discord" />
  </Wrapper>
)
