import styled from 'styled-components'

export const NewBadgePosition = styled.div`
  position: absolute;
  bottom: 42px;
  right: 0;
`

export const WrapperBadge = styled.div<{ green?: boolean }>`
  display: flex;
  background: ${(props) =>
    props.green
      ? 'radial-gradient(74.42% 200% at 25.58% 0%, #adff00 0%, #07ab18 47.83%, #00b533 91.88%)'
      : 'radial-gradient(74.42% 200% at 25.58% 0%, #00f0ff 0%, #001aff 47.83%, #ad00ff 91.88%)'};
  border-radius: 20px;
  padding: 8px 9px 8px 10px;
  flex-shrink: 0;
  margin-left: auto;
  align-items: center;
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 12px;
  line-height: 16px;
`

export const VertLine = styled.div`
  width: 1px;
  height: 16px;
  background: var(--white);
  opacity: 0.5;
  margin: 0 10px 0 7px;
`
