import { Common } from '../types'

export const ADDRESS_NULL = '0x' + '0'.repeat(40)

export const SIGNATURE_NULL = '0x' + '0'.repeat(130)

export const OrderConstant: Common = {
  data: {
    dataType: 'RARIBLE_V2_DATA_V1',
    payouts: [],
    originFees: []
  },
  type: 'RARIBLE_V2'
}

export const ENC_DATA =
  '0x00000000000000000000000000000000000000000000000000000000000000200000000000000000000000000000000000000000000000000000000000000040000000000000000000000000000000000000000000000000000000000000006000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000'
