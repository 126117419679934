import React, { useEffect, useState } from 'react'

import { ListProfile } from '../../../domain'
import { getPreviewToken } from '../../../domain/forms/methods/create'
import { NETWORK_ICONS } from '../../../domain/network/contstant/icons'
import { useRollapp } from '../../../hooks/useRollapp'
import { clientV3 } from '../../../services/api'
import { TokenImage } from '../../common/TokenImage'
import { NetworkBadge } from '../NetworkBadge'
import { CardBottom } from './Bottom'
import { ImageWrap, PreviewWrap } from './styles'

export const Preview = ({ account }: { account: string }): JSX.Element => {
  const [profile, setProfile] = useState<ListProfile>()
  const { create, network } = useRollapp()
  const token = getPreviewToken(create, account)

  useEffect(() => {
    clientV3.profile.preview(account).then((res) => {
      const { data } = res
      setProfile({
        avatarIpfsHash: data.photo.avatar,
        avatarS3Link: data.photo.avatarS3Link,
        type: 'user',
        walletAddress: account,
        id: '',
        name: data.personal.displayName,
        network: null,
        url: null
      })
    })
  }, [account])

  return (
    <PreviewWrap isImage={!!token}>
      {token ? (
        <ImageWrap>
          <div className="image">
            <TokenImage token={token} />
          </div>
        </ImageWrap>
      ) : (
        <p>Upload file to preview your brand new NFT</p>
      )}
      <NetworkBadge networks={[NETWORK_ICONS[network] || NETWORK_ICONS.eth]} />
      {profile && token && <CardBottom token={token} profiles={[profile]} preview />}
    </PreviewWrap>
  )
}
