import React from 'react'

import { CommentContent, CommentWrapper, DecorativeCircle, NickName, NickNameBlock } from './styled'

type Props = {
  content: string | JSX.Element
  name: string
  color: string
}

export const Comment = ({ color, name, content }: Props): JSX.Element => {
  return (
    <CommentWrapper>
      <NickNameBlock>
        <DecorativeCircle color={color} />
        <NickName>{name}</NickName>
      </NickNameBlock>
      <CommentContent>{content}</CommentContent>
    </CommentWrapper>
  )
}
