import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  padding: 0;
  background-color: var(--white);

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const Inner = styled.div`
  height: 100%;
  position: relative;
  padding: 0 70px;

  button {
    position: absolute;
    top: 54px;
    left: 24px;
  }
`
