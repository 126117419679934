import React from 'react'

import {
  MessageWrapper,
  OwnershipImage,
  OwnershipInfo,
  OwnershipStatus,
  OwnersName
} from './styled'

type Props = {
  image: string
  name: string
  info: string
  status: string
  top?: number
  left?: string
}

export const InfoMessage = ({ top, info, image, name, left, status }: Props): JSX.Element => {
  return (
    <MessageWrapper top={top} left={left} className="info-message">
      <OwnershipImage>
        <img src={image} />
      </OwnershipImage>
      <OwnersName>{name}</OwnersName>
      <OwnershipInfo>{info}</OwnershipInfo>
      <OwnershipStatus>{status}</OwnershipStatus>
    </MessageWrapper>
  )
}
