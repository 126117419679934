import React, { useEffect } from 'react'

import { Token } from '../../../../domain'
import { useRollapp } from '../../../../hooks/useRollapp'
import { useOffer } from '../../../../hooks/useTokenData'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Loader } from '../../../components'
import { Wrapper } from '../../../components/ModalStyles'
import { Form } from './Form'

export const EditBidModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const { setModal } = useRollapp()
  const offer = useOffer(token, web3.account)

  useEffect(() => {
    if (offer === null) {
      setModal(undefined)
    }
    // eslint-disable-next-line
  }, [offer])

  return (
    <Modal title="Edit bid" width="350px">
      <Wrapper>
        {offer ? <Form web3={web3} offer={offer} token={token} /> : <Loader center />}
      </Wrapper>
    </Modal>
  )
}
