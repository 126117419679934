import styled from 'styled-components'

export const Wrap = styled.div`
  padding: 45px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 34px;
    margin-bottom: 24px;
  }
`
