import styled from 'styled-components'

export const WalletButtonBlock = styled.button`
  display: flex;
  flex-flow: column;
  padding: 18px 16px 16px;
  max-width: 276px;
  width: 100%;
  border-radius: 16px;
  background: var(--white);
  text-align: left;

  @media (max-width: 770px) {
    max-width: 100%;
  }
`

export const WalletButtonRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 16px;
  width: 100%;
`

export const WalletButtonLogo = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  & img {
    width: auto;
    height: auto;
  }
`

export const WalletButtonExtra = styled.span`
  padding: 0 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
  display: block;
  background: var(--line-day);
  border-radius: 20px;
  margin-left: auto;
`

export const WalletButtonName = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
`

export const WalletButtonDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
`
