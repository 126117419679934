import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 48px;
  padding-bottom: 56px;
`

export const RoadmapCardsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 48px 24px;

  @media screen and (max-width: 1216px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 916px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 100%;
    grid-gap: 24px;
  }
`
