import React from 'react'

import { Avatar } from '../../../../ui/components/Avatar'
import { shortAddress } from '../../../../utils/shortAddress'
import {
  CardTitle,
  CardWrapper,
  // Indicator,
  LastMessageDate,
  MessagePreview,
  RightBlock,
  UserName
} from './styles'

interface ChatCardProps {
  message?: string
  createdAt: string
  sender: string | null
  openChat: (v: string | undefined) => void
}

export const ChatCard = ({
  message,
  sender,
  createdAt,
  openChat
}: ChatCardProps): JSX.Element | null => {
  return (
    <CardWrapper
      onClick={() => {
        openChat(sender ?? undefined)
      }}
    >
      <Avatar width="48px" image={'profile.avatarIpfsHash'} />
      <RightBlock>
        <CardTitle>
          <UserName>
            {shortAddress(sender ?? '')} {/* <Indicator /> */}
          </UserName>
          {createdAt && (
            <LastMessageDate>
              {new Date(createdAt).toLocaleString('en-US', { day: 'numeric', month: 'short' })}
            </LastMessageDate>
          )}
        </CardTitle>
        {message && <MessagePreview>{message}</MessagePreview>}
      </RightBlock>
    </CardWrapper>
  )
}
