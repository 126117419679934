import React from 'react'

import { ListProfile } from '../../../domain'
import { getProfileLink } from '../../../utils/getProfileLink'
import { Avatar } from '../Avatar'
import { Wrapper } from './styles'

export const MiniAvatar = ({
  profile,
  type,
  preview
}: {
  profile: ListProfile
  type: 'Collection' | 'Creator' | 'Owner'
  preview?: boolean
}): JSX.Element => {
  return (
    <Wrapper to={getProfileLink(profile)} preview={preview}>
      <Avatar width="36px" border="2px" image={profile.avatarS3Link || profile.avatarIpfsHash} />
      <span>{`${type}: ${profile.name || profile.walletAddress}`}</span>
    </Wrapper>
  )
}
