import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { ListProfile, Token } from '../../../domain'
import { useCurrentCategory } from '../../../hooks/useTokens'
import { getTokensData } from '../../../services/getTokensData'
import { Card } from '../Card'
import { Loading } from '../LoaderWindow'
import { NoData } from '../NoData'
import { ButtonWrapper, CardsWrap } from './styles'
import { Props } from './types'

export const Cards = ({
  columns,
  collection,
  created,
  onSale,
  owned,
  address
}: Props): JSX.Element => {
  const { account } = useWeb3React()

  const [tokens, setTokens] = useState<Token[]>([])
  const [profiles, setProfiles] = useState<ListProfile[]>([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [end, setEnd] = useState(false)

  const { category, blockchain } = useCurrentCategory()

  useEffect(() => {
    setPage(1)
    setTokens([])
    // eslint-disable-next-line
  }, [category, blockchain, account, onSale, owned, address, created, collection])

  useEffect(() => {
    setLoading(true)
    getTokensData(page, account, {
      owner: owned ? address || null : null,
      on_sale: onSale ? `${onSale}` : null,
      creator: created ? address || null : null,
      collection: collection ? address || null : null,
      category,
      blockchain
    }).then((res) => {
      setTokens(page === 1 ? res.tokens : [...tokens, ...res.tokens])
      setProfiles([...profiles, ...res.profiles])
      setEnd(res.end)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [page, category, blockchain, account, onSale, owned, address, created, collection])

  return (
    <div>
      {tokens.length > 0 && (
        <CardsWrap columns={columns || 3}>
          {tokens.map((token) => (
            <Card token={token} profiles={profiles} key={token.id} />
          ))}
        </CardsWrap>
      )}
      {loading && <Loading />}
      {tokens.length > 0 && !loading && !end && (
        <ButtonWrapper>
          <button className="button primary-button" onClick={() => setPage(page + 1)}>
            Load more
          </button>
        </ButtonWrapper>
      )}
      {tokens.length === 0 && !loading && <NoData />}
    </div>
  )
}
