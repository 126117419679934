import { CHAIN_ID } from '../../network/contstant'
import {
  CURIO_ASSETS_GEEK_721,
  CURIO_ASSETS_SNEAKERS,
  CURIO_ASSETS_WATCHES,
  CURIO_ASSETS_WINES,
  CURIO_TOKENIZED_CARS,
  CURIO_WRAPPED_ASSETS
} from './index'

export const WRAPPED_ETH = {
  [CURIO_ASSETS_SNEAKERS[CHAIN_ID.eth.mainnet]]: '0xA8AD67Ad3084566762D1d5E65E539871803b0B03',
  [CURIO_ASSETS_WINES[CHAIN_ID.eth.mainnet]]: '0xB97fcF0C6D3498ebf19fdecAAd0c622140B24d29',
  [CURIO_ASSETS_WATCHES[CHAIN_ID.eth.mainnet]]: '0x29B0aAcBc14a8b5161e7e0c328FCDBE27b83A96f',
  [CURIO_ASSETS_GEEK_721[CHAIN_ID.eth.mainnet]]: '0x61EB9D1C21e64e55Dc6B6c4a0923e442eb7b4783',
  [CURIO_WRAPPED_ASSETS[CHAIN_ID.eth.testnet]]: '0x6Ac12A3A8B72EE9744DB740a7e3Ca95D08F237fb'
}

export const WRAPPED = [
  CURIO_TOKENIZED_CARS[CHAIN_ID.skale.testnet],
  CURIO_ASSETS_SNEAKERS[CHAIN_ID.skale.mainnet],
  CURIO_ASSETS_WINES[CHAIN_ID.skale.mainnet],
  CURIO_ASSETS_WATCHES[CHAIN_ID.skale.mainnet],
  CURIO_ASSETS_GEEK_721[CHAIN_ID.skale.mainnet],

  CURIO_WRAPPED_ASSETS[CHAIN_ID.harmony.testnet]
]
