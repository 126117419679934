import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { Token } from '../domain'
import { defaultErrors, defaultValues } from '../domain/forms/constants/create'
import { getAppNetworkByChainId } from '../domain/network/mappers'
import { AppNetwork } from '../domain/network/types'
import { selectMetamaskNetwork } from '../services/metamask'
import { getAutoModal } from '../utils/checkAutoModal'

export const RollappContext = React.createContext({})

export const RollappProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { chainId, account, library } = useWeb3React()
  const [modal, setModal] = useState<string>()
  const [network, setNetwork] = useState<AppNetwork>()
  const [token, setToken] = useState<Token>()
  const [taker, setTaker] = useState<string | undefined>()
  const [depositToken, setDepositToken] = useState<Token | undefined>()

  const [create, setCreate] = useState(defaultValues)
  const [createErrors, setCreateErrors] = useState(defaultErrors)

  const openToken = (modal: string, token: Token, taker?: string) => {
    setModal(modal)
    setToken(token)
    setTaker(taker)
  }

  const rechargePool = () => {
    const value = token
    setToken(undefined)
    setModal('recharge-pool')
    setNetwork('eth')
    selectMetamaskNetwork('eth').then((res) => {
      if (res) {
        setDepositToken(value)
      }
    })
  }

  const backToNetworkMove = () => {
    const value = depositToken
    setModal(undefined)
    setNetwork('skale')
    setDepositToken(undefined)
    selectMetamaskNetwork('skale').then((res) => {
      if (res) {
        setToken(value)
        setModal('move-to-network')
      }
    })
  }

  useEffect(() => {
    if (modal === undefined) {
      setToken(undefined)
      setTaker(undefined)
    }
  }, [modal])

  useEffect(() => {
    if (chainId) {
      setNetwork(getAppNetworkByChainId(chainId))
    }
  }, [chainId])

  useEffect(() => {
    if (account && chainId) {
      getAutoModal({ account, library, chainId }).then((res) => {
        setModal(res)
      })
    }
  }, [account, chainId, library])

  return (
    <RollappContext.Provider
      value={{
        modal,
        setModal,
        network,
        setNetwork,
        token,
        taker,
        depositToken,
        rechargePool,
        backToNetworkMove,
        openToken,

        create,
        setCreate,
        createErrors,
        setCreateErrors
      }}
    >
      {children}
    </RollappContext.Provider>
  )
}
