import React, { useContext } from 'react'

import { MessengerContext } from '../../../../context/Messenger'
import { Buttons, MessengerContainer } from '../DeleteModal/styles'
import { MessengerModal } from '../MessengerModal'
import {
  ChoiceLabel,
  ChoiceName,
  FormBlock,
  FormName,
  ModalSubtitle,
  RadioButton,
  RadioButtons,
  SettingsForm,
  TwoFormsBlock
} from './styles'

export const SettingsModal = (): JSX.Element => {
  const { setModal } = useContext(MessengerContext)

  return (
    <MessengerModal title="Where do you want to see new messages?" fullHeight>
      <MessengerContainer>
        <TwoFormsBlock>
          <SettingsForm>
            <FormName>From people you follow on Rollapp:</FormName>
            <RadioButtons>
              <ChoiceLabel>
                <ChoiceName>Hide in message requests</ChoiceName>
                <RadioButton type="radio" name="follow-test" value="request" />
              </ChoiceLabel>
              <ChoiceLabel>
                <ChoiceName>Show in main inbox</ChoiceName>
                <RadioButton type="radio" name="follow-test" value="inbox" />
              </ChoiceLabel>
              <ChoiceLabel>
                <ChoiceName>Block messages</ChoiceName>
                <RadioButton type="radio" name="follow-test" value="block" />
              </ChoiceLabel>
            </RadioButtons>
          </SettingsForm>
          <SettingsForm>
            <FormName>From people you don`t follow:</FormName>
            <RadioButtons>
              <ChoiceLabel>
                <ChoiceName>Hide in message requests</ChoiceName>
                <RadioButton type="radio" name="unfollow-test" value="request" />
              </ChoiceLabel>
              <ChoiceLabel>
                <ChoiceName>Show in main inbox</ChoiceName>
                <RadioButton type="radio" name="unfollow-test" value="inbox" />
              </ChoiceLabel>
              <ChoiceLabel>
                <ChoiceName>Block messages</ChoiceName>
                <RadioButton type="radio" name="unfollow-test" value="block" />
              </ChoiceLabel>
            </RadioButtons>
          </SettingsForm>
        </TwoFormsBlock>
        <FormBlock>
          <ModalSubtitle>Who can see my online status</ModalSubtitle>
          <SettingsForm>
            <RadioButtons>
              <ChoiceLabel>
                <ChoiceName>All users</ChoiceName>
                <RadioButton type="radio" name="online-test" value="every" />
              </ChoiceLabel>
              <ChoiceLabel>
                <ChoiceName>Nobody</ChoiceName>
                <RadioButton type="radio" name="online-test" value="nobody" />
              </ChoiceLabel>
            </RadioButtons>
          </SettingsForm>
        </FormBlock>
        <Buttons>
          <button className="button" onClick={() => setModal('')}>
            Cancel
          </button>
          <button className="button primary-button" onClick={() => setModal('')}>
            Save
          </button>
        </Buttons>
      </MessengerContainer>
    </MessengerModal>
  )
}
