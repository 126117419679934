import React, { ChangeEvent, useState } from 'react'

import { transferHandle } from '../../../contracts/erc721/methods'
import { safeTransferFrom } from '../../../contracts/erc1155/methods'
import { getAvailable, Token } from '../../../domain'
import { useWhitelistERC1155 } from '../../../hooks/useWhitelist'
import { checkErrors, validate } from '../../../services/validate-form'
import { ErrorStatus } from '../../../services/validate-form/types'
import { Web3Type } from '../../../types/web3'
import { CheckWhitelist } from '../../common/CheckWhitelist'
import { Modal } from '../../common/Modal'
import { TransferWhitelistText } from '../../common/WhitelistText'
import { ErrorText } from '../../components/Form/Error'
import { Input } from '../../components/Form/Input'
import { LabelWrapper } from '../../components/Form/Label/LabelWrapper'
import { Description, Wrapper } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const TransferModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const [values, setValues] = useState({ address: '', quantity: '1' })
  const [errors, setErrors] = useState<{ address: ErrorStatus; quantity: ErrorStatus }>({
    address: undefined,
    quantity: null
  })

  const available = token.standard === 'ERC1155' ? getAvailable(web3.account, token) : 1
  const whitelisted = useWhitelistERC1155(web3, token, values.address)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })
    const error =
      name === 'address'
        ? validate(['required', 'eth'], value)
        : validate(['required', 'number'], value)
    setErrors({ ...errors, [name]: error })
    if (error === null && name === 'quantity') {
      setErrors({
        ...errors,
        quantity: parseInt(value) > available ? `Value must be less than ${available}` : null
      })
    }
  }

  const transfer = async (): Promise<boolean> =>
    token.standard === 'ERC1155'
      ? whitelisted
        ? await safeTransferFrom(
            web3,
            token.contract,
            token.tokenId,
            values.address,
            values.quantity
          )
        : false
      : await transferHandle(web3, token, values.address)

  return (
    <Modal title="Transfer token" width="350px">
      <CheckWhitelist web3={web3} token={token}>
        <Wrapper>
          <Description>You can transfer tokens from your address to another</Description>
          <LabelWrapper title="Receiver address" error={errors.address}>
            <>
              <Input
                name="address"
                value={values.address}
                placeholder="Paste address"
                onChange={onChange}
              />
              {whitelisted === false && (
                <ErrorText>
                  <TransferWhitelistText />
                </ErrorText>
              )}
            </>
          </LabelWrapper>
          {token.standard === 'ERC1155' && (
            <LabelWrapper title="Quantity" error={errors.quantity}>
              <Input
                name="quantity"
                value={values.quantity}
                placeholder="Paste quantity"
                onChange={onChange}
              />
              <small>Enter quantity. {available} available</small>
            </LabelWrapper>
          )}
          <StatusBlock
            name="Transfer"
            disabled={checkErrors(errors) || whitelisted === false}
            method={() => transfer()}
          />
        </Wrapper>
      </CheckWhitelist>
    </Modal>
  )
}
