import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled(Link)`
  display: grid;
  grid-template-columns: 48px calc(100% - 60px);
  grid-column-gap: 12px;
  align-items: center;
`

export const Name = styled.span`
  display: block;
  font-weight: 700;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Info = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
`
