import { OrderExchange } from '../../domain'
import { Web3Type } from '../../types/web3'
import { dataOrder } from './dataOrder'
import { EIP712 } from './EIP721'

export const signOrder = async (w: Web3Type, orderData: OrderExchange): Promise<Signature> => {
  const order = dataOrder(w.chainId)
  const data = EIP712.createTypeData(order.domain, order.structType, orderData, order.types)
  return await EIP712.signTypedData(w.library, w.account, data)
}
