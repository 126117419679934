import { names } from './index'

export const disconnectLS = (): void => {
  localStorage.setItem(names.connect, 'true')
}

export const connectLS = (): void => {
  localStorage.removeItem(names.connect)
}

export const isDisconnected = (): boolean => {
  return !!localStorage.getItem(names.connect)
}
