import { useEffect, useState } from 'react'

import { balancesOfNFT } from '../contracts/erc721/methods'
import { isMarioPresaleToken, Token } from '../domain'
import { Web3Type } from '../types/web3'

export const useBalancesOfNFT = (web3: Web3Type, token: Token): boolean => {
  const [value, setValue] = useState(true)

  useEffect(() => {
    if (isMarioPresaleToken(token)) {
      balancesOfNFT(web3).then((res) => {
        setValue(res)
      })
    } else {
      setValue(true)
    }
  }, [token, web3])

  return value
}
