import React, { useEffect, useState } from 'react'

import { Collection } from '../../domain'
import { clientV3 } from '../../services/api'
import { Loading } from '../../ui/components'
import { ButtonWrapper, CardsWrap } from '../../ui/components/Cards/styles'
import { MiniCard } from '../../ui/components/MiniCard'
import { NoData } from '../../ui/components/NoData'

export const SearchCollections = ({ query }: { query: string }): JSX.Element => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [end, setEnd] = useState(false)
  const [collections, setCollections] = useState<Collection[]>([])

  useEffect(() => {
    setLoading(true)
    clientV3.search.collections(query, page).then((res) => {
      setCollections([...collections, ...res.data.items])
      setEnd(res.data.pagination.pages === page)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [page])

  return (
    <>
      {collections && collections.length > 0 ? (
        <CardsWrap columns={3}>
          {collections.map((item) => (
            <MiniCard key={item.id} item={item} />
          ))}
        </CardsWrap>
      ) : (
        !loading && <NoData />
      )}
      {loading && <Loading />}
      {collections && collections.length > 0 && !loading && !end && (
        <ButtonWrapper>
          <button className="button primary-button" onClick={() => setPage(page + 1)}>
            Load more
          </button>
        </ButtonWrapper>
      )}
    </>
  )
}
