import React from 'react'

import { checkErrors } from '../../../domain/forms/methods/create'
import { ACCEPT_COVER, ACCEPT_DOCUMENT, ACCEPT_IPFS } from '../../../services/validate-form/file'
import { Input } from '../../../ui/components/Form/Input'
import { LabelWrapper } from '../../../ui/components/Form/Label/LabelWrapper'
import { Select } from '../../../ui/components/Form/Select'
import { Textarea } from '../../../ui/components/Form/Textares'
import { Upload } from '../../../ui/components/Form/Upload'
import { UploadImage } from '../../../ui/components/Form/UploadImage'
import { RadioCard } from '../../../ui/components/RadioCard'
import { RadioCards } from '../../../ui/components/RadioCards'
import Marketplace from '../../../ui/icons/Marketplace'
import { SubmitButton } from '../SubmitButton'
import { SuccessSendModal } from '../SuccessSendModal'
import { useCreateForm } from '../useCreateForm'
import { Form } from './styles'

export const LoanForm = ({ whitelisted }: { whitelisted: boolean }): JSX.Element => {
  const { clearImage, onChangeFile, onChange, submit, status, close, create, createErrors } =
    useCreateForm('LOAN')

  const submitHandle = () => {
    if (create.confirming) {
      const formData = new FormData()
      formData.append('firstName', create.firstName)
      formData.append('lastName', create.lastName)

      formData.append('phone', create.phone)

      formData.append('make', create.make)
      formData.append('model', create.model)
      formData.append('year', create.year)
      formData.append('descriptionOfCar', create.general)

      for (let i = 0; i < create.confirming.length; i++) {
        formData.append('files', create.confirming[i])
      }

      submit(formData)
    }
  }

  return (
    <Form>
      <UploadImage
        image={create.file}
        clearImage={() => clearImage('file')}
        error={createErrors.file}
      >
        <input type="file" name="file" accept={ACCEPT_IPFS} onChange={onChangeFile} />
      </UploadImage>

      {create.file?.type === 'audio/mpeg' && (
        <UploadImage
          image={create.cover}
          imageType={true}
          clearImage={() => clearImage('cover')}
          error={createErrors.cover}
        >
          <input type="file" name="cover" accept={ACCEPT_COVER} onChange={onChangeFile} />
        </UploadImage>
      )}

      <LabelWrapper title="First name" error={createErrors.firstName}>
        <Input value={create.firstName} name="firstName" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Last name" error={createErrors.lastName}>
        <Input value={create.lastName} name="lastName" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Email" error={createErrors.email}>
        <Input value={create.email} name="email" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Phone (optional)">
        <Input value={create.phone} name="phone" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Make" error={createErrors.make}>
        <Input value={create.make} name="make" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Model" error={createErrors.model}>
        <Input value={create.model} name="model" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Year" error={createErrors.year}>
        <Select value={create.year} name="year" onChange={onChange}>
          <option value="" disabled>
            Select year
          </option>
          {Array.from(Array(2021), (e, i) => {
            if (i > 1899) {
              return (
                <option key={`option-year-${i}`} value={i}>
                  {i}
                </option>
              )
            }
          })}
        </Select>
      </LabelWrapper>

      <LabelWrapper title="General description of the car" error={createErrors.general}>
        <Textarea value={create.general} name="general" onChange={onChange} />
      </LabelWrapper>

      <Upload
        title="Documents confirming the ownership of a car and more"
        files={create.confirming}
      >
        <input
          type="file"
          name="confirming"
          accept={ACCEPT_DOCUMENT}
          onChange={onChangeFile}
          multiple
        />
      </Upload>

      <RadioCards>
        <RadioCard title="User Assets" extra="ERC-721" icon={Marketplace}>
          <input
            type="radio"
            name="collection"
            value="user-assets"
            checked={create.collection === 'user-assets'}
            onChange={onChange}
          />
        </RadioCard>
      </RadioCards>

      <LabelWrapper title="Title" error={createErrors.title}>
        <Input name="title" value={create.title} onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Description" error={createErrors.description}>
        <Input
          name="description"
          value={create.description}
          error={!!createErrors.description}
          onChange={onChange}
        />
      </LabelWrapper>

      <LabelWrapper title="Request message (optional)">
        <Textarea rows={1} name="request" value={create.request} onChange={onChange} />
      </LabelWrapper>

      <SubmitButton
        whitelisted={whitelisted && !checkErrors(createErrors, 'LOAN')}
        submit={submitHandle}
      />
      <SuccessSendModal status={status} close={close} />
    </Form>
  )
}
