import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 14px;
  height: 34px;
  display: flex;
  grid-gap: 8px;
`
