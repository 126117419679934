import { CHAIN_ID } from '../../domain/network/contstant'

// here deposit box also
export const TOKEN_MANAGER = {
  [CHAIN_ID.eth.mainnet]: '0x7343d31eb99Fd31424bcca9f0a7EAFBc1F515f2d',
  [CHAIN_ID.eth.testnet]: '',
  [CHAIN_ID.skale.mainnet]: '0xD2aaa00600000000000000000000000000000000',
  [CHAIN_ID.skale.testnet]: '0xD2aaa00600000000000000000000000000000000'
}

export const TOKEN_MANAGER_ERC1155 = {
  [CHAIN_ID.eth.mainnet]: '0x3C02FdEe8E05B6dc4d44a6555b3ff5762D03871a',
  [CHAIN_ID.eth.testnet]: '',
  [CHAIN_ID.skale.mainnet]: '0xD2aaA00900000000000000000000000000000000',
  [CHAIN_ID.skale.testnet]: '0xD2aaA00900000000000000000000000000000000'
}
