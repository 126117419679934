import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 0;
  display: grid;
  grid-row-gap: 24px;
  position: sticky;
  left: 0;
  bottom: 0;
  background-color: var(--gray);
`

export const WrapperSale = styled.div`
  display: flex;
  width: 100%;
  grid-gap: 12px;
  flex-wrap: wrap;

  & button:first-child:not(:only-child),
  & button:nth-child(2) {
    width: calc(50% - 6px);
  }
`
