import { matchOrders } from '../../../contracts/exchange'
import { clientV3 } from '../../../services/api'
import { hashKey } from '../../../services/order/generateHash'
import { Web3Type } from '../../../types/web3'
import { isMarioPresaleToken } from '../../collection/mappers/collections.mapper'
import { Token } from '../../token'
import { SIGNATURE_NULL } from '../constant'
import { prepareExchangeOrder, prepareOrder } from '../getters/prepareOrder'
import { OrderApi } from '../types'

export const buyOrder = async (
  web3: Web3Type,
  token: Token,
  order: OrderApi,
  bid: string,
  amount: string
): Promise<boolean> => {
  const offer = prepareOrder(token, web3.account, order.maker, bid, amount, web3.chainId, true)
  if (offer === null) {
    return false
  }
  const offerExchange = prepareExchangeOrder(offer)
  const orderExchange = prepareExchangeOrder(order)
  const hash = hashKey(offerExchange)

  try {
    const res = await matchOrders(web3, {
      sellOrder: orderExchange,
      sellSignature: order.signature,
      buyOrder: offerExchange,
      buySignature: SIGNATURE_NULL
    })

    if (!isMarioPresaleToken(token) && res) {
      await clientV3.exchange.create({
        ...offer,
        hash,
        signature: SIGNATURE_NULL,
        isAuction: false,
        network: token.collection.network
      })
    }

    return res
  } catch (e) {
    console.error(e)
    return false
  }
}
