import { clientV3 } from '../../../services/api'
import { Token } from '../../token'
import { DEFAULT_PROFILE } from '../constants'
import { ListProfile, Profiles } from '../types/Profile'

export const getTokenAddresses = (token: Token, list: EthAddress[]): EthAddress[] => {
  const addresses = []
  if (!list.includes(token.creatorAddress)) {
    addresses.push(token.creatorAddress)
  }
  if (token.standard === 'ERC721' && token.ownerships[0]) {
    addresses.push(token.ownerships[0].ownerAddress)
  }
  return addresses
}

export const getListAddresses = (tokens: Token[]): EthAddress[] => {
  let list: EthAddress[] = []
  tokens.map((token) => {
    list = [...list, ...getTokenAddresses(token, list)]
  })
  return list
}

export const getProfiles = async (accounts: string[]): Promise<ListProfile[]> => {
  if (accounts.length > 0) {
    try {
      const { data } = await clientV3.profile.list(accounts)
      return data
    } catch (e) {
      return []
    }
  }
  return []
}

export const getProfilesList = (token: Token, profiles: ListProfile[]): Profiles => {
  const owner =
    token.standard === 'ERC721'
      ? profiles.filter(
          (item) =>
            token.ownerships[0].ownerAddress.toLowerCase() === item.walletAddress.toLowerCase()
        )
      : null
  const creator = profiles.filter(
    (item) => token.creatorAddress.toLowerCase() === item.walletAddress.toLowerCase()
  )
  const { collection } = token

  return {
    owner: owner ? owner[0] || DEFAULT_PROFILE(token.ownerships[0].ownerAddress) : null,
    creator: creator[0] || DEFAULT_PROFILE(token.creatorAddress),
    collection: {
      id: collection.id,
      name: collection.name,
      walletAddress: collection.ethAddress,
      avatarIpfsHash: collection.avatarS3Link,
      avatarS3Link: collection.avatarS3Link,
      type: 'nft_collection',
      network: collection.network,
      url: collection.url
    }
  }
}

export const getListOwners = (token: Token): UserAddress[] => {
  const list: UserAddress[] = []
  token.ownerships.map((owner) => {
    if (!list.includes(owner.ownerAddress)) {
      list.push(owner.ownerAddress)
    }
  })
  return list
}
