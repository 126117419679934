import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled(Link)<{ preview?: boolean }>`
  transition: 0.2s transform;
  cursor: pointer;
  display: block;
  position: relative;

  ${(props) => (props.preview ? 'pointer-events: none;' : '')}

  span {
    position: absolute;
    bottom: 110%;
    right: 50%;
    transform: translateX(50%);
    background-color: var(--white);
    font-size: 12px;
    line-height: 14px;
    border: 1px solid var(--light-gray);
    border-radius: 8px;
    padding: 4px 6px;
    white-space: pre;
    display: none;

    max-width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:hover {
    transform: translateY(-6px);
  }

  &:hover > span {
    display: block;
  }
`
