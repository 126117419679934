import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { Profile } from '../../domain'
import { clientV3 } from '../../services/api'
import { SettingSidebar } from '../../ui/common/Sidebar/SettingSidebar'
import { Loading } from '../../ui/components'
import { UploadCover } from '../../ui/components/Cover/UploadCover'
import Form from './Form'

const Settings = (): JSX.Element => {
  const { account } = useWeb3React()
  const [profile, setProfile] = useState<Profile>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const fetch = () => {
      if (account) {
        clientV3.profile
          .get(account)
          .then((res) => {
            setProfile(res.data)
            setLoaded(true)
          })
          .catch((e) => {
            console.error(e)
            setLoaded(true)
          })
      }
    }

    fetch()
  }, [account])

  return (
    <div>
      {loaded ? (
        profile && (
          <>
            <UploadCover cover={profile.photo.cover} />
            <div className="container grid" style={{ paddingTop: 74 }}>
              <SettingSidebar avatar={profile.photo.avatar} />
              <Form profile={profile} />
            </div>
          </>
        )
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default Settings
