import React from 'react'

import { getStockForCard, isChainlinkToken, ListProfile, Token } from '../../../domain'
import { isNewCollection } from '../../../domain'
import { getProfilesList } from '../../../domain/profile/mappers/profiles'
import { BidText } from '../../common/BidText'
import { Badge, NewBadge } from '../Badges'
import { ChainLinkPrice } from '../ChainLinkPrice'
import { LikeButton } from '../Like'
import { MiniAvatars } from '../MiniAvatars'
import { BuyButton } from './BuyButton'
import {
  Bottom,
  CardAvailable,
  CardIcons,
  CardInfo,
  CardInfoToken,
  CardRow,
  CardTitle
} from './styles'

export const CardBottom = ({
  token,
  profiles,
  preview
}: {
  token: Token
  profiles: ListProfile[]
  preview?: boolean
}): JSX.Element => (
  <Bottom>
    <CardIcons>
      <MiniAvatars profiles={getProfilesList(token, profiles)} preview={preview} />
      {!!token.category && <Badge category={token.category} />}
      {isNewCollection(token) && <NewBadge />}
    </CardIcons>
    <CardInfo>
      <CardTitle>{token.name}</CardTitle>
      <CardInfoToken>
        <BidText token={token} />
        <CardAvailable>{getStockForCard(token)}</CardAvailable>
        {isChainlinkToken(token) && <ChainLinkPrice />}
      </CardInfoToken>
      <CardRow>
        <BuyButton token={token} preview={preview} />
        <LikeButton token={token} preview={preview} />
      </CardRow>
    </CardInfo>
  </Bottom>
)
