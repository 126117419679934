import { Socket } from 'socket.io-client'

import { EVENTS } from '../constants/events'

export class ChatService {
  constructor(
    private connection: Socket | undefined,
    private friendWalletAddress: string,
    private conversationId: string | undefined
  ) {
    console.log(!!connection && !!conversationId)
  }

  join(): void {
    this.connection?.emit(EVENTS.joinConversation, {
      friendWalletAddress: this.friendWalletAddress
    })
  }

  acceptRequest(): void {
    this.connection?.emit(EVENTS.acceptRequest, { friendWalletAddress: this.friendWalletAddress })
  }

  sendMessage(message: string): void {
    this.connection?.emit(EVENTS.sendMessage, {
      conversationId: this.conversationId,
      message,
      fwdMessageId: null
    })
  }

  removeMessage(messagesId: string[]): void {
    this.connection?.emit(EVENTS.removeMessages, {
      conversationId: this.conversationId,
      messagesId
    })
  }
}
