import { TokenAsset } from '../types'

export const prepareTokenAsset = (
  assetClass: TokenStandard,
  contract: ContractAddress,
  tokenId: TokenId | null,
  value: string
): TokenAsset => ({
  assetType: {
    assetClass,
    contract,
    tokenId
  },
  value
})
