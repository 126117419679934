import React from 'react'

import { SwitchLabel } from './styles'

export const Switch = ({ children }: { children: JSX.Element }): JSX.Element => (
  <SwitchLabel>
    {children}
    <span />
  </SwitchLabel>
)
