import React from 'react'

import { useRollapp } from '../../../hooks/useRollapp'
import { Close, Header, Inner, Title, Wrap } from './styles'

export type Props = {
  children: JSX.Element
  title?: string
  width?: string
  close?: boolean
  closeHandle?: () => void
  noPadding?: boolean
}

export const Modal = (props: Props): JSX.Element => {
  const { setModal } = useRollapp()

  return (
    <Wrap>
      <Inner width={props.width} padding={props.noPadding ? '0' : undefined}>
        <Header over={props.noPadding}>
          <Title>{props.title}</Title>
          {(typeof props.close === 'undefined' || props.close) && (
            <Close
              onClick={() => (props.closeHandle ? props.closeHandle() : setModal(undefined))}
              type="button"
              over={props.noPadding}
            />
          )}
        </Header>
        {props.children}
      </Inner>
    </Wrap>
  )
}
