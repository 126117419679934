type UiStandard = {
  [key in TokenStandard]: string
}

const UI_STANDARD: UiStandard = {
  ERC1155: 'ERC-1155',
  ERC721: 'ERC-721',
  ERC20: 'ERC-20'
}

export const standardUi = (standards: TokenStandard[]): string => {
  let str = ''
  standards.map((item) => {
    str += `${str === '' ? '' : '/'}${UI_STANDARD[item]}`
  })
  return str
}
