import kovan from '../../../assets/img/networks/eth-kovan.svg'
import eth from '../../../assets/img/networks/eth-network.svg'
import harmony from '../../../assets/img/networks/harmony.svg'
import skale from '../../../assets/img/networks/skale.svg'
import { NetworkIcons } from '../types'

export const NETWORK_ICONS: NetworkIcons = {
  eth,
  harmony,
  skale,
  kovan
}
