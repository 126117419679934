import React from 'react'
import { useParams } from 'react-router-dom'

import { Token } from '../../../domain'
import { useOffers, useOwners } from '../../../hooks/useTokenData'
import { Tabs } from '../../../ui/components/Tabs'
import { TokenBlockchain } from '../TokenBlockchain'
import { Bids } from './Bids'
import { Owners } from './Owners'
import { Data } from './styles'

export const Details = ({ token }: { token: Token }): JSX.Element => {
  const { tab } = useParams<{ tab: undefined | string }>()
  const owners = useOwners(token)
  const { offers, list } = useOffers(token)

  const tabs = [
    {
      link: '',
      title: token.standard === 'ERC721' ? 'Details' : 'Owners'
    },
    {
      link: '/bids',
      title: 'Bids'
    }
  ]

  if (token.standard === 'ERC1155') {
    tabs.push({
      link: '/details',
      title: 'Details'
    })
  }

  return (
    <div>
      <Tabs items={tabs} />
      <Data>
        {tab === 'bids' ? (
          <Bids offers={offers} list={list} token={token} />
        ) : tab === 'details' ? (
          <TokenBlockchain token={token} />
        ) : (
          <Owners owners={owners} token={token} />
        )}
      </Data>
    </div>
  )
}
