import React from 'react'

import { Modal } from '../common/Modal'
import { WrapperCentered } from '../common/Modal/styles'
import { Loader } from '../components'

type Props = {
  title: string
  status: string
  method: () => void
  cancel: () => void
}

export const StatusModal = ({ status, title, method, cancel }: Props): JSX.Element => {
  return (
    <Modal close={false} width="370px">
      <WrapperCentered>
        {status === 'pending' && <Loader width={36} />}
        <h2>{status === 'error' ? 'Error' : title}</h2>
        <p>
          {status === 'error'
            ? 'Operation is cancelled. If the problem persist please Contact support'
            : `Sign message to ${title.toLowerCase()}`}
        </p>
        {status === 'error' && (
          <>
            <button className="button primary-button block-button" onClick={method}>
              Try again
            </button>
            <button className="button block-button" onClick={cancel}>
              Cancel
            </button>
          </>
        )}
      </WrapperCentered>
    </Modal>
  )
}
