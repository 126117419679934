export const MESSAGES = {
  edit_profile: 'I want to edit my profile',
  edit_cover: 'I want to edit my cover',
  edit_avatar: 'I want to edit my avatar',
  edit_credentials: 'I want to edit my credentials',
  get_credentials: 'I want to get my credentials',
  set_cover: 'I want to edit my cover',
  set_avatar: 'I want to edit my avatar',
  create: 'I want to create nft item',
  like: 'I want to like item',
  getEth: 'I want to request skETH'
}

type Message = (account: string, message: keyof typeof MESSAGES) => string

export const EDIT_PROFILE: Message = (account, message) =>
  `${MESSAGES[message]} - ${account.toLowerCase()}`

export const LIKE = (tokenId: TokenId): string => `I want to like item - ${tokenId}`
