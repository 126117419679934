import { CGT } from '../../../contracts/erc20/addresses'
import { assetEnc, codeAsset } from '../../../services/order/coders'
import { toWei } from '../../../utils/wei'
import { Token } from '../../token'
import { ADDRESS_NULL, ENC_DATA, OrderConstant } from '../constant'
import { Order, OrderApi, OrderExchange } from '../types'
import { generateSalt } from './generateSalt'
import { prepareTokenAsset } from './prepareAsset'

export const prepareOrder = (
  token: Token,
  maker: UserAddress | null,
  taker: UserAddress | null,
  bid: string,
  amount: string,
  chainId: ChainId,
  buy?: boolean
): Order | null => {
  if (CGT[chainId]) {
    const erc20 = prepareTokenAsset('ERC20', CGT[chainId], null, bid === '0' ? bid : toWei(bid))
    const nft = prepareTokenAsset(token.standard, token.contract, token.tokenId, amount)

    return {
      ...OrderConstant,
      maker: maker || ADDRESS_NULL,
      make: buy ? erc20 : nft,
      taker: taker || ADDRESS_NULL,
      take: buy ? nft : erc20,
      salt: generateSalt()
    }
  }
  return null
}

export const prepareExchangeOrder = (order: Order | OrderApi): OrderExchange => ({
  maker: order.maker.toLowerCase(),
  makeAsset: codeAsset(order.make),
  taker: order.taker.toLowerCase(),
  takeAsset: codeAsset(order.take),
  salt: parseInt(order.salt),
  start: 0,
  end: 0,
  dataType: assetEnc('V1'),
  // encode data
  data: ENC_DATA
})
