import { SeasonTag } from '../types'

type Assets = {
  [key in SeasonTag | string]: number
}

export const ASSETS: Assets = {
  'season-1': 900,
  'season-2': 900,
  'season-3': 900
}
