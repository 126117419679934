import React, { ChangeEvent, useState } from 'react'

import { useMessenger } from '../../context/useMessenger'
import { Buttons, Description, MessengerContainer } from '../DeleteModal/styles'
import { MessengerModal } from '../MessengerModal'
import { ModalForm, ModalInput } from './styles'

export const AddChatModal = (): JSX.Element => {
  const { request, close } = useMessenger()
  const [input, setInput] = useState('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setInput(value)
  }

  return (
    <MessengerModal title="Start new chat">
      <MessengerContainer>
        <Description>Enter an address below to start a new chat.</Description>
        <ModalForm>
          <ModalInput placeholder="e.g. 0x..." value={input} onChange={handleInputChange} />
        </ModalForm>
        <Buttons>
          <button type="button" className="button" onClick={close}>
            Cancel
          </button>
          <button type="button" className="button primary-button" onClick={() => request(input)}>
            Start chatting
          </button>
        </Buttons>
      </MessengerContainer>
    </MessengerModal>
  )
}
