import React, { useEffect } from 'react'

import { useRollapp } from '../../../../hooks/useRollapp'
import { useOrder } from '../../../../hooks/useTokenData'
import { Loader } from '../../../components'
import { Action } from './Action'
import { Props } from './types'

export const CheckoutModal = ({ token, web3, taker }: Props): JSX.Element => {
  const order = useOrder(token, taker, web3.account.toLowerCase() !== taker.toLowerCase())
  const { setModal } = useRollapp()

  useEffect(() => {
    if (order === null) {
      setModal(undefined)
    }
    // eslint-disable-next-line
 }, [order])

  return order ? <Action order={order} web3={web3} token={token} /> : <Loader center />
}
