import axios from 'axios'

import { URLS } from '../../config/rollapp'
import { Web3Type } from '../../types/web3'
import { signMessage } from '../sign'
import { MESSAGES } from '../sign/messages'
import { Allowance } from './types'

const faucetAPI = axios.create({
  baseURL: URLS.faucet,
  withCredentials: true
})

export const clientFaucet = {
  checkAllowance: (address: string): Promise<Allowance> =>
    faucetAPI.get(`/check-allowance/${address}`),
  requestSketh: (address: string, signature: string): Promise<Request> =>
    faucetAPI.post('request-sketh', { address, signature })
}

export const requestSketh = async (web3: Web3Type): Promise<boolean> => {
  const hash = await signMessage(MESSAGES.getEth, web3.account, web3.library)
  try {
    await clientFaucet.requestSketh(web3.account, hash)
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
