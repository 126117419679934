import React from 'react'

import notWin from '../../../../assets/mp4/lottery/not-win.mp4'
import winAdidas from '../../../../assets/mp4/lottery/win-adidas.mp4'
import winIndifferentduck from '../../../../assets/mp4/lottery/win-indifferentduck.mp4'
import { Claimed } from '../../../../contracts/nft-lottery/types'
import { ADDRESS_NULL, isAdidasToken } from '../../../../domain'
import { Video } from './styles'

export const WinProcess = ({ claimed }: { claimed: Claimed }): JSX.Element => (
  <Video autoPlay controls={false} playsInline muted>
    <source
      src={
        claimed.reward.collectionAddress === ADDRESS_NULL
          ? notWin
          : isAdidasToken(claimed.reward.collectionAddress)
          ? winAdidas
          : winIndifferentduck
      }
    />
  </Video>
)
