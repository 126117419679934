import { DaoData } from '../data'
import Info from '../info/Season2'

export const Season2: DaoData = {
  title: 'Season 2. Curio Founders Edition NFTs',
  hashtag: 'SEASON2',
  width: '680px',
  pages: [],
  info: {
    text: 'Curio Punks, 1985 NES Super Mario Bros, collectibles, and more. Drop on November 29',
    data: Info
  }
}
