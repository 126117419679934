import 'normalize.css'
import './assets/css/reset.css'
import './assets/css/vars.css'
import './assets/css/style.css'
import './assets/css/button.css'
import './assets/css/container.css'

import { Web3ReactProvider } from '@web3-react/core'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import { RollappProvider } from './context'
import reportWebVitals from './reportWebVitals'
import { Modals } from './ui/common/Modals'
import { Wrong } from './ui/common/WrongNetwork'
import Web3Manager from './Web3Manager'

// eslint-disable-next-line
function getLibrary(provider: any): any {
  console.info('init provider')
  return provider
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <RollappProvider>
        <Web3Manager>
          <>
            <Wrong />
            <App />
            <Modals />
          </>
        </Web3Manager>
      </RollappProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.info)
