type Rows = {
  cells: {
    value?: string
    check?: string
  }[]
}

export const rows: Rows[] = [
  {
    cells: [
      {
        value: ''
      },
      {
        value: 'RollApp'
      },
      {
        value: 'Ebay'
      },
      {
        value: 'Rarible'
      },
      {
        value: 'OpenSea'
      },
      {
        value: 'Nifty Gateway'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Digital assets'
      },
      {
        check: 'white'
      },
      {
        check: 'green'
      },
      {
        check: 'green'
      },
      {
        check: 'green'
      },
      {
        check: 'green'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Physical items'
      },
      {
        check: 'white'
      },
      {
        check: 'green'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Fees'
      },
      {
        value: '2%'
      },
      {
        value: '12%-15%'
      },
      {
        value: '5%'
      },
      {
        value: '2.5%'
      },
      {
        value: '5%'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Authenticated items'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'NFT staking'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Multichain'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Gasless'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Free minting'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Carbon neutral'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  },
  {
    cells: [
      {
        value: 'Swiss legal - NFT redeem guarantee'
      },
      {
        check: 'white'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      },
      {
        check: 'not'
      }
    ]
  }
]
