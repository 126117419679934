import { useWeb3React } from '@web3-react/core'
import { ChangeEvent, useState } from 'react'

import { defaultErrors, defaultValues } from '../../domain/forms/constants/create'
import {
  checkErrors,
  validateCreateFile,
  validateCreateValue
} from '../../domain/forms/methods/create'
import { CreateErrors, CreateValues } from '../../domain/forms/types/create.types'
import { useRollapp } from '../../hooks/useRollapp'
import { clientV3 } from '../../services/api'
import { signMessage } from '../../services/sign'
import { EDIT_PROFILE } from '../../services/sign/messages'
import { ipfsFileUpload } from '../../utils/ipfs'

type Response = {
  clearImage: (name: 'file' | 'cover') => void
  onChange: (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => void
  onChangeFile: (e: ChangeEvent<HTMLInputElement>) => void
  submit: (formData: FormData) => void
  status: undefined | 'pending' | 'done'
  close: () => void
  create: CreateValues
  createErrors: CreateErrors
}

export const useCreateForm = (form: 'DEFAULT' | 'LOAN'): Response => {
  const { account, library } = useWeb3React()
  const { create, createErrors, setCreateErrors, setCreate } = useRollapp()
  const [status, setStatus] = useState<undefined | 'pending' | 'done'>()

  const onChange = (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target
    setCreate({ ...create, [name]: value })
    setCreateErrors({ ...createErrors, [name]: validateCreateValue(name, value) })
  }

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target
    if (files && files.length > 0) {
      const val = validateCreateFile(name, files, name === 'addition' || name === 'confirming')
      setCreate({ ...create, [name]: val.value })
      setCreateErrors({ ...createErrors, [name]: val.error })
    }
  }

  const clearImage = (name: 'file' | 'cover') => {
    setCreate({ ...create, [name]: null })
  }

  const submit = async (formData: FormData) => {
    const check = checkErrors(createErrors, form)
    if (create.file && !check && account) {
      setStatus('pending')
      const signature = await signMessage(EDIT_PROFILE(account, 'create'), account, library)
      const hash = await ipfsFileUpload(create.file)

      formData.append('formType', form)
      formData.append('ipfsHash', hash)
      formData.append('title', create.title)
      formData.append('description', create.description)
      formData.append('email', create.email)

      formData.append('requestMessage', create.request)

      try {
        await clientV3.token.create(account, signature, formData)
        setCreate(defaultValues)
        setCreateErrors(defaultErrors)
        setStatus('done')
      } catch (e) {
        console.error(e)
        setStatus(undefined)
      }
    }
  }

  return {
    clearImage,
    onChange,
    onChangeFile,
    submit,
    status,
    close: () => setStatus(undefined),
    create,
    createErrors
  }
}
