import styled from 'styled-components'

export const SearchSideText = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: var(--text-gray);
  & > span {
    color: var(--text-dark);
  }
  @media (max-width: 916px) {
    padding-bottom: 32px;
  }
`
