import styled from 'styled-components'

export const SwitchLabel = styled.label`
  display: inline-block;
  border-radius: 16px;
  width: 51px;
  height: 31px;
  background: var(--light-gray);
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s background-color;

    &:before {
      content: '';
      border-radius: 16px;
      position: absolute;
      height: 27px;
      width: 27px;
      top: 2px;
      left: 2px;
      background-color: var(--white);
      transition: 0.5s left;
    }
  }
`

export const SwitchInput = styled.input`
  appearance: none;
  &:checked + span {
    background: var(--blue);
  }
  &:checked + span:before {
    left: 22px;
  }
  &:disabled + span {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
