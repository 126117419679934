import { useWeb3React } from '@web3-react/core'
import React, { useState } from 'react'

import { ADDRESS_NULL, isOwned, ListProfile, OrderApi, Token, UserData } from '../../../domain'
import { DEFAULT_PROFILE } from '../../../domain/profile/constants'
import { AcceptModal } from '../../../ui/modals/token/Accept'
import { getOfferBid } from '../../../utils/ownerships'
import { TokenUser } from '../TokenUser'
import { Wrapper } from './styles'

export const Bids = ({
  offers,
  list,
  token
}: {
  offers: OrderApi[]
  list: ListProfile[]
  token: Token
}): JSX.Element => {
  const { account, chainId, library } = useWeb3React()
  const [curOffer, setCurOffer] = useState<OrderApi | undefined>()

  const getUserData = (offer: OrderApi): UserData => ({
    profile:
      list.filter(
        (profile) => offer.maker.toLowerCase() === profile.walletAddress.toLowerCase()
      )[0] || DEFAULT_PROFILE(offer.maker),
    info: getOfferBid(offer)
  })

  return (
    <>
      {offers.length > 0
        ? offers.map(
            (offer) =>
              offer.taker === ADDRESS_NULL && (
                <Wrapper key={offer.hash}>
                  <TokenUser profile={getUserData(offer).profile} info={getUserData(offer).info} />
                  {isOwned(token, account) &&
                    offer.status === 'ACTIVE' &&
                    offer.maker.toLowerCase() !== account?.toLowerCase() && (
                      <button className="button primary-button" onClick={() => setCurOffer(offer)}>
                        Accept
                      </button>
                    )}
                </Wrapper>
              )
          )
        : 'No data'}
      {account && chainId && curOffer && (
        <AcceptModal
          token={token}
          web3={{ account, chainId, library }}
          offer={curOffer}
          cancel={() => setCurOffer(undefined)}
        />
      )}
    </>
  )
}
