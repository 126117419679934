import { useEffect, useState } from 'react'

import { allowance } from '../contracts/erc20/methods'
import { Web3Type } from '../types/web3'

export const useERC20Allowance = (web3: Web3Type, amount: PriceBN): boolean => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (amount === '0') {
      setValue(false)
    } else {
      allowance(web3, amount).then((res) => {
        setValue(res)
      })
    }
  }, [amount, web3])

  return value
}
