import React, { useEffect, useState } from 'react'

import { Token } from '../../../domain'
import { getTokenLink } from '../../../utils/getTokenLink'
import { TokenImage } from '../../common/TokenImage'
import { PlaceHolderLoading } from '../PlaceHolderLoading'
import { Blur, CardLink, ImageWrap } from './styles'

export const CardImage = ({ token }: { token: Token }): JSX.Element => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (token.videoUrl) {
      setLoaded(true)
    } else {
      const img = new Image()
      if (token.imageS3Url || token.imageUrl) {
        img.src = token.imageS3Url || token.imageUrl
        img.onload = () => {
          // when it finishes loading, update the component state
          setLoaded(true)
        }
      }
      return () => {
        img.onload = null
      }
    }
    // eslint-disable-next-line
  }, [])
  return (
    <CardLink to={getTokenLink(token)}>
      <ImageWrap blur={token.blur}>
        {!!token.blur && <Blur>{token.blur}</Blur>}
        {loaded ? (
          <div className="image">
            <TokenImage token={token} />
          </div>
        ) : (
          <PlaceHolderLoading height="100%" />
        )}
      </ImageWrap>
    </CardLink>
  )
}
