import { useWeb3React } from '@web3-react/core'
import React from 'react'

import { useRollapp } from '../../../hooks/useRollapp'
import { Web3Type } from '../../../types/web3'
import { AddFundsModal, BuyCGTModal } from '../../modals'
import { AccountModals } from './AccountModals'
import { TokenModals } from './TokenModals'

export const Modals = (): JSX.Element => {
  const { account, chainId, library } = useWeb3React()
  const { modal, token } = useRollapp()

  const web3 = (account: string, chainId: number): Web3Type => ({
    account,
    chainId,
    library
  })

  return (
    <>
      {modal === 'buy-cgt' && <BuyCGTModal />}
      {modal === 'add-funds' && <AddFundsModal />}
      {account && chainId && !!modal && <AccountModals web3={web3(account, chainId)} />}
      {account && chainId && !!modal && !!token && (
        <TokenModals web3={web3(account, chainId)} token={token} />
      )}
    </>
  )
}
