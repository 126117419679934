import { useEffect, useState } from 'react'

import { Collection, PreviewProfile, Token } from '../domain'
import { clientV3 } from '../services/api'

export const useSearch = (
  query: string
): { users: PreviewProfile[]; tokens: Token[]; collections: Collection[] } | undefined => {
  const [users, setUsers] = useState<PreviewProfile[]>()
  const [tokens, setTokens] = useState<Token[]>()
  const [collections, setCollections] = useState<Collection[]>()

  useEffect(() => {
    setUsers(undefined)
    setTokens(undefined)
    setCollections(undefined)
    const delay = setTimeout(() => {
      if (query.length > 0) {
        clientV3.search.all(query).then((res) => {
          setUsers(res.data.users.items)
          setTokens(res.data.tokens.items)
          setCollections(res.data.collections.items)
        })
      }
    }, 1000)
    return () => clearTimeout(delay)
  }, [query])

  return users && tokens && collections ? { users, tokens, collections } : undefined
}
