import React from 'react'

import { Collection } from '../../../config/hot-collection'
import { CardText, StyledLink } from './styles'

export const CollectionCard = ({ item }: { item: Collection }): JSX.Element => {
  const background = item.background ? item.background : 'none'

  return (
    <StyledLink to={item.link || ''} style={{ backgroundImage: background }}>
      <CardText dark={item.text === 'Super Mario Bros.'}>{item.text}</CardText>
      {item.video && (
        <video playsInline autoPlay controls={false} muted loop>
          <source src={item.video} type="video/mp4" />
        </video>
      )}
    </StyledLink>
  )
}
