import React, { useEffect, useState } from 'react'

import { Token } from '../../../domain'
import { Loader } from '../../../ui/components'
import { LikeButton } from '../../../ui/components/Like'
import { Popover } from '../../../ui/components/Popover'
import { TokenMenu } from '../../../ui/components/TokenMenu'
import { ZoomIn } from '../ZoomIn'
import { ImageInfo, ImageInner, ImageRow, ImageWrapper, MoreButton } from './styles'
export const TokenImage = ({ token }: { token: Token }): JSX.Element => {
  const [zoomIn, setZoomIn] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [popup, setPopup] = useState(false)
  const src = token.imageS3Url // || token.imageUrl

  useEffect(() => {
    if (token.videoUrl) {
      setLoaded(true)
    } else {
      const img = new Image()
      if (src) {
        img.src = src
      }
      img.onload = () => {
        // when it finishes loading, update the component state
        setLoaded(true)
      }

      return () => {
        img.onload = null
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ImageWrapper>
        <ImageInfo>
          <ImageRow>
            <LikeButton token={token} />
            <MoreButton onClick={() => setPopup(!popup)} />
            {popup && (
              <Popover close={() => setPopup(false)}>
                <TokenMenu token={token} />
              </Popover>
            )}
          </ImageRow>
          <ImageInner onClick={() => setZoomIn(true)}>
            {token.videoUrl ? (
              <video loop autoPlay controls={false} playsInline muted>
                <source src={token.videoS3Url || token.videoUrl} type="video/mp4" />
              </video>
            ) : (
              <img
                src={token.imageS3Url || token.imageUrl}
                alt={`${token.name} token`}
                style={{ display: loaded ? 'block' : 'none' }}
              />
            )}
            {!loaded && <Loader center />}
          </ImageInner>
        </ImageInfo>
      </ImageWrapper>

      {zoomIn && <ZoomIn token={token} close={() => setZoomIn(false)} />}
    </>
  )
}
