import styled from 'styled-components'

import ethBg from '../assets/eth-bg.svg'
import sklBg from '../assets/skale-bg.svg'

export const NetworkCardsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 1024px) {
    max-width: 576px;
  }
`

export const NetworkCardsTop = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  & > .network-card:first-child {
    color: var(--white);
    background: url(${ethBg}) right center no-repeat,
      radial-gradient(88.33% 105.6% at 8.75% 11.67%, #54b7ff 3.12%, #3971ff 52.6%, #1e31db 100%);
    background-size: auto 100%;
  }
  & > .network-card:last-child {
    color: var(--white);
    background: url(${sklBg}) right center no-repeat,
      radial-gradient(88.33% 105.6% at 8.75% 11.67%, #6b6b6b 3.12%, #464646 52.6%, #0c0c0c 100%);
    background-size: auto 100%;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const NetworkCardWrapper = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 24px;
  display: flex;
  justify-content: left;
  padding: 20px 17px;
`

export const NetworkCardContent = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const CardTokenBox = styled.div`
  gap: 8px;
  padding-left: 3px;
  display: flex;
  align-items: center;
`

export const NetworkIcon = styled.div`
  width: 100%;
  max-width: 40px;
  height: 40px;
  & > img {
    width: 100%;
    height: auto;
  }
`

export const NetworkName = styled.p`
  font-size: 20px;
`

export const NetworkStatus = styled.p`
  font-size: 28px;
`

export const NetCardsBottom = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  & > .network-card:first-child {
    color: var(--white);
    background: radial-gradient(
      88.33% 105.6% at 8.75% 11.67%,
      #c7ffa2 3.12%,
      #8fd460 52.6%,
      #1ec575 100%
    );
  }
  & > .network-card:nth-child(2) {
    color: var(--dark-gray);
    background: radial-gradient(
      88.33% 105.6% at 8.75% 11.67%,
      #f7f7f7 3.12%,
      #f0f0f0 52.6%,
      #c9c9c9 100%
    );
  }
  & > .network-card:nth-child(3) {
    color: var(--white);
    background: radial-gradient(
      88.33% 105.6% at 8.75% 11.67%,
      #ffe8f4 3.12%,
      #ff99ce 52.6%,
      #df2e8a 100%
    );
  }
  & > .network-card:last-child {
    color: var(--white);
    background: radial-gradient(
      88.33% 105.6% at 8.75% 11.67%,
      #b5dffc 3.12%,
      #5961e2 52.6%,
      #1823af 100%
    );
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
