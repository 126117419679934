import React from 'react'

import { Collection, standardUi } from '../../../domain'
import { getNetworkIcon } from '../../../domain/network/mappers'
import { getCollectionLink } from '../../../utils/getProfileLink'
import { Avatar } from '../Avatar'
import { NetworkBadge } from '../NetworkBadge'
import { CardAvatar, CardBottom, CardImage, CardInfo, CardTitle, MiniStyled, Wrap } from './styles'

export const MiniCard = ({ item }: { item: Collection }): JSX.Element => {
  return (
    <MiniStyled to={getCollectionLink(item)}>
      <Wrap>
        <NetworkBadge networks={[getNetworkIcon(item.network)]} />
        <CardImage bg={item.coverS3Link || item.coverIpfsHash} />
        <CardBottom>
          <CardAvatar>
            <Avatar border="white" image={item.avatarS3Link || item.avatarIpfsHash} width="72px" />
          </CardAvatar>
          <CardTitle title={item.name}>{item.name}</CardTitle>
          <CardInfo>{standardUi(item.tokenStandards)}</CardInfo>
        </CardBottom>
      </Wrap>
    </MiniStyled>
  )
}
