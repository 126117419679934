import React, { ChangeEvent, useState } from 'react'

import { moveToNetwork } from '../../../../contracts/bridge'
import { approveHandle } from '../../../../contracts/erc721/methods'
import { getOwnerByAddress, Token } from '../../../../domain'
import { isSkaleNetwork } from '../../../../domain/network/mappers'
import { useERC721Allowance } from '../../../../hooks/useERC721Allowance'
import { validate } from '../../../../services/validate-form'
import { Web3Type } from '../../../../types/web3'
import { CancelBtn } from '../../../components/CancelBtn'
import { Input, InputContainer } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import { Description } from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'

export const Action = ({
  token,
  web3,
  title
}: {
  token: Token
  web3: Web3Type
  title: string
}): JSX.Element => {
  const [quantity, setQuantity] = useState('1')
  const [error, setError] = useState<string | null>(null)
  const [approved, setApproved] = useState(false)

  const allowance = useERC721Allowance(web3, token, 'move')

  const owner = getOwnerByAddress(web3.account, token.ownerships)
  const available = owner.amount.toString()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const error = validate(['required', 'number'], value)
    setError(error)
    setQuantity(value)
    if (error === null) {
      setError(
        parseInt(value) > parseInt(available) ? `Value must be less than ${available}` : null
      )
    }
  }

  return (
    <>
      <Description>
        You can transfer NFTs from the{' '}
        {isSkaleNetwork(token.collection.network) ? 'Skale' : 'Ethereum'} network to the{' '}
        {isSkaleNetwork(token.collection.network) ? 'Ethereum' : 'Skale'} network for gasless
        transactions. This action takes 3-10 minutes.
      </Description>
      {token.standard === 'ERC1155' && (
        <LabelWrapper title="Quantity" error={error}>
          <InputContainer>
            <Input type="text" value={quantity} onChange={onChange} disabled={available === '1'} />
          </InputContainer>
          <small>Enter quantity. {available} available</small>
        </LabelWrapper>
      )}
      <StatusBlock
        name="Approve token"
        method={async () => {
          const res = await approveHandle(web3, token, 'move')
          setApproved(res)
          return res
        }}
        check={allowance}
      />
      <StatusBlock
        name={title}
        method={() => moveToNetwork(web3, token, quantity)}
        disabled={!allowance && !approved}
      />
      <CancelBtn />
    </>
  )
}
