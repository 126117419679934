import styled from 'styled-components'

import { Close } from '../../../common/Modal/styles'

export const UploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--white);
  position: relative;
  min-height: 140px;
  max-height: 334px;
  padding: 17px;
  text-align: center;

  input {
    position: absolute;
    left: -99999rem;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const Text = styled.span`
  display: block;
  color: var(--text-gray);
  margin-bottom: 10px;
`

export const Image = styled.div`
  border-radius: 16px;
  overflow: hidden;
  max-width: 300px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    vertical-align: middle;
  }

  video {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
    vertical-align: middle;
  }
`

export const CloseBtn = styled(Close)`
  position: absolute;
  z-index: 2;
  top: 16px;
  right: 16px;
`

export const StyledSpan = styled.p`
  margin-bottom: 9px;
`
