import styled from 'styled-components'

import clubBG from './assets/club-cur-bg-f.svg'
import davos from './assets/davos-bg.svg'
import s3 from './assets/season3-bg.svg'
import skaleBG from './assets/skale-bg-f.svg'

export const Wrapper = styled.div`
  margin-top: 34px;
  padding-bottom: 120px;
  @media screen and (max-width: 916px) {
    padding-bottom: 102px;
    margin-top: 42px;
  }
  @media screen and (max-width: 475px) {
    padding-bottom: 28px;
    margin-top: 36px;
  }
`

export const BlocksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  max-width: 1176px;
  margin: 0 auto;
  & .right {
    justify-content: flex-end;
  }
  & .fourth .black-text {
    color: var(--text-dark);
  }
  & .first {
    min-height: 420px;
    background-color: var(--violet-bg);
    background-image: url(${s3});
    background-repeat: no-repeat;
    background-position: 7px bottom;
    background-size: 45% auto;
  }
  & .second {
    min-height: 321px;
    background-color: var(--black);
    background-image: url(${skaleBG});
    background-repeat: no-repeat;
    background-position: calc(100% - 54px) center;
    background-size: 39% auto;
  }
  & .third {
    min-height: 321px;
    background-color: var(--green-bg);
    background-image: url(${clubBG});
    background-repeat: no-repeat;
    background-position: 46px center;
    background-size: 34% auto;
  }
  & .fourth {
    min-height: 420px;
    background-color: var(--yellow-bg);
    justify-content: space-between;
    padding: 32px 74px 32px 98px;
    gap: 36px;
  }
  & .fifth {
    min-height: 321px;
    background-color: var(--blue-bg);
    background-image: url(${davos});
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 51% auto;
  }
  @media screen and (max-width: 1216px) {
    & .first {
      min-height: 321px;
      background-size: 43% auto;
    }
    & .fourth {
      padding: 28px 52px 28px 48px;
      gap: 38px;
    }
  }
  @media screen and (max-width: 916px) {
    gap: 53px;
    & .right {
      justify-content: flex-start;
    }
    & .first {
      min-height: 725px;
      background-size: 535px auto;
      background-position: center bottom;
    }
    & .second {
      min-height: 665px;
      background-position: center bottom;
      background-size: 454px auto;
    }
    & .third {
      min-height: 665px;
      background-position: center bottom;
      background-size: 403px auto;
    }
    & .fourth {
      min-height: 665px;
      flex-direction: column;
      padding: 24px 24px 24px 24px;
    }
    & .fifth {
      min-height: 592px;
      background-position: -7px bottom;
      background-size: 113% auto;
    }
  }
  @media screen and (max-width: 475px) {
    gap: 36px;
    & .first {
      min-height: 585px;
      // min-height: 540px;
      background-size: 93% auto;
      padding: 24px 24px 280px;
    }
    & .second {
      min-height: 549px;
      background-size: 84% auto;
      padding: 24px 24px 250px;
    }
    & .third {
      min-height: 600px;
      background-size: 84% auto;
      padding: 24px 24px 280px;
    }
    & .fourth {
      min-height: 549px;
      gap: 48px;
    }
    & .fifth {
      min-height: 530px;
      padding: 24px 24px 165px;
    }
  }
  @media screen and (max-width: 320px) {
    & .first {
      padding: 24px 24px 220px;
    }
  }
`

export const BLockCard = styled.div`
  border-radius: 32px;
  width: 100%;
  display: flex;
  padding: 32px 98px;
  align-items: center;
  @media screen and (max-width: 1216px) {
    padding: 28px 48px;
  }
  @media screen and (max-width: 916px) {
    align-items: flex-start;
    padding: 24px 24px;
  }
`

export const BlockContent = styled.div`
  max-width: 378px;
  width: 100%;
  @media screen and (max-width: 916px) {
    max-width: 768px;
  }
`

export const BlockTitle = styled.h2`
  font-size: 36px;
  line-height: 48px;
  color: var(--white);
  margin-bottom: 12px;
  @media screen and (max-width: 475px) {
    font-size: 28px;
    line-height: 36px;
  }
`

export const BlockDate = styled.p`
  color: var(--white);
  margin-bottom: 12px;
`

export const BlockText = styled.p`
  color: var(--white);
  & .yellow-link {
    color: var(--yellow-link);
  }
  & .blue-link {
    color: var(--blue-bg);
  }
  & a:hover {
    text-decoration: underline;
  }
`

export const LowerBox = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: fit-content;
  margin-top: 32px;
`

export const Values = styled.div`
  color: var(--white);

  & > span {
    font-size: 32px;
    line-height: 24px;
    display: inline-block;
  }
`

export const GroupCards = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
  width: fit-content;
  & > div:first-child {
    transform: rotate(-15deg) translate(0px, 38px);
  }
  & > div:first-child .numbers {
    width: 73px;
    height: 73px;
    transform: translate(50%, -50%);
    bottom: unset;
    top: 0;
  }
  & > div:first-child .numbers span {
    font-size: 36px;
    line-height: 48px;
  }
  @media screen and (max-width: 916px) {
    padding-bottom: 46px;
    margin: 0 auto;
  }
  @media screen and (max-width: 475px) {
    gap: 26px;
    padding-bottom: 0;
    padding-right: 12px;
    & > div:first-child {
      transform: rotate(-15deg) translate(0px, -2px);
    }
    & > div:first-child .numbers {
      width: 32px;
      height: 32px;
    }
    & > div:first-child .numbers span {
      font-size: 15px;
      line-height: 21px;
    }
  }
`

export const MiniCard = styled.div`
  width: 100%;
  max-width: 220px;
  padding: 10px 10px 40px;
  background: var(--white);
  border-radius: 30px;
  position: relative;
  transform: rotate(15deg) translate(0px, -38px);
  @media screen and (max-width: 660px) {
    max-width: 160px;
  }
  @media screen and (max-width: 540px) {
    max-width: 140px;
  }
  @media screen and (max-width: 475px) {
    padding: 5px 5px 18px;
    max-width: 150px;
    border-radius: 12px;
    transform: rotate(15deg) translate(0px, -26px);
  }
`

export const VideoContent = styled.div`
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 475px) {
    border-radius: 8px;
  }
`

export const FirstContentGlance = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    71.55% 89.77% at 9.84% 9.9%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 62.5%
  );
`

export const SecondContentGlance = styled(FirstContentGlance)`
  background: radial-gradient(
    84.51% 44.19% at -14.15% 46.6%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 78.13%
  );
`

export const ValuesBox = styled.div`
  position: absolute;
  right: 0;
  transform: translate(50%, 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background: var(--red-bg);
  bottom: 0;
  & span {
    font-weight: 700;
    font-size: 45px;
    line-height: 60px;
    color: var(--white);
  }
  @media screen and (max-width: 475px) {
    width: 40px;
    height: 40px;
    & span {
      font-size: 19px;
      line-height: 26px;
    }
  }
`
