import { matchOrders } from '../../../contracts/exchange'
import { clientV3 } from '../../../services/api'
import { hashKey } from '../../../services/order/generateHash'
import { Web3Type } from '../../../types/web3'
import { fromWei } from '../../../utils/wei'
import { Token } from '../../token'
import { SIGNATURE_NULL } from '../constant'
import { prepareExchangeOrder, prepareOrder } from '../getters/prepareOrder'
import { OrderApi } from '../types'

export const sellOrder = async (
  token: Token,
  offer: OrderApi,
  web3: Web3Type
): Promise<boolean> => {
  const offerExchange = prepareExchangeOrder(offer)
  const order = prepareOrder(
    token,
    web3.account,
    offer.maker,
    fromWei(offer.make.value),
    offer.take.value,
    web3.chainId
  )
  if (order === null) {
    return false
  }
  try {
    const orderExchange = prepareExchangeOrder(order)
    const hash = hashKey(orderExchange)

    const res = await matchOrders(web3, {
      sellOrder: orderExchange,
      sellSignature: SIGNATURE_NULL,
      buyOrder: offerExchange,
      buySignature: offer.signature
    })

    if (res) {
      await clientV3.exchange.create({
        ...order,
        hash: hash,
        signature: SIGNATURE_NULL,
        isAuction: false,
        network: token.collection.network
      })
    }

    return res
  } catch (e) {
    console.error(e)
    return false
  }
}
