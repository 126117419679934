import styled from 'styled-components'

export const Button = styled.button`
  padding: 0;
  display: flex;
  align-items: center;

  svg path {
    transition: 0.2s fill, 0.2s stroke;
  }

  &.liked svg path {
    fill: var(--red);
    stroke: var(--red);
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: var(--text-gray);
    display: block;
    margin-left: 8px;
  }
`
