import { CHAIN_ID } from '../../domain/network/contstant'

export const EXCHANGEV2 = {
  [CHAIN_ID.eth.mainnet]: '0xccac05541E7C9CE80E87954d6A1625B65eF72EbE',
  [CHAIN_ID.eth.testnet]: '0x2Df487b2AC5704CbAE6791A59184add2bC238B70',
  [CHAIN_ID.skale.testnet]: '0x85CD74602aA635B3a5602111cbF6e8fe4F819b73',
  [CHAIN_ID.skale.mainnet]: '0xd133236D4beA16bFC6EE6bD24F52846869cbb1D7',
  [CHAIN_ID.harmony.testnet]: '0x10547328149b30D29F0cd3B413054Ef647adF3d3',
  [CHAIN_ID.kovan.testnet]: '0x41B4d88641A72Fd18d8014b106A406ade37D67db'
}
