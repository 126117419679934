import { useWeb3React } from '@web3-react/core'
import React, { ChangeEvent, useState } from 'react'

import { Profile } from '../../domain'
import { clientV3 } from '../../services/api'
import { signMessage } from '../../services/sign'
import { EDIT_PROFILE } from '../../services/sign/messages'
import { checkErrors, validate } from '../../services/validate-form'
import { ErrorStatus } from '../../services/validate-form/types'
import { Input, InputContainer } from '../../ui/components/Form/Input'
import { LabelWrapper } from '../../ui/components/Form/Label/LabelWrapper'
import { SocialInput } from '../../ui/components/Form/SocialInput'
import { Textarea } from '../../ui/components/Form/Textares'
import { StatusModal } from '../../ui/modals/Status'
import { Email } from './Email'
import { GroupInputs, Main } from './styles'

type Errors = {
  web: ErrorStatus
  short: ErrorStatus
}

const Form = ({ profile }: { profile: Profile }): JSX.Element => {
  const { account, library } = useWeb3React()
  const [values, setValues] = useState<Profile>(profile)
  const [status, setStatus] = useState<string | undefined>(undefined)

  const getErrors = (): Errors => ({
    web: profile.social.web.full ? validate(['web'], profile.social.web.full) : null,
    short: profile.personal.url ? validate(['short_url'], profile.personal.url) : null
  })

  const [errors, setErrors] = useState<Errors>(getErrors())

  const defaultValue = (value: string | null) => (value ? value : '')

  const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setErrors({ web: null, short: null })
    if (name === 'twitter' || name === 'instagram') {
      setValues({ ...values, social: { ...values.social, [name]: value } })
    } else if (name === 'web') {
      setValues({
        ...values,
        social: { ...values.social, web: { ...values.social.web, full: value } }
      })
    } else {
      setValues({ ...values, personal: { ...values.personal, [name]: value } })
    }
  }

  const validateHandle = (): boolean => {
    const errs = {
      web: !!values.social.web.full ? validate(['web'], values.social.web.full) : null,
      short: !!values.personal.url ? validate(['short_url'], values.personal.url) : null
    }
    setErrors(errs)
    return checkErrors(errs)
  }

  const submit = async () => {
    if (account && !validateHandle()) {
      setStatus('pending')
      try {
        const message = EDIT_PROFILE(account, 'edit_profile')
        const signature = await signMessage(message, account, library)
        console.info(message, 'signature')
        const data = {
          displayName: values.personal.displayName,
          url: values.personal.url,
          bio: values.personal.bio,
          twitter: values.social.twitter,
          instagram: values.social.instagram,
          web: values.social.web.full
        }
        await clientV3.profile.edit.data(signature, account, data)
        setStatus(undefined)
      } catch (e) {
        setStatus('error')
      }
    }
  }

  return (
    <Main>
      <GroupInputs>
        <LabelWrapper title="Display name">
          <Input
            name="displayName"
            value={defaultValue(values.personal.displayName)}
            onChange={onChange}
          />
        </LabelWrapper>
        <LabelWrapper title="Custom url" error={errors.short}>
          <InputContainer>
            <div className="before">{window.location.hostname}/</div>
            <input name="url" value={defaultValue(values.personal.url)} onChange={onChange} />
          </InputContainer>
        </LabelWrapper>
        <Email />
      </GroupInputs>
      <LabelWrapper title="Bio">
        <Textarea
          name="bio"
          value={defaultValue(values.personal.bio)}
          onChange={onChange}
          rows={1}
        />
      </LabelWrapper>
      <GroupInputs>
        <LabelWrapper title="Twitter username">
          <SocialInput>
            <Input name="twitter" value={defaultValue(values.social.twitter)} onChange={onChange} />
          </SocialInput>
        </LabelWrapper>
        <LabelWrapper title="Instagram username">
          <SocialInput>
            <Input
              name="instagram"
              value={defaultValue(values.social.instagram)}
              onChange={onChange}
            />
          </SocialInput>
        </LabelWrapper>
        <LabelWrapper title="Personal site" error={errors.web}>
          <Input
            name="web"
            value={defaultValue(values.social.web.full)}
            placeholder="https://"
            onChange={onChange}
          />
        </LabelWrapper>
      </GroupInputs>
      <button className="button primary-button" type="button" onClick={submit}>
        Save changes
      </button>
      {status && (
        <StatusModal
          title="Update settings"
          status={status}
          method={submit}
          cancel={() => setStatus(undefined)}
        />
      )}
    </Main>
  )
}

export default Form
