import styled from 'styled-components'

export const CardsBlock = styled.div`
  width: 100%;
  display: flex;
  gap: 49px;
  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 768px) {
    max-width: 359px;
  }
`

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 359px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media screen and (max-width: 1024px) {
    width: calc(50% - 25px);
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardIcon = styled.div`
  width: 100%;
  max-width: 64px;
  & > img {
    width: 100%;
    height: auto;
  }
`

export const CardContent = styled.div`
  width: 100%;
  padding-left: 69px;
`

export const CardTitle = styled.h4`
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const CardText = styled.p`
  font-weight: 400;
  color: var(--text-light);
`
