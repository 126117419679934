import React from 'react'

import { Loader } from '../../components'
import { Notification } from '../../components/ModalStyles'

export const Status = ({
  status,
  waitlist
}: {
  status: string
  waitlist?: string
}): JSX.Element => (
  <>
    <Notification>
      {status === 'success'
        ? waitlist
          ? 'You have successfully been added to the waiting list'
          : 'You have successfully subscribed to RollApp updates'
        : status === 'warning'
        ? waitlist
          ? 'You have already added to waiting list'
          : 'You have already subscribed to RollApp updates'
        : status === 'error'
        ? 'You have an invalid email'
        : status === 'pending' && <Loader center />}
    </Notification>
  </>
)
