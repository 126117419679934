import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { BackLink } from '../../ui/components/BackLink'
import { NotFoundPage } from '../NotFound'
import { DAVOS_CONTENT } from './content'
import { Marked } from './Marked'
import { DavosContainer, Grid, Text } from './styled'
import { Top } from './Top'

export const DavosPage = (): JSX.Element => {
  const history = useHistory()
  const { page } = useParams<{ page: string }>()

  const content = DAVOS_CONTENT[page]

  return content ? (
    <DavosContainer className="container">
      <BackLink onClick={() => history.push('/davos')}>Back</BackLink>
      <Top content={content} />
      <Grid>
        <Text>
          {content.about && (
            <div>
              <h2 className="h2">About</h2>
              <p>{content.about}</p>
            </div>
          )}
          {content.other}
        </Text>
        {content.marked && <Marked text={content.marked} />}
      </Grid>
    </DavosContainer>
  ) : (
    <NotFoundPage />
  )
}
