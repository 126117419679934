import styled from 'styled-components'

import arrow from '../../../../assets/img/icons/arrow-menu.svg'
import smile from '../../../../assets/img/icons/smile.svg'

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 75px);
  width: 100%;
  justify-content: flex-end;
  position: relative;
`

export const ChatUp = styled.div`
  border-radius: 50%;
  border: 1px solid var(--line-day);
  background: var(--white) url(${arrow}) no-repeat center;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  transform: rotate(90deg);
`

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  &::-webkit-scrollbar {
    width: 0;
  }
`

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  flex-shrink: 0;
`

export const MessagerBottom = styled.div`
  padding: 26px 25px;
  border-top: 1px solid var(--line-day);
  width: 100%;
`

export const MessagerField = styled.form`
  display: grid;
  grid-template-columns: 1fr 22px;
  gap: 12px;
  align-items: center;
  & input {
    border: none;
    background: none;
    width: 100%;
  }
  & input::placeholder {
    color: var(--text-gray);
  }
`

export const SendButton = styled.button`
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 0px;
  & svg {
    fill: var(--dark-blue);
    width: 22px;
  }
`

export const EmojiButton = styled.button`
  width: 100%;
  height: 22px;
  background: url(${smile}) center no-repeat;
`

export const StartChatWindow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const StartWindowDescription = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-gray);
`

export const StartWindowGreetingsButton = styled.button`
  width: 60px;
  padding: 0;
`
