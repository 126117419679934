import 'swiper/modules/grid/grid.min.css'
import 'swiper/swiper.min.css'
import './swiper.css'

import React from 'react'
import { Grid, Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

import { Collection } from '../../../domain'
import { MiniCard } from '../../../ui/components/MiniCard'
import { Container } from './styles'

export const Carousel = ({ collections }: { collections: Collection[] }): JSX.Element => (
  <Container>
    <div className="button-prev" />
    <div className="button-next" />
    <Swiper
      direction="horizontal"
      spaceBetween={24}
      slidesPerView={1}
      slidesPerGroup={1}
      width={276}
      navigation={{
        nextEl: '.button-next',
        prevEl: '.button-prev'
      }}
      mousewheel={{
        forceToAxis: true
      }}
      modules={[Grid, Mousewheel, Navigation]}
      breakpoints={{
        577: {
          slidesPerGroup: 2,
          slidesPerView: 2,
          width: null
        },
        917: {
          slidesPerGroup: 3,
          slidesPerView: 3,
          grid: {
            rows: 2,
            fill: 'row'
          },
          width: null
        },
        1217: {
          slidesPerGroup: 4,
          slidesPerView: 4,
          grid: {
            rows: 2,
            fill: 'row'
          },
          width: null
        }
      }}
    >
      {collections.map((item) => (
        <SwiperSlide key={item.id}>
          <MiniCard item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  </Container>
)
