import React from 'react'

import { RatingUser } from '../../../domain'
import { PriceUI } from '../../../ui/common/PriceUI'
import { Avatar } from '../../../ui/components/Avatar'
import { shortAddress } from '../../../utils/shortAddress'
import { TopCardName, TopCardNumber, TopCardValue, TopCardWrap } from './styles'

type Props = {
  index: number
  user: RatingUser
}

export const TopCard = ({ index, user }: Props): JSX.Element => {
  return (
    <TopCardWrap to={`/profile/${user.url || user.userAddress}/owned`}>
      <TopCardNumber>{index}</TopCardNumber>
      <Avatar width="36px" image={user.imageS3Link ?? user.imageIpfsHash} />
      <div>
        <TopCardName>
          {user.displayName ? user.displayName : shortAddress(user.userAddress)}
        </TopCardName>
        <TopCardValue>
          <PriceUI value={user.value} />
        </TopCardValue>
      </div>
    </TopCardWrap>
  )
}
