export type MenuItem = {
  title: string
  link: string
  exact?: boolean
}

export const MAIN_MENU: MenuItem[] = [
  {
    title: 'Explore',
    link: '/explore',
    exact: true
  },
  {
    title: 'My items',
    link: `/my-items`,
    exact: true
  },
  {
    title: 'About',
    link: '/roadmap',
    exact: true
  },
  {
    title: 'DAO NFTs',
    link: '/dao-nfts/super-mario-bros',
    exact: false
  }
]
