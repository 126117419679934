import { useEffect, useState } from 'react'

import { clientV3 } from '../services/api'

export const useMovingCollection = (
  isOwned: boolean,
  network: Network,
  contractAddress: string
): boolean => {
  const [isMove, setIsMove] = useState(false)

  useEffect(() => {
    if (isOwned) {
      clientV3.tokenManagers.tethers(network, contractAddress).then((res) => {
        setIsMove(!!res.data.tetheredCollections[0])
      })
    }
  }, [contractAddress, network])

  return isMove
}
