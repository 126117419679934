import styled from 'styled-components'

import { Styles } from './types'

export const Wrapper = styled.div<Styles>`
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: ${(props) => (props.p ? props.p : '24px')};
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 18;
  margin-top: ${(props) => (props.top ? props.top : '10px')};
  ${(props) => `width: ${props.width};`}
  white-space: nowrap;

  @media screen and (max-width: 640px) {
    right: ${(props) => (props.fullWidth ? '20px' : '0')};
    width: ${(props) =>
      props.fullWidth ? 'calc(100vw - 40px)' : props.width ? props.width : 'auto'};
  }
`
