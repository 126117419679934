import React, { ChangeEvent, useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import deleteIcon from '../../../assets/img/icons/gray-close.svg'
import { useSearch } from '../../../hooks/useSearch'
import { validate } from '../../../services/validate-form'
import { Loader } from '../../components'
import { ResultCard } from '../../components/ResultCard'
import {
  ClearButton,
  LinkBlock,
  NoResults,
  ResultCards,
  ResultList,
  SearchBlock,
  SearchCloseButton,
  SearchForm,
  SearchInputBar,
  SearchResultBlock
} from './styled'

export const HeaderSearchBar = ({ close }: { close?: () => void }): JSX.Element => {
  const [query, setQuery] = useState('')
  const res = useSearch(query)
  const history = useHistory()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const error = validate(['string'], value)
    if (error === null) {
      setQuery(value)
    }
  }

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      history.push(`/search?query=${query}`)
      setQuery('')
    }
  }

  return (
    <SearchBlock>
      <SearchForm>
        <SearchCloseButton onClick={close} />
        <SearchInputBar
          name="search"
          type="text"
          value={query}
          placeholder="Search"
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
        />
        {query.length > 0 && (
          <ClearButton onClick={() => setQuery('')}>
            <img src={deleteIcon} />
          </ClearButton>
        )}
      </SearchForm>
      {query.length > 0 && (
        <SearchResultBlock>
          {res ? (
            <>
              <ResultList>
                <b>Items</b>
                <ResultCards>
                  {res.tokens.length > 0 ? (
                    res.tokens.map((token) => <ResultCard key={token.id} token={token} />)
                  ) : (
                    <NoResults>No results found</NoResults>
                  )}
                </ResultCards>
              </ResultList>
              <ResultList>
                <b>Users</b>
                <ResultCards>
                  {res.users.length > 0 ? (
                    res.users.map((user) => <ResultCard key={user.walletAddress} user={user} />)
                  ) : (
                    <NoResults>No results found</NoResults>
                  )}
                </ResultCards>
              </ResultList>
              <ResultList>
                <b>Collections</b>
                <ResultCards>
                  {res.collections.length > 0 ? (
                    res.collections.map((collection) => (
                      <ResultCard key={collection.ethAddress} collection={collection} />
                    ))
                  ) : (
                    <NoResults>No results found</NoResults>
                  )}
                </ResultCards>
              </ResultList>
              <LinkBlock>
                <Link to={`/search?query=${query}`} className="button block-button">
                  All results
                </Link>
              </LinkBlock>
            </>
          ) : (
            <Loader />
          )}
        </SearchResultBlock>
      )}
    </SearchBlock>
  )
}
