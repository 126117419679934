import React, { useState } from 'react'

import { Avatar } from '../../../../ui/components/Avatar'
import { IMessage } from '../../interfaces/IMessage'
import MessageOptions from '../MessageOptions/MessageOptions'
import {
  Cloud,
  MessageBlock,
  MessageButton,
  MessageDate,
  MessageTop,
  MessageWrapper
} from './styles'

export const MessageCloud = ({
  message,
  displayDate,
  displayAvatar,
  removeMsg
}: {
  message: IMessage
  displayDate: boolean
  displayAvatar: boolean
  removeMsg: (v: string) => void
}): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false)
  const now = new Date()
  const date = new Date(message.updatedAt)
  const min2 = 1000 * 60 * 2
  const isJustNow = now.getTime() - date.getTime() < min2

  return (
    <MessageBlock>
      <MessageWrapper out={message.fromMe}>
        <MessageTop out={message.fromMe}>
          {displayAvatar && !message.fromMe && (
            <Avatar width="48px" image={'message.avatarIpfsHash'} />
          )}
          <Cloud className="message-cloud">{message.message}</Cloud>
          <MessageButton className="menu-button" onClick={() => setShowOptions(!showOptions)} />
        </MessageTop>
        {displayDate && (
          <MessageDate>{isJustNow ? 'Just now' : date.toLocaleDateString('en-US')}</MessageDate>
        )}
        {showOptions && (
          <MessageOptions
            removeMsg={() => removeMsg(message.id)}
            out={message.fromMe}
            close={() => setShowOptions(false)}
          />
        )}
      </MessageWrapper>
    </MessageBlock>
  )
}
