import { Token } from '../../token'
import { BLOCKCHAIN_NAME, CHAIN_NAME, SUPPORT_CHAIN_ID, SUPPORT_CHAIN_NAME } from '../contstant'
import { NETWORK_ICONS } from '../contstant/icons'
import { AppNetwork } from '../types'

export const isSkaleNetwork = (network: string): boolean => network === SUPPORT_CHAIN_NAME.skale
export const isEthNetwork = (network: string): boolean => network === SUPPORT_CHAIN_NAME.eth
export const isHarmonyNetwork = (network: string): boolean => network === SUPPORT_CHAIN_NAME.harmony

export const isSkaleChainId = (chainId: number): boolean => chainId === SUPPORT_CHAIN_ID.skale

export const getAppNetworkByChainId = (chainId: number): AppNetwork => {
  const network = Object.keys(SUPPORT_CHAIN_ID).find((key) => SUPPORT_CHAIN_ID[key] === chainId)
  return (network as AppNetwork) || 'eth'
}

export const getAppNetworkByChainName = (network: Network): AppNetwork => {
  const key = Object.keys(SUPPORT_CHAIN_NAME).find((key) => SUPPORT_CHAIN_NAME[key] === network)
  return key as AppNetwork
}

export const getChainIdByChainName = (network: Network): number =>
  SUPPORT_CHAIN_ID[getAppNetworkByChainName(network)]

export const isMappingNetwork = (token: Token, chainId: number): boolean =>
  token.collection.network === CHAIN_NAME[chainId]

export const getBlockchain = (network: AppNetwork): string =>
  BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME[network]] || BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME.eth]

export const getNetworkIcon = (network?: Network): string => {
  if (network) {
    const key = getAppNetworkByChainName(network)
    return NETWORK_ICONS[key]
  }
  return NETWORK_ICONS.eth
}

export const getMovingNetworkName = (network: Network): string =>
  isEthNetwork(network)
    ? BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME.skale]
    : BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME.eth]
