import Web3 from 'web3'

import { infuraProvider } from '../../config/infura'
import { Token } from '../../domain'
import { Web3Type } from '../../types/web3'
import { isInvestorAddressActive } from '../whitelist/methods'
import { ERC1155_ABI } from './abi'

export const safeTransferFrom = async (
  w: Web3Type,
  contract: ContractAddress,
  tokenId: TokenContractId,
  to: UserAddress,
  quantity: Quantity
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(ERC1155_ABI, contract).methods
      .safeTransferFrom(w.account, to, tokenId, quantity, '0x0')
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const isInvestorAddressActive1155 = async (
  w: Web3Type,
  contract: ContractAddress,
  address?: UserAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    const wlAddress = await new web3.eth.Contract(ERC1155_ABI, contract).methods
      .wlController()
      .call()
    return await isInvestorAddressActive(w, address || w.account, wlAddress)
  } catch (e) {
    console.error(e)
    return false
  }
}

export const balanceOfShares = async (token: Token, address: UserAddress): Promise<string> => {
  const web3 = new Web3(infuraProvider)
  return await new web3.eth.Contract(ERC1155_ABI, token.contract).methods
    .balanceOf(address, token.tokenId)
    .call()
}
