import React from 'react'

import { Loader } from '../Loader'
import { Wrapper } from './styles'

export const Loading = (): JSX.Element => {
  return (
    <Wrapper>
      <Loader width={24} bg="gray" />
    </Wrapper>
  )
}
