import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ArrowButton } from '../../../ui/components/ArrowButton/styled'
import { NotFound } from '../../../ui/components/NotFound'
import { DAO_DATA } from '../data/data'
import { PageName } from '../slides/styled'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

const Buttons = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(2, 24px);
  grid-column-gap: 34px;
  height: 16px;
  margin-bottom: 32px;
  margin-top: -11px;
`

const Video = styled.video`
  width: 100%;
  height: auto;
`

export const SliderInner = styled.div`
  display: flex;
  width: 100%;
  transition: 0.4s transform;

  & > div {
    flex-shrink: 0;
    width: 100%;
  }

  & > .slide-media {
    height: calc(100vh - 330px);
  }

  & > .slide-media > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

const Slider = (props: { images: string[] }): JSX.Element => {
  const { images } = props
  const [slide, setSlide] = useState(images.length)
  const [transition, setTransition] = useState('0.4s transform')

  const slideHandle = (right?: boolean) => {
    const next = right ? slide + 1 : slide - 1
    const end = right ? images.length * 2 + 1 : 1
    if (next === end) {
      setSlide(end)
      setTimeout(() => {
        setTransition('none')
        setSlide(images.length + 1)
      }, 400)
    } else {
      setTransition('0.4s transform')
      setSlide(next)
    }
  }

  return (
    <>
      <PageName className="h2">Visuals</PageName>
      <Buttons>
        <ArrowButton height={16} onClick={() => slideHandle()} />
        <ArrowButton onClick={() => slideHandle(true)} height={16} right />
      </Buttons>
      <Container>
        <SliderInner
          style={{
            transition,
            transform: `translateX(-${slide * 100}%)`
          }}
        >
          {images.map((item) => (
            <div key={item} className="slide-media">
              {item.includes('.mp4') ? (
                <Video playsInline={slide !== 1} autoPlay muted loop>
                  <source src={item} type="video/mp4" />
                </Video>
              ) : (
                <img src={item} />
              )}
            </div>
          ))}
          {images.map((item) => (
            <div key={item} className="slide-media">
              {item.includes('.mp4') ? (
                <Video playsInline muted loop autoPlay controls={false}>
                  <source src={item} type="video/mp4" />
                </Video>
              ) : (
                <img src={item} />
              )}
            </div>
          ))}
          {slide > images.length * 2 - 2 &&
            images.map((item) => (
              <div key={item} className="slide-media">
                {item.includes('.mp4') ? (
                  <Video
                    playsInline={slide !== images.length * 2 + 1}
                    muted
                    loop
                    autoPlay
                    controls={false}
                  >
                    <source src={item} type="video/mp4" />
                  </Video>
                ) : (
                  <img src={item} />
                )}
              </div>
            ))}
        </SliderInner>
      </Container>
    </>
  )
}

const Visuals = (): JSX.Element => {
  const params = useParams<{ page: string }>()
  const { visuals } = DAO_DATA[params.page]
  return visuals && visuals.images ? <Slider images={visuals.images} /> : <NotFound />
}

export default Visuals
