import React from 'react'

import { isChainlinkToken, isNewCollection, ListProfile, Token } from '../../../domain'
import { isRoyalties } from '../../../domain/collection/mappers/royalties.map'
import { getProfilesList } from '../../../domain/profile/mappers/profiles'
import { ChainLinkPrice } from '../../../ui/components/ChainLinkPrice'
import { Actions } from '../Actions'
import { BidStatus } from '../BidInfo'
import { Description } from '../Description'
import { Details } from '../Details'
import { GetButton } from '../GetButton'
import { TokenUser } from '../TokenUser'
import { TokenUsers } from '../TokenUsers'
import { Extra, InfoBlock, InfoBlockInner, SaleInfo } from './styles'

export const Info = ({ token, list }: { token: Token; list: ListProfile[] }): JSX.Element => {
  const profiles = getProfilesList(token, list)

  return (
    <InfoBlock>
      <InfoBlockInner>
        <div>
          <h1 className="h2">
            {token.name}
            {isNewCollection(token) && <Extra>New</Extra>}
          </h1>
          <p style={{ marginBottom: 24 }}>
            {isChainlinkToken(token) && (
              <>
                Asset price: <ChainLinkPrice />
              </>
            )}
          </p>
          <SaleInfo>
            <BidStatus token={token} />
          </SaleInfo>
        </div>
        <Description description={token.description} />
        <GetButton token={token} />
        {isRoyalties(token) && (
          <div className="button_gradient">{isRoyalties(token)}% of sales will go to creator</div>
        )}
        <TokenUsers>
          {profiles.creator && <TokenUser profile={profiles.creator} info="Creator" />}
          {profiles.creator && <TokenUser profile={profiles.collection} info="Collection" />}
        </TokenUsers>
        <Details token={token} />
        <Actions token={token} />
      </InfoBlockInner>
    </InfoBlock>
  )
}
