import { CHAIN_ID } from '../../domain/network/contstant'

export const ASSET_WHITELIST_PROXY = {
  [CHAIN_ID.eth.mainnet]: '0x89F8357247e0EBeeFf4b8925232173b6B62625Ae',
  [CHAIN_ID.eth.testnet]: '0xed89b7b9AB37f054d8316F875ee03A65a4b95529',
  [CHAIN_ID.skale.testnet]: '0xCf65239F3622bCc03dB4Ea324EF00A2a1FA25989',
  [CHAIN_ID.skale.mainnet]: '0xA1CCbD08a1d881ea8Fe0AF852bE920521a80Caae',
  [CHAIN_ID.harmony.testnet]: '0x24E1809DD19CEf883f4fc8421c637A31FA9A91e3',
  [CHAIN_ID.kovan.testnet]: '0x0e4Ea478Bf92E00DbdaBfEA9B6D0608c94645aa5'
}
