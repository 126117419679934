import React from 'react'

import { Cards } from '../../ui/components/Cards'
import { Blockchains } from './Blockchains'
import { Categories } from './Categories'
import { Collections } from './Collections'
import { HotCollections } from './HotCollections'
import { CategoriesBox, ExploreContainer, Title, TopsSection } from './styles'
import { TopBlock } from './TopBlock'

export const Explore = (): JSX.Element => {
  return (
    <ExploreContainer>
      <HotCollections />
      <TopsSection>
        <TopBlock title="Top sellers" />
        <TopBlock title="Top buyers" buyers />
      </TopsSection>
      <Collections />
      <section>
        <Title className="h2">
          <h2>Explore</h2>
          <CategoriesBox>
            <Categories />
            <Blockchains />
          </CategoriesBox>
        </Title>
        <Cards columns={4} />
      </section>
    </ExploreContainer>
  )
}
