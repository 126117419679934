import Web3 from 'web3'

import { Token } from '../../domain'
import { getOriginCollection } from '../../domain/collection/mappers/wrapped.mapper'
import { isSkaleNetwork } from '../../domain/network/mappers'
import { Web3Type } from '../../types/web3'
import { transferFrom } from '../erc721/methods'
import { WRAPPED_ABI } from './abi'
import { TEAM_ADDRESS } from './addresses'

export const redeemHandle = async (w: Web3Type, token: Token): Promise<string | null> => {
  const web3 = new Web3(w.library)
  const origin = getOriginCollection(token.contract, token.collection.network)
  if (origin) {
    try {
      if (!isSkaleNetwork(token.collection.network)) {
        await new web3.eth.Contract(WRAPPED_ABI, token.contract).methods
          .withdraw(token.tokenId)
          .send({ from: w.account })
      }
      return await transferFrom(w, origin, token.tokenId, TEAM_ADDRESS[w.chainId])
    } catch (e) {
      console.error(e)
      return null
    }
  }
  return null
}
