import React from 'react'

import { Star, Wrapper } from './styled'

export const Marked = ({ text }: { text: string }): JSX.Element => (
  <aside>
    <Wrapper>
      <Star />
      <p>{text}</p>
    </Wrapper>
  </aside>
)
