import { useEffect, useState } from 'react'
import React from 'react'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { DAO_DATA } from '../../data/data'
import { LinkLine, LinkLineBlock, LinkText, StyledLink, Wrapper } from './styles'

type Link = {
  link: string
  name: string
}

export const SideNavMenu = ({ tag }: { tag: string }): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const [links, setLinks] = useState<Link[]>([])

  const open = (link: string) => {
    // history.pushState({}, '', link)
    history.push(link)
  }

  const { pages } = DAO_DATA[tag]

  useEffect(() => {
    const arr: Link[] = []
    for (let i = 0; i < pages.length; i++) {
      arr.push({
        link: `#${pages[i]}`,
        name: `${
          pages[i] === 'info' ? 'Information' : pages[i].charAt(0).toUpperCase() + pages[i].slice(1)
        }`
      })
    }
    setLinks(arr)
  }, [pages])

  return (
    <Wrapper>
      {links.map((item) => (
        <StyledLink
          key={item.link}
          onClick={() => open(item.link)}
          className={
            (!!location.hash ? location.hash === item.link : item.link === '#info') ? 'active' : ''
          }
        >
          <LinkText>{item.name}</LinkText>
          <LinkLineBlock>
            <LinkLine />
          </LinkLineBlock>
        </StyledLink>
      ))}
    </Wrapper>
  )
}
