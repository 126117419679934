import React from 'react'

import { Token } from '../../../domain'
import { isMappingNetwork } from '../../../domain/network/mappers'
import { useRollapp } from '../../../hooks/useRollapp'
import { Web3Type } from '../../../types/web3'
import { ClaimRewardsModal } from '../../modals/mercedes/ClaimRewards'
import { StakeShares } from '../../modals/mercedes/StakeShares'
import { CancelBidModal } from '../../modals/token/CancelBid'
import { ChangePriceModal } from '../../modals/token/ChangePrice'
import { CheckoutModal } from '../../modals/token/Checkout'
import { EditBidModal } from '../../modals/token/EditBid'
import { GetBonusModal } from '../../modals/token/GetBonus'
import { GetETCPunkNFT } from '../../modals/token/GetETCPunkNFT'
import { GetNFTPrizeModal } from '../../modals/token/GetNFTPrize'
import { GetNFTsPackModal } from '../../modals/token/GetNFTsPack'
import { MigrateModal } from '../../modals/token/Migrate'
import { MoveToNetwork } from '../../modals/token/MoveToNetwork'
import { PlaceBidModal } from '../../modals/token/PlaceBid'
import { PutOnSaleModal } from '../../modals/token/PutOnSale'
import { RedeemModal } from '../../modals/token/Redeem'
import { RemoveFromSaleModal } from '../../modals/token/RemoveFromSale'
import { TransferModal } from '../../modals/token/Transfer'
import { WrongNetwork } from '../../modals/WrongNetwork'

export const TokenModals = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const { modal, taker } = useRollapp()
  return isMappingNetwork(token, web3.chainId) ? (
    <>
      {modal === 'redeem' && <RedeemModal web3={web3} token={token} />}
      {modal === 'place-bid' && <PlaceBidModal web3={web3} token={token} />}
      {modal === 'edit-bid' && <EditBidModal web3={web3} token={token} />}
      {modal === 'cancel-bid' && <CancelBidModal web3={web3} token={token} />}
      {modal === 'transfer' && <TransferModal web3={web3} token={token} />}
      {modal === 'migrate' && <MigrateModal web3={web3} token={token} />}
      {modal === 'stake-shares' && <StakeShares web3={web3} token={token} />}
      {modal === 'unstake-shares' && <StakeShares web3={web3} token={token} unstake />}
      {modal === 'put-on-sale' && <PutOnSaleModal web3={web3} token={token} />}
      {modal === 'remove-from-sale' && <RemoveFromSaleModal web3={web3} token={token} />}
      {modal === 'change-price' && <ChangePriceModal web3={web3} token={token} />}
      {modal === 'checkout' && taker && <CheckoutModal web3={web3} token={token} taker={taker} />}
      {modal === 'move-to-network' && <MoveToNetwork web3={web3} token={token} />}
      {modal === 'claim-rewards' && <ClaimRewardsModal web3={web3} />}
      {modal === 'get-bonus' && <GetBonusModal web3={web3} token={token} />}
      {modal === 'get-etc-punks' && <GetETCPunkNFT token={token} />}
      {modal === 'get-nfts-pack' && <GetNFTsPackModal />}
      {modal === 'get-nft-prize' && <GetNFTPrizeModal web3={web3} token={token} />}
    </>
  ) : (
    <WrongNetwork token={token} />
  )
}
