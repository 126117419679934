import { useEffect, useState } from 'react'

import { getGweiPrice } from '../services/gweiPrice'

export const useWeiPrice = (): string => {
  const [price, setPrice] = useState('0')

  useEffect(() => {
    getGweiPrice().then((res) => {
      setPrice(String(BigInt(res.data.average * 1000000000)))
    })
  }, [])

  return price
}
