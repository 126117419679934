import React from 'react'

import { Claimed } from '../../../../contracts/nft-lottery/types'
import { Description } from '../../../components/ModalStyles'

export const WinText = ({ claimed }: { claimed: Claimed }): JSX.Element => (
  <Description>
    This Founders Edition NFT participated in the lottery and won the prize NFT.{' '}
    <a href={`/token/${claimed.reward.collectionAddress}:${claimed.reward.tokenId}?network=skale`}>
      View prize
    </a>
  </Description>
)
