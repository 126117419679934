import React, { ChangeEvent, useState } from 'react'

import { redeemHandle } from '../../../../contracts/redeem/methods'
import { Token } from '../../../../domain'
import { isSkaleNetwork } from '../../../../domain/network/mappers'
import { useWhitelist } from '../../../../hooks/useWhitelist'
import { clientV3 } from '../../../../services/api'
import { checkErrors, validate } from '../../../../services/validate-form'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Input } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import { Description, Wrapper } from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'
import { initialErrors, InitialValues, rules } from './constants'

export const RedeemModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const whitelisted = useWhitelist()

  const [values, setValues] = useState(InitialValues)
  const [errors, setErrors] = useState(initialErrors)

  const transfer = async (): Promise<boolean> => {
    const transactionHash = await redeemHandle(web3, token)
    if (transactionHash) {
      setValues({ ...values, transactionHash })
    }
    return !!transactionHash
  }

  const send = async (): Promise<boolean> => {
    try {
      await clientV3.token.redeem(token.id, values)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setValues({ ...values, [name]: value })
    const error = validate(rules[name], value)
    setErrors({ ...errors, [name]: error })
  }

  return (
    <Modal title="Redeem" width="400px">
      <>
        {whitelisted ? (
          <Wrapper>
            <LabelWrapper title="Email" error={errors.email}>
              <Input
                onChange={handleInputChange}
                value={values.email}
                name="email"
                type="email"
                placeholder="Email"
              />
            </LabelWrapper>
            <LabelWrapper title="Phone" error={errors.phone}>
              <Input
                onChange={handleInputChange}
                value={values.phone}
                name="phone"
                type="text"
                placeholder="Phone"
              />
            </LabelWrapper>
            <LabelWrapper title="First name" error={errors.firstName}>
              <Input
                onChange={handleInputChange}
                value={values.firstName}
                name="firstName"
                type="text"
                placeholder="First name"
              />
            </LabelWrapper>
            <LabelWrapper title="Last name" error={errors.lastName}>
              <Input
                onChange={handleInputChange}
                value={values.lastName}
                name="lastName"
                type="text"
                placeholder="Last name"
              />
            </LabelWrapper>
            <LabelWrapper title="Delivery address" error={errors.deliveryAddress}>
              <Input
                onChange={handleInputChange}
                value={values.deliveryAddress}
                name="deliveryAddress"
                type="text"
                placeholder="Delivery address"
              />
            </LabelWrapper>
            <LabelWrapper title="Transaction hash">
              <Input value={values.transactionHash} type="text" placeholder="Read only" disabled />
            </LabelWrapper>

            <StatusBlock
              name="Redeem"
              text={`Metamask will request ${
                isSkaleNetwork(token.collection.network)
                  ? 'a transfer transaction'
                  : '2 transactions'
              }`}
              method={transfer}
              disabled={checkErrors(errors)}
            />

            <StatusBlock
              name="Send Redeem form"
              text={!!values.transactionHash ? "Don't close your browser!" : ''}
              disabled={!values.transactionHash || checkErrors(errors)}
              method={send}
            />
          </Wrapper>
        ) : (
          <Description>
            You need to have an account at curioinvest.com and go through the KYC / AML procedure.{' '}
            <a href="https://curioinvest.com/register" target="_blank" rel="noreferrer">
              Go to CurioInvest
            </a>
          </Description>
        )}
      </>
    </Modal>
  )
}
