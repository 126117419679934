import React from 'react'

import { Footer } from '../../ui/common/Footer'
import { Confidence } from './Confidence'
import { CrossChainSection } from './CrossChainSection'
import { FAQSection } from './FAQSection'
import { FeaturedSection } from './FeaturedSection'
import { FormsSection } from './FormsSection'
import { HeroSection } from './HeroSection'
import { ItemsSection } from './ItemsSection'
import { LandingHeader } from './LandingHeader'
import { QuestionSection } from './QuestionsSection'
import { LandingContainer, WrapperPage } from './styled'
import { TableListSection } from './TableListSection'

export const LandingPage = (): JSX.Element => (
  <WrapperPage>
    <LandingHeader />
    <LandingContainer className="container">
      <HeroSection />
      <ItemsSection />
      <FeaturedSection />
      <FAQSection />
      <Confidence />
      <TableListSection />
      <CrossChainSection />
      <QuestionSection />
      <FormsSection />
    </LandingContainer>
    <Footer />
  </WrapperPage>
)
