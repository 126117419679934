import React from 'react'

import { stake } from '../../../contracts/stalking'
import { useCGTBalance } from '../../../hooks/useCGTBalance'
import { usePendingReward } from '../../../hooks/useStaking'
import { Web3Type } from '../../../types/web3'
import { toFixed } from '../../../utils/toFixed'
import { fromWei } from '../../../utils/wei'
import { Modal } from '../../common/Modal'
import { CancelBtn } from '../../components/CancelBtn'
import {
  Description,
  InfoRow,
  InfoType,
  InfoValue,
  ModalInfo,
  Wrapper
} from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const ClaimRewardsModal = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  // TODO trigger
  const claimable = usePendingReward(web3)
  const balance = useCGTBalance(web3)

  return (
    <Modal title="Claim rewards" width="350px">
      <Wrapper>
        <Description>Claim rewards in CGT from NFT staking.</Description>

        <ModalInfo>
          <InfoRow>
            <InfoType>Your balance</InfoType>
            {balance && <InfoValue>{toFixed(fromWei(balance))} CGT</InfoValue>}
          </InfoRow>

          <InfoRow>
            <InfoType>Claimable</InfoType>
            <InfoValue>{claimable} CGT</InfoValue>
          </InfoRow>
        </ModalInfo>

        <StatusBlock
          name="Claim rewards"
          text={`Claim ${claimable} CGT`}
          method={() => stake(web3, '0')}
          disabled={parseFloat(claimable) === 0}
        />

        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
