import React from 'react'

const Like = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3294 2.21808L14.4249 2.32302C15.1247 3.11125 15.5077 4.13081 15.4999 5.18489L15.4998 5.18489L15.4999 5.1923C15.5077 6.24558 15.1253 7.2644 14.4265 8.05238L8.03044 14.941L8.02182 14.9502L8.01369 14.9599C8.00987 14.9645 8.00514 14.9682 7.99979 14.9708C7.99704 14.9721 7.99415 14.9732 7.99119 14.9739C7.98847 14.9733 7.98581 14.9725 7.98326 14.9714L7.98325 14.9714C7.97812 14.9691 7.97349 14.9659 7.96967 14.9618C7.96967 14.9617 7.96966 14.9617 7.96965 14.9617L1.5736 8.07318C0.872163 7.28226 0.48957 6.25886 0.500216 5.20163L0.500293 5.20163L0.500228 5.19289C0.492419 4.13882 0.875405 3.11926 1.57519 2.33103L1.67073 2.22609L1.67084 2.22619L1.67881 2.21699C2.0009 1.84546 2.39908 1.5475 2.84637 1.34329C3.29367 1.13908 3.77963 1.03339 4.27134 1.03339C4.76305 1.03339 5.24901 1.13908 5.69631 1.34329C6.14361 1.5475 6.54179 1.84546 6.86387 2.21699L6.86375 2.2171L6.87248 2.22666L7.63086 3.05704L8.00183 3.46323L8.37086 3.05528L9.12924 2.21689L9.12932 2.21697L9.13624 2.20899C9.45832 1.83746 9.8565 1.5395 10.3038 1.33529C10.7511 1.13108 11.2371 1.02539 11.7288 1.02539C12.2205 1.02539 12.7064 1.13108 13.1537 1.33529C13.601 1.5395 13.9992 1.83746 14.3213 2.20899L14.3212 2.20909L14.3294 2.21808Z"
      stroke="#7B8BA1"
      fill="#fff"
    />
  </svg>
)

export default Like
