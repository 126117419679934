import { Profile, ProfileUi } from '../domain'

export const convertProfileUI = (profile: Profile): ProfileUi => ({
  avatarIpfsHash: profile.photo.avatar,
  avatarS3Link: profile.photo.avatarS3Link,
  coverIpfsHash: profile.photo.cover,
  coverS3Link: profile.photo.coverS3Link,
  walletAddress: profile.walletAddress,
  name: profile.personal.displayName,
  description: profile.personal.bio
})
