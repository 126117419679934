import styled from 'styled-components'

export const MessageWrapper = styled.div<{ top?: number; left?: string }>`
  position: absolute;
  background: linear-gradient(90deg, rgba(241, 239, 239, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  backdrop-filter: blur(20px);

  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  top: ${(props) => (props.top ? props.top : 0)}px;
  left: ${(props) => (props.left ? props.left : 'unset')};
  width: max-content;
  @media screen and (max-width: 740px) {
    display: none;
  }
`

export const OwnershipImage = styled.div`
  width: auto;
  height: auto;
  & img {
    width: auto;
    height: auto;
    vertical-align: middle;
  }
`

export const OwnersName = styled.p`
  font-weight: 700;
  color: var(--black);
`

export const OwnershipInfo = styled.p`
  font-weight: 400;
  color: var(--black);
`

export const OwnershipStatus = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--black);
`
