export default `
BEFORE MAKING ANY PURCHASE OF CURIO NFTS PLEASE READ THIS DOCUMENT CAREFULLY. THIS DOCUMENT DOES NOT REPLACE THE EXPERT ADVICE OF A BANKER, LAWYER OR TAX ADVISOR REGARDING PURCHASE OF CURIO NFTS. NO REPRESENTATION OR WARRANTY IS MADE AS TO WHETHER, OR THE EXTENT TO WHICH, THE CURIO NFTS PURCHASE IS LEGAL FOR TOKEN RECIPIENTS FROM COUNTRIES WHERE THOSE ACTIVITIES ARE RESTRICTED. 
CURIO NFTS ARE NOT INTENDED TO CONSTITUTE SECURITIES PURSUANT TO SWISS LAW. DEPENDING ON THE RESIDENCE OF THE TOKEN RECIPIENT, SUCH QUALIFICATION MAY BE ALTERED FUNDAMENTALLY. ANY TOKEN RECIPIENT IN PARTICULAR FROM OUTSIDE OF SWITZERLAND AND THE EUROPEAN UNION SHOULD CONSULT THEIR LEGAL ADVISORS REGARDING SUCH MATTERS PRIOR TO ANY CURIO NFTS PURCHASE.
THESE TERMS SHALL IN NO WAY BE CONSIDERED TO CONSTITUTE A PROSPECTUS, A SOLICITATION FOR INVESTMENTS IN TOKENS OR INVESTMENT ADVICE BY ANY MEANS.
DUE TO THE LEGAL NATURE OF TOKENS IN GENERAL THERE ARE CERTAIN RISKS ASSOCIATED WITH CURIO NFT HICH ARE DESCRIBED HEREIN. BY ACCEPTING THESE TERMS AND CONDITIONS, TOKEN RECIPIENTS AGREE TO BE PROPERLY INFORMED ABOUT ALL POSSIBLE RISKS ARISING FROM THIS TOKEN SALE. THESE TERMS MIGHT CONTAIN BINDING WAIVERS WHICH - IF APPLICABLE TO THE INDIVIDUAL TOKEN RECIPIENT MIGHT ACCET HIS LEGAL RIGHTS. IF THE TOKEN RECIPIENT DOES NOT AGREE TO THESE TERMS AS STATED BELOW, HE SHALL NOT PARTICIPATE IN THE TOKEN SALE.
BY ACCEPTING THESE TERMS OR RECEIVING ANY CURIO NFTS, YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS AND ACKNOWLEDGE THAT YOU HAVE CAREFULLY REVIEWED THESE TERMS AND FULLY UNDERSTAND THE RISKS, COSTS, AND BENEFITS OF PURCHASING CURIO NFTS. IF YOU ARE IN ANY DOUBT AS TO THE NATURE OF THIS TOKEN SALE, THE RISKS INVOLVED OR THE ACTION YOU SHOULD TAKE, YOU SHOULD CONSULT YOUR ADVISORS. IF YOU HAVE NOT READ OR DO NOT AGREE WITH THE TERMS STATED IN THIS DOCUMENT, DO NOT PURCHASE CURIO NFTS.

In case questions arise regarding these Terms respectively the Token Sale in general, feel free to contact the Token Issuer anytime by e-Mail at: 
**info@curioinvest.com**

Potential purchases of Curio NFTs should be conducted by individuals or legal entities that have profound experience with, and understanding of the usage and intricacies of distributed ledger technology, blockchain-based software systems and applications, including and in particular the Ethereum-Blockchain and -tokens. 
Even though Curio Capital AG will do its best to assist its customers where it will be necessary and asked for, it is not liable for any losses of tokens due to actions exclusively performed by the Token Recipient. It is therefore up to the sole discretion of the Token Recipients to assess their capability to be able to perform all steps necessary to receive the Curio NFTs. By accepting these Terms, the Token Recipient acknowledges to satisfy the aforementioned requirements.
As further described herein and permitted by Swiss law, by purchasing the Curio NFTs, the Token Recipient agrees to not hold the Token Issuer or its respective past, present, and future employees, officers, directors, contractors, consultants and/or equity holders liable for any losses or any special, incidental, or consequential damages arising from, or in any way connected to the Token Sale.

## Terms and Abbreviations

**Curio or Curio Capital AG** - Curio Capital AG, [CurioInvest.com] CHE- 211.446.654 is a fintech company with focus in “digital asset instruments”, incorporated in June 7th, 2018 and located in Aabachstrasse 8, 6300 Zug

**CGT** - Curio Governance Token also used as means of exchange at the Curio

**Curio NFT or Curio Token** - unique, non-fungible, Ethereum based ERC-721 compliant token, representing ownership rights to individual goods.

**Terms** - Present terms and conditions

**Token Holder or Token Recipient** - Person, which purchases Curio NFTs

**Token Issuer** - Curio Capital AG, which issues Curio NFTs both technically and legally

**Token Sale** - Present procedure of purchase and sale of Curio NFT

Considering the recitals above, the Parties agree as follows:

## I. Terms Acceptance
These Terms become effective by the time the Token Recipient checks the respective boxes concerning the acceptance of these Terms and by clicking the "Submit" button on the application page at <https://rollapp.store>.

Curio Capital AG has published profound information on itself, on the Curio NFTs on <https://rollapp.store/roadmap>.

## II. Introduction
This document contains relevant terms and conditions that govern the purchase and redemption of Curio NFTs. 
These Tokens are unique ERC-721 tokens programmed using smart contract systems and compatible with the Ethereum blockchain.
Issuer of the Curio NFTs is Curio Capital AG, [CurioInvest.com] CHE-211.446.654 is a fintech company with focus in “digital asset instruments”, incorporated in June 7th, 2018 and located in Aabachstrasse 8, 6300 Zug. 
Curio Capital AG is a tokenization launchpad and NFT marketplace. It is powered by its native CGT token. It is a utility token that serves as means of exchange to purchase Curio NFTs within the marketplace.

## III. Registration and Data Protection
By registration and participation in this Token Sale, the Token Recipient warrants to provide accurate, current and complete information about his identity, residence, source of funds and further information that is or might become relevant. The Token Issuer may demand additional information during the course of the Token Recipients ownership of Curio NFTs. 
In order to validate the information provided by the Token Recipient, Curio Capital AG may submit inquiries including but not limited to commercial databases, population registers or other data provided by governmental agencies or accredited data vendors.
Any data provided by the Token Recipient during or as part of the Token Sale will be processed and stored in accordance with the applicable Swiss data protection laws. Any personal data will not be processed in a way that is incompatible with these laws. 
Please refer to Curio Capital AG’s Privacy Policy for further details.

## IV. Curio NFT
Curio purchases collectable objects such as cars (Tokenisation Object), not requiring special permissions to distribute.
Curio thus is a sole owner of the Tokenisation Object before starting Token Sale.
Tokenisation Object will be stored at a specialized facility, with which Curio has a storage agreement. 
Then Curio creates Curio NFTs for this Object.
Curio NFTs are unique, non-fungible, Ethereum based ERC-721 compliant token. 
Tokenisation Object 1
Mercedes-AMG GTS (2015 year, with 30,000 mileage), with supposed market value CHF 100 000.00 (hundred thousand Swiss francs). 
Curio will issue 100 NFTs with a price of CHF 1000.00 (one thousand Swiss francs).  each.
Curio NFTs can be purchased for CGT as means of exchange on the marketplace.
Each token will represent 1/100 share of co-ownership right in the Tokenisation Object.
Curio NFTs do not have features of any securities pursuant to Swiss law, the provisions of MiFID II or European Union Directive 2015/2366 and thus cannot be qualified as such.
Also, co-ownership of an object does not fall under collective investment scheme under Swiss law.

In general Curio NFT is not redeemable. It is not possible to physically allocate and distract share of co-ownership right in the Tokenisation Object, unless the Token Recipient purchases all tokens issued for the respective Tokenisation Object.
Moreover, Token Recipient agrees to be bound by Usage and Administration Rules for all Token Holders.

An unlimited number of Curio NFTs can be issued by Curio Capital AG from time to time for new Tokenization Objects. 

## V. Eligibility for Token Sale
In order to participate in the Token Sale, the Token Recipient must comply with the following requirements:
Token Recipient must be at least 18 years old;
Token Recipient must have full legal capacity; 
Token Recipient must reside within the countries listed as non-restricted countries pursuant to Section “VII.”;
Token Recipient must have a fully verified account with Curio Capital AG.
Generally, every person complying with the list mentioned above is eligible to participate in the Token Sale and purchase Curio NFTs. 
However, if any person features criteria mentioned below, he will be excluded from participation: 
Token Recipient who provides false or incorrect information concerning his personal information, source of funds, residence or other eligibility criteria by any means for whatever reason;
Token Recipient whose acquisition of Tokens would cause a breach of the law or requirements of any country or governmental authority, including anti-money laundering regulations or conventions; 
Token Recipient acting directly or indirectly on behalf of terrorists or terrorist organisations, including those persons or entities that are included on the List of Specially Designated Nationals and Blocked Persons maintained by the US Treasury Department's Office of Foreign Asset Control (“OFAC”);
Token Recipient who acts, directly or indirectly, for a political figure, any member of a foreign political figure's immediate family or any close associate of a senior foreign political figure;
Token Recipient who makes representations or warranties in the Terms that are not true when given or have ceased to be true; 
Token Recipient whose circumstances are such that, in the opinion of the Token Issuer’s directors, its continued ownership of Tokens would cause an undue risk of adverse tax or other consequences to the Token Issuer. Those circumstances include those that affect that Token Recipient directly or indirectly, whether taken alone or in conjunction with another person or persons, connected or not, or any other circumstance that appears to the directors to be relevant; and 
Token Recipient, or a Token Recipient that is an entity acting as trustee, agent, representative or nominee for a person, who is a United States person (within the meaning of Regulation S of the United States Securities Act). The Token Recipient must notify the Token Issuer immediately if he becomes a United States resident or becomes aware that any person for whom the Token Recipient holds shares as trustee, agent, representative or nominee has become a United States resident. 

## VI. Participation in the Token Sale
The participation process looks as follows:
Each participant is obligated to conduct account registration with Curio Capital AG through their webpage at https://curioinvest.com/register .
Each participant will be required to complete the whitelisting instructions. This step will demand information with regard to applicable anti-money laundering and "know your customer" procedures including the verification of the source of funds;
As for the nature of token transactions, each participant will be required to own a certain Ethereum-Blockchain compatible private wallet;
Curio NFTs will be acquired using CGT tokens as means of exchange. Therefore, each participant will be required to purchase CGT tokens. They can be bought / swapped on Uniswap (https://uniswap.org). Gas fees will arise from this kind of transaction.
The Token purchase price will be displayed at the Curio market place. The purchase price will be due in the moment the purchase order will be completed. This purchase price must be paid in accordance to the instructions displayed. Gas fees will arise from this kind of transaction. 
The Token Recipient will be required to provide a proper, valid and ERC-721 compliant Ethereum wallet address to which he wishes the acquired Curio NFTs to be transferred to. The Token Recipient shall pay duly attention to the correctness of the provided information. Any default or failure in the transmission of such information may lead into the irretrievable loss of the Token. 
Once the information was provided to the Token Issuer, the acquired Curio NFT will be delivered to the Token Recipient's wallet address within 10 business days. 
The participation process may be amended upon obtained experience from previously conducted Token Sale due to for instance network congestion or other technical challenges. 

## VII. List of Restricted and Non-Restricted Countries
The Token Recipient warrants to the Token Issuer that he is located within the territory or a country of Switzerland and the European Union, which are:
Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Latvia, Liechtenstein, Lithuania, Iceland, Ireland, Italy, Luxemburg, Malta, Netherlands, Norway, Poland, Portugal, Rumania, Sweden, Slovakia, Slovenia, Spain.
The Token Issuer has the right to amend the lists of non-restricted countries at any time and at its sole discretion. Depending on the Token Recipient's country of residence, the legal assessment of the Curio Token may be changed fundamentally upon respective amendments of domestic legislation. It is up to the Token Holder to keep track of such changes. The Token Issuer is not liable for any mutations in the legal assessment of the Token arising from any developments in domestic legislations within the countries of the above-mentioned list.  
Countries which are not stated in the above-mentioned lists are restricted countries. The Token Sale to Token Recipients residing in such countries is prohibited. The Token Issuer is not liable for any changes in the legal assessment pursuant to a certain national legislation of a restricted country that turned applicable due to the Token Holder's relocation. In such cases, the Token Recipient must keep track of the above-mentioned list of non-restricted countries.
The Token Recipient shall not participate in the Token Sale if there are applicable legal restrictions in his country of residence or domicile. It is his sole responsibility to ensure that the participation in the Token Sale is not prohibited under the applicable legal restrictions in his country of residence or domicile.

## VIII. Rejection of Token Purchase
The purchase of Tokens during the Token Sale is final. Purchases cannot be cancelled and purchases cannot be refunded, except as provided in these Terms or as may be required by applicable law.
The Token Issuer has the right to reject the Token Recipient's offer to purchase Curio NFTs, in whole or in part, without giving a reason for the rejection. Under those circumstances, the cryptocurrency, fiat or other payment submitted will be returned in the manner they were received.

## IX. Token Sale Agreement and Possession Transfer Agreement
The Token Sale Agreement is concluded, when Token Recipient has passed registration, KYC & AML and accepted the present Terms.

The transfer of ownership rights however requires so called Possession Transfer Agreement (Besitzanweisungsvertrag). 
Curio and Token Recipient agree, that transfer of Token to Token Recipient means transfer of 1/100 share of co-ownership right in the Tokenisation Object.

All purchases of Curio NFTs are final. Respective purchases are non-refundable. 
The Token Recipient acknowledges that the Token Issuer is not required to provide a refund for any reason besides the ones stated by Swiss law.

## X. Usage and Administration Rules
Usage and administration of the Tokenisation Object is governed by the rules of Swiss Civil Code for co-owners (Art. 647 et seq. CO). 

Token Recipient agrees to be bound by the following Usage and Administration Rules for all Token Holders:
The co-ownership right is restricted to having title to share in co-ownership and NFT as digital representation, which can be sold by the Token Holder; In general, the Token Holders do not have a right to use the Tokenization Object. They may rent it however using CGTs;
Token Holder appoints Curio as administrator of Tokenization Object for an indefinite period of time for ordinary administration and beyond;
Token Holder explicitly authorizes Curio in case Curio remains a co-owner and not to any actions needed for administration of Tokenisation Object including but not limited to concluding any possible agreements in the name of co-owners for rent, storage, insurance, repair etc.;
Ordinary administration and beyond is reserved to Curio and not to any of the co-owners;
Token Holder agrees that dissolution of co-ownership shall be excluded for 50 years;
Token Holder explicitly authorizes Curio to sell the Tokenization Object in the future in the name of co-owners, while proceeds minus respective costs should be distributed among all Token Holders proportionally. The Token Holder is required to send NFT to Curio for further transfer to future owner or burning upon future owner discretion.

## XI. Liabilities of Curio Capital AG
The Token Recipient acknowledges and agrees that, to the fullest extent permitted by Swiss law, the disclaimers of liability contained herein apply to all damages or injury whatsoever caused by or related to:
Use of, respectively the inability to use Curio NFTs;
Complications arising from the use or the inability to use the personal wallet used to receive the Curio NFTs. The application to set up a private wallet falls within the exclusive liability of all Token Recipients. The Token Issuer cannot be held liable for any inconveniences that arise from the use of any wallets obtained by the Token Recipients;
The use of bad wallet addresses. The Token Recipient is obligated to be informed that he must provide appropriate wallet addresses in order to be able to receive an ERC-721 compliant Ethereum based token. The Token Issuer cannot be held liable for any provision of bad / wrong wallet addresses which will lead to the irretrievable loss of the Curio NFT;
The purchase / swapping progress in order to acquire CGT tokens. Merely the Token Recipient is liable for any interferences that may occur during such processes;
Gas fees. The Token Recipient is obligated to keep track of the current Gas /GWEI prices and make sure that he has enough funds at his disposal to satisfy the respective Gas fees due. It may be the case that such Gas fees exceed the value of the transaction intended to be conducted. The Token Issuer cannot be held liable for any changes of Gas fees associated with the transaction of Curio NFTs. The Token Issuer may furthermore not be held liable for any circumstances arising due to the fact that Token Recipient does not dispose of enough funds to satisfy the amount of Gas fees due and therefore is not able to conduct the favoured transaction;
Curio Capital AG under any cause or action whatsoever including but not limited to actions for breach of warranty, breach of contract or tort;
Indirect, incidental, special, exemplary or consequential damages including for loss of profits, goodwill or data;
Damages or interferences arising out of any interactions with the smart contract implemented in relation to Curio NFTs;
Losses of the NFT for whatever reason;
Inability to redeem the Curio NFT; 
Tax liabilities arising to the Token Recipient. All Token Recipients are exclusively responsible for the determination of their own tax duties with respect to the cause of their emergence, their extent and their settlement. The Token Issuer cannot be held liable for any missinformation and defaulted transmission of facts that the Token Recipient owes to any national authority. 
Curio Capital AG cannot be held liable for any missinformation provided by the Token Recipient. In particular, the Token Issuer may not be held liable for any loss of Curio NFTs due to:
Wrong or bad wallet address provided by the Token Recipient;
False network (eg. BSC) wallet address provided by the Token Recipient;  
Loss of wallet keys by the Token Recipient; 
The Token Issuer's aggregate liability in tort, contract or otherwise, arising out of or in connection with the use, receipt or holding of Curio NFTs is limited to the amount paid by the Token Holder for the receipt of the Token.

## XII. Refunds
In any of the cases set forth in Section "X." of these terms, Token purchase price refunds are foreclosed. All cases under this part of the Terms constitute circumstances that are due to the fault of the Token Recipient. The Token Recipient must ensure that it has the necessary assets and provides the Token Issuer with the correct information. In cases where he does not do so, it is fair to exclude such reimbursement of the purchase price.

## XIII. Entire Agreement
These Terms constitute the entire agreement between the Token Recipient and the Token Issuer relating to the purchase and sale of Curio NFTs. It governs the Token Sale of Curio NFTs. This agreement replaces all formerly closed agreements (disregarding of orally or written) relating to the Curio Token Sale.

XIV. No Waiver
The failure of the Token Issuer to enforce or to exercise any rights or claims under these Terms shall not be construed as a waiver of such rights. The Token Issuer's express waiver of any rights or claims under these Terms shall not be deemed to be a waiver of any future obligation to comply with such provisions unless such waiver was expressly stated by the Token Issuer. Additionally, any such waiver, representations, statements consents or other acts (may they be orally or in written) shall have the effect of a modification of these Terms.

XV. Anti-Money Laundering Provisions
The Token Recipient represents and warrants to the Token Issuer that:
It is an eligible Token Recipient; 
It is no person or entity that controls, is controlled by or under common control with, the Token Recipient would be excluded pursuant these Terms; 
Neither the Token Recipient, nor any person having a direct or indirect beneficial interest in the Token Recipient or the Curio NFTs being acquired, is the subject of sanctions administered or enforced by any country or government or is organized or resident in a country or territory that is the subject of country-wide or territory-wide sanctions; and 
The Token Issuer may request such evidence as is necessary to verify the identity and source of funds of a prospective Token Holder and to confirm the anti-money laundering status of any transferring Token Holder. If, as a result of any information or other matter which comes to their attention, Token Recipient knows or suspects, or has reasonable grounds for knowing or suspecting, that another person is engaged in criminal conduct or is involved with terrorism or terrorist property and the information for that knowledge or suspicion came to their attention in the course of business in the regulated sector, or other trade, profession, business, or employment, the person will be required to report such knowledge or suspicion to: (i) the authority in charge, if the disclosure relates to criminal conduct or money laundering; or (ii) if the disclosure relates to involvement with terrorism or terrorist financing and property. Such a report shall not be treated as a breach of confidence or of any restriction upon the disclosure of information imposed by any enactment or otherwise.
`
