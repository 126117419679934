import styled from 'styled-components'

import star from '../assets/star.svg'

export const Wrapper = styled.div`
  background-color: var(--yellow-10);
  padding: 24px;
  border-radius: 32px;

  p {
    margin-top: 32px;
  }
  @media screen and (max-width: 916px) {
    max-width: 436px;
    width: 100%;
  }
`

export const Star = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--white) url(${star}) no-repeat center;
  background-size: 32px;
`
