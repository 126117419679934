import styled from 'styled-components'

export const Card = styled.div`
  padding: 16px 16px 12px;
  border-radius: 16px;
  background: var(--white);
  width: 100%;

  &:nth-child(5n + 1) > .step__top {
    background: radial-gradient(
        45.2% 95.1% at 73.36% -12.75%,
        #d7e8ff 0%,
        rgba(123, 139, 161, 0) 100%
      ),
      #7b8ba1;
  }

  &:nth-child(5n + 2) > .step__top {
    background: radial-gradient(45.2% 95.1% at 73.36% -12.75%, #dbff4d 0%, #29ae6f 100%), #29ae6f;
  }

  &:nth-child(5n + 3) > .step__top {
    background: radial-gradient(45.2% 95.1% at 73.36% -12.75%, #fff857 0%, #ff5925 100%), #ff2525;
  }

  &:nth-child(5n + 4) > .step__top {
    background: radial-gradient(45.2% 95.1% at 73.36% -12.75%, #65e3ff 0%, #452eff 100%), #452eff;
  }

  &:nth-child(5n + 5) > .step__top {
    background: radial-gradient(45.2% 95.1% at 73.36% -12.75%, #5259ff 0%, #ff2559 100%), #ff2559;
  }
`

export const CardTop = styled.div<{ bg?: string }>`
  width: 100%;
  min-height: 128px;
  grid-template-columns: 1fr;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 16px;
`

export const StatusBox = styled.span<{ status?: 'done' | 'in progress' }>`
  padding: 6px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--black);
  background-color: ${(props) => (props.status === 'done' ? 'var(--green)' : 'var(--white)')};
  border-radius: 20px;
  text-transform: uppercase;
`

export const DateBox = styled.p`
  font-weight: 400;
  margin-top: 1px;
  line-height: 30px;
  color: var(--white);
  text-transform: uppercase;
`

export const CardContent = styled.div`
  width: 100%;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  display: grid;
`

export const CardDescription = styled.h4`
  font-weight: 700;
`

export const CardText = styled.p`
  font-size: 12px;
  color: var(--text-gray);
`
