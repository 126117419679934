import { utils } from 'ethers'

import { ACCEPT_COVER, ACCEPT_DOCUMENT, ACCEPT_IPFS } from './file'
import { Rule } from './types'

// eslint-disable-next-line
const lengthValidate = (value: string, length: number) => {
  if (value) {
    if (value.length > length) {
      return `The maximum length must be less than ${length}`
    }
  }
  return ''
}

const regexTest = (type: string, value: string) => {
  let regex
  switch (type) {
    case 'string':
      regex = /^[a-zA-Z0-9- ]+$/
      break
    case 'email':
      regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
      break
    case 'float':
      regex = /^[+-]?\d+(\.\d+)?$/
      break
    case 'number':
      regex = /^\d+$/
      break
    case 'phone':
      regex = /^([+]?\d{1,2}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{4}$/
      break
    case 'web':
      regex = /^(https?):\/\/[^\s$.?#].[^\s]*$/
      break
    case 'short_url':
      regex = /^[a-zA-Z0-9_-]+$/
      break
    default:
      regex = null
  }
  if (regex) {
    return value && !regex.test(value)
  }
  return false
}

export const validateFile = (rules: Rule[], file: File | null): string | null => {
  if (file) {
    const ext = file.name.split('.').pop()
    if (rules.includes('100mb') && file.size > 104857600) {
      return 'File must be less 100Mb'
    } else if (rules.includes('30mb') && file.size > 31457280) {
      return 'File must be less 30Mb'
    } else if (file.size === 0) {
      return 'File must not to be empty'
    } else if (!ext || (rules.includes('ipfs') && !ACCEPT_IPFS.includes(ext.toLowerCase()))) {
      return 'File must be PNG, GIF, WEBP, MP4 or MP3 format'
    } else if (
      !ext ||
      (rules.includes('document') && !ACCEPT_DOCUMENT.includes(ext.toLowerCase()))
    ) {
      return 'File must be PDF, PNG, JPEG or JPG format'
    } else if (!ext || (rules.includes('image') && !ACCEPT_COVER.includes(ext.toLowerCase()))) {
      return 'File must be PNG, GIF, WEBP or MP4 format'
    }
  } else if (rules.includes('required')) {
    return 'Required'
  }
  return null
}

export const validateFiles = (rules: Rule[], files: FileList): string | null => {
  for (let i = 0; i < files.length; i++) {
    const val = validateFile(rules, files[i])
    if (val !== null) {
      return val
    }
  }
  return null
}

// return null if no errors
export const validate = (rules: Rule[], value: string): string | null => {
  if (rules) {
    if (value.length > 0) {
      if (rules.includes('string')) {
        if (regexTest('string', value)) {
          return 'You have not string type'
        }
      } else if (rules.includes('number')) {
        if (regexTest('number', value)) {
          return 'You have not numeric type'
        }
        if (rules.includes('required') && !parseInt(value)) {
          return 'Required'
        }
      } else if (rules.includes('float')) {
        if (regexTest('float', value)) {
          return 'You have not numeric type'
        }
      } else if (rules.includes('email')) {
        if (regexTest('email', value)) {
          return 'You have not given a correct e-mail address'
        }
      } else if (rules.includes('number')) {
        if (regexTest('number', value)) {
          return 'You have not numeric type'
        }
      } else if (rules.includes('phone')) {
        if (regexTest('phone', value)) {
          return 'You have not phone type'
        }
      } else if (rules.includes('eth')) {
        if (!utils.isAddress(value)) {
          return 'Must have valid ETH address'
        }
      }
      if (rules.includes('royalties')) {
        if (value !== '0' && value !== '10' && value !== '20' && value !== '30') {
          return 'Royalties no suggested'
        }
      }
      if (rules.includes('web')) {
        if (regexTest('web', value)) {
          return 'Must be a valid uri'
        }
      }
      if (rules.includes('short_url')) {
        if (regexTest('short_url', value)) {
          return 'Must have valid alphanum value'
        }
      }
    } else if (rules.includes('required')) {
      return 'Required'
    }
  }
  return null
}

export const checkErrors = (errors: { [key in string]: string | null | undefined }): boolean => {
  let haveError = false
  for (const errorsKey in errors) {
    haveError = haveError || errors[errorsKey] !== null
  }
  return haveError
}
