import React from 'react'

const MiniApple = (): JSX.Element => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.59283 0C8.76064 0.0589996 7.78795 0.604996 7.22112 1.31599C6.70404 1.96099 6.27868 2.91898 6.44453 3.84997C7.3538 3.87897 8.29332 3.31998 8.83771 2.59698C9.34698 1.92399 9.73235 0.971993 9.59283 0Z" />
    <path d="M12.8816 5.36796C12.0826 4.34097 10.9597 3.74497 9.89918 3.74497C8.49917 3.74497 7.90698 4.43197 6.93429 4.43197C5.93136 4.43197 5.16941 3.74697 3.95867 3.74697C2.7694 3.74697 1.50305 4.49197 0.700124 5.76596C-0.42866 7.55995 -0.235488 10.9329 1.59379 13.8059C2.24842 14.8339 3.12257 15.9899 4.26599 15.9999C5.28355 16.0099 5.57038 15.3309 6.94892 15.3239C8.32746 15.3159 8.58893 16.0089 9.60454 15.9979C10.7489 15.9889 11.6709 14.7079 12.3255 13.6799C12.7948 12.9429 12.9694 12.5719 13.3333 11.7399C10.6865 10.7069 10.2621 6.84895 12.8816 5.36796Z" />
  </svg>
)

export default MiniApple
