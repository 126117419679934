import { clientV3 } from '../../../services/api'
import { hashKey } from '../../../services/order/generateHash'
import { signOrder } from '../../../services/order/sign'
import { Web3Type } from '../../../types/web3'
import { getSum } from '../../../utils/sum'
import { toWei } from '../../../utils/wei'
import { prepareExchangeOrder } from '../getters/prepareOrder'
import { OrderApi } from '../types'

export const changePriceOrder = async (
  web3: Web3Type,
  network: Network,
  order: OrderApi,
  newPrice: Price,
  stock: string,
  isBid?: boolean
): Promise<boolean> => {
  const value = toWei(getSum(newPrice, parseInt(stock)))
  const newOrder = isBid
    ? { ...order, make: { ...order.make, value }, take: { ...order.take, value: stock } }
    : { ...order, take: { ...order.take, value }, make: { ...order.make, value: stock } }
  const orderExchange = prepareExchangeOrder(newOrder)

  try {
    const signature = await signOrder(web3, orderExchange)
    const hash = hashKey(orderExchange)
    await clientV3.exchange.create({
      ...newOrder,
      signature,
      hash,
      isAuction: false,
      network
    })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
