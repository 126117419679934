import React from 'react'

import { useShares } from '../../hooks/useShares'
import ValueBlocks from '../info/ValueBlocks'
import { ProgressBar } from './ProgressBar'

export const Info = (): JSX.Element => {
  const { founded, values } = useShares(true)

  return (
    <div>
      <ProgressBar perc={founded} green />
      <ValueBlocks values={values} />
    </div>
  )
}
