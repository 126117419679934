import React, { useContext } from 'react'

import { MessengerContext } from '../../../../context/Messenger'
import { MessengerModal } from '../MessengerModal'
import { Buttons, Description, MessengerContainer } from './styles'

export const DeleteModal = (): JSX.Element => {
  const { setModal } = useContext(MessengerContext)

  return (
    <MessengerModal title="Delete message">
      <MessengerContainer>
        <Description>
          Are you sure you want to delete this message? This cannot be undone.
        </Description>
        <Buttons>
          <button className="button" onClick={() => setModal('')}>
            Cancel
          </button>
          <button className="button gray-button" onClick={() => setModal('')}>
            Delete
          </button>
        </Buttons>
      </MessengerContainer>
    </MessengerModal>
  )
}
