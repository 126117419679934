import React from 'react'

import { haveAuction, Token } from '../../../domain'
import { PriceUI } from '../../../ui/common/PriceUI'

const availableText = (token: Token) =>
  !!token.totalStock ? ` · ${token.totalStock} of ${token.supply} available` : ` · x${token.supply}`

const HighestBid = ({ highestBid }: { highestBid: string | null }): JSX.Element | null =>
  highestBid && highestBid !== '0' ? (
    <>
      {' '}
      · Highest bid{' '}
      <span style={{ color: 'var(--blue)' }}>
        <PriceUI value={highestBid} />
      </span>
    </>
  ) : null

export const BidStatus = ({ token }: { token: Token }): JSX.Element => {
  const { minPrice, highestBid } = token

  return minPrice ? (
    <>
      {token.standard === 'ERC1155' && 'From '}
      <span style={{ color: 'var(--text-dark)' }}>
        <PriceUI value={minPrice} />
      </span>
      {availableText(token)}
      <HighestBid highestBid={highestBid} />
    </>
  ) : haveAuction(token.ownerships) ? (
    <>
      <>Auction</>
      {availableText(token)}
      <HighestBid highestBid={highestBid} />
    </>
  ) : (
    <>
      Not for sale
      {availableText(token)}
      <HighestBid highestBid={highestBid} />
    </>
  )
}
