import React from 'react'
import styled from 'styled-components'

import { HeaderInner } from '../ui/common/Header/HeaderInner'

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  --white: #0032e6;
  --text-gray: #86adff;
  --text-light: #fff;
  --search-line-day: #86adff;
  --line-day: #5a86e1;
  --text-dark: #fff;
  --blue: #fff;
  --button-white: #10253e;
  --dark-blue: #e6ecf2;
  --result-gray: #0052ff;
  & p {
    color: #f7faff;
  }
  & b {
    color: #f7faff;
  }
  & div {
    color: #f7faff;
  }
  --big-path-fill: #fff;
  --small-path-fill: #fff;
`

export const DavosLayout = ({ children }: { children: JSX.Element }): JSX.Element => (
  <Wrapper>
    <StyledHeader>
      <HeaderInner blue />
    </StyledHeader>
    {children}
  </Wrapper>
)
