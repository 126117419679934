import React from 'react'
import styled from 'styled-components'

import BrowseLink from '../_common/BrowseLink'
import document from '../media/icons/details.svg'
import { PageName } from '../slides/styled'

export const TextBlock = styled.p`
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 28px;

  a {
    color: var(--blue);
  }
`

const Mercedes = (): JSX.Element => (
  <>
    <PageName className="h2">Mercedes AMG GTS</PageName>
    <TextBlock>
      The fractional NFTs represent a tokenized luxury sports car located in the Zurich area,
      Switzerland, a Mercedes-Benz AMG GTS. Renting out the vehicle makes it profitable; therefore,
      NFT holders can get bonuses on their NFT. A community of CurioDAO Car NFT holders will own
      this world`s first tokenized DAO car, 100 fractional NFTs of which have been minted and may be
      staked on rollapp.store.
    </TextBlock>
    <TextBlock>
      Powerful appearance and timeless elegance: with a muscular rear end, wide track and large
      wheels, the Mercedes-AMG GTS Coupé is in the starting blocks. But this dynamic racing
      symbiosis of sophisticated driving dynamics and pure-bred motorsport is not solely a visual
      eyecatcher. The Mercedes-AMG GTS combines the fascination of an authentic sports car with
      segment-specific technology leadership and superb everyday practicality. With a top speed of
      380km/h and an acceleration from 0 to 100 km/h in 3.2 seconds, the Mercedes-AMG GTS is the
      ideal fit for a sporty lifestyle. The Mercedes-AMG GTS expresses the essence of the
      performance brand AMG.
    </TextBlock>
    <TextBlock>Take a ride into the digital asset world with Mercedes-AMG GTS NFTs!</TextBlock>
    <BrowseLink img={document} text="Browse all details" to="/dao-nfts/mercedes-amg-gts/details" />
  </>
)

const Mario = (): JSX.Element => (
  <>
    <PageName className="h2">1985 Super Mario Bros.</PageName>

    <TextBlock>
      There is an extra-special Real-World Asset NFT in the RollApp NFT collection: an original copy
      of the Nintendo Super Mario Bros game!
    </TextBlock>
    <TextBlock>
      When video games first appeared on the scene, game developers were limited in how many pixels
      they could use to build their virtual worlds. The history of computer graphics is the history
      of how many pixels computer memory could handle. Orson Welles once remarked that “the enemy of
      art is the absence of limitations”.{' '}
      <a href="https://nintendo.fandom.com/wiki/Shigeru_Miyamoto" target="_blank" rel="noreferrer">
        Shigeru Miyamoto
      </a>{' '}
      proved his point by using the limitations of 8-bit computation to create the original, iconic
      Mario Bros games that changed the face of home and arcade entertainment forever. Our reverence
      towards such creations is not driven by mere nostalgia - it is driven by awe and respect for
      the kind of technological wizardry that continues in the world of cryptocurrency, spearheaded
      by the likes of Satoshi Nakomoto and Vitalik Buterin.
    </TextBlock>
    <TextBlock>
      This is why RollApp is so proud to announce the Super Mario Bros Real-World Asset NFT. The
      original 1985 Mario Bros game is now tokenized and available for purchase on rollapp.store
      exclusively using the Curio Governance Token (CGT). We’re giving you the chance to own a share
      in one of the most important artifacts in the history of digital technology. To get a sense of
      how valuable this is: an unopened copy of the game has sold for{' '}
      <a
        href="https://www.cnbc.com/2021/08/31/rally-co-founder-on-record-sale-of-super-mario-bros-nfts-investing.html"
        target="_blank"
        rel="noreferrer"
      >
        2 million dollars
      </a>
      !
    </TextBlock>
    <TextBlock>
      With such extraordinary value comes great responsibility. How do we ensure the security of
      such a precious item? This is where{' '}
      <a href="https://kleros.io" target="_blank" rel="noreferrer">
        Kleros
      </a>{' '}
      and{' '}
      <a href="https://www.proofofhumanity.id" target="_blank" rel="noreferrer">
        Proof of Humanity
      </a>{' '}
      come in. RollApp is teaming up with the Kleros and Proof of Humanity to ensure that our
      Real-World Asset NFTs are backed by the best security and guarantees of authenticity that
      blockchain technology has to offer. Proof of Humanity is most famous for supporting the
      decentralized Basic Income project{' '}
      <a
        href="https://blog.kleros.io/introducing-ubi-universal-basic-income-for-humans/"
        target="_blank"
        rel="noreferrer"
      >
        UBI
      </a>
      , and both Kleros and PoH have received the{' '}
      <a
        href="https://twitter.com/santisiri/status/1416733339499761665"
        target="_blank"
        rel="noreferrer"
      >
        blessing
      </a>{' '}
      of Vitalik Buterin himself.
    </TextBlock>
  </>
)

type Details = {
  [key in string]: JSX.Element
}

export const DETAILS: Details = {
  'mercedes-amg-gts': Mercedes(),
  'super-mario-bros': Mario()
}
