import React from 'react'

import { useRollapp } from '../../hooks/useRollapp'
import { Modal } from '../common/Modal'
import { Description, ModalLink, Wrapper } from '../components/ModalStyles'

export const AddFundsModal = (): JSX.Element => {
  const { setModal } = useRollapp()

  return (
    <Modal title="Add funds" width="350px">
      <Wrapper>
        <Description>
          There is no CGT yet for purchases on RollApp, you can buy CGT on
          <ModalLink
            href="https://capitaldex.exchange/#/swap?outputCurrency=0xf56b164efd3cfc02ba739b719b6526a6fa1ca32a"
            target="blank"
          >
            Capital DEX
          </ModalLink>
          or right here.
        </Description>
        <button
          className="primary-button block-button button"
          onClick={() => setModal('buy-cgt')}
          type="button"
        >
          Buy CGT
        </button>
      </Wrapper>
    </Modal>
  )
}
