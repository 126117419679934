import { IS_TESTNET } from '../../../config/rollapp'
import { ChainName, SupportChainId, SupportChainName } from '../types'

export const CHAIN_ID = {
  eth: {
    mainnet: 1,
    testnet: 5
  },
  skale: {
    mainnet: 836542336838601,
    testnet: 1171947029810826
  },
  harmony: {
    mainnet: 1666600000,
    testnet: 1666700000
  },
  kovan: {
    testnet: 42
  }
}

export const CHAIN_NAME: ChainName = {
  [CHAIN_ID.eth.mainnet]: 'ethereum-mainnet',
  [CHAIN_ID.eth.testnet]: 'ethereum-goerli',
  [CHAIN_ID.skale.mainnet]: 'skale-mainnet',
  [CHAIN_ID.skale.testnet]: 'skale-testnet',
  [CHAIN_ID.harmony.mainnet]: 'harmony-mainnet',
  [CHAIN_ID.harmony.testnet]: 'harmony-testnet',
  [CHAIN_ID.kovan.testnet]: 'ethereum-kovan'
}

export const SUPPORT_CHAIN_ID: SupportChainId = {
  eth: CHAIN_ID.eth[IS_TESTNET ? 'testnet' : 'mainnet'],
  skale: CHAIN_ID.skale[IS_TESTNET ? 'testnet' : 'mainnet'],
  kovan: CHAIN_ID.kovan.testnet
}

export const SUPPORT_CHAIN_NAME: SupportChainName = {
  eth: CHAIN_NAME[CHAIN_ID.eth[IS_TESTNET ? 'testnet' : 'mainnet']],
  skale: CHAIN_NAME[CHAIN_ID.skale[IS_TESTNET ? 'testnet' : 'mainnet']],
  harmony: CHAIN_NAME[CHAIN_ID.harmony[IS_TESTNET ? 'testnet' : 'mainnet']],
  kovan: CHAIN_NAME[CHAIN_ID.kovan.testnet]
}

export let BLOCKCHAIN_NAME = {
  [SUPPORT_CHAIN_NAME.eth]: 'Ethereum',
  [SUPPORT_CHAIN_NAME.skale]: 'Skale'
}

if (IS_TESTNET) {
  BLOCKCHAIN_NAME = { ...BLOCKCHAIN_NAME, [SUPPORT_CHAIN_NAME.kovan]: 'Ethereum Kovan' }
}
