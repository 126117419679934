import { useEffect, useState } from 'react'
import React from 'react'

import { getLocalUsd } from '../../../services/usd'
import { toFixed } from '../../../utils/toFixed'
import { fromWei } from '../../../utils/wei'

export const PriceUI = ({ value }: { value: PriceBN }): JSX.Element => {
  const [usd, setUsd] = useState('0')

  useEffect(() => {
    const getUsd = (usd: number) => parseFloat(fromWei(value)) * usd

    getLocalUsd().then((res) => {
      const val = getUsd(res)
      setUsd(val.toFixed(4))
    })
  }, [value])

  return (
    <>
      {toFixed(fromWei(value))} CGT (~{usd}$)
    </>
  )
}
