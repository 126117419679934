import React from 'react'

import { SubTitle } from '../styled'

export const LeoTrust = (): JSX.Element => (
  <>
    <h2 className="h2">The asset</h2>
    <p>
      Splinters must fall where wood is chopped? No way: colors hammer wherever music is performed!
    </p>
    <p>
      Among Swiss painters, Mr.Grandcello is the perpetual mobile. His Sturm und Dranglauf propelled
      him to the world&apos;s biggest stages. He beat his way into the hearts of followers of Nelly
      Furtado, Nora Jones, Brian Adams, Justin Bieber, and others, among others.
    </p>
    <p>
      Mr. Grandcello was not intimidated by the world&apos;s great stages. No, but the outstanding
      talent&apos;s sheer originality and, of course, willpower finally led him to the fine arts.
    </p>
    <p>
      The successful blend of tradition and innovation in Grandcello&apos;s Limitless. Tradition and
      innovation are not dictums, nor is image cultivation half-silver (PR). The outstanding talent
      of the Swiss manages to mix the finest of tradition and modernity in an unmistakable way. In
      doing so, he consciously transforms music into art. Mr. Grandcello transforms music into
      gorgeous pictures in full color.
    </p>
    <p>
      Each of his renowned works is accompanied by a pleasant piece of music composed by top-notch
      musicians and a sound artist from the Hans Zimmer family. Mr. Grandcello plans meticulously
      and methodically, no matter how what, or with whom.
    </p>
    <p>
      Washington, Australia, and London... Mr. Grandcello also demonstrates that he is meticulous in
      his selection of paint manufacturers. The color pigments in «his» KEIM mineral paints have
      endured for centuries (!). The other: The USA (White House), Australia (Sidney Opera House),
      and England (Buckingham Palace) swear by the unique KEIM quality.{' '}
    </p>
    <p>
      The Grandcello&apos;s Limitless series includes the ocean and its mirror in the universe. The
      earth&apos;s coral reefs were the godfathers of «the ocean.» &quot;The coral reef is a natural
      wonder and a whim of nature,&quot; the artist explains, adding, &quot;There is no ecological
      message in the artwork.&quot; Rather, it is meant to honor nature&apos;s and the ocean&apos;s
      strength, as well as the distinctiveness and vivid spectacle of untouched coral reefs.&quot;
    </p>
    <p>
      With the support of Leo Trust, Trusted Advisors, and CurioInvest, Mr. Grandcello has not only
      brought it to land but is also bringing it to the digital world. The work is called “the
      ocean”, but the magic word here is NFT, the acronym for non-fungible tokens. Are you token?
    </p>
    <SubTitle>About Leo Trust</SubTitle>
    <p>
      Leo Trust Switzerland AG has been an owner-managed and globally active financial company for
      over 40 years, offering a wide range of services for institutional and private clients.
    </p>
  </>
)
