import React from 'react'
import styled from 'styled-components'

import soldOut from '../../../assets/img/shares/sold-out.png'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const SoldOut = (): JSX.Element => {
  return (
    <Wrapper>
      <img src={soldOut} />
    </Wrapper>
  )
}

export default SoldOut
