import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const Item = styled(NavLink)`
  display: block;
  font-weight: 700;
  padding-bottom: 6px;
  color: var(--text-gray);

  &.active {
    color: var(--text-dark);
    position: relative;
  }

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    background-color: var(--blue);
    height: 1px;
    width: 100%;
  }
`
