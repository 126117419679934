import { useWeb3React } from '@web3-react/core'
import React, { ChangeEvent, useState } from 'react'

import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { EDIT_PROFILE } from '../../../services/sign/messages'
import { validate } from '../../../services/validate-form'
import { Rule } from '../../../services/validate-form/types'
import { Input, InputContainer } from '../../../ui/components/Form/Input'
import { LabelWrapper } from '../../../ui/components/Form/Label/LabelWrapper'
import { Button } from './styles'

export const Email = (): JSX.Element => {
  const { account, library } = useWeb3React()
  const [hidden, setHidden] = useState(true)
  const [email, setEmail] = useState('')
  const [error, setError] = useState<undefined | string | null>()
  const rules: Rule[] = ['required', 'email']

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const error = validate(rules, value)
    setError(error)
    setEmail(value)
  }

  const sign = async (account: string, edit?: boolean): Promise<string> =>
    await signMessage(
      EDIT_PROFILE(account, edit ? 'edit_credentials' : 'get_credentials'),
      account,
      library
    )

  const emailHandle = async () => {
    if (account) {
      if (hidden) {
        const signature = await sign(account)
        const { data } = await clientV3.profile.credentials.get(signature, account.toLowerCase())
        setEmail(data.email ? data.email : '')
        if (data.email) {
          setError(null)
        }
        setHidden(false)
      } else {
        const signValue = await sign(account, true)
        await clientV3.profile.credentials.set(signValue, account.toLowerCase(), email)
        setHidden(true)
        setEmail('')
      }
    }
  }

  return (
    <LabelWrapper title="Email address">
      <InputContainer>
        <Input
          placeholder={hidden ? 'Hidden' : 'Enter your email'}
          value={email}
          onChange={onChange}
        />
        <Button onClick={emailHandle} disabled={!hidden && error !== null}>
          {hidden ? 'Sign message' : 'Confirm'}
        </Button>
      </InputContainer>
    </LabelWrapper>
  )
}
