import React from 'react'

import { HOT_COLLECTIONS } from '../../../config/hot-collection'
import { CollectionCard } from '../../../ui/components/CollectionCard'
import { Box, Container } from './styles'

export const HotCollections = (): JSX.Element => (
  <Box>
    <Container>
      {HOT_COLLECTIONS.map((item) => (
        <CollectionCard item={item} key={item.text} />
      ))}
    </Container>
  </Box>
)
