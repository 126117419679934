import React from 'react'

import { approveHandle } from '../../../../contracts/erc721/methods'
import { createOrder, Token } from '../../../../domain'
import { useSaleForm } from '../../../../hooks/useSaleForm'
import { Web3Type } from '../../../../types/web3'
import { CancelBtn } from '../../../components/CancelBtn'
import { Input, InputContainer } from '../../../components/Form/Input'
import { LabelWrapper } from '../../../components/Form/Label/LabelWrapper'
import { Description, TokenName } from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'

export const FixedPrice = ({
  token,
  web3
}: {
  token: Token
  web3: Web3Type
}): JSX.Element | null => {
  const { onChange, available, values, errors, sum, approved, setApproved, allowance } =
    useSaleForm(web3, token)

  return (
    <>
      <Description>Enter new price. Your NFT will be pushed in top of marketplace</Description>

      <LabelWrapper error={errors.bid}>
        <InputContainer>
          <Input
            type="number"
            placeholder="Enter price for one piece"
            value={values.bid}
            name="bid"
            onChange={onChange}
          />
          <TokenName>CGT</TokenName>
        </InputContainer>
      </LabelWrapper>

      {token.standard === 'ERC1155' && (
        <LabelWrapper title="Enter quantity" error={errors.quantity}>
          <Input
            value={values.quantity}
            name="quantity"
            type="number"
            min="1"
            onChange={onChange}
          />
          <small>{available} available</small>
        </LabelWrapper>
      )}
      <StatusBlock
        name="Approve collection"
        text="This transaction is conducted only once per collection"
        method={async () => {
          const res = await approveHandle(web3, token)
          setApproved(res)
          return res
        }}
        disabled={
          parseInt(values.bid) === 0 &&
          (parseInt(values.quantity) === 0) !== null &&
          available === 0
        }
        check={allowance}
      />
      <StatusBlock
        name="Set price"
        text="Sign message to set fixed price"
        method={() => createOrder(web3, token, sum, values.quantity)}
        disabled={!allowance && !approved}
      />
      <CancelBtn />
    </>
  )
}
