import React, { useEffect, useState } from 'react'

import { useRollapp } from '../../../../hooks/useRollapp'
import { getTimeLimit, MIN5 } from '../../../../services/localStorage/bridge-timer'

export const Timer = (): JSX.Element => {
  const { token, openToken } = useRollapp()
  const [restTime, setRestTime] = useState(0)

  useEffect(() => {
    const start = getTimeLimit()
    const interval = setInterval(() => {
      const now = new Date()
      const diff = now.getTime() - start
      if (MIN5 - diff <= 0) {
        const value = token
        openToken(undefined, undefined)
        openToken('move-to-network', value)
        setRestTime(0)
        clearInterval(interval)
      } else {
        setRestTime(MIN5 - diff)
      }
    }, 1000)

    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      Transfer from SKALE to Ethereum can be done no more than once every 5 minutes. You can make
      the next transfer in {(restTime / 1000).toFixed()} seconds.
    </>
  )
}
