import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { DavosLayout } from './layouts/davosLayout'
import { DefaultLayout } from './layouts/default'
import { MainLayout } from './layouts/main'
import { Collection } from './pages/Collection'
import { Create } from './pages/Create'
import { DaoPage } from './pages/DaoNFT'
import { Details } from './pages/DaoNFT/views/Details'
import Visuals from './pages/DaoNFT/views/Visuals'
import { Davos } from './pages/DavosPage'
import { DavosPage } from './pages/DavosPage/page'
import { Explore } from './pages/Explore'
import { LandingPage } from './pages/LandingPage'
import { Login } from './pages/Login'
import { NotFoundPage } from './pages/NotFound'
import { Profile } from './pages/Profile'
import { Roadmap } from './pages/Roadmap'
import { Search } from './pages/Search'
import { Season3 } from './pages/Season3'
import Settings from './pages/Settings'
import { TermsPage } from './pages/Terms'
// import { TestDao } from './pages/TestDao'
import { TokenPage } from './pages/Token'

export const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={['/explore', '/roadmap', '/terms', '/create', '/season-3']} exact>
          <MainLayout>
            <Switch>
              <Route path="/explore" exact component={Explore} />
              <Route path="/roadmap" exact component={Roadmap} />
              <Route path="/season-3" exact component={Season3} />
              <Route path="/terms" exact component={TermsPage} />
              <Route path="/create" exact component={Create} />
            </Switch>
          </MainLayout>
        </Route>
        <Route
          path={[
            '/settings',
            '/token/:id/:tab?',
            '/dao-nfts/:page/*',
            '/search/:page?',
            '/profile/:address/:tab?',
            '/my-items/:tab?',
            '/collection/:address/:tab?',
            '/testing-page'
          ]}
          exact
        >
          <DefaultLayout>
            <Switch>
              <Route path="/settings" exact component={Settings} />
              <Route path="/token/:id/:tab?" exact component={TokenPage} />
              <Route path="/dao-nfts/:page/gallery" exact component={Visuals} />
              <Route path="/dao-nfts/:page/details" exact component={Details} />
              <Route path="/search/:page?" exact component={Search} />
              <Route path="/profile/:address/:tab?" exact component={Profile} />
              <Route path="/my-items/:tab?" exact component={Profile} />
              <Route path="/collection/:address/:tab?" exact component={Collection} />
              {/* <Route path="/testing-page" exact component={TestDao} /> */}
            </Switch>
          </DefaultLayout>
        </Route>
        <Route path={['/davos', '/davos/:page']} exact>
          <DavosLayout>
            <Switch>
              <Route path="/davos" exact component={Davos} />
              <Route path="/davos/:page" exact component={DavosPage} />
            </Switch>
          </DavosLayout>
        </Route>
        <Route path="/" exact component={LandingPage} />
        <Route path="/dao-nfts/:page" exact component={DaoPage} />
        <Route path="/login" exact component={Login} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  )
}
