import React from 'react'

import { useActiveWeb3React } from '../../../hooks/useActiveWeb3React'
import { addTokenToMetamask } from '../../../services/metamask/addToken'
import { Logo, Tooltip, Wrapper } from './styles'

export const AddCGTContract = (): JSX.Element => {
  const { chainId } = useActiveWeb3React()

  return (
    <Wrapper onClick={() => addTokenToMetamask(chainId)}>
      <Logo />
      <Tooltip className="tooltip">Add to Metamask</Tooltip>
    </Wrapper>
  )
}
