import React from 'react'

import { HeaderInner } from './HeaderInner'
import { Wrapper } from './styles'

export const Header = (): JSX.Element => {
  return (
    <Wrapper>
      <HeaderInner />
    </Wrapper>
  )
}
