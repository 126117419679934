import React from 'react'
import { Link } from 'react-router-dom'

import { Wrap } from './styles'

export const NotFound = (): JSX.Element => {
  return (
    <Wrap>
      <p>Something go wrong! Page not found ...</p>
      <Link to="/" className="button primary-button">
        Go to marketplace
      </Link>
    </Wrap>
  )
}
