import { useWeb3React } from '@web3-react/core'
import React from 'react'

import {
  isBonusContract,
  isBonusOnSkale,
  isGetETCPunks,
  isOwned,
  isWinBonus,
  Token
} from '../../../domain'
import { useRollapp } from '../../../hooks/useRollapp'

export const GetButton = ({ token }: { token: Token }): JSX.Element | null => {
  const { account } = useWeb3React()
  const { openToken } = useRollapp()

  return isBonusContract(token) && isOwned(token, account) ? (
    <>
      <button
        className="button_gradient"
        onClick={() =>
          openToken(
            isWinBonus(token)
              ? 'get-bonus'
              : isGetETCPunks(token)
              ? 'get-etc-punks'
              : isBonusOnSkale(token)
              ? 'get-nfts-pack'
              : undefined,
            token
          )
        }
      >
        {isWinBonus(token)
          ? 'Win bonus'
          : isGetETCPunks(token)
          ? 'Get ETCPunk NFT'
          : isBonusOnSkale(token) && 'Get NFTs Pack'}
      </button>
      {isBonusOnSkale(token) && (
        <button className="button_gradient" onClick={() => openToken('get-nft-prize', token)}>
          Win NFT Prize
        </button>
      )}
    </>
  ) : null
}
