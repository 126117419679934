import React from 'react'
import styled from 'styled-components'

import { Footer } from '../ui/common/Footer'
import { Header } from '../ui/common/Header'

const Wrapper = styled.div`
  background-color: var(--gray);
  // for footer in bottom
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export const DefaultLayout = ({ children }: { children: JSX.Element }): JSX.Element => (
  <Wrapper>
    <Header />
    {children}
    <Footer />
  </Wrapper>
)
