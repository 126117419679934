import React from 'react'

import { ListProfile } from '../../../domain'
import { Avatar } from '../../../ui/components/Avatar'
import { getProfileLink } from '../../../utils/getProfileLink'
import { shortAddress } from '../../../utils/shortAddress'
import { Info, Name, Wrapper } from './styles'

export const TokenUser = ({
  profile,
  info
}: {
  profile: ListProfile
  info: string
}): JSX.Element => {
  return (
    <Wrapper to={getProfileLink(profile)}>
      <Avatar width="48px" image={profile.avatarS3Link || profile.avatarIpfsHash} />
      <div>
        <Name>{profile.name || shortAddress(profile.walletAddress)}</Name>
        <Info>{info}</Info>
      </div>
    </Wrapper>
  )
}
