import React from 'react'

const Time = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 6C11 5.73779 11.1011 5.49902 11.2661 5.3208C11.4487 5.12354 11.71 5 12 5C12.5522 5 13 5.44775 13 6V11H18C18.5522 11 19 11.4478 19 12C19 12.5522 18.5522 13 18 13H12C11.4478 13 11 12.5522 11 12V6Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM12 22C17.5229 22 22 17.5229 22 12C22 6.47705 17.5229 2 12 2C6.47705 2 2 6.47705 2 12C2 17.5229 6.47705 22 12 22Z"
    />
  </svg>
)

export default Time
