import React, { ChangeEvent, useEffect, useState } from 'react'

import { Period, RatingUser } from '../../../domain'
import { clientV3 } from '../../../services/api'
import { Loader } from '../../../ui/components'
import { TopCard } from '../TopCard'
import { TopCardsBox, TopContainer, TopRow, TopSelector, TopTitle } from './styles'

export const TopBlock = ({ title, buyers }: { title: string; buyers?: boolean }): JSX.Element => {
  const [loaded, setLoaded] = useState(false)
  const [users, setUsers] = useState<RatingUser[]>([])

  const getUsers = (period: Period) => {
    setLoaded(false)
    clientV3.exchange
      .rating(period, buyers || false)
      .then((res) => {
        setUsers(res.data)
        setLoaded(true)
      })
      .catch(() => {
        setLoaded(true)
      })
  }

  const changeHandle = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    getUsers(value as Period)
  }

  useEffect(() => {
    getUsers('month')
    // eslint-disable-next-line
  }, [])

  return (
    <TopContainer>
      <TopRow>
        <TopTitle>{title}</TopTitle>
        <TopSelector name="date" defaultValue="1" onChange={changeHandle}>
          <option value="month">30 days</option>
          <option value="week">7 days</option>
        </TopSelector>
      </TopRow>
      <TopCardsBox>
        {loaded ? (
          users.length > 0 ? (
            users.map((item, index) => <TopCard key={index} user={item} index={index + 1} />)
          ) : (
            <div>No data</div>
          )
        ) : (
          <Loader center />
        )}
      </TopCardsBox>
    </TopContainer>
  )
}
