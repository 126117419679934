import React from 'react'

import { ProfileUi } from '../../../domain'
import { Address } from '../../components/Address'
import { Avatar } from '../../components/Avatar'
import { SocialsBlock } from '../../components/SocialBlock'
import { AvatarPosition, Description, WrapperAside } from './styles'

const Sidebar = ({ profile }: { profile: ProfileUi }): JSX.Element => {
  return (
    <WrapperAside>
      <AvatarPosition>
        <Avatar
          width="100px"
          border="white"
          image={profile.avatarS3Link || profile.avatarIpfsHash}
        />
      </AvatarPosition>
      <h1 className="h2">{profile.name}</h1>
      <Address address={profile.walletAddress} network={profile.network} />
      {profile.description && <Description>{profile.description}</Description>}
      {profile.socials && <SocialsBlock socials={profile.socials} />}
    </WrapperAside>
  )
}

export default Sidebar
