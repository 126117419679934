import styled from 'styled-components'

export const Label = styled.label<{ size?: string }>`
  position: relative;

  span {
    display: block;
    font-weight: ${(props) => (props.size === 'small' ? 600 : 700)};
    font-size: ${(props) => (props.size === 'small' ? 12 : 16)}px;
    line-height: ${(props) => (props.size === 'small' ? 14 : 24)}px;
    margin-bottom: 9px;
  }

  small {
    margin-top: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: var(--text-gray);
  }

  .before {
    font-weight: 600;
  }
`
