import React, { useState } from 'react'

import { BLOCKCHAIN_NAME, SUPPORT_CHAIN_NAME } from '../../../domain/network/contstant'
import { useCurrentCategory } from '../../../hooks/useTokens'
import { FilterButton } from '../../../ui/components/FilterButton'
import { Popover } from '../../../ui/components/Popover'
import { Box } from '../../../ui/icons/Box'
import { Wrapper } from './styles'

export const Blockchains = (): JSX.Element => {
  const { blockchain, setParam } = useCurrentCategory()
  const [show, setShow] = useState(false)

  const changeLocation = (key?: string) => {
    setParam('blockchain', key === 'eth' ? 'ethereum' : key)
    setShow(false)
  }

  return (
    <div style={{ position: 'relative' }}>
      <FilterButton
        icon={Box()}
        title={
          blockchain
            ? BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME[blockchain === 'ethereum' ? 'eth' : blockchain]]
            : 'Blockchain'
        }
        onClick={() => setShow(!show)}
      />
      {show && (
        <Popover width="180px" close={() => setShow(false)}>
          <Wrapper>
            {Object.keys(SUPPORT_CHAIN_NAME).map((key) => (
              <li
                style={{
                  fontWeight:
                    key === blockchain || (key === 'eth' && blockchain === 'ethereum') ? 700 : 500
                }}
                key={key}
                onClick={() => changeLocation(key)}
              >
                {BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME[key]]}
              </li>
            ))}
            <button className="button block-button" onClick={() => changeLocation()}>
              Clear
            </button>
          </Wrapper>
        </Popover>
      )}
    </div>
  )
}
