import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import { InputContainer, StyledInput, SubscribeButton } from '../../../../ui/common/Footer/styled'
import { LabelWrapper } from '../../../../ui/components/Form/Label/LabelWrapper'
import { SubscribeModal } from '../../../../ui/modals/SubscribeModal'
import { TextBlock } from '../details'

const Block = styled(TextBlock)`
  text-align: center;

  a {
    color: var(--blue);
  }
`

const Info = (): JSX.Element => {
  const [modalState, setModalState] = useState(false)
  const [email, setEmail] = useState('')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }

  const open = () => {
    setModalState(true)
  }

  return (
    <div>
      <Block>Join the waiting list & get notified when the sale starts</Block>
      <LabelWrapper>
        <InputContainer style={{ maxWidth: '100%', marginBottom: 24 }}>
          <StyledInput
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleInputChange}
          />
          <SubscribeButton className="primary-button button subscribe-button" onClick={open}>
            Notify when active
          </SubscribeButton>
        </InputContainer>
      </LabelWrapper>
      <Block>
        Curious to find out more?{' '}
        <a
          href="https://investcurio.medium.com/near-helps-curioinvest-expand-carbon-neutral-tokenization-of-collectible-assets-a2c11bc122a"
          target="_blank"
          rel="noreferrer"
        >
          Read this article
        </a>{' '}
        on partnership with NEAR.
      </Block>
      {modalState && (
        <SubscribeModal value={email} close={() => setModalState(false)} waitlist={'tesla'} />
      )}
    </div>
  )
}

export default Info
