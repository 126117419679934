import buy from '../assets/faq/buy.svg'
import discover from '../assets/faq/discover.svg'
import invest from '../assets/faq/invest.svg'
import list from '../assets/faq/list.svg'
import manage from '../assets/faq/manage.svg'
import redeem from '../assets/faq/redeem.svg'
import sell from '../assets/faq/sell.svg'
import share from '../assets/faq/share.svg'
import trade from '../assets/faq/trade.svg'

export const buyers = [
  {
    title: 'Discover',
    icon: `${discover}`,
    text: `Browse the highly prized collectibles on Rollapp.store.`
  },
  {
    title: 'Buy and bid',
    icon: `${buy}`,
    text: `All items are authenticated and securely stored, so you can bid and buy with confidence.`
  },
  {
    title: 'Redeem or sell',
    icon: `${redeem}`,
    text: `Redeem your NFT to receive the underlying asset, or resell it on RollApp. No high fees or logistical headaches.`
  }
]

export const investors = [
  {
    title: 'Invest',
    icon: `${invest}`,
    text: `Buy and sell shares in luxury, collectible real-world assets. Backed bu Curio Capital AG.`
  },
  {
    title: 'Manage collection',
    icon: `${manage}`,
    text: `Keep track of your portfolio and price data whenever you want.`
  },
  {
    title: 'Trade it',
    icon: `${trade}`,
    text: `Receive rewards on your investment, sell your NFTs to other RollApp members, or receive a potential return if we sell one of the underlying assets you invested in.`
  }
]

export const sellers = [
  {
    title: 'List it',
    icon: `${list}`,
    text: `We authenticate your items and issue you tokens that you can list on our marketplace.`
  },
  {
    title: 'Share it',
    icon: `${share}`,
    text: `Share your listings with your network and we’ll feature them in our marketplace to attract collectors.`
  },
  {
    title: 'Sell it',
    icon: `${sell}`,
    text: `Your asset, your price. We charge only 2% on all post market sales, the lowest fee in the industry.`
  }
]
