import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import {
  CURIO_ASSETS_CARS,
  CURIO_ASSETS_GEEK_1155,
  getFixedPriceOrder,
  getFixedPriceOrderByOwner,
  ListProfile,
  OrderApi,
  Ownership,
  Token,
  UserData
} from '../domain'
import { CHAIN_NAME, SUPPORT_CHAIN_ID } from '../domain/network/contstant'
import { DEFAULT_PROFILE } from '../domain/profile/constants'
import { getListOwners } from '../domain/profile/mappers/profiles'
import { MERCEDES_AMG_GTS, SUPER_MARIO_BROS } from '../domain/token/constants'
import { clientV3 } from '../services/api'
import { getOwnerInfo } from '../utils/ownerships'

type Data = { list: ListProfile[]; offers: OrderApi[] }

export const useOffers = (token: Token): Data => {
  const [offers, setOffers] = useState<Data>({ list: [], offers: [] })

  useEffect(() => {
    const fetch = async () => {
      const { data } = await clientV3.token.offers(token.id)
      const list = await clientV3.profile.list(data.map((item) => item.maker))
      setOffers({ list: list.data, offers: data })
    }
    fetch()
  }, [token])

  return offers
}

export const useOwners = (token: Token): UserData[] => {
  const [owners, setOwners] = useState<UserData[]>([])

  useEffect(() => {
    const getUserData = (owner: Ownership, list: ListProfile[]): UserData => ({
      profile:
        list.filter(
          (profile) => owner.ownerAddress.toLowerCase() === profile.walletAddress.toLowerCase()
        )[0] || DEFAULT_PROFILE(owner.ownerAddress),
      info: token.standard === 'ERC1155' ? getOwnerInfo(owner) : 'Owner'
    })

    const accounts = getListOwners(token)
    clientV3.profile.list(accounts).then((res) => {
      const { data } = res
      const { ownerships } = token
      setOwners(ownerships.map((owner) => getUserData(owner, data)))
    })
  }, [token])

  return owners
}

export const useOffer = (token: Token, maker: AccountAddress): OrderApi | null | undefined => {
  const [offer, setOffer] = useState<OrderApi | undefined | null>()

  useEffect(() => {
    if (maker) {
      clientV3.token.offers(token.id).then((res) => {
        const offer = res.data.filter(
          (item) => item.maker === maker.toLowerCase() && item.status === 'ACTIVE'
        )[0]
        if (offer) {
          setOffer(offer)
        } else {
          setOffer(null)
        }
      })
    }
  }, [maker, token])

  return offer
}

export const useOrder = (
  token: Token,
  user: EthAddress,
  owned: boolean
): OrderApi | null | undefined => {
  const [order, setOrder] = useState<OrderApi | undefined | null>()

  useEffect(() => {
    clientV3.token.orders(token.id).then((res) => {
      const order = owned
        ? getFixedPriceOrderByOwner(res.data, user)[0]
        : getFixedPriceOrder(res.data, user)[0]
      if (order) {
        setOrder(order)
      } else {
        setOrder(null)
      }
    })
  }, [user, token, owned])

  return order
}

export const useDaoToken = (isMario?: boolean): Token | undefined => {
  const { account, chainId } = useWeb3React()
  const [token, setToken] = useState<Token>()

  useEffect(() => {
    if (chainId) {
      const chain = SUPPORT_CHAIN_ID.eth
      const tokenId = isMario ? SUPER_MARIO_BROS : MERCEDES_AMG_GTS
      const contract = isMario ? CURIO_ASSETS_GEEK_1155[chain] : CURIO_ASSETS_CARS[chain]
      clientV3.token.byId(CHAIN_NAME[chain], contract, tokenId, account).then((res) => {
        setToken(res.data)
      })
    }
  }, [account, isMario, chainId])

  return token
}
