export const header = ['Satisfaction Guarantee', 'Transfer Benefit', 'Easy Resale']

export const rows = [
  ['Buy with confidence', 'Own without the hard work', 'Resell with ease'],
  [
    'If you don’t love your asset after your first purchase, we’ll give you your money back',
    'Easily transfer ownership to another asset',
    'Rollapp listings resell on average in just 30 days'
  ]
]
