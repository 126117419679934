import { utils } from 'ethers'
import Web3 from 'web3'

import { AssetExchange, TokenAsset } from '../../domain'

export const enc = (token: string, tokenId: string | null): string => {
  const coder = new utils.AbiCoder()
  if (tokenId) {
    return coder.encode(['address', 'uint256'], [token, tokenId])
  } else {
    return coder.encode(['address'], [token])
  }
}

export const assetEnc = (asset: string): string => {
  const web3 = new Web3()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return web3.utils.soliditySha3(asset).substr(0, 10)
}

export const codeAsset = (asset: TokenAsset): AssetExchange => {
  return {
    assetType: {
      assetClass: assetEnc(asset.assetType.assetClass),
      data: enc(asset.assetType.contract, asset.assetType.tokenId)
    },
    value: asset.value
  }
}
