import styled from 'styled-components'

export const CardsWrap = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 48px 24px;

  @media (max-width: 1216px) {
    grid-template-columns: repeat(${(props) => props.columns - 1}, 1fr);
  }

  @media (max-width: 916px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 484px) {
    grid-template-columns: 100%;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0;
`
