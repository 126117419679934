import styled from 'styled-components'

export const FormBlock = styled.div`
  width: 100%;
  // display: flex;
  max-width: 800px;
`

export const RadiosBlock = styled.div`
  width: 100%;
  margin-bottom: 42px;
`

export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
`

export const RadioGroup = styled.div`
  width: 100%;
  max-width: 606px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
`

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  & > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
  }

  & > span {
    display: flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    transition: 0.2s;
    position: relative;

    border: 1px solid var(--line-day);
    background-color: var(--white);
  }
  & > span::after {
    content: '';
    background: var(--white);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  & > input:checked + span {
    border: 1px solid var(--blue);
  }
  & > input:checked + span::after {
    background: var(--blue);
  }
`

export const ChooseTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: var(--black);
`

export const InputsBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;
  margin-bottom: 42px;
  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`

export const LabelWrapper = styled.label`
  width: 100%;
  & > input {
    border: 1px solid var(--line-day);
    transition: 0.2s border-color;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 16px;
    padding: 0 20px;
    background-color: var(--white);
    font-weight: 400;
  }
  & > input::placeholder {
    color: var(--light-gray);
  }

  & > span {
    font-size: 12px;
    line-height: 16px;
    margin-top: 9px;
    color: var(--red);
  }
`

export const InputTitle = styled.p`
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 9px;
`

export const CheckBoxesBlock = styled.div`
  width: 100%;
`

export const Checkboxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 14px 24px;
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  & > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: none;
  }

  & > span {
    display: flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    transition: 0.2s;
    position: relative;

    border: 1px solid var(--line-day);
    background-color: var(--white);
  }
  & > span:before {
    content: '';
    background: var(--white);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    transform: translate(-50%, -50%);
  }
  & > input:checked + span:before {
    background: var(--blue);
  }
`

export const ButtonBlock = styled.div`
  width: 100%;
  margin: 64px auto 0;
  max-width: 248px;
  @media screen and (max-width: 768px) {
    max-width: 220px;
  }
`
