import React from 'react'

import { checkErrors } from '../../../domain/forms/methods/create'
import { ACCEPT_COVER, ACCEPT_DOCUMENT, ACCEPT_IPFS } from '../../../services/validate-form/file'
import { Input } from '../../../ui/components/Form/Input'
import { LabelWrapper } from '../../../ui/components/Form/Label/LabelWrapper'
import { Textarea } from '../../../ui/components/Form/Textares'
import { Upload } from '../../../ui/components/Form/Upload'
import { UploadImage } from '../../../ui/components/Form/UploadImage'
import { RadioCard } from '../../../ui/components/RadioCard'
import { RadioCards } from '../../../ui/components/RadioCards'
import Marketplace from '../../../ui/icons/Marketplace'
import { SubmitButton } from '../SubmitButton'
import { SuccessSendModal } from '../SuccessSendModal'
import { useCreateForm } from '../useCreateForm'
import { Form } from './styles'

export const DefaultForm = ({ whitelisted }: { whitelisted: boolean }): JSX.Element => {
  const { clearImage, onChangeFile, onChange, submit, status, close, create, createErrors } =
    useCreateForm('DEFAULT')

  const submitHandle = () => {
    const formData = new FormData()

    if (create.addition) {
      for (let i = 0; i < create.addition.length; i++) {
        formData.append('files', create.addition[i])
      }

      submit(formData)
    }
  }

  return (
    <Form>
      <UploadImage
        image={create.file}
        clearImage={() => clearImage('file')}
        error={createErrors.file}
      >
        <input type="file" name="file" accept={ACCEPT_IPFS} onChange={onChangeFile} />
      </UploadImage>

      {create.file?.type === 'audio/mpeg' && (
        <UploadImage
          image={create.cover}
          imageType={true}
          clearImage={() => clearImage('cover')}
          error={createErrors.cover}
        >
          <input type="file" name="cover" accept={ACCEPT_COVER} onChange={onChangeFile} />
        </UploadImage>
      )}

      <RadioCards>
        <RadioCard title="User Assets" extra="ERC-721" icon={Marketplace}>
          <input
            type="radio"
            name="collection"
            value="user-assets"
            checked={create.collection === 'user-assets'}
            onChange={onChange}
          />
        </RadioCard>
      </RadioCards>

      <LabelWrapper title="Title" error={createErrors.title}>
        <Input name="title" value={create.title} onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Description" error={createErrors.description}>
        <Input
          name="description"
          value={create.description}
          error={!!createErrors.description}
          onChange={onChange}
        />
      </LabelWrapper>

      <LabelWrapper title="Email" error={createErrors.email}>
        <Input value={create.email} name="email" onChange={onChange} />
      </LabelWrapper>

      <LabelWrapper title="Request message (optional)">
        <Textarea rows={1} name="request" value={create.request} onChange={onChange} />
      </LabelWrapper>

      <Upload title="Attach additional files (optional)" files={create.confirming}>
        <input
          type="file"
          name="addition"
          accept={ACCEPT_DOCUMENT}
          onChange={onChangeFile}
          multiple
        />
      </Upload>

      <SubmitButton
        whitelisted={whitelisted && !checkErrors(createErrors, 'DEFAULT')}
        submit={submitHandle}
      />
      <SuccessSendModal status={status} close={close} />
    </Form>
  )
}
