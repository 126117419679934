export const parseText = (text: string): string => {
  let newLineText = text.replace(
    '\n',
    `

`
  )
  const links = newLineText.match(
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  )
  if (links) {
    links.map((link) => {
      newLineText = newLineText.replace(link, `<${link}>`)
    })
  }
  return newLineText
}
