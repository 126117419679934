import React from 'react'

import { Profiles } from '../../../domain'
import { MiniAvatar } from '../MiniAvatar'
import { CardMiniavas } from './styles'

export const MiniAvatars = ({
  profiles,
  preview
}: {
  profiles: Profiles
  preview?: boolean
}): JSX.Element => {
  return (
    <CardMiniavas>
      <MiniAvatar profile={profiles.collection} type="Collection" preview={preview} />
      {profiles.owner && <MiniAvatar profile={profiles.owner} type="Owner" preview={preview} />}
      <MiniAvatar profile={profiles.creator} type="Creator" preview={preview} />
    </CardMiniavas>
  )
}
