import styled from 'styled-components'

export const Button = styled.button`
  color: var(--blue);
  position: absolute;
  bottom: 17px;
  right: 20px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  padding: 0;

  &[disabled] {
    opacity: 0.7;
  }
`
