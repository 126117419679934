import React from 'react'

import { useRollapp } from '../../../hooks/useRollapp'

export const CancelBtn = (): JSX.Element => {
  const { setModal } = useRollapp()
  return (
    <button onClick={() => setModal(undefined)} className="block-button button">
      Cancel
    </button>
  )
}
