import React from 'react'

import { useRollapp } from '../../../hooks/useRollapp'
import { Web3Type } from '../../../types/web3'
import { RechargePoolModal } from '../../modals'
import { GetSkETHModal, SignUpModal } from '../../modals'
import { GetOneModal } from '../../modals/GetOne'

export const AccountModals = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  const { modal, network } = useRollapp()

  return (
    <>
      {modal === 'sign-up' && <SignUpModal web3={web3} />}
      {modal === 'get-sketh' && network === 'skale' && <GetSkETHModal web3={web3} />}
      {modal === 'recharge-pool' && network === 'eth' && <RechargePoolModal web3={web3} />}
      {modal === 'get-one' && network === 'harmony' && <GetOneModal web3={web3} />}
    </>
  )
}
