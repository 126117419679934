import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router'

import { Token } from '../../../domain'
import { useRollapp } from '../../../hooks/useRollapp'
import { getTokenLink } from '../../../utils/getTokenLink'
import { PriceUI } from '../../common/PriceUI'
import { CardBid } from './styles'

export const BuyButton = ({ token, preview }: { token: Token; preview?: boolean }): JSX.Element => {
  const { openToken } = useRollapp()
  const { minPrice, highestBid } = token
  const { account } = useWeb3React()
  const history = useHistory()

  const onClick = (buy: boolean) => {
    if (!preview) {
      if (account) {
        if (buy) {
          history.push(getTokenLink(token))
        } else {
          openToken('place-bid', token, account)
        }
      } else {
        history.push('/login')
      }
    }
  }

  return (
    <CardBid onClick={() => onClick(!!minPrice)}>
      {minPrice ? (
        'Buy now'
      ) : highestBid !== '0' && highestBid ? (
        <>
          Highest bid <PriceUI value={highestBid} />
        </>
      ) : (
        'Place bid'
      )}
    </CardBid>
  )
}
