import { EXCHANGEV2 } from '../../contracts/exchange/addresses'
import { AssetType, DataOrder } from './types'

export const dataOrder = (chainId: ChainId): DataOrder => ({
  '@type': 'EIP712',
  domain: {
    name: 'Exchange',
    version: '2',
    chainId: chainId,
    verifyingContract: EXCHANGEV2[chainId]
  },
  structType: 'Order',
  types: {
    AssetType: [
      {
        name: 'assetClass',
        type: 'bytes4'
      },
      {
        name: 'data',
        type: 'bytes'
      }
    ],
    Asset: [
      {
        name: 'assetType',
        type: 'AssetType'
      },
      {
        name: 'value',
        type: 'uint256'
      }
    ],
    Order: [
      {
        name: 'maker',
        type: 'address'
      },
      {
        name: 'makeAsset',
        type: 'Asset'
      },
      {
        name: 'taker',
        type: 'address'
      },
      {
        name: 'takeAsset',
        type: 'Asset'
      },
      {
        name: 'salt',
        type: 'uint256'
      },
      {
        name: 'start',
        type: 'uint256'
      },
      {
        name: 'end',
        type: 'uint256'
      },
      {
        name: 'dataType',
        type: 'bytes4'
      },
      {
        name: 'data',
        type: 'bytes'
      }
    ]
  }
})

export const DOMAIN_TYPE: AssetType[] = [
  {
    type: 'string',
    name: 'name'
  },
  {
    type: 'string',
    name: 'version'
  },
  {
    type: 'uint256',
    name: 'chainId'
  },
  {
    type: 'address',
    name: 'verifyingContract'
  }
]
