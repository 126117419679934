import styled from 'styled-components'

export const Box = styled.section`
  width: 100%;
  overflow: auto;
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;

  @media (max-width: 1216px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 916px) {
    grid-template-columns: repeat(8, 276px);
  }
  @media (max-width: 475px) {
    grid-template-columns: repeat(8, 184px);
    grid-gap: 12px;
  }
`
