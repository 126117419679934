import React from 'react'

import imageComment from '../../../assets/img/gifs/giphy.gif'
import av2 from '../assets/cards/card-avatar-1.svg'
import av3 from '../assets/cards/card-avatar-2.svg'
import av1 from '../assets/cards/card-avatar-3.svg'
import av5 from '../assets/cards/card-avatar-4.svg'
import av4 from '../assets/cards/card-avatar-5.svg'
import av6 from '../assets/cards/card-avatar-6.svg'
import av7 from '../assets/cards/card-avatar-7.svg'
import img2 from '../assets/cards/card-image-1.svg'
import img3 from '../assets/cards/card-image-2.svg'
import img1 from '../assets/cards/card-image-3.svg'
import img5 from '../assets/cards/card-image-4.svg'
import img4 from '../assets/cards/card-image-5.svg'
import img6 from '../assets/cards/card-image-6.svg'
import img7 from '../assets/cards/card-image-7.svg'
import {
  Container,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import { LandingCard } from './LandingCard'
import { Grid } from './styled'

const cards = [
  {
    avatar: av1,
    image: img1,
    comment: {
      name: 'Matt',
      text: 'Hustle 😎',
      color: '#679BFF'
    },
    url: '/collection/curio-geek-skale/owned'
  },
  {
    avatar: av2,
    image: img2,
    url: '/token/0xC98585469CEc63CDE9DDE8d3D1576f8FEeD36EC7:48193376455309515967050522751845148421088194490049922871417546149085602381838?network=skale'
  },
  {
    avatar: av3,
    image: img3,
    url: '/token/0xC98585469CEc63CDE9DDE8d3D1576f8FEeD36EC7:48193376455309515967050522751845148421088194490049922871417546149085602381850?network=skale'
  },
  {
    avatar: av4,
    image: img4,
    comment: {
      name: 'Gavin',
      text: 'Expect chaos 🔥',
      color: '#FFDA57'
    },
    url: '/token/0xC98585469CEc63CDE9DDE8d3D1576f8FEeD36EC7:48193376455309515967050522751845148421088194490049922871417546149085602381847?network=skale'
  },
  {
    avatar: av5,
    image: img5,
    comment: {
      name: 'Mike',
      text: <img src={imageComment} />,
      color: '#2395FF'
    },
    url: '/token/0xe89f14E3fcF4bE1B7976548A1060087E3CCC97F2:58730539959040472068292902044525789677171959723598098704435800198311974010885'
  },
  {
    avatar: av6,
    image: img6,
    comment: {
      name: 'Vitalik',
      text: 'Vitalik agree 👍',
      color: '#88FFC6'
    },
    url: '/token/0x6884eF328EA1862E69beD5aA30FfAfD4ed096Ce8:99326131137483886891004264430325786609158860882489678491178031342521990250497/details'
  },
  {
    avatar: av7,
    image: img7,
    url: '/token/0x8EFcFc84643a8e46dAC36a4b6777e12e516AEee4:48193376455309515967050522751845148421088194490049922871417546149085602381826?network=skale'
  }
]

export const ItemsSection = (): JSX.Element => {
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SectionTitle>Featured items</SectionTitle>
          <SectionDescription>View and tour these stunning valuable objects.</SectionDescription>
        </SectionHeader>
        <Grid>
          {cards.map((item) => (
            <LandingCard
              key={item.image}
              image={item.image}
              avatar={item.avatar}
              comment={item.comment}
              url={item.url}
            />
          ))}
        </Grid>
      </Container>
    </SectionWrapper>
  )
}
