import React from 'react'

import { fileListString } from '../../../../utils/fileListString'
import { FilesWrapper, Label } from './styles'

type Props = {
  children: JSX.Element
  title: string
  files?: FileList | null
}

export const Upload = ({ title, children, files }: Props): JSX.Element => {
  return (
    <Label>
      <p>{title}</p>
      <FilesWrapper>
        <div className="button">Upload</div>
        {files && <span>{fileListString(files)}</span>}
      </FilesWrapper>
      {children}
    </Label>
  )
}
