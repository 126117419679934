import { useEffect, useState } from 'react'

import { ProfileUi } from '../domain'
import { clientV3 } from '../services/api'
import { convertProfileUI } from '../utils/convertProfileUI'
import { getUsingNetwork } from '../utils/network'
import { useParamsAddress } from './useParamsAddress'

export const useProfileData = (collection?: boolean): ProfileUi | undefined | null => {
  const [data, setData] = useState<ProfileUi | undefined | null>()
  const { address } = useParamsAddress()
  const network = getUsingNetwork()

  useEffect(() => {
    if (collection && address) {
      clientV3.collection
        .get(address, address.includes('0x') ? network : undefined)
        .then((res) => {
          setData({ ...res.data, walletAddress: res.data.ethAddress })
        })
        .catch(() => {
          setData(null)
        })
    } else if (!collection && address) {
      clientV3.profile
        .get(address)
        .then((res) => {
          setData(convertProfileUI(res.data))
        })
        .catch(() => {
          setData(null)
        })
    }
  }, [address, collection, network])

  return data
}
