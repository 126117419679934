import { CHAIN_ID } from '../domain/network/contstant'

export const SCHAIN_NAME = {
  [CHAIN_ID.eth.mainnet]: 'fit-betelgeuse',
  [CHAIN_ID.eth.testnet]: 'fit-graffias'
}

export const SCHAIN_HASH = {
  [CHAIN_ID.eth.mainnet]: '0x8d646f556e5d9d6f1edcf7a39b77f5ac253776eb34efcfd688aacbee518efc26',
  [CHAIN_ID.eth.testnet]: '0x429e12e684e8aa808f80f10367d1bb117bc57a2e9d1ebf80675be903aaa3b719'
}
