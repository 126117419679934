import React, { useState } from 'react'

import { claim } from '../../../../contracts/nft-lottery'
import { ADDRESS_NULL, Token } from '../../../../domain'
import { useNFTPrizeClaimed, useNFTPrizeCollections } from '../../../../hooks/useBonus'
import { Web3Type } from '../../../../types/web3'
import { Modal } from '../../../common/Modal'
import { Loader } from '../../../components'
import { CancelBtn } from '../../../components/CancelBtn'
import { Description, Wrapper } from '../../../components/ModalStyles'
import { StatusBlock } from '../../../components/StatusBlock'
import { NFTPrizeDescription } from './Description'
import { WinProcess } from './WinProcess'
import { WinText } from './WinText'

export const GetNFTPrizeModal = ({
  web3,
  token
}: {
  web3: Web3Type
  token: Token
}): JSX.Element => {
  const [done, setDone] = useState(false)
  const collections = useNFTPrizeCollections(web3)
  const claimed = useNFTPrizeClaimed(web3, token, done, collections)

  return (
    <Modal title="Get NFT Prize" width="350px">
      <Wrapper>
        {collections === undefined || claimed === undefined ? (
          <Loader center />
        ) : !collections ? (
          <Description>
            Unfortunately, all NFT prizes have already been raffled off in this lottery before.
          </Description>
        ) : (
          claimed && (
            <>
              {!done && <NFTPrizeDescription />}
              {claimed.isSpent &&
                (done ? (
                  <WinProcess claimed={claimed} />
                ) : claimed.reward.collectionAddress !== ADDRESS_NULL ? (
                  <WinText claimed={claimed} />
                ) : (
                  <Description>
                    This Founders Edition NFT participated the lottery and did not win the prize
                    NFT.
                  </Description>
                ))}
              {!claimed.isSpent && (
                <StatusBlock
                  name="Try to win"
                  text="Get NFT Prize"
                  method={async () => {
                    const res = await claim(web3, token)
                    setDone(res)
                    return res
                  }}
                />
              )}
              {done ? (
                <button onClick={() => setDone(false)} className="block-button button">
                  Ok
                </button>
              ) : (
                <CancelBtn />
              )}
            </>
          )
        )}
      </Wrapper>
    </Modal>
  )
}
