import axios from 'axios'

import { Client } from './types'

export const baseURL = 'https://beta.rollapp.store/messenger-api/v1'

const client = axios.create({
  baseURL
})

const createHeaders = (account: UserAddress, sign: string) => ({
  headers: {
    'x-Wallet-signature': sign,
    'x-Wallet-address': account
  }
})

export const api: Client = {
  chat: {
    create: (to, from, sign) =>
      client.post('/message/create-conversation', { memberId: to }, createHeaders(from, sign)),
    all: (account, sign) => client.get('/message/get-conversations', createHeaders(account, sign)),
    get: (id, account, sign) =>
      client.get('/message/get-messages-by-id', {
        params: { chatId: id, pageSize: 10, pageIndex: 0 },
        ...createHeaders(account, sign)
      })
  }
}
