import { useEffect, useState } from 'react'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Modal } from '../../../ui/common/Modal'

export const TermsModal = ({
  terms,
  local,
  close,
  accept
}: {
  terms: string
  local: string
  accept?: () => void
  close?: () => void
}): JSX.Element | null => {
  const [accepted, setAccepted] = useState(true)

  const acceptHandle = () => {
    setAccepted(true)
    localStorage.setItem(local, 'true')
    if (accept) {
      accept()
    }
  }

  useEffect(() => {
    if (!localStorage.getItem(local)) {
      setAccepted(false)
    }
  }, [local])

  return (!accepted && !localStorage.getItem(local)) || !!close ? (
    <Modal
      title="Buy Shares Terms and Conditions"
      width="1000px"
      close={!!close}
      closeHandle={close}
    >
      <>
        <ReactMarkdown className="markdown">{terms}</ReactMarkdown>
        {!close && (
          <button className="button primary-button center" onClick={acceptHandle}>
            Accept
          </button>
        )}
      </>
    </Modal>
  ) : null
}
