import { useEffect, useState } from 'react'
import React from 'react'

import { MAIN_MENU } from '../../../config/menu'
import { MenuBlock } from '../../components/MenuBlock'
import { Nav, NavButton } from './styles'

export const Menu = (): JSX.Element => {
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { innerWidth } = window
      if (innerWidth > 1024) {
        setMenu(false)
      }
    })
  }, [])

  return (
    <>
      <Nav>
        <MenuBlock items={MAIN_MENU} />
      </Nav>
      {menu && <MenuBlock items={MAIN_MENU} mobile />}
      <NavButton onClick={() => setMenu(!menu)} />
    </>
  )
}
