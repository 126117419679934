import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'

import { isInvestorAddressActive1155 } from '../contracts/erc1155/methods'
import { isInvestorAddressActive } from '../contracts/whitelist/methods'
import { Token } from '../domain'
import { validate } from '../services/validate-form'
import { Web3Type } from '../types/web3'

export const useWhitelist = (): boolean | undefined => {
  const { account, chainId, library } = useWeb3React()
  const [whitelisted, setWhitelisted] = useState<boolean>()

  useEffect(() => {
    setWhitelisted(undefined)
    if (account && chainId) {
      isInvestorAddressActive({ account, chainId, library }).then((res) => {
        setWhitelisted(res)
      })
    } else {
      setWhitelisted(false)
    }
  }, [account, chainId, library])

  return whitelisted
}

export const useWhitelistERC1155 = (
  web3: Web3Type,
  token: Token,
  address?: UserAddress
): boolean | undefined => {
  const [whitelisted, setWhitelisted] = useState<boolean>()

  useEffect(() => {
    if (token.collection.isKycRequired) {
      const valid = address ? validate(['eth'], address) === null : true
      if (valid) {
        isInvestorAddressActive1155(web3, token.contract, address).then((res) => {
          setWhitelisted(res)
        })
      }
    } else {
      setWhitelisted(true)
    }
  }, [address, token, web3])

  return whitelisted
}
