import { names } from './index'

export const MIN5 = 1000 * 60 * 5

export const getTimeLimit = (): number => {
  const localData = localStorage.getItem(names.timer)
  return localData ? parseInt(JSON.parse(localData)) : 0
}

export const isTimeLimit = (): boolean => {
  const now = new Date()
  const time = getTimeLimit()
  const condition = time === 0 || now.getTime() - time > MIN5
  if (condition) {
    localStorage.removeItem(names.timer)
  }
  return condition
}

export const setTimeLimit = (): void => {
  const now = new Date()
  localStorage.setItem(names.timer, String(now.getTime()))
}
