import React, { useState } from 'react'

import arrow from './../../../../assets/img/icons/selector-arrow.svg'
import { CardAnswer, CardContent, CardIconBlock, CardQuestion, CardWrapper } from './styled'

type Props = {
  question: string
  answer: string
}

export const QuestionCard = (props: Props): JSX.Element => {
  const [openCard, setOpenCard] = useState(false)
  return (
    <CardWrapper onClick={() => setOpenCard(!openCard)}>
      <CardIconBlock open={openCard}>
        <img src={arrow} />
      </CardIconBlock>
      <CardContent>
        <CardQuestion>{props.question}</CardQuestion>
        {openCard && <CardAnswer>{props.answer}</CardAnswer>}
      </CardContent>
    </CardWrapper>
  )
}
