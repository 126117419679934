import styled from 'styled-components'

import dots from '../../../assets/img/icons/dots-grey.svg'

export const Wrapper = styled.main`
  position: relative;
  --width: 532px;
  --margin-row: 36px;

  @media (max-width: 970px) {
    --width: 376px;
  }

  @media (max-width: 768px) {
    --width: 100%;
    padding-top: 0;
  }
`

export const ImageWrapper = styled.div`
  top: var(--header-height);
  bottom: 0;
  right: 0;
  left: var(--width);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 40px calc(var(--header-height) + 50px);
  background-color: var(--white);

  @media (max-width: 768px) {
    position: initial;
    padding: 50px 20px 48px;
  }

  @media (max-width: 576px) {
    padding-bottom: 20px;
  }
`

export const ImageInner = styled.div`
  width: 100%;
  max-width: 512px;
  height: calc(100% - 32px - var(--margin-row));

  img,
  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
    cursor: zoom-in;
  }
`

export const ImageInfo = styled.div`
  height: 100%;
`

export const ImageRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 32px;
  margin-bottom: var(--margin-row);
  position: relative;
`

export const MoreButton = styled.button`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid var(--line-day);
  margin-left: 16px;
  background: url(${dots}) no-repeat center;
`
