import React from 'react'

import { createOrder, Token } from '../../../../domain'
import { Web3Type } from '../../../../types/web3'
import { StatusBlock } from '../../../components/StatusBlock'

export const Unlimited = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  return (
    <StatusBlock
      name="Sign message"
      text="Sign message to put your NFT on our marketplace. It'll be also available for bids"
      method={() => createOrder(web3, token, '0', '0', false, true)}
    />
  )
}
