export const names = {
  connect: 'rollapp-disconnected',
  usd: 'rollapp-usd',
  stake: 'rollapp-stake-terms',
  timer: 'rollapp-skale-bridge',
  marioTerms: 'rollapp-1155-mario',
  mercTerms: 'rollapp-merc-1155',
  musicClubTerms: 'rollapp-music-club',
  leoTrust: 'rollapp-leo-trust',
  messenger: 'rollapp-messenger'
}
