import styled from 'styled-components'

export const Textarea = styled.textarea`
  border: 1px solid var(--line-day);
  transition: 0.2s border-color;
  border-radius: 8px;
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 20px;
  background-color: var(--white);
  font-weight: 400;
  outline: none;
  resize: none;

  &::placeholder {
    color: var(--light-gray);
  }

  &:focus {
    border-color: var(--blue);
  }
`
