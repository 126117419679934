import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { Modal } from '../../../ui/common/Modal'
import { IFrame } from '../../../ui/common/Modal/styles'
import { Avatar } from '../../../ui/components/Avatar'
import { ComingSoonModal } from '../../../ui/modals/ComingSoonModal'
import { SubscribeFormModal } from '../../../ui/modals/SubscribeModal/Form'
import { Content } from '../content'
import cards from './../../../assets/img/cards-icon.svg'
import {
  AvatarBlock,
  InfoLowerBlock,
  InfoTitles,
  OrganizationName,
  TitleName,
  TopInfoBlock,
  ValuesImage,
  ValuesInfo,
  ValuesNumber,
  Wrapper
} from './styled'

export const Top = ({ content }: { content: Content }): JSX.Element => {
  const [modal, setModal] = useState(false)
  const [terms, setTerms] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')
  const [modalSoon, setModalSoon] = useState(false)
  const { card, staking, nft, secondNft, claim, secondBuyUrl } = content
  const history = useHistory()

  const onClick = (url?: string) => {
    if (
      url &&
      card.url !== 'music-club' &&
      card.url !== 'leo-trust' &&
      card.url !== 'vindome-wine'
    ) {
      history.push(url)
    } else if (url) {
      setCurrentUrl(url)
      setTerms(true)
    }
  }

  const accept = () => {
    if (currentUrl !== '') {
      history.push(currentUrl)
    }
  }

  return (
    <Wrapper bg={content.cover}>
      <TopInfoBlock>
        <AvatarBlock>
          <Avatar width="80px" src={card.avatar} />
          {card.secondAvatar && <Avatar width="80px" src={card.secondAvatar} />}
        </AvatarBlock>
        <InfoTitles>
          <OrganizationName>{card.organization}</OrganizationName>
          <TitleName>{card.name}</TitleName>
        </InfoTitles>
        <InfoLowerBlock>
          {content.buyUrl ? (
            <button className="button" onClick={() => onClick(content.buyUrl)}>
              {content.buyName ? content.buyName : 'Buy NFT'}
            </button>
          ) : (
            <button onClick={() => setModal(true)} className="button">
              Buy NFT
            </button>
          )}
          {staking && (
            <button onClick={() => setModal(true)} className="button">
              Staking
            </button>
          )}
          {claim && (
            <button onClick={() => setModalSoon(true)} className="button">
              Claim original
            </button>
          )}
          <ValuesInfo>
            <ValuesImage>
              <img src={cards} />
            </ValuesImage>
            <ValuesNumber>x{nft} NFT</ValuesNumber>
          </ValuesInfo>
        </InfoLowerBlock>
        {secondBuyUrl && (
          <InfoLowerBlock>
            <button className="button" onClick={() => onClick(secondBuyUrl)}>
              {content.secondBuyName ? content.secondBuyName : 'Buy NFT'}
            </button>
            {secondNft && (
              <ValuesInfo>
                <ValuesImage>
                  <img src={cards} />
                </ValuesImage>
                <ValuesNumber>x{secondNft} NFT</ValuesNumber>
              </ValuesInfo>
            )}
          </InfoLowerBlock>
        )}
      </TopInfoBlock>
      {modal && <SubscribeFormModal close={() => setModal(false)} coming />}
      {modalSoon && <ComingSoonModal close={() => setModalSoon(false)} />}
      {terms && (
        <Modal
          title="Buy Shares Terms and Conditions"
          width="1000px"
          close
          closeHandle={() => setTerms(false)}
        >
          <>
            <IFrame src="/docs/curio-terms-and-conditions-individual-goods-cars-tokenization.pdf" />
            <button className="button primary-button center" onClick={accept}>
              Accept
            </button>
          </>
        </Modal>
      )}
    </Wrapper>
  )
}
