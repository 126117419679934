import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TopCardWrap = styled(Link)`
  display: grid;
  grid-template-columns: 14px 36px 1fr;
  grid-gap: 12px;
  align-items: center;
  @media screen and (max-width: 640px) {
    width: 100%;
  }
  @media screen and (max-width: 475px) {
    grid-gap: 8px;
  }
`

export const TopCardNumber = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
  min-width: 14px;
  text-align: center;
`

export const TopCardName = styled.p`
  font-weight: bold;
`

export const TopCardValue = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
`
