import styled from 'styled-components'

export const Wrapper = styled.a`
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-shrink: 0;
  position: fixed;
  bottom: 28px;
  border-radius: 50%;
  background: var(--line-day);
  z-index: 14;
  right: 32px;
  opacity: 50%;
  &:hover {
    opacity: 100%;
  }
  & img {
    width: 28px;
    height: auto;
  }
  @media (max-width: 640px) {
    right: 40px;
  }
`
