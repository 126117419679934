import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledLink = styled(Link)`
  display: block;
  max-width: 276px;
  min-height: 180px;
  padding: 23px 25px;
  border-radius: 20px;
  text-decoration: none;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    z-index: 0;
  }

  @media screen and (max-width: 475px) {
    min-height: 120px;
    padding: 16px;
  }
`

export const CardText = styled.p<{ dark?: boolean }>`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  color: var(${(props) => (props.dark ? '--black' : '--white')});
  z-index: 1;
  position: relative;

  @media screen and (max-width: 475px) {
    font-size: 20px;
    line-height: 28px;
  }
`
