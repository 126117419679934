import styled from 'styled-components'

import select from '../../../../assets/img/icons/selector-arrow.svg'

export const Select = styled.select`
  padding: 0 20px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--line-day);
  transition: 0.3s border-color;
  outline: none;
  width: 100%;
  appearance: none;
  background: url(${select}) no-repeat var(--white);
  background-position: calc(100% - 20px) center;
  background-size: auto;
  font-size: 14px;

  &:focus {
    border-color: var(--blue);
  }
`
