import React from 'react'

import { MenuItem } from '../../../config/menu'
import { Item, Nav, Wrapper } from './styles'

export const MenuBlock = ({
  items,
  mobile
}: {
  items: MenuItem[]
  mobile?: boolean
}): JSX.Element => {
  return (
    <Nav className={mobile ? 'nav_mobile' : ''}>
      <Wrapper>
        {items.map((item) => (
          <li key={item.title}>
            <Item to={item.link} exact={item.exact}>
              {item.title}
            </Item>
          </li>
        ))}
      </Wrapper>
    </Nav>
  )
}
