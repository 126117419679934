import { ADDRESS_NULL } from '../constant'
import { OrderApi } from '../types'

export const getFixedPriceOrderByOwner = (orders: OrderApi[], owner: UserAddress): OrderApi[] =>
  orders.filter(
    (item) =>
      !!parseInt(item.take.value) &&
      item.status === 'ACTIVE' &&
      (owner ? item.maker.toLowerCase() === owner.toLowerCase() : true) &&
      item.taker === ADDRESS_NULL
  )

export const getFixedPriceOrder = (orders: OrderApi[], owner: UserAddress): OrderApi[] =>
  orders.filter(
    (item) =>
      !!parseInt(item.take.value) &&
      item.status === 'ACTIVE' &&
      item.maker.toLowerCase() !== owner.toLowerCase() &&
      item.taker === ADDRESS_NULL
  )

export const getOrderByOwner = (orders: OrderApi[], owner: UserAddress): OrderApi | undefined =>
  orders.filter((order) => order.maker.toLowerCase() === owner.toLowerCase())[0]
