import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Title = styled.p`
  font-size: 24px;
  margin-bottom: 24px;
`

export const P = styled.p`
  color: var(--text-gray);
  margin-bottom: 24px;
`
