import React from 'react'

import { Step } from '../../../config/roadmap-steps'
import { Card, CardContent, CardDescription, CardText, CardTop, DateBox, StatusBox } from './styles'

export const RoadmapCard = ({ step }: { step: Step }): JSX.Element => (
  <Card>
    <CardTop className="step__top">
      {step.status && <StatusBox status={step.status}>{step.status}</StatusBox>}
      <DateBox>{step.date}</DateBox>
    </CardTop>
    <CardContent>
      <CardDescription>{step.description}</CardDescription>
      <CardText>{step.text}</CardText>
    </CardContent>
  </Card>
)
