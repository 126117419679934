import { names } from '../../../../services/localStorage'
import car1 from '../../media/cars/1-2.mp4'
import car1Mini from '../../media/cars/1-mini.mp4'
import car2 from '../../media/cars/2-2.mp4'
import car2Mini from '../../media/cars/2-mini.mp4'
import car3 from '../../media/cars/3-2.mp4'
import car3Mini from '../../media/cars/3-mini.mp4'
import car4 from '../../media/cars/4-2.mp4'
import car4Mini from '../../media/cars/4-mini.mp4'
import fernando from '../../media/fernando-squer.png'
import mercedes from '../../media/mercedes.png'
import mercedesAvatar from '../../media/mercedes-squer.png'
import { DaoData } from '../data'
import { DETAILS } from '../details'
import Info from '../info/Mercedes'

export const MercedesAmgGTS: DaoData = {
  hashtag: 'MERCEDESAMGGTS',
  title: 'Mercedes AMG GTS',
  pages: ['info', 'price', 'details', 'quotes', 'visuals', 'timeline'],
  footer: true,
  info: {
    img: mercedes,
    docs: {
      opinions: true,
      legal: true,
      details: true,
      gallery: true
    },
    soldOut: true,
    data: Info
  },
  details: DETAILS['mercedes-amg-gts'],
  price: {
    value: '100,000',
    change: '+10'
  },
  quotes: [
    {
      name: 'Mercedes-Benz',
      image: mercedesAvatar,
      text: '"The best or nothing"'
    },
    {
      name: 'Rey Fernando Verboonen',
      image: fernando,
      text: 'What next? Oh yes, turning a luxury car into a non-fungible token'
    }
  ],
  visuals: {
    text: 'Powerful appearance and timeless elegance',
    images: [car1, car2, car3, car4],
    mini: [car1Mini, car2Mini, car3Mini, car4Mini]
  },
  timeline: {
    events: [
      {
        date: '09.2015',
        text: 'Mercedes-Benz AMG GTS production '
      },
      {
        date: '08.2016',
        text: 'The car was delivered to its owner in Moscow, Russia'
      },
      {
        date: '06.2020',
        text: 'A new car owner from St.Petersburg, Russia'
      },
      {
        date: '07.2021',
        text: 'The Mercedes-Benz AMG GTS has come to Zurich, Switzerland, thanks to a new owner from St.Petersburg'
      },
      {
        date: '09.2021',
        text: 'Curio Capital AG is a new Mercedes-Benz AMG GTS car owner'
      },
      {
        date: '10.2021',
        text: 'The world`s first DAO car owned by the community'
      }
    ]
  },
  moreDetails: [
    {
      title: 'Financials',
      items: [
        {
          title: 'Current price per share',
          value: '$1,000'
        },
        {
          title: 'Initial offering price per share',
          value: '$1,000'
        },
        {
          title: 'Current market cap',
          value: '$100,000'
        },
        {
          title: 'Initial offering market cap',
          value: '$100,000'
        },
        {
          title: 'Numbers of shares',
          value: '100'
        },
        {
          title: 'Initial offering',
          value: 'October 2021'
        },
        {
          title: 'Storage location',
          value: 'Curio Capital AG'
        }
      ]
    },
    {
      title: 'Assets specifications',
      items: [
        {
          title: 'Year',
          value: '2015'
        },
        {
          title: 'Make',
          value: 'Mercedes'
        },
        {
          title: 'Mileage',
          value: '≈ 30 000'
        }
      ]
    }
  ],
  terms: {
    local: names.mercTerms
  }
}
