import React from 'react'

import { CheckboxLabel } from './styles'

type Props = {
  children: JSX.Element
  right?: number
  text: string
  center?: boolean
}

export const Checkbox = ({ right, text, children, center }: Props): JSX.Element => (
  <CheckboxLabel right={right} center={center}>
    {children}
    <span />
    <div>{text}</div>
  </CheckboxLabel>
)

export { CheckboxInput } from './styles'
