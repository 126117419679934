import React from 'react'

import BrowseLink from '../_common/BrowseLink'
import { DAO_DATA, VisualsType } from '../data/data'
import gallery from '../media/icons/gallery.svg'
import {
  GalleryItem,
  Inner,
  ItemName,
  MiniGallery,
  PageContainer,
  PageName,
  TextBlock,
  Wrapper
} from './styled'

export const Visuals = (props: { tag: string; visuals: VisualsType }): JSX.Element => {
  const { title, hashtag } = DAO_DATA[props.tag]

  return (
    <Inner id="visuals" className="slide">
      <Wrapper>
        <PageContainer>
          <ItemName>#{hashtag}</ItemName>
          <PageName className="h2">{title}</PageName>
          {props.visuals.text && <TextBlock>{props.visuals.text}</TextBlock>}
          <MiniGallery>
            {props.visuals.mini.map((item) => (
              <GalleryItem key={item}>
                {item.includes('.mp4') ? (
                  <video playsInline autoPlay muted loop>
                    <source src={item} type="video/mp4" />
                  </video>
                ) : (
                  <img src={item} />
                )}
              </GalleryItem>
            ))}
          </MiniGallery>
          <BrowseLink img={gallery} text="Launch gallery" to={`/dao-nfts/${props.tag}/gallery`} />
        </PageContainer>
      </Wrapper>
    </Inner>
  )
}

export default Visuals
