import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

import { CONNECTORS_EXTRA, connectorsByName } from '../../config/connectors'
import { ConnectorActivate } from '../../config/connectors/types'
import { useRollapp } from '../../hooks/useRollapp'
import { connectLS } from '../../services'
import { selectMetamaskNetwork } from '../../services/metamask'
import { BackLink } from '../../ui/components/BackLink'
import { WalletButton } from '../../ui/components/WalletButton'
import {
  LoginContainer,
  LoginContent,
  LoginDescription,
  LoginDisclaimer,
  LoginLeftSide,
  LoginTitle,
  LoginWallets
} from './style'

export const Login = (): JSX.Element => {
  const history = useHistory()
  const { account, activate } = useWeb3React()
  const { network } = useRollapp()
  const [metamaskErr, setMetamaskErr] = useState(false)

  const connect = async (connector: ConnectorActivate, isMetamask: boolean) => {
    if (isMetamask && metamaskErr) {
      window.open('https://metamask.io/download/', '_blank')
    } else {
      await activate(connector)
      const connected = await selectMetamaskNetwork(network)
      if (connected) {
        connectLS()
        history.goBack()
      }
    }
  }

  useEffect(() => {
    setMetamaskErr(!(window as any).ethereum)
  }, [])

  return !account ? (
    <>
      <LoginContainer>
        <LoginLeftSide />
        <LoginContent>
          <BackLink onClick={() => history.push('/')}>Back</BackLink>
          <LoginTitle>Connect your wallet</LoginTitle>
          <LoginDescription>
            Connect with one of available wallet providers or create a new wallet
          </LoginDescription>
          <LoginWallets>
            <>
              {Object.keys(connectorsByName).map((name) => {
                const currentConnector = connectorsByName[name]

                return (
                  <WalletButton
                    name={currentConnector.display.name}
                    key={currentConnector.display.name}
                    logo={currentConnector.display.logo}
                    extra={CONNECTORS_EXTRA[name]}
                    description={currentConnector.display.description}
                    onClick={() =>
                      connect(
                        currentConnector.connect,
                        currentConnector.display.name === 'Metamask'
                      )
                    }
                    metamaskErr={metamaskErr}
                  />
                )
              })}
            </>
          </LoginWallets>
          <LoginDisclaimer>
            We do not own your private keys and cannot access your funds without your confirmation
          </LoginDisclaimer>
        </LoginContent>
      </LoginContainer>
    </>
  ) : (
    <Redirect to="/" />
  )
}
