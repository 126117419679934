import Web3 from 'web3'

import { SCHAIN_NAME } from '../../config/shain'
import { Token } from '../../domain'
import { isSkaleNetwork } from '../../domain/network/mappers'
import { clientV3 } from '../../services/api'
import { setTimeLimit } from '../../services/localStorage/bridge-timer'
import { Web3Type } from '../../types/web3'
import { contractError } from '../contractError'
import { BRIDGE_721_ABI, BRIDGE_1155_ABI, DEPOSIT_BOX_ABI } from './abi'
import { TOKEN_MANAGER, TOKEN_MANAGER_ERC1155 } from './addresses'

export const moveToSkale1155 = async (
  w: Web3Type,
  token: Token,
  amount: string
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(TOKEN_MANAGER_ERC1155[w.chainId])
    await new web3.eth.Contract(DEPOSIT_BOX_ABI, TOKEN_MANAGER_ERC1155[w.chainId]).methods
      .depositERC1155(SCHAIN_NAME[w.chainId], token.contract, token.tokenId, amount)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

// Move ERC-721 from Ethereum to SKALE
export const moveToSkale = async (w: Web3Type, token: Token): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(TOKEN_MANAGER[w.chainId])
    await new web3.eth.Contract(DEPOSIT_BOX_ABI, TOKEN_MANAGER[w.chainId]).methods
      .depositERC721(SCHAIN_NAME[w.chainId], token.contract, token.tokenId)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

// Get tethered contract on Ethereum by SKALE contract
const getContractOnEthereum = async (
  network: Network,
  contractAddress: ContractAddress
): Promise<ContractAddress | undefined> => {
  try {
    const tetheredCollections = await clientV3.tokenManagers.tethers(network, contractAddress)
    return tetheredCollections.data.tetheredCollections[0].ethAddress
  } catch (e) {
    console.error(e)
  }
}

// Move ERC-721 from SKALE to Ethereum
export const moveToEth = async (w: Web3Type, token: Token): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(TOKEN_MANAGER[w.chainId])

    const contractOnEthereum = await getContractOnEthereum(token.collection.network, token.contract)

    await new web3.eth.Contract(BRIDGE_721_ABI, TOKEN_MANAGER[w.chainId]).methods
      .exitToMainERC721(contractOnEthereum, token.tokenId)
      .send({ from: w.account })

    setTimeLimit()
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

// Move ERC-1155 from SKALE to Ethereum
export const moveToEth1155 = async (
  w: Web3Type,
  token: Token,
  amount: string
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(TOKEN_MANAGER_ERC1155[w.chainId])

    const contractOnEthereum = await getContractOnEthereum(token.collection.network, token.contract)

    await new web3.eth.Contract(BRIDGE_1155_ABI, TOKEN_MANAGER_ERC1155[w.chainId]).methods
      .exitToMainERC1155(contractOnEthereum, token.tokenId, amount)
      .send({ from: w.account })

    setTimeLimit()
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const moveToNetwork = async (
  w: Web3Type,
  token: Token,
  amount: string
): Promise<boolean> => {
  return isSkaleNetwork(token.collection.network)
    ? token.standard === 'ERC1155'
      ? await moveToEth1155(w, token, amount)
      : await moveToEth(w, token)
    : token.standard === 'ERC1155'
    ? await moveToSkale1155(w, token, amount)
    : await moveToSkale(w, token)
}
