import React from 'react'

import { Tab } from '../Tab'
import { Wrapper } from './styles'

type Props = {
  items: {
    link: string
    title: string
  }[]
}

export const Tabs = ({ items }: Props): JSX.Element => {
  const path = window.location.pathname
    .replace('/owned', '')
    .replace('/created', '')
    .replace('/bids', '')
    .replace('/users', '')
    .replace('/collections', '')
    .replace('/details', '')
  const pre = path.slice(-1) === '/' ? path.slice(0, -1) : path

  return (
    <Wrapper>
      {items.map((item) => (
        <li key={item.title}>
          <Tab
            title={item.title}
            link={`${pre}${item.link}${window.location.search.replace('%20', ' ')}`}
          />
        </li>
      ))}
    </Wrapper>
  )
}
