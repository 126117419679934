import React from 'react'

export const Category = (): JSX.Element => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    className="sc-bdvvtL sc-gsDKAQ jsnDXb sc-iqseJM gCFTKw"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8548 1.50746L11.957 0.730998C10.8901 0.445115 9.7934 1.07828 9.50751 2.14521L8.73106 5.04299C8.44517 6.10992 9.07834 7.2066 10.1453 7.49248L13.043 8.26894C14.11 8.55482 15.2067 7.92165 15.4925 6.85472L16.269 3.95694C16.5549 2.89001 15.9217 1.79334 14.8548 1.50746ZM10.9564 2.53344C11.0279 2.26671 11.302 2.10842 11.5688 2.17989L14.4666 2.95634C14.7333 3.02781 14.8916 3.30198 14.8201 3.56872L14.0436 6.46649C13.9722 6.73323 13.698 6.89152 13.4313 6.82005L10.5335 6.04359C10.2668 5.97212 10.1085 5.69795 10.1799 5.43122L10.9564 2.53344ZM5.5 1.49998L2.5 1.49998C1.39543 1.49998 0.5 2.39541 0.5 3.49998L0.5 6.49998C0.5 7.60455 1.39543 8.49998 2.5 8.49998H5.5C6.60457 8.49998 7.5 7.60455 7.5 6.49998V3.49998C7.5 2.39541 6.60457 1.49998 5.5 1.49998ZM2 3.49998C2 3.22384 2.22386 2.99998 2.5 2.99998L5.5 2.99998C5.77614 2.99998 6 3.22384 6 3.49998V6.49998C6 6.77612 5.77614 6.99998 5.5 6.99998L2.5 6.99998C2.22386 6.99998 2 6.77612 2 6.49998L2 3.49998ZM10.5 9.49998H13.5C14.6046 9.49998 15.5 10.3954 15.5 11.5V14.5C15.5 15.6046 14.6046 16.5 13.5 16.5H10.5C9.39546 16.5 8.50003 15.6046 8.50003 14.5V11.5C8.50003 10.3954 9.39546 9.49998 10.5 9.49998ZM10.5 11C10.2239 11 10 11.2238 10 11.5V14.5C10 14.7761 10.2239 15 10.5 15H13.5C13.7762 15 14 14.7761 14 14.5V11.5C14 11.2238 13.7762 11 13.5 11H10.5ZM5.5 9.49998H2.5C1.39543 9.49998 0.5 10.3954 0.5 11.5L0.5 14.5C0.5 15.6046 1.39543 16.5 2.5 16.5H5.5C6.60457 16.5 7.5 15.6046 7.5 14.5L7.5 11.5C7.5 10.3954 6.60457 9.49998 5.5 9.49998ZM2 11.5C2 11.2238 2.22386 11 2.5 11H5.5C5.77614 11 6 11.2238 6 11.5L6 14.5C6 14.7761 5.77614 15 5.5 15H2.5C2.22386 15 2 14.7761 2 14.5L2 11.5Z"
      fill="currentColor"
    />
  </svg>
)
