import React from 'react'

import { Collection, getStockForCard, PreviewProfile, standardUi, Token } from '../../../domain'
import { BLOCKCHAIN_NAME } from '../../../domain/network/contstant'
import { getNetworkIcon } from '../../../domain/network/mappers'
import { getCollectionLink } from '../../../utils/getProfileLink'
import { getTokenLink } from '../../../utils/getTokenLink'
import { shortAddress } from '../../../utils/shortAddress'
import { BidText } from '../../common/BidText'
import { TokenImage } from '../../common/TokenImage'
import { Avatar } from '../Avatar'
import { NetworkLogo } from '../NetworkLogo'
import {
  AvatarWrapper,
  CardInfo,
  CardStatus,
  CardWrapper,
  ItemImage,
  NetworkBadge,
  StatusText,
  StatusValue
} from './styled'

type Props = {
  token?: Token
  user?: PreviewProfile
  collection?: Collection
}

export const ResultCard = ({ token, user, collection }: Props): JSX.Element => {
  return (
    <CardWrapper
      to={
        token
          ? getTokenLink(token)
          : user
          ? `/profile/${user.personal.url || user.walletAddress}/owned`
          : collection
          ? getCollectionLink(collection)
          : ''
      }
    >
      {token ? (
        <ItemImage>
          <TokenImage token={token} />
        </ItemImage>
      ) : user ? (
        user && <Avatar width="48px" image={user.photo.avatarS3Link || user.photo.avatar} />
      ) : (
        collection && (
          <AvatarWrapper>
            <Avatar image={collection.avatarS3Link || collection.avatarIpfsHash} width="48px" />
            <NetworkBadge>
              <NetworkLogo icon={getNetworkIcon(collection.network)} size={24} />
            </NetworkBadge>
          </AvatarWrapper>
        )
      )}
      <CardInfo>
        <b>
          {token
            ? token.name
            : user
            ? user.personal.displayName || shortAddress(user.walletAddress)
            : collection
            ? collection.name
            : ''}
        </b>
        {token ? (
          <CardStatus>
            <StatusText>
              <BidText token={token} />
              <StatusValue>{getStockForCard(token)}</StatusValue>
            </StatusText>
          </CardStatus>
        ) : user ? (
          <StatusText />
        ) : (
          collection && (
            <StatusText>
              {standardUi(collection.tokenStandards)}, {BLOCKCHAIN_NAME[collection.network]}
            </StatusText>
          )
        )}
      </CardInfo>
    </CardWrapper>
  )
}
