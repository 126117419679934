import { useWeb3React } from '@web3-react/core'
import { useEffect, useState } from 'react'
import React from 'react'

import { injected } from './config/connectors'
import { isDisconnected } from './services'
import { Loading } from './ui/components'

type Props = {
  children: JSX.Element
}

const Web3Manager = (props: Props): JSX.Element => {
  const { active, activate } = useWeb3React()
  const [loaded, setLoaded] = useState(false)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const condition = !active && window.ethereum && !isDisconnected()

  useEffect(() => {
    const connect = () => {
      if (condition) {
        setLoaded(false)
        activate(injected)
          .then(() => {
            console.info('connected metamask')
            setLoaded(true)
          })
          .catch((e) => {
            console.error(e)
            setLoaded(true)
          })
      } else {
        setLoaded(true)
      }
    }
    connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return loaded ? props.children : <Loading />
}

export default Web3Manager
