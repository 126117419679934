import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { NetworkLogo } from '../NetworkLogo'

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
  & > .network__button {
    display: none;
  }
  & > .create__button {
    display: none;
  }
  @media screen and (max-width: 560px) {
    & > .network__button {
      display: flex;
    }
    & > .create__button {
      display: flex;
    }
  }
`

export const OwnerLogin = styled.div`
  font-weight: 700;
`

export const BalanceBlock = styled.div`
  display: grid;
  grid-template-columns: 48px auto;
  grid-gap: 24px;
`

export const AvatarBlock = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: fit-content;
`

export const Network = styled(NetworkLogo)`
  position: absolute;
  right: -4px;
  bottom: -4px;
`

export const BalanceInfo = styled.div`
  display: block;
  white-space: break-spaces;
`

export const BalanceText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-light);
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BalanceNumber = styled.p`
  font-weight: 700;
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: var(--line-day);
`

export const MenuLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
`

export const StyledButton = styled(Link)`
  font-weight: 700;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledLink = styled(Link)`
  color: var(--blue);
`
