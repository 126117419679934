import { useWeb3React } from '@web3-react/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { BLOCKCHAIN_NAME, SUPPORT_CHAIN_NAME } from '../../../domain/network/contstant'
import { NETWORK_ICONS } from '../../../domain/network/contstant/icons'
import { AppNetwork } from '../../../domain/network/types'
import { useRollapp } from '../../../hooks/useRollapp'
import { selectMetamaskNetwork } from '../../../services/metamask'
import { Popover } from '../Popover'
import { Button, Wrapper } from './styles'

export const NetworkSelector = (): JSX.Element => {
  const { chainId } = useWeb3React()
  const [show, setShow] = useState(false)
  const { network, setNetwork } = useRollapp()
  const history = useHistory()

  const selectNetwork = async (networkName: AppNetwork) => {
    if (chainId) {
      const status = await selectMetamaskNetwork(networkName)
      if (status) {
        setNetwork(networkName)
        setShow(false)
      }
    } else {
      history.push('/login')
    }
  }

  return (
    <Wrapper>
      <Button
        onClick={() => setShow(!show)}
        size={36}
        icon={NETWORK_ICONS[network] || NETWORK_ICONS.eth}
      />
      {show && (
        <Popover top="25px" p="16px" close={() => setShow(false)}>
          {Object.keys(SUPPORT_CHAIN_NAME).map((key) => (
            <p
              key={key}
              onClick={() => selectNetwork(key as AppNetwork)}
              style={{ fontWeight: key === network ? 700 : 600 }}
            >
              {BLOCKCHAIN_NAME[SUPPORT_CHAIN_NAME[key]]}
            </p>
          ))}
        </Popover>
      )}
    </Wrapper>
  )
}
