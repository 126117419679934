import { IS_TESTNET } from '../../../config/rollapp'

export const MERCEDES_AMG_GTS = IS_TESTNET
  ? '22847037604832116773906763181943495684969485916138401108664810502585464127489'
  : '99326131137483886891004264430325786609158860882489678491178031342521990250497'

export const SUPER_MARIO_BROS = IS_TESTNET
  ? '22847037604832116773906763181943495684969485916138401108664810502585464127489'
  : '99326131137483886891004264430325786609158860882489678491178031342521990250497'

// TODO
export const MUSIC_CLUB_ID =
  '99326131137483886891004264430325786609158860882489678491178031342521990250497'

export const DAVOS_TOKEN_ID =
  '99326131137483886891004264430325786609158860882489678491178031342521990250497'

export const WINES_WITH_ROYALTIES_TOKEN_IDS = [
  '48193376455309515967050522751845148421088194490049922871417546149085602381834',
  '48193376455309515967050522751845148421088194490049922871417546149085602381835',
  '48193376455309515967050522751845148421088194490049922871417546149085602381836',
  '48193376455309515967050522751845148421088194490049922871417546149085602381837',
  '48193376455309515967050522751845148421088194490049922871417546149085602381838',
  '48193376455309515967050522751845148421088194490049922871417546149085602381839',
  '48193376455309515967050522751845148421088194490049922871417546149085602381840',
  '48193376455309515967050522751845148421088194490049922871417546149085602381841',
  '48193376455309515967050522751845148421088194490049922871417546149085602381842',
  '48193376455309515967050522751845148421088194490049922871417546149085602381843'
]

type PunkId = {
  [key in TokenContractId]: number
}

export const PUNK_ID: PunkId = {
  '18': 386,
  '19': 481,
  '20': 558,
  '21': 583,
  '22': 605,
  '23': 670,
  '24': 723,
  '25': 749,
  '26': 955,
  '27': 1061,
  '28': 1088,
  '29': 1154,
  '30': 1339,
  '31': 1362,
  '32': 1607,
  '33': 1654,
  '34': 1695,
  '35': 1706,
  '90': 1853,
  '91': 2177,
  '92': 2241,
  '93': 2257,
  '94': 2286,
  '95': 2308,
  '96': 2453,
  '97': 2607,
  '98': 2630,
  '99': 2662,
  '100': 2666,
  '101': 2928,
  '102': 3251,
  '103': 3329,
  '104': 3471,
  '105': 3479,
  '106': 3497,
  '107': 3540,
  '126': 3566,
  '127': 3599,
  '128': 3629,
  '129': 3664,
  '130': 4061,
  '131': 4176,
  '132': 4312,
  '133': 4419,
  '134': 4503,
  '135': 4598,
  '136': 4628,
  '137': 4647,
  '138': 4737,
  '139': 4884
}
