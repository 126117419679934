import React from 'react'

import { SubTitle } from '../styled'

export const ItRemainsAbout = (): JSX.Element => (
  <>
    At the RollApp NFT Shop in Davos, you may purchase a one-of-a-kind NFT from the show. Author
    Vasil Tuchkov and veteran Pixar art director Antoni Hristov collaborated on the It Remains
    collection of &quot;beyond dystopian&quot; NFTs. This is a beautifully created collection of
    NFTs, inspired by environmental issues and accompanied by a graphic novel, that attendees will
    have the opportunity to add to their portfolios. The show includes 7,777 hand-drawn and randomly
    produced elements from the graphic novel. The right to decide the destiny of the graphic novel
    and the possibility of acquiring action figures signed graphic novels, and physical prints come
    with owning an It Remains NFT.
  </>
)

export const ItRemains = (): JSX.Element => (
  <>
    <SubTitle>It Remains</SubTitle>
    <p>
      The beyond dystopian universe of &quot;It Remains,&quot; born of the team&apos;s enthusiasm
      for art and technology, intends to alter the way the undying craft of storytelling is
      promoted, distributed, and developed across new generation mediums and audiences through
      collaboration and innovation. Collectors will have the opportunity to acquire excellent art -
      genuine pieces from a complex story that has already taken two years to complete. The
      community of NFT holders will administer the decentralized franchise, and they will have
      voting rights over significant decisions affecting &quot;It Remains,&quot; future initiatives.
      This includes metaverse projects, games, and animated programs.
    </p>
  </>
)
