import React from 'react'

import { useETHBalance } from '../../../hooks/useETHBalance'
import { usePoolBalance } from '../../../hooks/usePoolBalance'
import { Web3Type } from '../../../types/web3'
import { Modal } from '../../common/Modal'
import { Loading } from '../../components'
import { Wrapper } from '../../components/ModalStyles'
import { Content } from './Content'

export const RechargePoolModal = ({ web3 }: { web3: Web3Type }): JSX.Element => {
  const poolBalanceBn = usePoolBalance(web3)
  const balanceEthBn = useETHBalance(web3)

  return (
    <Modal title="Recharge ETH pool">
      <Wrapper>
        {poolBalanceBn && balanceEthBn ? (
          <Content poolBalanceBn={poolBalanceBn} balanceEthBn={balanceEthBn} web3={web3} />
        ) : (
          <Loading />
        )}
      </Wrapper>
    </Modal>
  )
}
