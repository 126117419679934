import React from 'react'

import { getOrderByOwner, Token } from '../../../domain'
import { clientV3 } from '../../../services/api'
import { signMessage } from '../../../services/sign'
import { Web3Type } from '../../../types/web3'
import { Modal } from '../../common/Modal'
import { CancelBtn } from '../../components/CancelBtn'
import { Description, Wrapper } from '../../components/ModalStyles'
import { StatusBlock } from '../../components/StatusBlock'

export const CancelBidModal = ({ web3, token }: { web3: Web3Type; token: Token }): JSX.Element => {
  const { account } = web3

  const cancelHandle = async (): Promise<boolean> => {
    try {
      const { data } = await clientV3.token.offers(token.id)
      const offer = getOrderByOwner(data, account)
      if (offer && offer.id) {
        const signature = await signMessage(
          `I want to cancel the order - ${offer.hash}`,
          account,
          web3.library
        )
        await clientV3.exchange.cancel(offer, signature, account)
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  return (
    <Modal title="Cancel bid" width="350px">
      <Wrapper>
        <Description>
          Do you really want to cancel bid for <span>{token.name}</span> from{' '}
          <span>{token.collection.name}</span> collection?
        </Description>
        <StatusBlock name="Cancel bid" method={() => cancelHandle()} />
        <CancelBtn />
      </Wrapper>
    </Modal>
  )
}
