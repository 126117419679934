import React from 'react'

const Marketplace = (): JSX.Element => (
  <svg width="32" height="36" viewBox="0 0 32 36" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H1.06667C0.477563 0 0 0.478745 0 1.06931V34.9307C0 35.5213 0.477564 36 1.06667 36H29.5647C30.515 36 30.9909 34.8482 30.319 34.1746L22.0034 25.8384C21.6592 25.4934 21.128 25.4309 20.6909 25.6458C19.2752 26.3419 17.6831 26.7327 16 26.7327C10.109 26.7327 5.33333 21.9452 5.33333 16.0396C5.33333 10.134 10.109 5.34653 16 5.34653C21.891 5.34653 26.6667 10.134 26.6667 16.0396C26.6667 17.7269 26.2768 19.323 25.5825 20.7421C25.3681 21.1803 25.4304 21.7128 25.7746 22.0579L28.1503 24.4394C28.6169 24.9071 29.3931 24.8431 29.7323 24.2758C31.1722 21.8684 32 19.0509 32 16.0396C32 7.18118 24.8366 0 16 0Z" />
    <path d="M16 21.3861C18.9455 21.3861 21.3333 18.9924 21.3333 16.0396C21.3333 13.0868 18.9455 10.6931 16 10.6931C13.0545 10.6931 10.6667 13.0868 10.6667 16.0396C10.6667 18.9924 13.0545 21.3861 16 21.3861Z" />
  </svg>
)

export default Marketplace
