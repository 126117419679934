import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  width: 100%;
  padding: 32px 0 15px;
  background: var(--table-bg);
  position: relative;
  z-index: 1;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 1256px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 36px;
    max-width: 648px;
    gap: 14px;
  }
  @media screen and (max-width: 475px) {
    padding: 0 20px;
  }
`

export const FooterMainBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 17px;
  border-bottom: 1px solid var(--gray-line);
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 21px;
  }
`

export const MainLeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const LogoLink = styled(Link)`
  width: 100%;
  max-width: 143px;
  & img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
`

export const SocialsLinks = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 24px;
  width: fit-content;
  align-items: center;
  height: 36px;
  @media screen and (max-width: 768px) {
    height: 24px;
    grid-gap: 48px;
  }
`

export const SocialLink = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;

  & svg {
    width: 24px;
    height: auto;
    fill: var(--gray-icon);
  }

  &:hover svg {
    fill: var(--text-gray);
  }

  &:hover span {
    display: block;
  }
`

export const MainRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column-reverse;
    gap: 29px;
  }
`

export const FooterText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: var(--gray-icon);
  max-width: 523px;
  @media screen and (max-width: 1024px) {
    max-width: 380px;
  }
  @media screen and (max-width: 768px) {
    max-width: 523px;
  }
`

export const LabelWrapper = styled.label`
  width: 100%;
  max-width: 401px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  @media screen and (max-width: 768px) {
    max-width: 600px;
  }
`

export const SubsribeInput = styled.input`
  background: var(--table-bg);
  border: 1px solid var(--gray-line);
  box-sizing: border-box;
  border-radius: 20px;
  transition: 0.2s border-color;
  height: 36px;
  display: block;
  font-size: 13px;
  line-height: 28px;
  padding: 4px 20px;
  font-weight: 400;
  color: var(--gray-icon);
  width: 100%;
  max-width: 285px;

  &::placeholder {
    color: var(--text-gray);
  }

  &:focus {
    border-color: var(--blue);
  }
  @media screen and (max-width: 768px) {
    max-width: 460px;
  }
`

export const SubscribeButton = styled.button`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  color: var(--black);
`

export const FooterBottomText = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: var(--gray-icon);
  @media screen and (max-width: 768px) {
    max-width: 600px;
    text-align: center;
  }
`

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  @media screen and (max-width: 475px) {
    max-width: 475px;
  }
`

export const StyledInput = styled.input`
  background: var(--white);
  border: 1px solid var(--line-day);
  box-sizing: border-box;
  border-radius: 40px;
  transition: 0.2s border-color;
  height: 40px;
  width: 100%;
  display: block;
  font-size: 12px;
  line-height: 14px;
  padding: 12px 98px 12px 14px;
  font-weight: 400;
  color: var(--text-dark);

  &::placeholder {
    color: var(--text-gray);
  }

  &:focus {
    border-color: var(--blue);
  }
`

export const VertLine = styled.div`
  width: 1px;
  height: 100%;
  background: var(--line-day);
`

export const Popup = styled.span`
  display: none;
  position: absolute;
  width: 146px;
  top: -24px;
  left: 50%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-dark);
  padding: 24px;
  background: var(--white);
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transform: translate(-50%, -100%);
`
