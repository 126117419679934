import React from 'react'

import {
  CasualLink,
  Container,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import { questions } from './content'
import { QuestionCard } from './QuestionCard'
import { DescriptionBlock, QuestionsBlock } from './styled'

export const QuestionSection = (): JSX.Element => {
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SectionTitle>Frequently asked questions</SectionTitle>
          <DescriptionBlock>
            <SectionDescription>Your most common questions, answered.</SectionDescription>
            <SectionDescription>
              Missing something? Ask us here{' '}
              <CasualLink href="mailto:info@curioinvest.com" target="_blank">
                info@curioinvest.com
              </CasualLink>
            </SectionDescription>
          </DescriptionBlock>
        </SectionHeader>
        <QuestionsBlock>
          {questions.map((item) => (
            <QuestionCard key={item.question} question={item.question} answer={item.answer} />
          ))}
        </QuestionsBlock>
      </Container>
    </SectionWrapper>
  )
}
