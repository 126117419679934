import React, { useEffect, useState } from 'react'

import { PreviewProfile } from '../../domain'
import { clientV3 } from '../../services/api'
import { Loading } from '../../ui/components'
import { ButtonWrapper, CardsWrap } from '../../ui/components/Cards/styles'
import { NoData } from '../../ui/components/NoData'
import { UserCard } from '../../ui/components/UserCard'

export const SearchUsers = ({ query }: { query: string }): JSX.Element => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [end, setEnd] = useState(false)
  const [users, setUsers] = useState<PreviewProfile[]>([])

  useEffect(() => {
    setLoading(true)
    clientV3.search.users(query, page).then((res) => {
      setUsers([...users, ...res.data.items])
      setEnd(res.data.pagination.pages === page)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [page, query])

  return (
    <div>
      {users.length > 0 ? (
        <CardsWrap columns={3}>
          {users.map((user) => (
            <UserCard key={user.walletAddress} user={user} />
          ))}
        </CardsWrap>
      ) : (
        !loading && <NoData />
      )}
      {loading && <Loading />}
      {users.length > 0 && !loading && !end && (
        <ButtonWrapper>
          <button className="button primary-button" onClick={() => setPage(page + 1)}>
            Load more
          </button>
        </ButtonWrapper>
      )}
    </div>
  )
}
