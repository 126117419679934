import styled from 'styled-components'

import loginBG from '../../assets/img/login-bg.png'

export const LoginContainer = styled.div`
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: 23% 1fr;
  grid-gap: 100px;
  background: var(--gray);
  @media (max-width: 1216px) {
    grid-template-columns: 20% 1fr;
  }

  @media (max-width: 916px) {
    grid-template-columns: 1fr;
  }
`

export const LoginLeftSide = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  background: url(${loginBG}) right top no-repeat;
  background-size: cover;

  @media (max-width: 916px) {
    display: none;
  }
`

export const LoginContent = styled.div`
  display: block;
  padding: 100px 96px 200px 0;
  max-width: 972px;

  @media (max-width: 916px) {
    padding: 49px 20px;
  }
`

export const LoginTitle = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
`

export const LoginDescription = styled.p`
  color: var(--text-gray);
  margin-bottom: 48px;
`

export const LoginWallets = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-bottom: 48px;
  @media (max-width: 1216px) {
    grid-template-columns: 1fr 1fr;
    max-width: 576px;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

export const LoginDisclaimer = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-gray);
`
