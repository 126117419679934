import React from 'react'

export const PencilIcon = (): JSX.Element => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1018_23800)">
      <path d="M16.3 8.286l-.65-.375.65.375zm-6.403 11.09l.65.375-.65-.375zm-.559.451l.23.714-.23-.713zm-3.016-1.741l-.734.158.734-.158zm.43 1.998l.732-.158-.733.158zm.641.37l-.23-.713.23.714zm6.092-13.793l-6.402 11.09-1.3-.75 6.403-11.09 1.3.75zm-4.237 12.34L15.65 7.91l1.3.75-6.403 11.09-1.3-.75zm-2.193-1.073l.43 1.998-1.467.315-.43-1.997 1.467-.316zm.108 1.813l1.945-.627.46 1.427-1.945.627-.46-1.427zM15.65 7.91a1.25 1.25 0 00-.457-1.707l.75-1.3a2.75 2.75 0 011.006 3.757l-1.299-.75zm-5.103 11.84a1.75 1.75 0 01-.979.79l-.46-1.427a.25.25 0 00.14-.113l1.299.75zm-3.464-2a.25.25 0 00-.028.177l-1.467.316A1.75 1.75 0 015.784 17l1.299.75zm.401 2.175a.25.25 0 00-.32-.185l.46 1.427a1.25 1.25 0 01-1.606-.927l1.466-.315zm4.702-14.015a2.75 2.75 0 013.757-1.006l-.75 1.299a1.25 1.25 0 00-1.708.457l-1.299-.75z" />
      <path d="M12 6.454l-.75 1.299 4.619 2.666.75-1.299z" />
      <rect x="12" y="19.763" width="8" height="1.5" rx=".75" />
    </g>
    <defs>
      <clipPath id="clip0_1018_23800">
        <path transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
