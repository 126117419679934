import Web3 from 'web3'
import { provider } from 'web3-core'

export const signMessage = async (
  message: string,
  account: string,
  provider: provider
): Promise<Signature> => {
  const web3 = new Web3(provider)
  return await web3.eth.personal.sign(message, account.toLowerCase(), '')
}
