import React, { useState } from 'react'

import arrowBack from '../../../../assets/img/icons/arrow-back.svg'
import block from '../../../../assets/img/icons/block.svg'
import copyIcon from '../../../../assets/img/icons/copy-black.svg'
import { ListProfile } from '../../../../domain'
import { Avatar } from '../../../../ui/components/Avatar'
import { getProfileLink } from '../../../../utils/getProfileLink'
import { shortAddress } from '../../../../utils/shortAddress'
import { getProfileFromChat } from '../../libs/getProfileFromChat'
import {
  ArrowBack,
  ChatHeader,
  ChatOptions,
  OptionButton,
  OptionIcon,
  SettingsButton,
  UserBlock,
  UserName,
  UserRightBlock
} from '../MessengerHeader/styles'

export const ChatTop = ({
  userAddress,
  list,
  back
}: {
  userAddress: UserAddress
  list: ListProfile[]
  back: () => void
}): JSX.Element => {
  const [showChatOptions, setShowChatOptions] = useState(false)
  const profile = getProfileFromChat(list, userAddress)

  return (
    <ChatHeader>
      <ArrowBack onClick={back}>
        <img src={arrowBack} />
      </ArrowBack>
      <UserBlock to={getProfileLink(profile)}>
        <Avatar width="24px" image={profile.avatarIpfsHash} />
        <UserRightBlock>
          <UserName>{profile.name || shortAddress(profile.walletAddress)}</UserName>
        </UserRightBlock>
      </UserBlock>
      <SettingsButton onClick={() => setShowChatOptions(!showChatOptions)} />
      {showChatOptions && (
        <ChatOptions>
          <OptionButton>
            <OptionIcon>
              <img src={copyIcon} />
            </OptionIcon>
            <p>Copy eth address</p>
          </OptionButton>
          <OptionButton>
            <OptionIcon>
              <img src={block} />
            </OptionIcon>
            <p>Block person</p>
          </OptionButton>
        </ChatOptions>
      )}
    </ChatHeader>
  )
}
