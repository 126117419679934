import React from 'react'
import styled from 'styled-components'

import { Modal } from '../../../ui/common/Modal'

const IFrame = styled.iframe`
  width: 100%;
  height: auto;
  min-height: 500px;
`

const VideoModal = (props: { close: () => void }): JSX.Element => (
  <Modal closeHandle={props.close} noPadding={true} width="1000px">
    <IFrame
      src="https://www.youtube.com/embed/w1T845S-I0g"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  </Modal>
)

export default VideoModal
