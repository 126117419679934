import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
  font-weight: 700;
  display: block;
  @media screen and (max-width: 475px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`
