import React from 'react'

import { Switch } from '../Switch'
import { SwitchInput } from '../Switch/styles'
import { Addition, Title, Top, Wrapper } from './styles'

type Props = {
  title: string
  addition: string
  value: boolean
  onChange: () => void
}

export const SwitchBlock = ({ title, addition, onChange, value }: Props): JSX.Element => {
  return (
    <Wrapper>
      <Top>
        <div>
          <Title>{title}</Title>
          <Addition>{addition}</Addition>
        </div>
        <Switch>
          <SwitchInput type="checkbox" checked={value} onChange={onChange} />
        </Switch>
      </Top>
    </Wrapper>
  )
}
