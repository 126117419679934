import Web3 from 'web3'

import { Token } from '../../domain'
import { getLocalUsd } from '../../services/usd'
import { Web3Type } from '../../types/web3'
import { fromWei } from '../../utils/wei'
import { contractError } from '../contractError'
import { balanceOfShares } from '../erc1155/methods'
import { STAKING_ABI } from './abi'
import { STAKING } from './addresses'

export const stake = async (w: Web3Type, quantity: string, unstake?: boolean): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    contractError(STAKING[w.chainId])
    await new web3.eth.Contract(STAKING_ABI, STAKING[w.chainId]).methods[
      unstake ? 'withdraw' : 'deposit'
    ](quantity).send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}

export const pendingReward = async (w: Web3Type): Promise<string> => {
  const web3 = new Web3(w.library)
  try {
    contractError(STAKING[w.chainId])
    const res = await new web3.eth.Contract(STAKING_ABI, STAKING[w.chainId]).methods
      .pendingReward(w.account)
      .call()
    return parseFloat(fromWei(res)).toFixed(2)
  } catch (e) {
    console.error(e)
    return '0'
  }
}

export const userInfo = async (w: Web3Type): Promise<string> => {
  const web3 = new Web3(w.library)
  const res = await new web3.eth.Contract(STAKING_ABI, STAKING[w.chainId]).methods
    .userInfo(w.account)
    .call()
  return res.amount
}

const rewardPerBlock = async (w: Web3Type): Promise<number> => {
  const web3 = new Web3(w.library)
  const res = await new web3.eth.Contract(STAKING_ABI, STAKING[w.chainId]).methods
    .rewardPerBlock()
    .call()
  return parseFloat(fromWei(res))
}

export const getAPY = async (w: Web3Type, token: Token, quantity: string): Promise<string> => {
  const blocks_per_year = 2360479
  const rewards_per_block = await rewardPerBlock(w)
  const total_quantity_staked = parseInt(await balanceOfShares(token, STAKING[w.chainId]))
  const cgt_price_usd = await getLocalUsd()
  const share_price_cgt = 1000 / cgt_price_usd

  const value =
    quantity.length > 0
      ? ((rewards_per_block * blocks_per_year) /
          ((total_quantity_staked + parseInt(quantity)) * share_price_cgt)) *
        100
      : 0

  return value.toFixed(2)
}
