import styled from 'styled-components'

export const InputContainer = styled.div<{ error?: boolean }>`
  border: 1px solid ${(props) => (props.error ? 'var(--red) !important' : 'var(--line-day)')};
  transition: 0.2s border-color;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 0 20px;
  background-color: var(--white);
  font-weight: 400;
  display: flex;
  align-items: center;
  grid-gap: 4px;
  position: relative;

  &::placeholder {
    color: var(--light-gray);
  }

  &:focus {
    border-color: var(--blue);
  }

  input {
    border: none;
    height: 100%;
    padding: 0;
  }
`

export const Input = styled.input<{ error?: boolean }>`
  border: 1px solid ${(props) => (props.error ? 'var(--red) !important' : 'var(--line-day)')};
  transition: 0.2s border-color;
  border-radius: 8px;
  height: 48px;
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 16px;
  padding: 0 20px;
  background-color: var(--white);
  font-weight: 400;

  &::placeholder {
    color: var(--light-gray);
  }

  &:focus {
    border-color: var(--blue);
  }
`
