import React, { useState } from 'react'

import { Loader } from '../../../../ui/components'
import { PencilIcon } from '../../../../ui/icons/Pencil'
import { useMessenger } from '../../context/useMessenger'
import { AddChatModal } from '../AddChatModal'
import { ChatBody } from '../ChatBody'
import { ChatCard } from '../ChatCard'
import { DeleteModal } from '../DeleteModal'
import { MessageHeader } from '../MessengerHeader'
import { ReportModal } from '../ReportModal'
import { SettingsModal } from '../SettingsModal'
import {
  AddChatButton,
  Chats,
  MessengerBody,
  MessengerWrapper,
  SignInText,
  SignInWindow
} from './styles'

export const Messenger = ({ closeMessenger }: { closeMessenger: () => void }): JSX.Element => {
  const loading = false
  const { isSignIn, signIn, chats, setModal, modal, requests } = useMessenger()
  const [tab, setTab] = useState('chats')
  const [chatAccount, setChatAccount] = useState<string | undefined>()

  const openChat = (v: string | undefined) => {
    if (v) {
      setTab('')
      setChatAccount(v)
    }
  }

  return (
    <MessengerWrapper>
      <MessageHeader
        signIn={isSignIn}
        close={closeMessenger}
        tab={tab}
        setTab={(v) => {
          setChatAccount(undefined)
          setTab(v)
        }}
      />
      <MessengerBody>
        {!loading ? (
          isSignIn ? (
            chatAccount ? (
              <ChatBody chatAccount={chatAccount} />
            ) : (
              <Chats>
                {tab === 'chats'
                  ? chats.map((chat) => (
                      <ChatCard
                        key={chat.peer}
                        sender={chat.lastMessage.sender}
                        createdAt={chat.lastMessage.createdAt}
                        message={chat.lastMessage.message}
                        openChat={openChat}
                      />
                    ))
                  : requests.map((request) => (
                      <ChatCard
                        key={request.creator.id}
                        createdAt={request.createdAt}
                        sender={request.creator.ethAddress || request.creator.ss58Address}
                        openChat={openChat}
                      />
                    ))}
                <AddChatButton
                  onClick={() => {
                    setModal('add-chat')
                  }}
                >
                  <PencilIcon />
                </AddChatButton>
              </Chats>
            )
          ) : (
            <SignInWindow>
              <SignInText>Please sign in for using messenger</SignInText>
              <button className="button primary-button" onClick={signIn}>
                Sign
              </button>
            </SignInWindow>
          )
        ) : (
          <Loader />
        )}
      </MessengerBody>
      {modal === 'delete' && <DeleteModal />}
      {modal === 'add-chat' && <AddChatModal />}
      {modal === 'report' && <ReportModal />}
      {modal === 'settings' && <SettingsModal />}
    </MessengerWrapper>
  )
}
