import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const WrapperPage = styled.div`
  width: 100%;
`

export const LandingContainer = styled.main`
  width: 100%;
  max-width: 1356px;
  margin: 0 auto;
  padding: 238px 90px 0;
  position: relative;
  @media screen and (max-width: 1024px) {
    padding: 160px 90px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 60px 90px 0px;
  }
  @media screen and (max-width: 740px) {
    padding: 60px 40px 0px;
  }
`

export const SectionWrapper = styled.section`
  width: 100%;
  &:not(:nth-child(2)) {
    padding-bottom: 200px;
  }
  @media screen and (max-width: 1024px) {
    &:not(:nth-child(2)) {
      padding-bottom: 160px;
    }
  }
  @media screen and (max-width: 768px) {
    &:not(:nth-child(2)) {
      padding-bottom: 120px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: center;
  @media screen and (max-width: 475px) {
    gap: 52px;
  }
`

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 740px) {
    max-width: 576px;
  }
`

export const SectionTitle = styled.h2`
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  max-width: 706px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

export const SectionDescription = styled.p`
  font-weight: 400;
  text-align: center;
  color: var(--text-light);
  max-width: 706px;
`

export const SectionButtons = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: 740px) {
    flex-wrap: wrap;
  }
`

export const LandingButton = styled.button<{ active?: boolean }>`
  max-width: 160px;
  width: 100%;
  color: ${(props) => (props.active ? 'var(--blue)' : 'var(--text-dark)')};
  background: var(--white);
  border: 1px solid ${(props) => (props.active ? 'var(--blue)' : 'var(--text-dark)')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  flex-shrink: 0;
  transition: 0.2s;
  font-size: 16px;
  line-height: 24px;
  border-radius: 32px;
  height: 61px;
  @media screen and (max-width: 740px) {
    max-width: 140px;
  }
`

export const LandingLink = styled.a`
  color: var(--text-dark);
  background: var(--white);
  border: 1px solid var(--text-dark);
  &:hover {
    background: var(--text-dark);
    border: 1px solid var(--text-dark);
    color: var(--white);
  }
`

export const ExploreLink = styled(Link)`
  color: var(--white);
  background: linear-gradient(180deg, #5153ff 0%, #b297ff 100%);
  &:hover {
    background: linear-gradient(180deg, #3537e8 0%, #9a80e3 100%);
  }
`

export const ExploreA = styled.a`
  color: var(--white);
  background: linear-gradient(180deg, #5153ff 0%, #b297ff 100%);
  &:hover {
    background: linear-gradient(180deg, #3537e8 0%, #9a80e3 100%);
  }
`

export const CasualLink = styled.a`
  color: var(--blue);
  text-decoration: underline;
`
