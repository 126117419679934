import React from 'react'

import {
  WalletButtonBlock,
  WalletButtonDescription,
  WalletButtonExtra,
  WalletButtonLogo,
  WalletButtonName,
  WalletButtonRow
} from './styles'

type Props = {
  extra?: string
  name: string
  description: string
  logo: string
  onClick: () => void
  metamaskErr?: boolean
}

export const WalletButton = ({
  onClick,
  extra,
  description,
  name,
  logo,
  metamaskErr
}: Props): JSX.Element => (
  <WalletButtonBlock onClick={onClick}>
    <WalletButtonRow>
      <WalletButtonLogo>
        <img src={logo} alt="icon" />
      </WalletButtonLogo>
      {extra && <WalletButtonExtra>{extra}</WalletButtonExtra>}
    </WalletButtonRow>
    <WalletButtonName>
      {metamaskErr && name === 'Metamask' ? 'Install' : ''} {name}
    </WalletButtonName>
    <WalletButtonDescription>{description}</WalletButtonDescription>
  </WalletButtonBlock>
)
