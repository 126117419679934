import styled from 'styled-components'

export const CardWrapper = styled.button`
  width: 100%;
  display: flex;
  gap: 28px;
  border-bottom: 1px solid var(--black);
  outline: none;
  padding: 32px 0;
  // max-width: 706px;
`

export const CardIconBlock = styled.div<{ open: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transform: ${(props) => (props.open ? 'rotate(-180deg)' : 'rotate(0deg)')};
  & > img {
    width: 100%;
    height: auto;
  }
`

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: left;
`

export const CardQuestion = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
`

export const CardAnswer = styled.p`
  font-weight: 400;
`
