import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { Link } from 'react-router-dom'

import cgtAqua from '../../../assets/img/tokens/cgt-aqua.svg'
import { NETWORK_ICONS } from '../../../domain/network/contstant/icons'
import { useActiveWeb3React } from '../../../hooks/useActiveWeb3React'
import { useCGTBalance } from '../../../hooks/useCGTBalance'
import { useRollapp } from '../../../hooks/useRollapp'
import { disconnectLS } from '../../../services'
import { shortAddress } from '../../../utils/shortAddress'
import { PriceUI } from '../../common/PriceUI'
import { AddCGTContract } from '../AddCGTContract'
import { Avatar } from '../Avatar'
import {
  AvatarBlock,
  BalanceBlock,
  BalanceInfo,
  BalanceText,
  Line,
  MenuLinks,
  MenuWrapper,
  Network,
  OwnerLogin,
  StyledButton,
  StyledLink
} from './styles'

export const AccountMenu = ({ name }: { name: UserName }): JSX.Element => {
  const web3 = useActiveWeb3React()
  const { deactivate } = useWeb3React()
  const { setModal, network } = useRollapp()
  const balance = useCGTBalance(web3)

  const disconnect = () => {
    disconnectLS()
    deactivate()
  }

  return (
    <MenuWrapper>
      <OwnerLogin>
        <p>{shortAddress(web3.account, 11, 4)}</p>
        <p>{name ? name : <StyledLink to="/settings">Set display name</StyledLink>}</p>
      </OwnerLogin>
      <BalanceBlock>
        <AvatarBlock>
          <Avatar width="48px" src={cgtAqua} />
          <Network icon={NETWORK_ICONS[network] || NETWORK_ICONS.eth} size={25} />
        </AvatarBlock>
        <BalanceInfo>
          <BalanceText>
            Balance <AddCGTContract />
          </BalanceText>
          {balance && <PriceUI value={balance} />}
        </BalanceInfo>
      </BalanceBlock>
      <button className="button primary-button block-button" onClick={() => setModal('buy-cgt')}>
        Buy CGT
      </button>
      {network === 'skale' ? (
        <button
          className="button header__button network__button block-button"
          onClick={() => setModal('get-sketh')}
        >
          Get skETH
        </button>
      ) : (
        <button
          className="button header__button network__button block-button"
          onClick={() => setModal('recharge-pool')}
        >
          ETH Pool
        </button>
      )}
      <Link
        className="button primary-button header__button create__button block-button"
        to="/create"
      >
        Create
      </Link>
      <Line />
      <MenuLinks>
        <StyledButton to="/my-items">My items</StyledButton>
        <StyledButton to="/settings">Edit profile</StyledButton>
        <StyledButton to="#" onClick={disconnect}>
          Disconnect
        </StyledButton>
      </MenuLinks>
    </MenuWrapper>
  )
}
