import styled from 'styled-components'

export const StepBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
`

export const ProgresBox = styled.div`
  display: flex;
  align-items: center;
`

export const Circle = styled.div<{ status?: string }>`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex-shrink: 0;
  background: ${(props) =>
    props.status === 'done'
      ? 'var(--blue)'
      : props.status === 'error'
      ? 'var(--error)'
      : 'var(--line-day)'};
  & img {
    width: ${(props) => (props.status === 'error' ? '14' : '20')}px;
    height: auto;
  }
`

export const StepInfo = styled.div`
  margin-left: 24px;
  display: block;
`

export const StepType = styled.p`
  width: fit-content;
  font-weight: 700;
  margin-bottom: 4px;
`

export const StepDescription = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: var(--text-gray);
`
