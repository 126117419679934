import { useWeb3React } from '@web3-react/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { IS_TESTNET } from '../../../config/rollapp'
import { OWNER_ERC1155 } from '../../../contracts/erc1155/constants'
import { CHAIN_ID } from '../../../domain/network/contstant'
import { useRollapp } from '../../../hooks/useRollapp'
import { useDaoToken } from '../../../hooks/useTokenData'
import { Modals } from '../../../types/modalTypes'
import termsMario from '../../../ui/common/Terms/terms-mario'
import termsText from '../../../ui/common/Terms/text'
import { TermsModal } from './TermsModal'

export const Wrapper = styled.footer<{ open?: boolean }>`
  width: 100%;
  padding: 16px 0;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0px -12px 20px rgba(100, 123, 157, 0.1);
  margin-top: auto;
  position: ${(props) => (props.open ? 'relative' : 'sticky')};
  bottom: 0;
  left: 0;
`

export const Buttons = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 16px;
`

export const OldPrice = styled.span`
  text-decoration: line-through;
  padding-left: 5px;
`

const BuyFooter = (props: { isMario?: boolean; terms?: { local: string } }): JSX.Element => {
  const [terms, setTerms] = useState(false)
  const history = useHistory()
  const { account } = useWeb3React()
  const { openToken } = useRollapp()
  const newPrice = '45$'
  const oldPrice = '50$'

  const owner = OWNER_ERC1155[IS_TESTNET ? CHAIN_ID.eth.testnet : CHAIN_ID.eth.mainnet]

  const token = useDaoToken(props.isMario)
  const open = (modal: Modals) => {
    if (account) {
      openToken(modal, token, owner)
    } else {
      history.push('/login')
    }
  }

  const buyShares = () => {
    if (props.terms) {
      if (!localStorage.getItem(props.terms.local)) {
        setTerms(true)
      } else {
        openToken('checkout', token, owner)
      }
    } else {
      openToken('checkout', token, owner)
    }
  }

  const accept = () => {
    openToken('checkout', token, owner)
    setTerms(false)
  }

  return (
    <Wrapper open={terms}>
      <Buttons>
        {props.isMario ? (
          <button className="primary-button button" onClick={buyShares}>
            Buy shares {newPrice} <OldPrice>{oldPrice}</OldPrice>
          </button>
        ) : (
          <>
            <button className="primary-button button" onClick={() => open('stake-shares')}>
              Stake
            </button>
            <button className="button" onClick={() => open('unstake-shares')}>
              Unstake
            </button>
            <button className="primary-button button" onClick={() => open('claim-rewards')}>
              Claim rewards
            </button>
          </>
        )}
      </Buttons>
      {props.terms && terms && (
        <TermsModal
          local={props.terms.local}
          terms={props.isMario ? termsMario : termsText}
          accept={accept}
        />
      )}
    </Wrapper>
  )
}

export default BuyFooter
