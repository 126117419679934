import styled from 'styled-components'

export const ExploreContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 68px;
  width: 100%;
  margin-top: 49px;
  padding-bottom: 60px;
  @media screen and (max-width: 475px) {
    grid-gap: 36px;
  }
`

export const TopsSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px 24px;
  width: 100%;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 475px) {
    grid-gap: 36px 24px;
  }
`

export const Title = styled.div`
  display: flex;
  grid-gap: 24px;
  align-items: center;

  h2 {
    font-size: inherit;
  }
`

export const CategoriesBox = styled.div`
  display: flex;
  gap: 12px;
`
