import React from 'react'

import { Token } from '../../../domain'

export const TokenImage = ({ token }: { token: Token }): JSX.Element =>
  token.videoUrl ? (
    <video playsInline autoPlay controls={false} loop muted>
      <source src={token.videoS3Url || token.videoUrl} type="video/mp4" />
    </video>
  ) : (
    <img src={token.imageS3Url || token.imageUrl} alt={`${token.name} token`} />
  )
