import { useEffect, useState } from 'react'

import { claimed } from '../contracts/bonus/methods'
import { claimed as nftClaimed, collections } from '../contracts/nft-lottery'
import { Claimed } from '../contracts/nft-lottery/types'
import { Token } from '../domain'
import { Web3Type } from '../types/web3'

export const useClaimed = (web3: Web3Type, token: Token, trigger?: boolean): string | undefined => {
  const [value, setValue] = useState<string | undefined>()

  useEffect(() => {
    claimed(web3, token.tokenId).then((res) => {
      setValue(res)
    })
  }, [web3, token, trigger])

  return value
}

export const useNFTPrizeCollections = (web3: Web3Type): boolean | undefined => {
  const [value, setValue] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    collections(web3).then((res) => {
      setValue(!!res)
    })
  }, [web3])

  return value
}

export const useNFTPrizeClaimed = (
  web3: Web3Type,
  token: Token,
  trigger: boolean,
  collections: boolean | undefined
): Claimed | undefined => {
  const [value, setValue] = useState<Claimed | undefined>()

  useEffect(() => {
    if (collections !== undefined) {
      nftClaimed(web3, token).then((res) => {
        setValue(res)
      })
    }
  }, [web3, token, trigger, collections])

  return value
}
