import React from 'react'

import davosLogo from './assets/DAVOS.svg'
import { DAVOS_CARDS } from './content'
import { DavosCard } from './DavosCard'
import {
  LinkCards,
  PageContainer,
  TitleBlock,
  TitleDescription,
  TitleLogo,
  WrapperPage
} from './styled'

export const Davos = (): JSX.Element => (
  <WrapperPage>
    <PageContainer className="container">
      <TitleBlock>
        <TitleLogo>
          <img src={davosLogo} />
        </TitleLogo>
        <TitleDescription>Bring digital culture to physical assets</TitleDescription>
      </TitleBlock>
      <LinkCards>
        {DAVOS_CARDS.map((card) => (
          <DavosCard key={card.url} item={card} />
        ))}
      </LinkCards>
    </PageContainer>
  </WrapperPage>
)
