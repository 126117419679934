import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 292px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    height: 226px;
  }
`

export const WrappperProfileBackground = styled.div`
  position: relative;
`

export const CoveringProfileBackground = styled.label`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(16, 37, 62, 0.8);
  z-index: 2;
  top: 0;
  overflow: hidden;
  cursor: pointer;
`
