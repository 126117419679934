import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router'

import { getMinPrice, haveFixedPrice, isOwned, isTokenOnSale, onSale, Token } from '../../../domain'
import { isWrapped } from '../../../domain/collection/mappers/wrapped.mapper'
import { useRollapp } from '../../../hooks/useRollapp'
import { Modals } from '../../../types/modalTypes'
import { Wrapper, WrapperSale } from './styles'

const buttonClasses = (primary?: boolean): string =>
  `button block-button ${primary && 'primary-button'}`

export const Actions = ({ token }: { token: Token }): JSX.Element => {
  const { account } = useWeb3React()
  const history = useHistory()
  const { openToken } = useRollapp()
  const minPrice = getMinPrice(token, account)

  const isOwnedToken = isOwned(token, account)
  const onSaleToken =
    token.standard === 'ERC721'
      ? onSale(token.ownerships)
      : !!token.ownerships.find(
          (owner) =>
            owner.ownerAddress.toLowerCase() === account?.toLowerCase() &&
            owner.status !== 'NOT_FOR_SALE'
        )

  const open = (modal: Modals) => {
    if (account) {
      openToken(modal, token, account)
    } else {
      history.push('/login')
    }
  }

  return (
    <Wrapper>
      {isOwnedToken &&
        isWrapped(token.contract, token.collection.network) &&
        !onSale(token.ownerships) && (
          <button className="button block-button" onClick={() => open('redeem')}>
            Redeem
          </button>
        )}
      <WrapperSale>
        {(!isOwnedToken || token.standard === 'ERC1155') &&
          haveFixedPrice(token.ownerships) &&
          minPrice && (
            <button className={buttonClasses(true)} onClick={() => open('checkout')}>
              Buy for {minPrice} CGT
            </button>
          )}
        {isOwnedToken && !onSaleToken && (
          <button className={buttonClasses(true)} onClick={() => open('put-on-sale')}>
            Put on sale
          </button>
        )}
        {isOwnedToken && isTokenOnSale(token, account) && (
          <button className={buttonClasses()} onClick={() => open('remove-from-sale')}>
            Remove from sale
          </button>
        )}
        {(!isOwnedToken || token.standard === 'ERC1155') && (
          <button
            className={buttonClasses()}
            onClick={() => open(token.exchange.isBid ? 'edit-bid' : 'place-bid')}
          >
            {token.exchange.isBid ? 'Edit' : 'Place a'} bid
          </button>
        )}
      </WrapperSale>
    </Wrapper>
  )
}
