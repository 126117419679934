import styled from 'styled-components'

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 258px;
  background: var(--white);
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
`

export const CardImage = styled.div`
  width: 100%;
  height: 114px;
  overflow: hidden;
  border-radius: 32px 32px 0 0;
  background: radial-gradient(
    71.49% 384.37% at 12.43% 20.14%,
    #ffac2f 0%,
    #ffc46c 60.94%,
    #ffd18d 100%
  );
  & > img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  @media screen and (max-width: 586px) {
    height: 89px;
  }
`

export const CardInfo = styled.div`
  width: 100%;
  padding: 42px 16px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const AvatarBlock = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  align-items: center;
  & > div:first-child {
    z-index: 3;
  }
  & > div:nth-child(2) {
    z-index: 2;
    margin-left: -20px;
  }
`

export const OrganizationName = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 28px;
  text-align: center;
  color: var(--text-gray);
`

export const ProjectName = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: var(--text-dark);
`
