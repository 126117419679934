import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useState } from 'react'

import { useRollapp } from '../../../hooks/useRollapp'
import { WrongNetwork } from '../../modals/WrongNetwork'

export const Wrong = (): JSX.Element => {
  const { error } = useWeb3React()
  const [wrong, setWrong] = useState(false)
  const { setModal } = useRollapp()

  useEffect(() => {
    if (error?.message.includes('Unsupported chain id')) {
      setWrong(true)
      setModal(undefined)
    } else {
      setWrong(false)
    }
    // eslint-disable-next-line
  }, [error])
  return <>{wrong && <WrongNetwork />}</>
}
