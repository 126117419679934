import styled from 'styled-components'

import copy_icon from '../../../assets/img/icons/copy.svg'

export const Wrapper = styled.div`
  display: inline-flex;
  gap: 11px;
  align-items: center;
  margin-bottom: 8px;
`

export const AddressText = styled.p`
  color: var(--text-light);
`

export const Copy = styled.button`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(${copy_icon}) no-repeat center;
  background-size: contain;
  vertical-align: middle;
  padding: 0;
`
