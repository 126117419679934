import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 12px;
  margin-top: 24px;
`

export const StyleLink = styled.a`
  display: flex;
  align-items: center;
`

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`

export const Username = styled.span`
  font-weight: 700;
`
