import carsImg from '../assets/img/gifs/cars.jpg'
import carsGif from '../assets/img/gifs/cars.mp4'
import clockImg from '../assets/img/gifs/clock.jpg'
import clockGif from '../assets/img/gifs/clock.mp4'
import mario from '../assets/img/gifs/mario.mp4'
import marioImg from '../assets/img/gifs/mario.svg'
import nikeImg from '../assets/img/gifs/nike.jpg'
import nikeGif from '../assets/img/gifs/nike.mp4'
import season3Video from '../assets/img/gifs/season-3.mp4'
import season3Img from '../assets/img/gifs/season-3.svg'
import winesImg from '../assets/img/gifs/wines.jpg'
import winesGif from '../assets/img/gifs/wines.mp4'

export type Collection = {
  text: string
  background: string
  video?: string
  link?: string
}

export const HOT_COLLECTIONS: Collection[] = [
  {
    text: 'Super Mario Bros.',
    link: '/dao-nfts/super-mario-bros',
    background: `url(${marioImg})`,
    video: mario
  },
  {
    text: 'Founders Edition: Season 3',
    link: '/season-3',
    background: `url(${season3Img})`,
    video: season3Video
  },
  {
    text: 'Curio Assets Sneakers',
    video: nikeGif,
    link: `/collection/curio-sneakers/owned`,
    background: `url(${nikeImg})`
  },
  {
    text: 'Curio Assets Wines',
    link: `/collection/curio-wines/owned`,
    video: winesGif,
    background: `url(${winesImg})`
  },
  {
    text: 'Curio Assets Watches',
    video: clockGif,
    link: `/collection/curio-watches/owned`,
    background: `url(${clockImg})`
  },
  {
    text: 'Curio Tokenized Cars',
    video: carsGif,
    link: `/collection/ct-nft/owned`,
    background: `url(${carsImg})`
  },
  {
    text: 'Real estate \n (coming soon)',
    background: 'radial-gradient(100% 100% at 0% 4.09%, #5259ff 29.88%, #ff2559 73.63%)'
  },
  {
    text: 'Art \n (coming soon)',
    background: 'radial-gradient(96.36% 96.36% at 50% 0%, #65b5ff 29.88%, #4325ff 100%)'
  }
]
