import { useEffect, useState } from 'react'

import { latestAnswer } from '../contracts/chainlink/methods'

export const useChainlinkPrice = (): string => {
  const [value, setValue] = useState('')

  useEffect(() => {
    latestAnswer().then((res) => {
      setValue(res)
    })
  })

  return `${value}$`
}
