import styled from 'styled-components'

import options from '../../../../assets/img/icons/dots-dark.svg'

export const MessageBlock = styled.div`
  width: 100%;
`

export const MessageWrapper = styled.div<{ out: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: ${(props) => (props.out ? 'auto' : 'unset')};
  gap: 8px;
  & > p {
    text-align: ${(props) => (props.out ? 'right' : 'left')};
    margin-left: ${(props) => (props.out ? 'unset' : '64px')};
  }
  & .menu-button {
    opacity: 0;
    grid-column: ${(props) => (props.out ? '2' : '3')};
  }
  &:hover .menu-button {
    opacity: 1;
  }
  & .options {
    right: ${(props) => (props.out ? '-16px' : '0')};
  }
`

export const MessageTop = styled.div<{ out: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => (props.out ? 'auto 16px' : '48px auto 16px')};
  gap: 4px;
  margin-right: ${(props) => (props.out ? '-20px' : '0')};
  align-items: flex-start;
  justify-content: ${(props) => (props.out ? 'flex-end' : '')};
  & > div:first-child {
    margin-top: ${(props) => (props.out ? 'unset' : 'auto')};
  }
  & > .message-cloud {
    background: ${(props) => (props.out ? 'var(--blue)' : 'var(--second-gray)')};
    color: ${(props) => (props.out ? 'var(--white)' : 'var(--text-dark)')};
    margin-left: ${(props) => (props.out ? 'unset' : '12px')};
    grid-column: ${(props) => (props.out ? '1' : '2')};
  }
`

export const Cloud = styled.div`
  width: fit-content;
  padding: 15px 16px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`

export const MessageButton = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  flex-shrink: 0;
  background: url(${options}) center no-repeat;
  background-size: 100%;
  transform: rotate(90deg);
`

export const MessageDate = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: var(--text-gray);
`
