import { useWeb3React } from '@web3-react/core'
import { useParams } from 'react-router-dom'

type Params = {
  address: string | undefined
  tab: string | undefined
}

export const useParamsAddress = (): { address: AccountAddress; tab: string | undefined } => {
  const { account } = useWeb3React()
  const { address, tab } = useParams<Params>()

  return {
    address: address || account,
    tab
  }
}
