import styled from 'styled-components'

export const MaxBtn = styled.button`
  background-color: var(--blue);
  border-radius: 16px;
  color: var(--white);
  font-size: 12px;
  padding: 4px 8px;
  position: absolute;
  z-index: 2;
  top: 46px;
  right: 12px;
`
