import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledButton = styled(Link)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 700;
  background: none;
  & img {
    margin-right: 8px;
    width: 24px;
    height: auto;
  }
`

type Props = {
  to: string
  text: string
  img: string
}

const BrowseLink = (props: Props): JSX.Element => {
  return (
    <StyledButton to={props.to}>
      <img src={props.img} />
      <span>{props.text}</span>
    </StyledButton>
  )
}

export default BrowseLink
