import React from 'react'
import styled from 'styled-components'

export type ValueType = {
  title: string
  value: string
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 24px 36px;
  grid-row-gap: 8px;
  justify-content: center;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
`

const Value = styled.span`
  font-size: 28px;
  line-height: 48px;
`

const ValueBlock = (props: ValueType): JSX.Element => {
  return (
    <Wrapper>
      <span>{props.title}</span>
      <Value>{props.value}</Value>
    </Wrapper>
  )
}

export default ValueBlock
