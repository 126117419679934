import { useWeb3React } from '@web3-react/core'
import React from 'react'
import { useHistory } from 'react-router'

import {
  isMercedesCollection,
  isMigrateCollection,
  isOldCollection,
  isOwned,
  isTokenOnSale,
  Token
} from '../../../domain'
import { getMovingNetworkName } from '../../../domain/network/mappers'
import { useMovingCollection } from '../../../hooks/useMovingCollection'
import { useRollapp } from '../../../hooks/useRollapp'
import { Modals } from '../../../types/modalTypes'
import { MercedesMenu } from './MercedesMenu'
import { CardLine, CardMenu, CardMenuItem } from './styles'

export const TokenMenu = ({ token }: { token: Token }): JSX.Element => {
  const { openToken } = useRollapp()
  const { account } = useWeb3React()
  const onSale = isTokenOnSale(token, account)
  const isOld = isOldCollection(token)
  const history = useHistory()
  const isMoving = useMovingCollection(
    isOwned(token, account),
    token.collection.network,
    token.collection.ethAddress
  )

  const openModal = (modal: Modals) => {
    openToken(modal, token)
  }

  const newBid = () => {
    if (account) {
      openModal('place-bid')
    } else {
      history.push('/login')
    }
  }

  return (
    <CardMenu>
      {isOwned(token, account) && (
        <>
          {!onSale ? (
            <CardMenuItem onClick={() => openModal('put-on-sale')}>Put on sale</CardMenuItem>
          ) : (
            <>
              {token.minPrice && (
                <CardMenuItem onClick={() => openModal('change-price')}>Change price</CardMenuItem>
              )}
              <CardMenuItem onClick={() => openModal('remove-from-sale')}>
                Remove from sale
              </CardMenuItem>
            </>
          )}
          <CardLine />
          <CardMenuItem onClick={() => openModal('transfer')}>Transfer</CardMenuItem>
          {isMigrateCollection(token) && (
            <CardMenuItem onClick={() => openModal('migrate')}>
              {isOld ? 'Migrate to new' : 'Migrate to old'}
            </CardMenuItem>
          )}
          {isMoving && (
            <CardMenuItem onClick={() => openModal('move-to-network')}>
              Move to {getMovingNetworkName(token.collection.network)}
            </CardMenuItem>
          )}
        </>
      )}
      {!token.exchange.isBid ? (
        <CardMenuItem onClick={newBid}>New bid</CardMenuItem>
      ) : (
        <>
          <CardMenuItem onClick={() => openModal('edit-bid')}>Edit bid</CardMenuItem>
          <CardMenuItem onClick={() => openModal('cancel-bid')}>Cancel bid</CardMenuItem>
        </>
      )}
      {isMercedesCollection(token) && <MercedesMenu openModal={openModal} />}
    </CardMenu>
  )
}
