import styled from 'styled-components'

export const ModalForm = styled.form`
  width: 100%;
`

export const ModalInput = styled.input`
  display: flex;
  overflow: auto;
  align-items: center;
  width: 100%;
  padding: 10px 15px 10px 15px;
  border: 1px solid var(--line-day);
  border-radius: 8px;
  font-size: 13px;
  line-height: 15px;
  &::placeholder {
    color: var(--text-gray);
  }
`
