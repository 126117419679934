import React from 'react'

import { Card, Extra, Icon } from './styles'

type Props = {
  children: JSX.Element
  onClick?: () => void
  title: string
  extra?: string
  icon: () => JSX.Element
}

export const RadioCard = ({ onClick, extra, title, icon, children }: Props): JSX.Element => {
  return (
    <Card onClick={onClick ? onClick : undefined}>
      {children}
      <div>
        <Icon>{React.createElement(icon)}</Icon>
        <span>{title}</span>
        {extra && <Extra>{extra}</Extra>}
      </div>
    </Card>
  )
}
