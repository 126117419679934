import React from 'react'

import { Category } from '../../../domain'
import { ASSETS } from '../../../domain/categories/constants'
import { NewBadgePosition, VertLine, WrapperBadge } from './styles'

export const Badge = ({ category }: { category: Category }): JSX.Element => {
  return (
    <WrapperBadge green={category.tag !== 'season-1'}>
      <span>{category.name}</span>
      <VertLine />
      <span>{ASSETS[category.tag]} Assets</span>
    </WrapperBadge>
  )
}

export const NewBadge = (): JSX.Element => {
  return (
    <NewBadgePosition>
      <WrapperBadge green>New</WrapperBadge>
    </NewBadgePosition>
  )
}
