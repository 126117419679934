import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { useWeb3React } from '@web3-react/core'
import React, { useEffect, useRef, useState } from 'react'

import { ListProfile } from '../domain'
import { getProfiles } from '../domain/profile/mappers/profiles'
import { api, baseURL } from '../modules/Messenger/api'
import { Chat, ChatEvent, ChatResponse } from '../modules/Messenger/interfaces/messenger'
import { createChatHandle } from '../modules/Messenger/libs/createChatHandle'
import {
  getMessengerSignByAccount,
  setMessengerSignes
} from '../modules/Messenger/services/messenger'
import { signMessage } from '../services/sign'

type TabType = 'chats' | 'requests'
type ModalType = '' | 'add-chat' | 'report' | 'delete' | 'settings'

type ContextType = {
  loading: boolean
  signIn: boolean
  tab: TabType
  modal: ModalType
  chat: Chat | undefined
  chats: ChatResponse[]
  profileList: ListProfile[]
  closeChat: () => void
  setTab: (tab: TabType) => void
  setModal: (modal: ModalType) => void
  submitMessage: (text: string, chatId: number) => void
  createChat: (to: UserAddress) => void
  getChatById: (id: number, address: UserAddress) => void
  connectToChat: () => void
}

const defaultValues: ContextType = {
  loading: true,
  signIn: false,
  chat: undefined,
  tab: 'chats',
  modal: '',
  chats: [],
  profileList: [],
  closeChat: () => null,
  setTab: () => null,
  setModal: () => null,
  submitMessage: () => null,
  createChat: () => null,
  getChatById: () => null,
  connectToChat: () => null
}

export const MessengerContext = React.createContext<ContextType>(defaultValues)

export const MessengerProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { library, account } = useWeb3React()

  const [sign, setSign] = useState('')
  const [signIn, setSignIn] = useState(defaultValues.signIn)
  const [loading, setLoading] = useState(defaultValues.loading)
  const [connection, setConnection] = useState<HubConnection>()

  const [tab, setTab] = useState<TabType>(defaultValues.tab)
  const [modal, setModal] = useState<ModalType>(defaultValues.modal)

  const [chat, setChat] = useState<Chat | undefined>(defaultValues.chat)
  const [chats, setChats] = useState<ChatResponse[]>(defaultValues.chats)
  const [profileList, setProfileList] = useState<ListProfile[]>(defaultValues.profileList)

  // for event listener chat
  const chatRef = useRef<Chat | undefined>(undefined)
  chatRef.current = chat

  const submitMessage = async (text: string, chatId: number) => {
    if (connection && account && chat) {
      await connection.invoke('chat.message.send', { text, chatId })
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          { date: new Date().toString(), text, fromId: account, id: 0, out: true, forwarded: null }
        ]
      })
    }
  }

  const getChats = async (account: UserAddress) => {
    const { data } = await api.chat.all(account, sign)
    if (data) {
      setChats(data.items)
    }
  }

  const getChatById = async (chatId: number, address: UserAddress) => {
    if (account) {
      try {
        const { data } = await api.chat.get(chatId, account, sign)
        setChat({ chatId, to: address, messages: data.items.reverse() })
      } catch (e) {
        setChat(undefined)
        console.log(e)
      }
    }
  }

  const createChat = async (to: UserAddress) => {
    if (!!account && !!sign) {
      setLoading(true)
      const chatId = await createChatHandle(to, account, sign)
      if (chatId) {
        const listData = await getProfiles([to])
        setChat({ chatId, to, messages: [] })
        if (listData[0]) {
          setProfileList([...profileList, listData[0]])
        }
      } else {
        try {
          const chat = chats.filter(
            (chat) => chat.peer.memberId.toLowerCase() === to.toLowerCase()
          )[0]
          if (chat) {
            await getChatById(chat.peer.chatId, to)
          }
        } catch (e) {
          console.error(e)
        }
      }
      setLoading(false)
      setModal('')
    }
  }

  const closeChat = async () => {
    setChat(undefined)
    if (account) {
      await getChats(account)
    }
  }

  const connectToChat = async () => {
    if (!!account) {
      const sign = await signMessage('I want to connect to messenger', account, library)
      setSign(sign)
      setMessengerSignes({ sign, signer: account })
    }
  }

  useEffect(() => {
    if (account) {
      const signByAccount = getMessengerSignByAccount(account)
      setSign(signByAccount?.sign || '')
    }
  }, [account])

  useEffect(() => {
    ;(async () => {
      setLoading(!!account)
      setChat(undefined)
      if (connection) {
        connection.off('chat.message.send:listen')
      }

      if (!!account && !!sign) {
        const connection = new HubConnectionBuilder()
          .withUrl(`${baseURL}//ws/chat?x-Wallet-signature=${sign}&x-eth-address=${account}`)
          .configureLogging(LogLevel.Information)
          .build()

        try {
          await connection.start()
          await getChats(account)
          connection.on('chat.message.send:listen', (data: ChatEvent) => {
            console.log('chat.message.send:listen ', data)
            if (
              chatRef.current &&
              chatRef.current.chatId === data.peer.conversationId &&
              data.peer.fromId.toLowerCase() !== account.toLowerCase()
            ) {
              const chat = [...chatRef.current.messages]
              setChat({
                chatId: chatRef.current.chatId,
                to: chatRef.current.to,
                messages: [
                  ...chat,
                  {
                    id: 0,
                    out: data.peer.fromId.toLowerCase() === account?.toLowerCase(),
                    fromId: data.peer.fromId,
                    forwarded: null,
                    date: data.date,
                    text: data.text
                  }
                ]
              })
            }
          })
          setSignIn(true)
          setConnection(connection)
        } catch (e) {
          console.error('messenger error', e)
          setSignIn(false)
          setConnection(undefined)
        }
      } else if (!sign) {
        setSignIn(false)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line
  }, [sign])

  useEffect(() => {
    if (!!chats.length) {
      setLoading(true)
      getProfiles(chats.map((chat) => chat.peer.memberId)).then((res) => {
        setProfileList(res)
        setLoading(false)
      })
    }
  }, [chats])

  return (
    <MessengerContext.Provider
      value={{
        signIn,
        loading,
        profileList,
        tab,
        setTab,
        modal,
        setModal,
        chat,
        chats,
        closeChat,
        getChatById,
        submitMessage,
        createChat,
        connectToChat
      }}
    >
      {children}
    </MessengerContext.Provider>
  )
}
