import React from 'react'

import { Description } from '../../../components/ModalStyles'

export const NFTPrizeDescription = (): JSX.Element => (
  <Description>
    Win a prize of one NFT from the following collections:{' '}
    <a href="https://www.adidas.com/into_the_metaverse" target="_blank" rel="noreferrer">
      Adidas Originals
    </a>{' '}
    and{' '}
    <a href="https://www.theindifferentduck.com/" target="_blank" rel="noreferrer">
      The Indifferent Duck
    </a>
    . Those who have purchased Curio Founders Edition NFT Season 3 have the opportunity to
    participate in the prize NFT lottery. Each Curio Founders Edition NFT Season 3 can participate
    in the lottery only once. The lottery is held on the rollapp.store platform on the SKALE
    network. Prize NFT can be transferred from the SKALE network to Ethereum using the rollapp.store
    platform.
  </Description>
)
