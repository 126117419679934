import React from 'react'

import EventBlockDecor from '../../../ui/components/EventBlockDecor'
import { DAO_DATA, TimelineType } from '../data/data'
import {
  EventBlock,
  EventDate,
  EventText,
  Inner,
  ItemName,
  PageContainer,
  PageName,
  TimelineBlock,
  Wrapper
} from './styled'

const Timeline = (props: { tag: string; timeline: TimelineType }): JSX.Element => {
  const { hashtag } = DAO_DATA[props.tag]
  const { timeline } = props

  return (
    <Inner id="timeline" className="slide">
      <Wrapper>
        <PageContainer>
          <ItemName>#{hashtag}</ItemName>
          <PageName className="h2">Timeline</PageName>
          <TimelineBlock>
            {timeline.events.map((item) => (
              <EventBlock key={item.text}>
                <EventDate>{item.date}</EventDate>
                <EventBlockDecor />
                <EventText>{item.text}</EventText>
              </EventBlock>
            ))}
          </TimelineBlock>
        </PageContainer>
      </Wrapper>
    </Inner>
  )
}

export default Timeline
