import styled from 'styled-components'

export const Wrapper = styled.ul`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  li {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
