import styled from 'styled-components'

import close from '../../../assets/img/icons/close.svg'

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  display: flex;
  overflow: auto;
  padding: 24px 40px;
  background: rgba(16, 37, 62, 0.8);
`

export const Inner = styled.div<{ width?: string; padding?: string }>`
  border-radius: 24px;
  ${(props) => !props.padding && 'overflow: hidden;'}
  background-color: var(--white);
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}` : '709px')};
  margin: auto;
  padding: ${(props) => (props.padding ? props.padding : '24px')};

  iframe {
    border-radius: 24px;
  }
`

export const Header = styled.header<{ over?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 36px;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  ${(props) => props.over && 'height: 0; margin-bottom: 0;'}
`

export const Close = styled.button<{ over?: boolean }>`
  width: 36px;
  height: 36px;
  display: block;
  border-radius: 50%;
  border: 1px solid var(--line-day);
  background-color: var(--white);
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${close});
  background-size: 9px;
  ${(props) =>
    props.over &&
    `
    position: absolute;
    top: 0;
    right: -48px;
    z-index: 3;
  `}
  &:hover {
    border: 1px solid var(--text-dark);
  }
`

export const Title = styled.h5`
  font-size: 26px;
  line-height: 32px;
  font-weight: 700;
`

export const WrapperCentered = styled.div`
  display: grid;
  grid-row-gap: 24px;
  text-align: center;
  justify-items: center;
`

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 75vh;
`
