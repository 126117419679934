import { CHAIN_ID } from '../../domain/network/contstant'

export const TRANSFER_PROXY = {
  [CHAIN_ID.eth.mainnet]: '0x8E6f1f12C01AAf87A283DF8C4Bc9eb71b5Dbf764',
  [CHAIN_ID.eth.testnet]: '0x3D525833328412473f9Abe22898616B46715f055',
  [CHAIN_ID.skale.testnet]: '0xF634093Ac0e3b465f4a2ea89086670168Cd4E29F',
  [CHAIN_ID.skale.mainnet]: '0x15FaB6A057d836BF73A7D85b441CAA4a8F4540A5',
  [CHAIN_ID.harmony.testnet]: '0x2634023a25D3C8D6E73dD1eCa0411325aA0BF57F',
  [CHAIN_ID.kovan.testnet]: '0xfa0C79EfE8341465Bea7633F167c64d61aC9F4d6'
}
