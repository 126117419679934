import React from 'react'

import { Container, SectionHeader, SectionTitle, SectionWrapper } from '../styled'
import { rows } from './content'
import { TableCell, TableContainer, TableListBlock, TableListWrapper, TableRow } from './styled'

export const TableListSection = (): JSX.Element => {
  return (
    <SectionWrapper>
      <TableContainer>
        <SectionHeader>
          <SectionTitle>NFT marketplace comparison</SectionTitle>
        </SectionHeader>
        <TableListWrapper>
          <TableListBlock className="highlight">
            {rows.map((row, index) => (
              <TableRow className="table-row highlight" key={index}>
                {row.cells.map((cell, index) => (
                  <TableCell className={`table-cell ${cell.check}`} key={index}>
                    {cell.value && cell.value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableListBlock>
        </TableListWrapper>
      </TableContainer>
    </SectionWrapper>
  )
}
