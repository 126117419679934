import Web3 from 'web3'

import { isOldCollection, Token } from '../../domain'
import { Web3Type } from '../../types/web3'
import { MIGRATOR_ABI } from './abi'
import { MIGRATOR } from './addresses'

export const migrateTo = async (w: Web3Type, token: Token): Promise<boolean> => {
  const web3 = new Web3(w.library)
  const contract = new web3.eth.Contract(MIGRATOR_ABI, MIGRATOR[w.chainId])
  try {
    await contract.methods[isOldCollection(token) ? 'migrateToNew' : 'migrateToOld'](
      token.tokenId
    ).send({
      from: w.account
    })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
