import React from 'react'
import { useLocation } from 'react-router-dom'

import mario from '../../media/mario-mini.svg'
import { Tab } from '../Tab'
import { Tab_Type } from '../Tab/types'
import ferrari from './../../../../assets/img/shares/ferrari-mini.png'
import mercedes from './../../../../assets/img/shares/mercedes-mini.png'
import tesla from './../../../../assets/img/shares/tesla-mini.png'

export const Tabs = (): JSX.Element => {
  const location = useLocation()
  const { pathname } = location
  const isCars = pathname.includes('mercedes-amg-gts') || pathname.includes('tesla-roadster')
  const current = isCars
    ? 'Cars'
    : pathname.includes('season-2')
    ? 'Founders edition'
    : pathname.includes('super-mario-bros')
    ? 'Geek zone'
    : null

  const tabs: Tab_Type[] = [
    {
      type: `Cars`,
      disabled: false,
      links: [
        {
          name: `Mercedes AMG GTS`,
          image: mercedes,
          status: `Now trading`,
          link: '/dao-nfts/mercedes-amg-gts'
        },
        {
          name: `Ferrari F12tdf`,
          image: ferrari,
          status: `soon`,
          link: '/dao-nfts/mercedes-amg-gts',
          disabled: true
        },
        {
          name: `Tesla Roadster`,
          image: tesla,
          status: 'Waiting list',
          link: '/dao-nfts/tesla-roadster'
        }
      ]
    },
    {
      type: `Real estate`,
      disabled: true,
      links: []
    },
    {
      type: 'Geek zone',
      disabled: false,
      links: [
        {
          name: 'Super Mario Bros.',
          image: mario,
          status: 'Now trading',
          link: '/dao-nfts/super-mario-bros'
        }
      ]
    }
  ]
  return (
    <div>
      {tabs.map((item) => (
        <Tab item={item} key={item.type} isOpenValue={item.type === current} />
      ))}
    </div>
  )
}
