import { shortAddress } from '../../../utils/shortAddress'
import { ListProfile } from '../types/Profile'

export const DEFAULT_PROFILE = (address: EthAddress): ListProfile => ({
  walletAddress: address,
  name: shortAddress(address),
  avatarIpfsHash: null,
  avatarS3Link: null,
  id: `${address}-no-sign-user`,
  type: 'user',
  network: null,
  url: null
})
