import Web3 from 'web3'

import { Web3Type } from '../../types/web3'
import { contractError } from '../contractError'
import { ERC20_ABI } from './abi'
import { CGT, CT1, ERC20_TRANSFER_PROXY } from './addresses'

export const balanceOfCGT = async (w: Web3Type): Promise<string | undefined> => {
  try {
    contractError(CGT[w.chainId])
    const web3 = new Web3(w.library)
    return await new web3.eth.Contract(ERC20_ABI, CGT[w.chainId]).methods
      .balanceOf(w.account)
      .call()
  } catch (e) {
    console.error(e)
    return undefined
  }
}

export const balanceOfCT1 = async (w: Web3Type): Promise<string> => {
  const web3 = new Web3(w.library)
  return await new web3.eth.Contract(ERC20_ABI, CT1[w.chainId]).methods.balanceOf(w.account).call()
}

export const balanceOfETH = async (w: Web3Type): Promise<string> => {
  const web3 = new Web3(w.library)
  return await web3.eth.getBalance(w.account)
}

export const allowance = async (
  w: Web3Type,
  price: PriceBN,
  account?: UserAddress
): Promise<boolean> => {
  const web3 = new Web3(w.library)
  const value = await new web3.eth.Contract(ERC20_ABI, CGT[w.chainId]).methods
    .allowance(account || w.account, ERC20_TRANSFER_PROXY[w.chainId])
    .call()
  return BigInt(price) <= BigInt(value)
}

export const approve = async (w: Web3Type, price: PriceBN): Promise<boolean> => {
  const web3 = new Web3(w.library)
  try {
    await new web3.eth.Contract(ERC20_ABI, CGT[w.chainId]).methods
      .approve(ERC20_TRANSFER_PROXY[w.chainId], price)
      .send({ from: w.account })
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
