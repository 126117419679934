import React from 'react'

import { Modal } from '../common/Modal'

export const BuyCGTModal = (): JSX.Element => (
  <Modal title="Buy CGT" width="1440px">
    <iframe
      frameBorder="0"
      style={{ height: 'calc(100vh - 156px)', width: '100%' }}
      src="https://capitaldex.exchange/#/swap?outputCurrency=0xf56b164efd3cfc02ba739b719b6526a6fa1ca32a"
    />
  </Modal>
)
