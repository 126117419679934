import styled from 'styled-components'

export const QuotesBottomSlider = styled.div`
  width: 100%;
  overflow: hidden;
`

export const QuoteUserBlock = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: column;
`

export const QuotesNameSlider = styled.div`
  width: 100%;
  padding: 0 24px;
  overflow: hidden;
`

export const UserNameBlock = styled.div`
  width: 100%;
  flex-shrink: 0;
  text-align: center;
  margin-top: 16px;
`

export const UserName = styled.p`
  font-weight: 700;
`

export const StyledLink = styled.a`
  display: flex;
  flex-shrink: 0;
  margin: 0 auto;
  align-items: center;
  font-weight: 700;
  & img {
    margin-right: 8px;
    width: 24px;
    height: auto;
  }
`

export const SliderInner = styled.div`
  display: flex;
  width: 100%;
  transition: 0.4s transform;

  & > div {
    flex-shrink: 0;
    width: 100%;
  }

  & > .slide-media {
    height: calc(100vh - 330px);
  }

  & > .slide-media > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

export const QuotesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const QuoteBlock = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 46px;
`

export const QuotesIcons = styled.div`
  width: 50px;
  height: 50px;
  margin-bottom: 46px;
  & img {
    width: 100%;
    height: auto;
  }
`

export const QuoteText = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
`

export const QuotesButtons = styled.div`
  width: 100%;
  max-width: 224px;
  margin-bottom: 46px;
  display: flex;
  justify-content: space-between;
`
