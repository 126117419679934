import { CHAIN_ID } from '../../domain/network/contstant'

export const TEAM_ADDRESS = {
  [CHAIN_ID.eth.mainnet]: '0x94ddecAFF0109b615e51C482e07312abce704042',
  [CHAIN_ID.eth.testnet]: '0x3282f70c169ae4dDF6DaBD23f1811f540C4CfaE9',
  [CHAIN_ID.skale.testnet]: '0xB844C65F3E161061bA5D5dD8497B3C04B71c4c83',
  [CHAIN_ID.skale.mainnet]: '0x7F98e37810bC94AB5a0311038072420D85BF5274',
  [CHAIN_ID.harmony.testnet]: '0xB844C65F3E161061bA5D5dD8497B3C04B71c4c83',
  [CHAIN_ID.harmony.mainnet]: '0x7F98e37810bC94AB5a0311038072420D85BF5274'
}

// deprecated
export const MULTICALL = {
  [CHAIN_ID.eth.mainnet]: '0xa616239a69b16a49f8DfA446687BeBBB6775769A',
  [CHAIN_ID.eth.testnet]: ''
}
