import React, { useState } from 'react'

import {
  Container,
  LandingButton,
  SectionButtons,
  SectionDescription,
  SectionHeader,
  SectionTitle,
  SectionWrapper
} from '../styled'
import { buyers, investors, sellers } from './content'
import { CardContent, CardIcon, CardsBlock, CardText, CardTitle, CardWrapper } from './styled'

export const FAQSection = (): JSX.Element => {
  const [cards, setCards] = useState(buyers)
  return (
    <SectionWrapper>
      <Container>
        <SectionHeader>
          <SectionTitle>
            The more responsible way to build your crypto portfolio with valuable physical items
          </SectionTitle>
          <SectionDescription>
            As the global leader in co-ownership, Rollapp makes it simple for anyone to store
            physical assets on-chain by transforming real-world collectibles into Physically-Backed
            NFTs.
          </SectionDescription>
          <SectionButtons>
            <LandingButton active={cards === buyers} onClick={() => setCards(buyers)}>
              For buyers
            </LandingButton>
            <LandingButton active={cards === investors} onClick={() => setCards(investors)}>
              For investors
            </LandingButton>
            <LandingButton active={cards === sellers} onClick={() => setCards(sellers)}>
              For sellers
            </LandingButton>
          </SectionButtons>
        </SectionHeader>
        <CardsBlock>
          {cards.map((card) => (
            <CardWrapper key={card.title}>
              <div>
                <CardIcon>
                  <img src={card.icon} />
                </CardIcon>
              </div>
              <CardContent>
                <CardTitle>{card.title}</CardTitle>
                <CardText>{card.text}</CardText>
              </CardContent>
            </CardWrapper>
          ))}
        </CardsBlock>
      </Container>
    </SectionWrapper>
  )
}
